import React from 'react'
import './styles/moduleHtml.scss'

function ModuleHtml(props){
  let{module} = props
  return (
    <div className="module-html" dangerouslySetInnerHTML={{ __html: module.url }}>
    </div>
  )
}
export default ModuleHtml
