import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import MODAL_ACTIONS from '../../modals/state/modalActions'
import './dealer-center.scss'
import SearchInventoryFilterBox from '../../../common/common-components/searchInventoryFilterBox'
import SEARCH_PAGE_ACTIONS from '../search-page/state/searchPageActions'



//images
import DeviceImg1 from '../../../common/common-assets/images/dealer-center-device1.png'
import DeviceImg2 from '../../../common/common-assets/images/dealer-center-device2.png'
import DeviceImg3 from '../../../common/common-assets/images/dealer-center-device3.png'
import DeviceImg4 from '../../../common/common-assets/images/iPacket_HomePage_WebImages_LanguageTranslation_v3.png'
import DeviceImg5 from '../../../common/common-assets/images/iPacket_HomePage_WebImages_AI-Enabled-Content_v1.png'
import DeviceImg6 from '../../../common/common-assets/images/iPacket_HomePage_WebImages_ChromeExt_v3.png'
import LogoBG from '../../../common/common-assets/images/dealer-center-logobg.png'
import ZoomMeeting from '../../../common/common-assets/images/dealer-center-zoom.png'
import PlayBtn from '../../../common/common-assets/icons/dc_play.js'
import EyeIcon from '../../../common/common-assets/icons/dc_eye.js'
import WebsiteIcon from '../../../common/common-assets/icons/dc_website.js'
import FolderIcon from '../../../common/common-assets/icons/dc_folder.js'
import CarIcon from '../../../common/common-assets/icons/dc_car.js'
import ReviewStar from '../../../common/common-assets/icons/dc_star.js'
import Windowsticker_example_opacity25 from '../../../common/common-assets/images/windowsticker_example_opacity25.png'
import Ipacket_Statsgraphic_A_Webengagement from '../../../common/common-assets/images/iPacket_StatsGraphic_A_WebEngagement.png'
import Ipacket_Statsgraphic_B_CustomerViews from '../../../common/common-assets/images/iPacket_StatsGraphic_B_CustomerViews.png'
import Ipacket_Statsgraphic_C_SessionDuration from '../../../common/common-assets/images/iPacket_StatsGraphic_C_SessionDuration.png'
import Ipacket_Statsgraphic_D_WebEngagement from '../../../common/common-assets/images/iPacket_StatsGraphic_D_WebEngagement.png'
import Recon from '../../../common/common-assets/images/ipacket_recon_example_page.png'
//Dealer Logos
import AlfaLogo from '../../../common/common-assets/images/manufacturer-logos/Alfa.png'
import AudiLogo from '../../../common/common-assets/images/manufacturer-logos/Audi.png'
import BentleyLogo from '../../../common/common-assets/images/manufacturer-logos/Bentley.png'
import BMWLogo from '../../../common/common-assets/images/manufacturer-logos/BMW.png'
import BuickLogo from '../../../common/common-assets/images/manufacturer-logos/Buick.png'
import CadillacLogo from '../../../common/common-assets/images/manufacturer-logos/Cadillac.png'
import ChevroletLogo from '../../../common/common-assets/images/manufacturer-logos/Chevrolet.png'
import ChryslerLogo from '../../../common/common-assets/images/manufacturer-logos/Chrysler.png'
import DodgeLogo from '../../../common/common-assets/images/manufacturer-logos/Dodge.png'
import FiatLogo from '../../../common/common-assets/images/manufacturer-logos/Fiat.png'
import FordLogo from '../../../common/common-assets/images/manufacturer-logos/Ford.png'
import GenesisLogo from '../../../common/common-assets/images/manufacturer-logos/Genesis.png'
import GMCLogo from '../../../common/common-assets/images/manufacturer-logos/GMC.png'
import HyundaiLogo from '../../../common/common-assets/images/manufacturer-logos/Hyundai.png'
import InfinitiLogo from '../../../common/common-assets/images/manufacturer-logos/Infiniti.png'
import JaguarLogo from '../../../common/common-assets/images/manufacturer-logos/Jaguar.png'
import JeepLogo from '../../../common/common-assets/images/manufacturer-logos/Jeep.png'
import KiaLogo from '../../../common/common-assets/images/manufacturer-logos/Kia.png'
import LandRoverLogo from '../../../common/common-assets/images/manufacturer-logos/LandRover.png'
import LexusLogo from '../../../common/common-assets/images/manufacturer-logos/Lexus.png'
import LincolnLogo from '../../../common/common-assets/images/manufacturer-logos/Lincoln.png'
import MazdaLogo from '../../../common/common-assets/images/manufacturer-logos/Mazda.png'
import MercedesLogo from '../../../common/common-assets/images/manufacturer-logos/Mercedes.png'
import MiniLogo from '../../../common/common-assets/images/manufacturer-logos/Mini.png'
import NissanLogo from '../../../common/common-assets/images/manufacturer-logos/Nissan.png'
import RAMLogo from '../../../common/common-assets/images/manufacturer-logos/RAM.png'
import SmartLogo from '../../../common/common-assets/images/manufacturer-logos/Smart.png'
import SubaruLogo from '../../../common/common-assets/images/manufacturer-logos/Subaru.png'
import ToyotaLogo from '../../../common/common-assets/images/manufacturer-logos/Toyota.png'
import VolvoLogo from '../../../common/common-assets/images/manufacturer-logos/Volvo.png'
import VWLogo from '../../../common/common-assets/images/manufacturer-logos/VW.png'
// recon logo
import ReconLogo from '../../../common/common-assets/images/iPacketRecon_H_W_Large.png'

let ManufacturerLogos = {
  AlfaLogo,
  AudiLogo,
  BentleyLogo,
  BMWLogo,
  BuickLogo,
  CadillacLogo,
  ChevroletLogo,
  ChryslerLogo,
  DodgeLogo,
  FiatLogo,
  FordLogo,
  GenesisLogo,
  GMCLogo,
  HyundaiLogo,
  InfinitiLogo,
  JaguarLogo,
  JeepLogo,
  KiaLogo,
  LandRoverLogo,
  LexusLogo,
  LincolnLogo,
  MazdaLogo,
  MercedesLogo,
  MiniLogo,
  NissanLogo,
  RAMLogo,
  SmartLogo,
  SubaruLogo,
  ToyotaLogo,
  VolvoLogo,
  VWLogo,
}

function sideScroll(element,direction,speed,distance,step){
    var scrollAmount = 0;
    var slideTimer = setInterval(function(){
        if(direction == 'left'){
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
}

const DealerCenterSegment1 = (props) => {
    const {pushModal} = props
    return(
            <div className="dealer-center-cap dealer-center-header" id="shop_cars">
                <div className="dealer-center-video-background">
                    <video autoPlay muted loop className="dealer-center-video">
                        <source src="https://s3.amazonaws.com/cdn.autoipacket.com/media/videos/iPacket_DealerCenter_HeaderVideo_Reel_4x3_shortened_LowBR.mp4"/>
                    </video>
                </div>
                <div className="dealer-center-content">
                    <h1>The most comprehensive CRM-integrated Digital Vehicle Presentation solution</h1>
                    <h2>Take the guesswork out of car shopping. Start your search right here with iPacket verified car listings.</h2>
                    <SearchInventoryFilterBox
                      searchButtonClassName="ga-marketplace-landing-page-search-button"
                      onSearchAction={()=>{
                        clearSearchPage(false)

                      }}
                    />
                    <div className="dealer-center-cap-buttons">
                        <NavLink to="/schedule-demo" className="dealer-center-cap-button1">
                            BOOK A DEMO
                        </NavLink>
                        <a
                            className="dealer-center-cap-button2"
                            onClick={() => {
                                pushModal({
                                  type: MODAL_ACTIONS.MODAL_CONSTANTS.VIDEO_MODAL,
                                  props: {videoUrl: 'https://player.vimeo.com/video/668768376?h=0973fb265e&title=0&byline=0&portrait=0&autoplay=1'},
                                });
                              }}
                        >
                            <PlayBtn/> WATCH THE VIDEO
                        </a>
                    </div>
                    <h2>Speak with the iPacket team at <a href="tel:+8558939340"><b>(855)893-9340</b></a>!</h2>
                </div>
            </div>
    )
}



const DealerCenterSegment2 = () => {
    return(
            <div className="dealer-center-body1" id="next_level_tech">
                    <h1>What it is, and how iPacket can totally transform your sales process.</h1>
                    <div className="dealer-center-body1-overlay">
                        <button
                            onClick={() => {var container = document.getElementById('dealer-center-body1-row');
                            sideScroll(container,'left',1,2000,10);}}
                            className="dealer-center-body1-row-scrollLeft"
                            id="dealer-center-body1-row-scrollLeft"
                        >
                        &#10094;
                        </button>
                        <button
                            onClick={() => {var container = document.getElementById('dealer-center-body1-row');
                            sideScroll(container,'right',1,2000,10);}}
                            className="dealer-center-body1-row-scrollRight"
                            id="dealer-center-body1-row-scrollRight"
                        >
                        &#10095;
                        </button>
                        <div className="dealer-center-body1-row" id="dealer-center-body1-row" onScroll={(e)=>{
                          if(e.target.scrollLeft>0){
                            document.getElementById('dealer-center-body1-row-scrollLeft').style.visibility = "visible"
                          }
                          else if(e.target.scrollLeft===0){
                            document.getElementById('dealer-center-body1-row-scrollLeft').style.visibility = "hidden"
                          }

                          if(e.target.scrollLeft<(e.target.scrollWidth - e.target.offsetWidth)){
                            document.getElementById('dealer-center-body1-row-scrollRight').style.visibility = "visible"
                          }
                          else if(((e.target.scrollWidth - e.target.offsetWidth)-e.target.scrollLeft)<10){
                            document.getElementById('dealer-center-body1-row-scrollRight').style.visibility = "hidden"
                          }
                        }}>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg1} alt="Desktop View" />
                                </div>
                                <div className="dealer-center-body1-rowitemtxt">
                                    <h2>Digital Vehicle Presentation</h2>
                                    <span>No scanning, uploading or printing. Every document on every car in your inventory is right at your fingertips, and can be easily shared with customers.</span>
                                </div>
                            </div>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg2} alt="Laptop View" />
                                </div>
                                    <div className="dealer-center-body1-rowitemtxt">
                                        <h2>MicroData Heatmapping</h2>
                                        <span>Our real-time insights give you a live view of how your shoppers are engaging with the iPacket. Our heatmaps highlight the most engaged modules, while live view alerts ensure timely follow-up.</span>
                                    </div>
                            </div>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg3} alt="Mobile View" />
                                </div>
                                <div className="dealer-center-body1-rowitemtxt">
                                    <h2>iPacket Mobile App</h2>
                                    <span>Instantly pull, access, share, & track VIN specific documentation for every vehicle directly from your mobile device. Pull window stickers at your fingertips.</span>
                                </div>
                            </div>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg4} alt="Mobile View" />
                                </div>
                                <div className="dealer-center-body1-rowitemtxt">
                                    <h2>Language Translation</h2>
                                    <span>Offer an added level of transparency by serving iPackets in over 5 different languages. English, French, Spanish, Russian, Portuguese, Polish and counting!</span>
                                </div>
                            </div>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg5} alt="Mobile View" />
                                </div>
                                <div className="dealer-center-body1-rowitemtxt">
                                    <h2>AI Enabled Content</h2>
                                    <span>iPacket has worked to add verified OEM stickers to our Chrome extension, and have gone one step further to use predictive AI to pull even more. Dealers can now opt-in to our predictive AI technology to pull a window sticker if not provided. Standardize your communication flow with innovative AI assets including tailored follow-up tools, data pulls and more!</span>
                                </div>
                            </div>
                            <div className="dealer-center-body1-rowitem">
                                <div className="dealer-center-body1-rowitem-img">
                                    <img src={DeviceImg6} alt="Mobile View" />
                                </div>
                                <div className="dealer-center-body1-rowitemtxt">
                                    <h2>Chrome Extension</h2>
                                    <span>Over 30 OEM stickers made available at your fingertips! Seamlessly pull accurate OEM build sheets/window stickers. Unlimited on-demand OEM stickers for your inventory, appraisals, auctions and more.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='next_level_tech'>
                        <h1>As a partner of iPacket, the stats don't lie...</h1>
                    </div>
                    <div className="dealer-center-body1-overlay">
                        <button
                            onClick={() => {var container = document.getElementById('dealer-center-body1-row2');
                            sideScroll(container,'left',1,500,5);}}
                            className="dealer-center-body1-row2-scrollLeft"
                            id="dealer-center-body1-row2-scrollLeft"
                        >
                        &#10094;
                        </button>
                        <button
                            onClick={() => {var container = document.getElementById('dealer-center-body1-row2');
                            sideScroll(container,'right',1,500,5);}}
                            className="dealer-center-body1-row2-scrollRight"
                            id="dealer-center-body1-row2-scrollRight"
                        >
                        &#10095;
                        </button>
                        <div className='dealer-center-body1-row2 dealer-center-body1-stats-graphics' id="dealer-center-body1-row2" onScroll={(e)=>{
                          if(e.target.scrollLeft>0){
                            document.getElementById('dealer-center-body1-row2-scrollLeft').style.visibility = "visible"
                          }
                          else if(e.target.scrollLeft===0){
                            document.getElementById('dealer-center-body1-row2-scrollLeft').style.visibility = "hidden"
                          }

                          if(e.target.scrollLeft<(e.target.scrollWidth - e.target.offsetWidth)){
                            document.getElementById('dealer-center-body1-row2-scrollRight').style.visibility = "visible"
                          }
                          else if(((e.target.scrollWidth - e.target.offsetWidth)-e.target.scrollLeft)<=1){
                            document.getElementById('dealer-center-body1-row2-scrollRight').style.visibility = "hidden"
                          }
                        }}>
                            <div className="dealer-center-body1-row2-rowitem" style={{backgroundImage:`url(${Ipacket_Statsgraphic_A_Webengagement})`}}>
                                <h2>47% lift in website engagement with iPacket <p>®</p> </h2>
                            </div>
                            <div className="dealer-center-body1-row2-rowitem" style={{backgroundImage:`url(${Ipacket_Statsgraphic_B_CustomerViews})`}}>
                                <h2>The average customer views an iPacket <p>®</p> 4 times</h2>
                            </div>
                            <div className="dealer-center-body1-row2-rowitem" style={{backgroundImage:`url(${Ipacket_Statsgraphic_C_SessionDuration})`}}>
                                 <h2>Viewers log session durations in excess of 38 minutes</h2>
                            </div>
                            <div className="dealer-center-body1-row2-rowitem" style={{backgroundImage:`url(${Ipacket_Statsgraphic_D_WebEngagement})`}}>
                                <h2>20% increase in inventory turn with iPacket <p>®</p></h2>
                            </div>
                        </div>
                    </div>
                    <NavLink to="/schedule-demo" className="dealer-center-body1-button">
                        BOOK A DEMO
                    </NavLink>
            </div>
    )
}

const DealerCenterSegment3 = () => {
    let numLogos = Object.keys(ManufacturerLogos).length
    let dynamicLogoStyleString = `
      .dealer-center-logorow-banner{
        height: 100px;
        width: ${numLogos*100}px;
      }
      @keyframes "bannermoveleft" {
       0% {margin-left: 0px;}
       100% {margin-left: -${numLogos*100}px;}
      }

      @-moz-keyframes bannermoveleft {
       0% {margin-left: 0px;}
       100% {margin-left: -${numLogos*100}px;}
      }

      @-webkit-keyframes "bannermoveleft" {
       0% {margin-left: 0px;}
       100% {margin-left: -${numLogos*100}px;}
      }

      @-ms-keyframes "bannermoveleft" {
       0% {margin-left: 0px;}
       100% {margin-left: -${numLogos*100}px;}
      }

      @-o-keyframes "bannermoveleft" {
       0% {margin-left: 0px;}
       100% {margin-left: -${numLogos*100}px;}
      }


      @keyframes "bannermoveright" {
       0% {margin-left: -${numLogos*100}px;}
       100% {margin-left: 0px;}
      }

      @-moz-keyframes bannermoveright {
       0% {margin-left: -${numLogos*100}px;}
       100% {margin-left: 0px;}
      }

      @-webkit-keyframes "bannermoveright" {
       0% {margin-left: -${numLogos*100}px;}
       100% {margin-left: 0px;}
      }

      @-ms-keyframes "bannermoveright" {
       0% {margin-left: -${numLogos*100}px;}
       100% {margin-left: 0px;}
      }

      @-o-keyframes "bannermoveright" {
       0% {margin-left: -${numLogos*100}px;}
       100% {margin-left: 0px;}
      }

    `

    return(
            <div>
                <style>
                {dynamicLogoStyleString}
                </style>
                <div className="dealer-center-logorow" id="oem_window_sticker">
                  <div className="dealer-center-logorow-banner" >
                    {Object.keys(ManufacturerLogos).map((logo,i)=>{
                      return(
                        <img class={`dealer-center-logorow-banner-item${i<1?' dealer-center-logorow-banner-item-first-move-left':''}`} src={ManufacturerLogos[logo]}/>
                      )
                    })}
                    {Object.keys(ManufacturerLogos).map((logo,i)=>{
                      return(
                        <img class={`dealer-center-logorow-banner-item`} src={ManufacturerLogos[logo]}/>
                      )
                    })}
                  </div>
                </div>
                <div className="dealer-center-body2" style={{backgroundImage:`linear-gradient(rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.55)),url(${Windowsticker_example_opacity25})`}}>
                    <h1>Sticker Puller Provides Instant Access to 30+ OEM Automations and 20 Million MSRPS.</h1>
                    <h2>
                        iPacket’s sticker puller can read VIN numbers to instantly pull the vehicle’s original window sticker or build sheet on any site across the internet! Whether you need an OEM sticker for inventory, appraisal, auction, or as a sales tool - iPacket’s system will pull it effortlessly!
                        <br/>
                        <br/>
                        Download our Chrome extension to make sticker searching even more convenient!
                    </h2>
                    <a className="dealer-center-body2-button" href="https://chrome.google.com/webstore/detail/ipacket%C2%AE-sticker-puller/lpanlmnkaioofmijifhpadfcafphopin" target="_blank">
                        CHROME STORE
                    </a>
                    <NavLink to="/schedule-demo" className="dealer-center-body2-button">
                        BOOK A DEMO
                    </NavLink>
                </div>
                <div className="dealer-center-logorow">
                  <div className="dealer-center-logorow-banner" >
                    {Object.keys(ManufacturerLogos).reverse().map((logo,i)=>{
                      return(
                        <img class={`dealer-center-logorow-banner-item${i<1?' dealer-center-logorow-banner-item-first-move-right':''}`} src={ManufacturerLogos[logo]}/>
                      )
                    })}
                    {Object.keys(ManufacturerLogos).reverse().map((logo,i)=>{
                      return(
                        <img class={`dealer-center-logorow-banner-item`} src={ManufacturerLogos[logo]}/>
                      )
                    })}
                  </div>
                </div>
            </div>
    )
}

const DealerCenterSegment4 = () => {
    return(
            <div className="dealer-center-body3" id="retargeting_power"  style={{backgroundImage:`linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),url(${LogoBG})`}}>
                <div className="dealer-center-container">
                    <div className="dealer-center-body3-row1">
                        <div className="dealer-center-body3-column1">
                            <h1>The ultimate CRM-integrated digital vehicle presentation tool</h1>
                            <h2>Our powerful platform seamlessly integrates with your existing CRM system, giving you a streamlined and efficient way to share stunning digital presentations that captivate and engage your audience.</h2>
                        </div>
                    </div>
                    {/*
                    <div className="dealer-center-body3-row2">
                        <div className="dealer-center-body3-rowitem">
                            <EyeIcon/>
                            <h2>275%+</h2>
                            iPacket Views
                        </div>
                        <div className="dealer-center-body3-rowitem">
                            <WebsiteIcon/>
                            <h2>50%+</h2>
                            Lift in Website Visits
                        </div>
                        <div className="dealer-center-body3-rowitem">
                            <FolderIcon/>
                            <h2>29%+</h2>
                            in Sent Packets Opened
                        </div>
                        <div className="dealer-center-body3-rowitem">
                            <CarIcon/>
                            <h2>47%+</h2>
                            Cars Sold
                        </div>
                    </div>
                    */}
                </div>
                <NavLink to="/schedule-demo" className="dealer-center-body3-button">
                    BOOK A DEMO
                </NavLink>
            </div>
    )
}



const DealerCenterSegment5 = () => {
    let reviews = [
        {
          stars: 5,
          review: "My dealership sells over 600 cars a month and in my opinion, iPacket is in the top 2 resources we have. I could write you a book about the price change emails, email alerts when customers look at their Ipacket several times to alert you of their level of interest and the sheer ease of use. As a manager, I'm sending them every day to internet customers and the feed back from those customers is all positive. I sincerely wouldn't change a thing.",
          name: 'Jeremy-Allen R.',
        },
        {
          stars: 5,
          review: "I use iPacket regularly at my dealership. I am very happy with the program, and I have converted many leads to a sale. All the iPacket teams that I work with are very responsive and professional. A very nice and original tool to use for making sales.",
          name: 'Ermir K.',
        },
        {
          stars: 5,
          review: "AutoiPacket has been a tremendous benefit in my sales!  This amazing tool really helps to initiate and engage customer communication and sort out which leads require more time and focus. I strongly recommend implementing this software -- it's worth the investment!",
          name: 'Nancy S.',
        },
        {
          stars: 5,
          review: "Today's consumer wants information quickly and we have a limited time to maintain their attention. The iPacket puts everything at the fingertips of your customer. I see a 30% increase in customer interaction directly due to the neat little package that can be sent by email or mobile app. Thanks for making our job easier!",
          name: 'Patrick B.',
        },
        {
          stars: 5,
          review: "If you're in the car business, you need this application!  No more paper folders to lose.  Digital history reports of all vehicles in your inventory to instantly share with potential clients with all the follow-up data you could use.",
          name: 'Bill G.',
        },
        {
          stars: 5,
          review: "We couldn't be happier with our experience! Our customers love getting the details they need in one message. The customer service is beyond exceptional. If I have any questions I can always message their support team and they will contact me asap. 10/10",
          name: 'Courtney H.',
        }
    ]
    return(
            <div className="dealer-center-body4">
                <div className="dealer-center-container">
                    <h1>From coast-to-coast and dealer-to-dealer, the reviews are exceptional.</h1>
                    <div className="dealer-center-body4-row">
                        {reviews.map(reviews=>{
                            return(
                            <div className="dealer-center-body4-rowitem">
                                <ReviewStar numStars={reviews.stars} />
                                <br/>
                                <br/>
                                "{reviews.review}"
                                <div className="dealer-center-body4-signature">
                                    <div className="dealer-center-body4-signature-text">
                                        <h1>{reviews.name}</h1>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
    )
}

const DealerCenterSegment6 = () => {
    return(
            <div className="dealer-center-body5 dealer-center-body5a">
                <div className="dealer-center-container">
                    <div className="dealer-center-body5-row">
                        <div className="dealer-center-body5-column1">
                            <h1>Placeholder section for addition of future content.</h1>
                            <h2>Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here.</h2>
                        </div>
                        <div className="dealer-center-body5-column2">
                            <div className="greybox"/>
                        </div>
                    </div>
                    <NavLink to="/schedule-demo" className="dealer-center-body5-button">
                        BOOK A DEMO
                    </NavLink>
                </div>
            </div>
    )
}

const DealerCenterSegment7 = () => {
    return(
            <div className="dealer-center-body5 dealer-center-body5b">
                <div className="dealer-center-container">
                    <div className="dealer-center-body5-row">
                        <div className="dealer-center-body5-column1">
                            <h1>Placeholder section for addition of future content.</h1>
                            <h2>Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here. Stuff about iPacket will go here.</h2>
                        </div>
                        <div className="dealer-center-body5-column2">
                            <div className="greybox"/>
                        </div>
                    </div>
                    <NavLink to="/schedule-demo" className="dealer-center-body5-button">
                       BOOK A DEMO
                    </NavLink>
                </div>
            </div>
    )
}


const DealerCenterSegment9 = () => {
    return(
            <div className="dealer-center-body5 dealer-center-body5c">
                <div className="dealer-center-container">
                    <div className="dealer-center-body5-row">
                        <div className="dealer-center-body5-column1">
                            <img src={ZoomMeeting} alt="iPacket Demo"/>
                        </div>
                        <div className="dealer-center-body5-column2">
                            <h1>We'd love to show you firsthand.</h1>
                            <h2>Schedule a demo and have a conversation about how iPacket can help amplify your business. Reach out today.</h2>
                                <NavLink to="/schedule-demo" className="dealer-center-body5-button">
                                    BOOK A DEMO
                                </NavLink>
                        </div>
                    </div>
                </div>
            </div>
    )
}

//Sam
const DealerCenterSegment10 = () => {
    return(
    <div id="iPacket-recon" className="dealer-center-body3b">
        <div className="dealer-center-body3b-box1">
            <img src={Recon} alt='iPacket recon '/>
        </div>
        <div className="dealer-center-body3b-box2">
            <img src={ReconLogo} alt="iPacketRecon"/>
            <h1>Driving recon faster.</h1>
            <p>
                Welcome to a completely new age in vehicle reconditioning, and a whole
                new level of speed, efficiency and transparency. Cut your downtime, and get
                vehicles to the showroom faster than ever with iPacket Recon.
            </p>
            <a href="https://ipacketrecon.com/" target="_blank" className="dealer-center-body3b-button">
                LEARN MORE
            </a>
        </div>
    </div>
    )
}

//

function DealerCenter(props) {
    const {pushModal} = props;
    return(
        <div className="dealer-center-page">
            <a href="https://ipacketrecon.com/" className='dealer-center-page-iPacket-recon' target="_blank">
                ⓘ NOW INTRODUCING iPACKET RECON - LEARN MORE&nbsp;<b style={{'fontSize':'1.25em', 'lineHeight':'1.05em'}}>{'>'}</b>
            </a>
            {/* HERO VIDEO 1 */}
            <DealerCenterSegment1 pushModal={pushModal}/>
            {/* NEXT LEVEL TECH */}
            <DealerCenterSegment2  />
            {/* OEM WINDOW STICKERS */}
            <DealerCenterSegment3/>
            {/* RETARGETING POWER */}
            <DealerCenterSegment4/>
            {/* RECON*/}
            <DealerCenterSegment10/>
            {/* REVIEWS */}
            <DealerCenterSegment5/>
            {/* DEMO OFFER */}
            <DealerCenterSegment9/>
        </div>
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
    ...SEARCH_PAGE_ACTIONS.dispatchActions,

}

const mapStateToProps = state => ({})
export default connect(mapStateToProps, mapDispatchToProps)(DealerCenter)
