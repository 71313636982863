import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/glyphicon.css'
import AppContainer from './apps/app-container/appContainer.js'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './state/configureStore'
import parseURL from "./utils/parseURL"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://5cf8881a37d249008203b9d86bfa7092@o141269.ingest.sentry.io/6242772",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

export const {store, persistor} = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppContainer/>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('react-autoipacket-marketplace')
);

serviceWorker.unregister();
