import React,{useState} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import SearchInventoryFilterBox from '../../../common/common-components/searchInventoryFilterBox'
import './newAndUsedLandingPage.scss'
import SEARCH_PAGE_ACTIONS from '../search-page/state/searchPageActions'
import NewBackgroundImg from '../../../common/common-assets/images/new_Background_Image.jpg'
import UsedBackgroundImg from '../../../common/common-assets/images/used_Background_Image.jpg'
import NewIMG from '../../../common/common-assets/images/image23.jpg'
import UsedIMG from '../../../common/common-assets/images/image29.png'



function NewAndUsedLandingPage(props){
  const {page, clearSearchPage} = props

  if (page === "newCars"){
    return(
      <div className="new-and-used-page-container">
        <div className="new-and-used-page-sections" style={{backgroundImage: `url(${NewBackgroundImg})`}}>
          <h1 className="new-and-used-page-title">Find New Cars For Sale</h1>
          <SearchInventoryFilterBox
            condition='New'
            searchButtonClassName="ga-marketplace-new-cars-search-button"
            onSearchAction={()=>{
              clearSearchPage(false)

            }}
          />
        </div>
        <div className="new-and-used-page-sections">
          <BuyingSmartIntroduction pageType="New"/>
        </div>
      </div>
    )
  }
  return(
    <div className="new-and-used-page-container">
      <div className="new-and-used-page-sections" style={{backgroundImage: `url(${UsedBackgroundImg})`}}>
        <h1 className="new-and-used-page-title">Find Used Cars For Sale</h1>
        <SearchInventoryFilterBox
          condition='Used & CPO'
          searchButtonClassName="ga-marketplace-used-cars-search-button"
          onSearchAction={()=>{
            clearSearchPage(false)

          }}
        />
      </div>
      <div className="new-and-used-page-sections">
        <BuyingSmartIntroduction pageType="Used"/>
      </div>
    </div>
  )
}

const BuyingSmartIntroduction = ({pageType}) => {
  var newUsedImg = (pageType === "New") ? NewIMG : UsedIMG;
  return(
      <div className="new-and-used-page-info-blurb">
          <div className="paragraph-and-image">
            <div className="paragraph-and-button">
              {/*TODO: Fix Htm Formatting of this section*/}
              <h1>What is iPacket? Why is it important?</h1>
              <p>
                  When you’re shopping for a vehicle, you have so many choices, and so many questions. And you want to buy the right car at a fair price, from someone you can trust. Purchasing a vehicle without reviewing all the available documentation can be an expensive mistake.
              </p>
              <Link to="/buying-smart">
                <button className="primary-button ga-marketplace-education-center-button">
                  Learn More
                </button>
              </Link>
            </div>
            <div className="image-holder">
              <img src={newUsedImg}/>
            </div>
          </div>
      </div>
  )
}

const LinkToBuyingSmartPage = () => {
  return(
    <div className="new-and-used-page-buying-smart-link">
      <h1>Still not sure what works for you?</h1>
      <p>
        Check out our Buying Smart page for more tips and tricks on how to enhance your vehicle purchasing experience.
      </p>
      <Link to="/buying-smart" className="primary-button">Buying Smart Page</Link>
    </div>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps= {
  ...SEARCH_PAGE_ACTIONS.dispatchActions,
}

export default connect(mapStateToProps,mapDispatchToProps)(NewAndUsedLandingPage)
