import MARKETPLACE_APP_ACTIONS from './state/marketplaceAppActions'
import MARKETPLACE_APP_CONSTANTS from './state/marketplaceAppConstants'
import { store } from '../../index'


async function termsOfServiceIntercept(response){
  if(response.status === 403){
    let res = await response.json()
    if(res.issue === 'is_profile_updated'){
        store.dispatch({
          type: MARKETPLACE_APP_CONSTANTS.PROFILE_UPDATE_REQUIRED,
          profileUpdateRequired: true,
        })
    }
    else if(res.issue === 'is_terms_of_service_unaccepted'){
      store.dispatch({
        type: MARKETPLACE_APP_CONSTANTS.TERMS_OF_SERVICE_REQUIRED,
        termsOfServiceRequired: true,
      })
    }

  }
}
async function invalidTokenIntercept(response){
  if(response.status === 401){
    let res = await response.json()
    if(res.detail==='Invalid token.'){
      MARKETPLACE_APP_ACTIONS.logoutUser()
    }
  }
}

export default function MarketplaceApiIntercepts(response){
  let intercepts = [
    termsOfServiceIntercept,
    invalidTokenIntercept,
  ]
  intercepts.map(intercept=>{
    try{
      intercept(response)
    }
    catch(e){
      console.log(e)
    }
  })
}
