const SAVED_VEHICLES_PAGE_CONSTANTS = {
    GET_SAVED_VEHICLES: "GET_SAVED_VEHICLES",
    ADD_SAVED_VEHICLE: "ADD_SAVED_VEHICLE",
    DELETE_SAVED_VEHICLE: "DELETE_SAVED_VEHICLE",
    GET_VEHICLE_SEARCH_FILTER:"GET_VEHICLE_SEARCH_FILTER",
    UPDATE_SEARCH_QUERY:"UPDATE_SEARCH_QUERY",
    UPDATE_SELECTED_FIELD: "UPDATE_SELECTED_FIELD",
    UPDATE_SORT_BY_FIELD: "UPDATE_SORT_BY_FIELD",
    UPDATE_SAVED_VEHICLES_PAGE: "UPDATE_SAVED_VEHICLES_PAGE",
    UPDATE_SAVED_LOADING:"UPDATE_SAVED_LOADING",
}
export default SAVED_VEHICLES_PAGE_CONSTANTS