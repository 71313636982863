import SAVED_VEHICLES_PAGE_CONSTANTS from './savedVehiclePageConstants'
import mockVehicles from './mockSearchReturn'




const initialState = {
  savedVehicles: [],
  savedVehiclesCount: 0,
  sortByValue: "Recommended",
  page: 1,
  loading:true,
}

function savedVehiclePageReducer(state=initialState, action){
  switch(action.type){
    case "MOCKADD":
      return{
        ...state,
        savedVehicles:[...state.savedVehicles, mockVehicles[9]]
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.GET_SAVED_VEHICLES:
      return{
        ...state,
        savedVehicles:[...action.results],
        savedVehiclesCount: action.count,
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.ADD_SAVED_VEHICLE:
      let updatedSavedVehicles = state.savedVehicles.slice()
      if (!updatedSavedVehicles.find(vehicle => vehicle.vin === action.vehicle.vin)){
        updatedSavedVehicles = [...updatedSavedVehicles, {...action.vehicle, is_liked: true, like_count: action.vehicle.like_count+1}]
      }
      return{
        ...state,
        savedVehicles: updatedSavedVehicles,
        savedVehiclesCount: state.savedVehiclesCount + 1,
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.DELETE_SAVED_VEHICLE:
      updatedSavedVehicles = state.savedVehicles.slice()
      let foundVehicleIndex = updatedSavedVehicles.findIndex(vehicle => vehicle.vin === action.vehicle.vin)
      if (foundVehicleIndex !== -1) {
        updatedSavedVehicles.splice(foundVehicleIndex, 1)
      }
      return{
        ...state,
        savedVehicles: updatedSavedVehicles,
        savedVehiclesCount: state.savedVehiclesCount - 1,
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.UPDATE_SORT_BY_FIELD:
      return {
        ...state,
        sortByValue: action.sortByValue
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.UPDATE_SAVED_VEHICLES_PAGE:
      return{
        ...state,
        page: action.page
      }
    case SAVED_VEHICLES_PAGE_CONSTANTS.UPDATE_SAVED_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state
  }
}

export default savedVehiclePageReducer
