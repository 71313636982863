import React from 'react'

function PersonIcon(props){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M24 23.3q-3.85 0-6.3-2.45-2.45-2.45-2.45-6.3 0-3.85 2.45-6.3Q20.15 5.8 24 5.8q3.85 0 6.325 2.45t2.475 6.3q0 3.85-2.475 6.3Q27.85 23.3 24 23.3ZM6.75 41.9v-6.05q0-2.2 1.15-3.875 1.15-1.675 2.95-2.525 3.45-1.55 6.675-2.325Q20.75 26.35 24 26.35q3.3 0 6.5.8t6.6 2.3q1.9.8 3.05 2.475t1.15 3.925v6.05Z"/>
    </svg>

  )
}
export default PersonIcon
