import React from 'react'
import './styles/moduleSkeleton.scss'

function ModuleSkeleton(props){
  return(
    <div className="module-skeleton">
      <div className="module-skeleton-section module-skeleton-half-width">
      </div>
      <div className="module-skeleton-section module-skeleton-full-width module-skeleton-double-height">
      </div>
      <div className="module-skeleton-section module-skeleton-half-width">
      </div>
      <div className="module-skeleton-section module-skeleton-half-width">
      </div>
      <div className="module-skeleton-section module-skeleton-full-width">
      </div>
      <div className="module-skeleton-section module-skeleton-half-width module-skeleton-double-height">
      </div>
      <div className="module-skeleton-section module-skeleton-half-width module-skeleton-double-height">
      </div>
    </div>
  )
}
export default ModuleSkeleton
