import React from "react";

function JeepIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1800"
      height="1007"
      viewBox="0 0 999.501 402.709"
    >
      <path
        fill="#231f20"
        d="M833.993 215.25c-.813 44.86 8.875 68.62 45.855 68.62 36.919 0 42.217-40.475 42.217-71.276 0-30.82-7.006-60.74-45.794-60.74-23.795 0-41.364 18.49-42.278 63.396zM759.18 402.71V99.054h75.727v12.317c0 16.43 6.627 9.655 11.966 3.252.894-1.07 17.771-21.728 55.78-21.728 37.895 0 96.847 26.397 96.847 119.7 0 72.17-35.17 124.97-95.911 124.97-33.47 0-52.82-13.178-57.245-18.496-4.431-5.245-10.583-6.16-10.583 0v83.64H759.18M173.874.5h81.859v214.75c0 36.94-15.861 114.415-129.382 114.415C12.817 329.665.5 257.454.5 216.998v-39.601h79.217v38.707c0 23.761 15.847 45.754 45.754 45.754 29.92 0 48.403-16.715 48.403-44.86V.5M426.925 182.675c0-22.027-12.297-43.146-40.462-43.146-27.284 0-36.973 19.344-36.973 43.146h77.435zm-48.356-92.422c64.285 0 120.588 24.635 120.588 138.175H346.908c0 36.086 14.947 51.052 41.35 51.052 26.41 0 35.205-21.139 35.205-21.139h74.833c0 32.576-44.893 76.582-112.68 75.708-77.429 0-115.282-57.211-115.282-116.183 0-58.993 33.422-127.613 108.235-127.613M670.315 182.675c0-22.027-12.311-43.146-40.476-43.146-27.27 0-36.98 19.344-36.98 43.146h77.456zm-48.356-92.422c64.278 0 120.567 24.635 120.567 138.175H590.284c0 36.086 14.96 51.052 41.384 51.052 26.383 0 35.191-21.139 35.191-21.139h74.833c0 32.576-44.9 76.582-112.666 75.708-77.47 0-115.323-57.211-115.323-116.183 0-58.993 33.463-127.613 108.256-127.613M949.376 366.306c0-12.026 9.79-21.796 21.857-21.796 12.027 0 21.797 9.77 21.797 21.796 0 12.006-9.77 21.837-21.797 21.837-12.066 0-21.857-9.831-21.857-21.837zm-2.52 0c0 13.456 10.895 24.378 24.377 24.378 13.395 0 24.35-10.922 24.35-24.378s-10.955-24.378-24.35-24.378c-13.482 0-24.377 10.922-24.377 24.378"
      />
      <path
        fill="#231f20"
        d="M980.997 378.773l-5.062-11.952c1.606-.556 2.141-.949 2.995-1.863 1.111-1.193 1.81-3.076 1.81-4.784 0-4.309-3.314-7.242-8.118-7.242H962.46v25.84h3.015V367.58h7.345l4.824 11.193h3.354zm-15.604-13.815v-9.215h7.168c2.995 0 4.899 1.708 4.899 4.35 0 3.002-2.02 4.865-5.319 4.865h-6.748"
      />
    </svg>
  );
}
export default JeepIcon;
