import React from "react";

const DC_ReviewStar = ({ numStars }) => {
  const stars = [];
  for (let i = 0; i < numStars; i++) {
    stars.push(
      <svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
        <path d="m56,237 74-228 74,228L10,96h240"/>
      </svg>
    );
  }
  return (
    <>
      {stars}
    </>
  );
};
export default DC_ReviewStar;