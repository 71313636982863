const SEARCH_PAGE_CONSTANTS = {
  GET_VEHICLE_SEARCH_FILTER:"GET_VEHICLE_SEARCH_FILTER",
  GET_VEHICLE_SEARCH_DEFAULT_FILTERS:"GET_VEHICLE_SEARCH_DEFAULT_FILTERS",
  UPDATE_SEARCH_QUERY:"UPDATE_SEARCH_QUERY",
  UPDATE_SELECTED_FIELD: "UPDATE_SELECTED_FIELD",
  UPDATE_SORT_BY_FIELD: "UPDATE_SORT_BY_FIELD",
  UPDATE_PAGE: "UPDATE_PAGE",
  CLEAR_SEARCH_PAGE:"CLEAR_SEARCH_PAGE",
  UPDATE_ZIP_CODE:"UPDATE_ZIP_CODE",
  UPDATE_SEARCH_LOADING:"UPDATE_SEARCH_LOADING",
}
export default SEARCH_PAGE_CONSTANTS
