import React from "react";

function DodgeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path d="M186.914 98.957h-20.229l.615-2.459h20.16l.957-3.417H163.27l-1.641 6.013c-.342 1.504-.068 3.281 1.709 3.281h22.619l.957-3.418zM163.883 90.552c.41-1.572.889-3.417 3.828-3.417h22.211l-.889 3.417h-25.15zM103.061 87.135l-.889 3.417h19.068c3.074.068 2.766 2.453 2.596 3.349-.273 1.435-2.119 4.511-4.715 5.056h-14.146l1.367-5.398h-4.99l-2.254 8.816h21.869c5.125 0 8.199-6.355 8.336-8.748s.068-6.492-7.176-6.492h-19.066zM93.356 87.135c5.126 0 6.835 3.485 6.356 6.834-1.162 6.765-7.04 8.406-9.227 8.406H76.203c-5.125 0-6.834-3.484-6.356-6.834 1.162-6.766 8.475-8.406 10.662-8.406l2.46 3.007c-2.939 0-7.859 1.436-8.27 5.057-.41 3.623 1.708 4.17 4.511 4.17h8.815c2.938 0 6.424-1.436 6.834-5.058.41-3.622-1.708-4.169-4.51-4.169h-7.38l-2.46-3.007h12.847zM161.355 90.552h-19.34c-2.939 0-6.494 1.913-6.697 4.647-.273 3.635 1.707 3.758 4.51 3.758h9.363c2.203 0 3.523-.576 4.219-2.461h-10.781l.889-3.416h17.092c-.008.296-.168.593-.211.889-1.162 6.765-7.107 8.406-9.295 8.406H136.82c-5.125 0-6.834-3.484-6.355-6.834 1.162-6.766 8.475-8.406 10.662-8.406h16.742c2.051 0 3.555.888 3.486 3.417 0 0 .069-2.529 0 0zM42.101 87.135l-.888 3.417H60.28c3.075.068 2.768 2.453 2.597 3.349-.273 1.435-2.118 4.511-4.715 5.056H44.015l1.366-5.398h-4.988l-2.256 8.816h21.869c5.125 0 8.201-6.355 8.338-8.748s.068-6.492-7.176-6.492H42.101zM17.089 81.565c5.262 0 9.26.342 11.447.547 1.23.136 2.836.888 2.802 3.007.035 1.503-.102 11.208-.375 14.215-.068 2.119-.342 3.998-2.426 5.434-2.624 1.807-5.604 3.93-11.447 6.424-5.843-2.494-8.823-4.617-11.447-6.424-2.084-1.436-2.358-3.314-2.426-5.434-.274-3.007-.41-12.711-.376-14.215-.035-2.119 1.571-2.87 2.801-3.007 2.187-.205 6.185-.547 11.447-.547 0 0-5.262 0 0 0z" />
        <path
          fill="#fff"
          d="M17.089 82.659c4.75.068 9.089.273 10.935.547.888.068 2.05.205 2.118 1.982.137 2.871-.205 12.301-.375 14.795-.205 2.154-.555 2.75-1.675 3.691-1.503 1.264-7.722 5.057-11.002 6.287-3.28-1.23-9.5-5.023-11.003-6.287-1.119-.941-1.469-1.537-1.674-3.691-.171-2.494-.513-11.924-.376-14.795.068-1.777 1.23-1.914 2.119-1.982 1.844-.274 6.183-.479 10.933-.547 0 0-4.75.068 0 0z"
        />
        <path d="M6.223 93.798c-.341-.103-.683-.068-.82.615-.137.683-.103 1.093.068 1.503-.171.272-.035 1.435.239 1.64-.102.342.171 1.367.513 1.537-.068.41.546 1.266.923 1.436-.274.137.664.736 1.093.889.581.205 1.606.205 2.016.137.342 0 .444-.547.137-.785-.307-.24-3.553-2.871-3.656-6.219.033-.308-.172-.684-.513-.753 0 0 .341.069 0 0zM16.508 88.707c-.273-.273-.512-.547-.991-.547-.205-.376-.82-.82-1.196-.854-.205-.341-.957-.82-1.401-.786-.171-.308-1.777-.376-2.119-.171-.308-.171-1.606.103-1.743.513-.444.103-1.333.82-1.777 1.879-.171.307.171.41.342.205s1.435-.786 2.152-.546c.718.239 2.563 1.127 4.75 4.168.376.547 1.64.923 1.879-.239.297-1.438.582-3.075.104-3.622 0 0 .478.547 0 0zM27.955 93.798c.342-.103.684-.068.82.615.137.683.103 1.093-.068 1.503.171.272.034 1.435-.239 1.64.103.342-.171 1.367-.513 1.537.069.41-.547 1.266-.922 1.436.273.137-.664.736-1.093.889-.581.205-1.606.205-2.016.137-.342 0-.445-.547-.137-.785.308-.24 3.554-2.871 3.656-6.219-.035-.308.17-.684.512-.753 0 0-.342.069 0 0z" />
        <path d="M17.089 104.254c.342.137.649.035.888-.137-.41 0-.683-.273-.683-.717 0-.445.137-.719.581-.648.444.066.718-.07.581-.787s-.205-1.059-.137-1.4l.649-2.152c.512-.068 1.093-.24 1.025-.924-.308-.068-.82.205-.923.479-.103.172-.41 0-.308-.205l.171-.479c.068-.205.307-.307.444-.375s.342-.205.923-.273c.171-.514.41-1.743-.068-2.119-.171-.102-.035-.205.137-.205.171 0 .41-.103.581-.41.17-.308 1.572-3.383 2.734-4.408 1.161-1.025 3.212-.375 3.724.513.513.889.786 2.221.684 3.178 0 .273-.137.342-.307.376a1.27 1.27 0 0 0-.479.239c-.171.137-.171.308-.171.444s-.41 3.109-2.563 4.783c-.41.275-.82.035-.82-.307s0-.99.342-1.162c-.206-.17-.103-1.229.137-1.436-.171-.239-.068-1.4.273-1.571-.171-.103-.286-.616-.273-.752.068-.752-.717-1.196-1.025-.444.239.103.547.376.376 1.708l-1.845 1.127c.103.342-.103 1.436-.308 2.086-.205.648-.41 1.674-.923 2.254-.512.582-.854 1.23-1.161 2.836-.068.582-.547.684-.854.752.479.172.239.547-.068.719-.308.17-.751.443-1.333.443s-1.025-.273-1.333-.443c-.307-.172-.546-.547-.068-.719-.308-.068-.786-.17-.854-.752-.308-1.605-.649-2.254-1.162-2.836-.513-.58-.718-1.605-.923-2.254-.205-.65-.41-1.744-.307-2.086l-1.846-1.127c-.17-1.333.137-1.606.376-1.708-.308-.752-1.093-.308-1.025.444.012.136-.103.649-.273.752.341.171.444 1.332.273 1.571.239.206.341 1.266.137 1.436.342.172.342.82.342 1.162s-.411.582-.82.307c-2.153-1.674-2.563-4.646-2.563-4.783s0-.308-.171-.444a1.242 1.242 0 0 0-.479-.239c-.17-.034-.307-.103-.307-.376-.103-.957.171-2.289.683-3.178.513-.888 2.563-1.538 3.725-.513s2.563 4.101 2.734 4.408.41.41.581.41c.17 0 .307.103.136.205-.478.376-.239 1.605-.068 2.119.581.068.786.205.922.273s.376.17.444.375l.171.479c.103.205-.205.377-.308.205-.103-.273-.615-.547-.923-.479-.068.684.513.855 1.025.924l.649 2.152c.069.342 0 .684-.136 1.4-.137.717.136.854.581.787.444-.07.581.203.581.648 0 .443-.273.717-.684.717.239.172.547.274.889.137 0 0-.342.137 0 0z" />
        <path d="M17.67 88.707c.273-.273.512-.547.991-.547.205-.376.82-.82 1.196-.854.205-.341.957-.82 1.401-.786.171-.308 1.777-.376 2.119-.171.308-.171 1.605.103 1.742.513.444.103 1.333.82 1.777 1.879.17.307-.171.41-.342.205s-1.436-.786-2.153-.546-2.563 1.127-4.75 4.168c-.376.547-1.641.923-1.88-.239-.295-1.438-.58-3.075-.101-3.622 0 0-.479.547 0 0z" />
      </g>
    </svg>
  );
}
export default DodgeIcon;
