import React from 'react'

const IPacketSimpleIcon = props => {
  return (
    <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.8,0C23.8-0.1,0.1,23.2,0,52c-0.1,29,23.4,52.5,52.5,52.5c29.1,0.1,52.6-23.3,52.7-52.3C105.2,23.6,81.6,0.1,52.8,0z M52.6,97.4c-25,0-45.4-20.2-45.4-45.1c0-24.9,20.3-45.2,45.3-45.2c25,0,45.4,20.2,45.4,45.1C97.9,77.1,77.6,97.4,52.6,97.4z"/>
    <path d="M51.5,35.6c-1.8,0-3-0.3-2.8-2.5c0.1-1.4-0.6-1.8-1.9-1.8c-5.8,0-11.6,0-17.4,0c-1.4,0-2.1,0.5-2,2c0.1,1.2-0.2,1.8-1.5,2.5c-1.7,0.8-3.4,2-3,4.6c0.5,3.1,0.4,6.2,0.5,9.4c0.4,7.3,0.8,14.5,1.2,21.8C24.8,73,25.1,74,27,74c17.1,0,34.3,0,51.4,0c1.7,0,2.2-0.7,2.3-2.3c0.1-3.1,0.3-6.2,0.5-9.3c0.5-8.2,1-16.5,1.5-24.6c-4.9-2.3-4.9-2.3-9.8-2.3C65.8,35.6,58.7,35.5,51.5,35.6z"/>
    </svg>
  )
}
export default IPacketSimpleIcon
