import React from "react";

function VolkswagenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.744 192.744"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.744v192.744H0V0z" />
        <path
          fill="#2f4698"
          d="M96.408 178.128c45.145 0 81.721-36.576 81.721-81.72 0-45.216-36.576-81.792-81.721-81.792-45.144 0-81.792 36.576-81.792 81.792 0 45.144 36.648 81.72 81.792 81.72zm80.928-81.72c0 44.64-36.217 80.855-80.928 80.855-44.712 0-80.928-36.216-80.928-80.855 0-44.712 36.216-80.928 80.928-80.928 44.711 0 80.928 36.216 80.928 80.928z"
        />
        <path
          fill="#2f4799"
          d="M96.408 178.128c45.145 0 81.721-36.576 81.721-81.72 0-45.216-36.576-81.792-81.721-81.792-45.144 0-81.792 36.576-81.792 81.792 0 45.144 36.648 81.72 81.792 81.72zm78.623-81.72c0 43.416-35.207 78.552-78.623 78.552-43.417 0-78.624-35.136-78.624-78.552s35.208-78.624 78.624-78.624 78.623 35.208 78.623 78.624z"
        />
        <path
          fill="#2f489a"
          d="M177.336 96.408c0-44.712-36.217-80.928-80.928-80.928-44.712 0-80.928 36.216-80.928 80.928 0 44.64 36.216 80.855 80.928 80.855 44.711.001 80.928-36.215 80.928-80.855zm-4.607 0c0 42.119-34.201 76.248-76.32 76.248-42.12 0-76.32-34.129-76.32-76.248 0-42.192 34.2-76.32 76.32-76.32 42.118 0 76.32 34.128 76.32 76.32z"
        />
        <path
          fill="#2e4a9b"
          d="M175.031 96.408c0-43.416-35.207-78.624-78.623-78.624-43.417 0-78.624 35.208-78.624 78.624s35.208 78.552 78.624 78.552 78.623-35.136 78.623-78.552zm-4.607 0c0 40.824-33.119 73.943-74.016 73.943-40.896 0-74.016-33.119-74.016-73.943 0-40.896 33.12-74.017 74.016-74.017 40.897.001 74.016 33.121 74.016 74.017z"
        />
        <path
          fill="#2e4b9c"
          d="M172.729 96.408c0-42.192-34.201-76.32-76.32-76.32-42.12 0-76.32 34.128-76.32 76.32 0 42.119 34.2 76.248 76.32 76.248 42.118 0 76.32-34.129 76.32-76.248zm-4.61 0c0 39.527-32.111 71.64-71.711 71.64s-71.712-32.112-71.712-71.64c0-39.601 32.112-71.712 71.712-71.712s71.711 32.112 71.711 71.712z"
        />
        <path
          fill="#2e4c9c"
          d="M170.424 96.408c0-40.896-33.119-74.017-74.016-74.017-40.896 0-74.016 33.12-74.016 74.017 0 40.824 33.12 73.943 74.016 73.943 40.897.001 74.016-33.119 74.016-73.943zm-4.608 0c0 38.304-31.104 69.336-69.408 69.336S27 134.712 27 96.408 58.104 27 96.408 27c38.305 0 69.408 31.104 69.408 69.408z"
        />
        <path
          fill="#2d4e9d"
          d="M168.119 96.408c0-39.601-32.111-71.712-71.711-71.712S24.696 56.808 24.696 96.408c0 39.527 32.112 71.64 71.712 71.64s71.711-32.112 71.711-71.64zm-4.68 0c0 37.008-30.023 67.031-67.031 67.031-37.008 0-67.032-30.023-67.032-67.031 0-37.08 30.024-67.104 67.032-67.104 37.008 0 67.031 30.024 67.031 67.104z"
        />
        <path
          fill="#2d4f9e"
          d="M165.816 96.408C165.816 58.104 134.713 27 96.408 27 58.104 27 27 58.104 27 96.408s31.104 69.336 69.408 69.336c38.305 0 69.408-31.032 69.408-69.336zm-4.679 0c0 35.712-28.945 64.728-64.729 64.728S31.68 132.12 31.68 96.408c0-35.784 28.944-64.8 64.728-64.8 35.783 0 64.729 29.016 64.729 64.8z"
        />
        <path
          fill="#2d509f"
          d="M163.439 96.408c0-37.08-30.023-67.104-67.031-67.104-37.008 0-67.032 30.024-67.032 67.104 0 37.008 30.024 67.031 67.032 67.031 37.008 0 67.031-30.023 67.031-67.031zm-4.607 0c0 34.416-27.936 62.424-62.424 62.424s-62.424-28.008-62.424-62.424c0-34.488 27.936-62.424 62.424-62.424s62.424 27.936 62.424 62.424z"
        />
        <path
          fill="#2c51a0"
          d="M161.137 96.408c0-35.784-28.945-64.8-64.729-64.8S31.68 60.624 31.68 96.408c0 35.712 28.944 64.728 64.728 64.728 35.783 0 64.729-29.016 64.729-64.728zm-4.61 0c0 33.191-26.928 60.048-60.119 60.048-33.192 0-60.12-26.856-60.12-60.048 0-33.265 26.928-60.12 60.12-60.12 33.192 0 60.119 26.856 60.119 60.12z"
        />
        <path
          fill="#2c53a0"
          d="M158.832 96.408c0-34.488-27.936-62.424-62.424-62.424S33.984 61.92 33.984 96.408c0 34.416 27.936 62.424 62.424 62.424s62.424-28.008 62.424-62.424zm-4.607 0c0 31.896-25.92 57.744-57.816 57.744s-57.816-25.849-57.816-57.744c0-31.968 25.92-57.816 57.816-57.816s57.816 25.848 57.816 57.816z"
        />
        <path
          fill="#2c54a1"
          d="M156.527 96.408c0-33.265-26.928-60.12-60.119-60.12-33.192 0-60.12 26.855-60.12 60.12 0 33.191 26.928 60.048 60.12 60.048 33.192 0 60.119-26.856 60.119-60.048zm-4.607 0c0 30.6-24.84 55.439-55.512 55.439s-55.512-24.84-55.512-55.439c0-30.672 24.84-55.512 55.512-55.512s55.512 24.84 55.512 55.512z"
        />
        <path
          fill="#2b55a2"
          d="M154.225 96.408c0-31.968-25.92-57.816-57.816-57.816S38.593 64.44 38.593 96.408c0 31.896 25.92 57.744 57.816 57.744s57.816-25.848 57.816-57.744zm-4.61 0c0 29.376-23.832 53.136-53.207 53.136-29.376 0-53.208-23.76-53.208-53.136S67.032 43.2 96.408 43.2c29.375 0 53.207 23.832 53.207 53.208z"
        />
        <path
          fill="#2b56a3"
          d="M151.92 96.408c0-30.672-24.84-55.512-55.512-55.512s-55.512 24.84-55.512 55.512c0 30.6 24.84 55.439 55.512 55.439 30.672.001 55.512-24.839 55.512-55.439zm-4.608 0c0 28.08-22.824 50.832-50.904 50.832s-50.904-22.752-50.904-50.832c0-28.152 22.824-50.904 50.904-50.904s50.904 22.752 50.904 50.904z"
        />
        <path
          fill="#2b57a4"
          d="M149.615 96.408c0-29.376-23.832-53.208-53.207-53.208C67.032 43.2 43.2 67.032 43.2 96.408c0 29.376 23.832 53.136 53.208 53.136 29.375 0 53.207-23.76 53.207-53.136zm-4.679 0c0 26.783-21.744 48.527-48.527 48.527-26.784 0-48.528-21.744-48.528-48.527 0-26.856 21.744-48.601 48.528-48.601 26.782.001 48.527 21.745 48.527 48.601z"
        />
        <path
          fill="#2a59a5"
          d="M147.312 96.408c0-28.152-22.824-50.904-50.904-50.904S45.504 68.256 45.504 96.408c0 28.08 22.824 50.832 50.904 50.832s50.904-22.752 50.904-50.832zm-4.679 0c0 25.487-20.664 46.224-46.225 46.224-25.56 0-46.224-20.736-46.224-46.224 0-25.561 20.664-46.296 46.224-46.296 25.561 0 46.225 20.736 46.225 46.296z"
        />
        <path
          fill="#2a5aa6"
          d="M144.936 96.408c0-26.856-21.744-48.601-48.527-48.601-26.784 0-48.528 21.744-48.528 48.601 0 26.783 21.744 48.527 48.528 48.527 26.782.001 48.527-21.744 48.527-48.527zm-4.608 0c0 24.264-19.656 43.92-43.92 43.92-24.264 0-43.92-19.656-43.92-43.92 0-24.265 19.656-43.992 43.92-43.992 24.264 0 43.92 19.728 43.92 43.992z"
        />
        <path
          fill="#295ba7"
          d="M142.633 96.408c0-25.561-20.664-46.296-46.225-46.296-25.56 0-46.224 20.736-46.224 46.296 0 25.487 20.664 46.224 46.224 46.224 25.561 0 46.225-20.736 46.225-46.224zm-4.61 0c0 22.968-18.648 41.615-41.615 41.615-22.968 0-41.616-18.647-41.616-41.615 0-23.04 18.648-41.616 41.616-41.616 22.967 0 41.615 18.576 41.615 41.616z"
        />
        <path
          fill="#295ca7"
          d="M140.328 96.408c0-24.265-19.656-43.992-43.92-43.992-24.264 0-43.92 19.728-43.92 43.992 0 24.264 19.656 43.92 43.92 43.92 24.264 0 43.92-19.656 43.92-43.92zm-4.607 0c0 21.672-17.641 39.239-39.312 39.239-21.672 0-39.312-17.567-39.312-39.239 0-21.744 17.64-39.312 39.312-39.312 21.671 0 39.312 17.568 39.312 39.312z"
        />
        <path
          fill="#295da8"
          d="M138.023 96.408c0-23.04-18.648-41.616-41.615-41.616-22.968 0-41.616 18.576-41.616 41.616 0 22.968 18.648 41.615 41.616 41.615 22.967 0 41.615-18.647 41.615-41.615zm-4.607 0c0 20.376-16.561 36.936-37.008 36.936-20.448 0-37.008-16.56-37.008-36.936C59.4 75.96 75.96 59.4 96.408 59.4c20.447 0 37.008 16.56 37.008 37.008z"
        />
        <path
          fill="#285ea9"
          d="M135.721 96.408c0-21.744-17.641-39.312-39.312-39.312-21.672 0-39.312 17.568-39.312 39.312 0 21.672 17.64 39.239 39.312 39.239 21.671 0 39.312-17.567 39.312-39.239zm-4.61 0c0 19.151-15.551 34.632-34.703 34.632s-34.704-15.48-34.704-34.632c0-19.152 15.552-34.704 34.704-34.704 19.153 0 34.703 15.552 34.703 34.704z"
        />
        <path
          fill="#2860aa"
          d="M133.416 96.408c0-20.448-16.561-37.008-37.008-37.008C75.96 59.4 59.4 75.96 59.4 96.408c0 20.376 16.56 36.936 37.008 36.936 20.447 0 37.008-16.56 37.008-36.936zm-4.68 0c0 17.855-14.473 32.328-32.328 32.328-17.856 0-32.328-14.473-32.328-32.328 0-17.928 14.472-32.4 32.328-32.4s32.328 14.472 32.328 32.4z"
        />
        <path
          fill="#2761ab"
          d="M131.111 96.408c0-19.152-15.551-34.704-34.703-34.704S61.704 77.256 61.704 96.408c0 19.151 15.552 34.632 34.704 34.632 19.153 0 34.703-15.48 34.703-34.632zm-4.679 0c0 16.56-13.393 30.023-30.023 30.023-16.632 0-30.024-13.464-30.024-30.023 0-16.632 13.392-30.096 30.024-30.096 16.63 0 30.023 13.464 30.023 30.096z"
        />
        <path
          fill="#2762ac"
          d="M128.736 96.408c0-17.928-14.473-32.4-32.328-32.4-17.856 0-32.328 14.472-32.328 32.4 0 17.855 14.472 32.328 32.328 32.328s32.328-14.472 32.328-32.328zm-4.607 0c0 15.264-12.385 27.72-27.721 27.72s-27.72-12.456-27.72-27.72c0-15.336 12.384-27.792 27.72-27.792 15.336 0 27.721 12.456 27.721 27.792z"
        />
        <path
          fill="#2663ac"
          d="M126.432 96.408c0-16.632-13.393-30.096-30.023-30.096-16.632 0-30.024 13.464-30.024 30.096 0 16.56 13.392 30.023 30.024 30.023 16.63.001 30.023-13.463 30.023-30.023zm-4.608 0c0 14.04-11.377 25.416-25.416 25.416-14.04 0-25.417-11.376-25.417-25.416 0-14.112 11.376-25.488 25.417-25.488 14.039 0 25.416 11.448 25.416 25.488z"
        />
        <path
          fill="#2664ad"
          d="M124.129 96.408c0-15.336-12.385-27.792-27.721-27.792s-27.72 12.456-27.72 27.792c0 15.264 12.384 27.72 27.72 27.72 15.336 0 27.721-12.456 27.721-27.72zm-4.609 0c0 12.744-10.367 23.111-23.111 23.111s-23.112-10.367-23.112-23.111c0-12.816 10.368-23.185 23.112-23.185s23.111 10.369 23.111 23.185z"
        />
        <path
          fill="#2565ae"
          d="M121.824 96.408c0-14.112-11.377-25.488-25.416-25.488-14.04 0-25.417 11.448-25.417 25.488s11.376 25.416 25.417 25.416c14.039 0 25.416-11.376 25.416-25.416zm-4.607 0c0 11.447-9.289 20.808-20.809 20.808-11.52 0-20.808-9.36-20.808-20.808 0-11.521 9.288-20.809 20.808-20.809 11.52.001 20.809 9.289 20.809 20.809z"
        />
        <path
          fill="#2566af"
          d="M119.52 96.408c0-12.816-10.367-23.185-23.111-23.185S73.296 83.592 73.296 96.408c0 12.744 10.368 23.111 23.112 23.111s23.112-10.367 23.112-23.111zm-4.608 0c0 10.151-8.279 18.432-18.504 18.432-10.224 0-18.504-8.28-18.504-18.432 0-10.225 8.28-18.504 18.504-18.504 10.225 0 18.504 8.28 18.504 18.504z"
        />
        <path
          fill="#2467b0"
          d="M117.217 96.408a20.773 20.773 0 0 0-20.809-20.809c-11.52 0-20.808 9.288-20.808 20.809 0 11.447 9.288 20.808 20.808 20.808s20.809-9.361 20.809-20.808zm-4.61 0c0 8.928-7.271 16.128-16.199 16.128s-16.2-7.2-16.2-16.128c0-9 7.272-16.2 16.2-16.2 8.928 0 16.199 7.2 16.199 16.2z"
        />
        <path
          fill="#2468b1"
          d="M114.912 96.408c0-10.225-8.279-18.504-18.504-18.504-10.224 0-18.504 8.28-18.504 18.504 0 10.151 8.28 18.432 18.504 18.432 10.225 0 18.504-8.28 18.504-18.432zm-4.68 0c0 7.632-6.193 13.824-13.824 13.824-7.632 0-13.824-6.192-13.824-13.824 0-7.704 6.192-13.896 13.824-13.896 7.631 0 13.824 6.192 13.824 13.896z"
        />
        <path
          fill="#2369b2"
          d="M112.607 96.408c0-9-7.271-16.2-16.199-16.2s-16.2 7.2-16.2 16.2c0 8.928 7.272 16.128 16.2 16.128 8.928 0 16.199-7.2 16.199-16.128zm-4.679 0c0 6.336-5.111 11.52-11.52 11.52s-11.52-5.184-11.52-11.52c0-6.408 5.112-11.592 11.52-11.592s11.52 5.184 11.52 11.592z"
        />
        <path
          fill="#236bb3"
          d="M110.232 96.408c0-7.704-6.193-13.896-13.824-13.896-7.632 0-13.824 6.192-13.824 13.896 0 7.632 6.192 13.824 13.824 13.824 7.631 0 13.824-6.192 13.824-13.824zm-4.609 0c0 5.04-4.104 9.216-9.215 9.216-5.112 0-9.216-4.176-9.216-9.216 0-5.112 4.104-9.288 9.216-9.288 5.112 0 9.215 4.176 9.215 9.288z"
        />
        <path
          fill="#226cb4"
          d="M107.928 96.408c0-6.408-5.111-11.592-11.52-11.592S84.888 90 84.888 96.408c0 6.336 5.112 11.52 11.52 11.52s11.52-5.184 11.52-11.52zm-4.608 0a6.914 6.914 0 0 1-6.912 6.912 6.914 6.914 0 0 1-6.912-6.912c0-3.888 3.096-6.984 6.912-6.984 3.817 0 6.912 3.096 6.912 6.984z"
        />
        <path
          fill="#216db4"
          d="M105.623 96.408c0-5.112-4.104-9.288-9.215-9.288-5.112 0-9.216 4.176-9.216 9.288 0 5.04 4.104 9.216 9.216 9.216 5.112 0 9.215-4.176 9.215-9.216zm-4.607 0c0 2.52-2.088 4.607-4.607 4.607-2.52 0-4.608-2.088-4.608-4.607 0-2.592 2.088-4.68 4.608-4.68 2.519 0 4.607 2.088 4.607 4.68z"
        />
        <path
          fill="#216eb5"
          d="M103.32 96.408c0-3.888-3.096-6.984-6.912-6.984s-6.912 3.096-6.912 6.984a6.913 6.913 0 0 0 13.824 0z"
        />
        <path
          fill="#206fb6"
          d="M101.016 96.408c0-2.592-2.088-4.68-4.607-4.68-2.52 0-4.608 2.088-4.608 4.68 0 2.52 2.088 4.607 4.608 4.607 2.519.001 4.607-2.087 4.607-4.607z"
        />
        <path
          fill="#1e71b8"
          d="M98.713 96.408c0-1.296-1.01-2.376-2.305-2.376-1.296 0-2.304 1.08-2.304 2.376 0 1.224 1.008 2.304 2.304 2.304 1.295 0 2.305-1.08 2.305-2.304z"
        />
        <path
          fill="#33348e"
          d="M54.36 141.696c5.112 5.111 11.088 9.432 17.712 12.527L54.36 113.616l-14.832-33.84c-1.872 5.904-2.88 12.24-2.88 18.792 0 16.776 6.768 32.039 17.712 43.128zm87.335 15.696c18-13.319 29.664-34.704 29.664-58.823 0-24.121-11.664-45.504-29.664-58.896-12.168-9.072-27.287-14.4-43.703-14.4-16.344 0-31.392 5.328-43.632 14.4-18 13.392-29.664 34.776-29.664 58.896 0 24.119 11.664 45.504 29.664 58.823 12.24 9.072 27.288 14.473 43.632 14.473 16.416-.001 31.535-5.401 43.703-14.473zm0-101.952c2.16 2.16 4.248 4.536 6.049 7.056l-6.049 13.824-22.463 51.768-11.736-26.568H88.56l-11.664 26.568L54.36 76.32l-6.048-13.824c1.872-2.521 3.888-4.896 6.048-7.056 3.744-3.816 7.92-7.128 12.528-9.792l21.384 49.608H107.783l21.385-49.608c4.607 2.664 8.783 5.976 12.527 9.792zm0 58.176l14.832-33.84c1.873 5.904 2.953 12.24 2.953 18.792 0 16.775-6.768 32.039-17.785 43.128-5.039 5.111-11.088 9.432-17.711 12.527l17.711-40.607zm-43.703 4.752l17.207 39.096c-5.471 1.656-11.23 2.448-17.207 2.448-5.904 0-11.664-.792-17.136-2.448l17.136-39.096zm0-38.376l17.711-40.248c-5.615-1.656-11.52-2.592-17.711-2.592-6.12 0-12.024.936-17.64 2.592l17.64 40.248z"
        />
        <path
          fill="#fff"
          d="M52.704 139.536a64.248 64.248 0 0 0 17.712 12.527l-17.712-40.607-14.76-33.84C36 83.52 34.992 89.856 34.992 96.408c0 16.776 6.768 32.04 17.712 43.128zm87.407 15.696c17.928-13.32 29.592-34.705 29.592-58.824 0-24.12-11.664-45.504-29.592-58.896-12.24-9.072-27.359-14.472-43.703-14.472s-31.464 5.4-43.704 14.472C34.776 50.904 23.112 72.288 23.112 96.408c0 24.119 11.664 45.504 29.592 58.824 12.24 9.071 27.36 14.472 43.704 14.472s31.463-5.4 43.703-14.472zm0-101.952a56.357 56.357 0 0 1 5.977 7.056l-5.977 13.824-22.535 51.768-11.664-26.568H86.904L75.24 125.928 52.704 74.16l-5.976-13.824a56.265 56.265 0 0 1 5.976-7.056 59.224 59.224 0 0 1 12.6-9.792l21.312 49.608h19.583l21.312-49.608a59.224 59.224 0 0 1 12.6 9.792zm0 58.176l14.76-33.84c1.945 5.904 2.953 12.24 2.953 18.792 0 16.775-6.768 32.04-17.713 43.128a64.243 64.243 0 0 1-17.711 12.527l17.711-40.607zm-43.703 4.752l17.137 39.096a60.847 60.847 0 0 1-17.137 2.448 60.845 60.845 0 0 1-17.136-2.448l17.136-39.096zm0-38.448l17.641-40.176c-5.545-1.728-11.521-2.592-17.641-2.592-6.12 0-12.024.864-17.64 2.592l17.64 40.176z"
        />
      </g>
    </svg>
  );
}
export default VolkswagenIcon;
