import React from 'react';

function HeartIcon(){
  return(
    <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5633 17.5646L10.5608 17.563L10.5602 17.5626C10.5411 17.5498 10.0483 17.2194 9.30408 16.672C8.54285 16.1121 7.52597 15.3304 6.48773 14.434C3.56319 11.9077 1.91488 9.75719 1.36432 8.04869L1.35634 8.0239L1.34707 7.99957C1.11894 7.4004 1.00293 6.77658 1.00072 6.14265L1.00068 6.13393L1.0005 6.12521L1.00001 6.10171C1.00531 3.26762 3.2579 1 5.97772 1C7.49681 1 8.95693 1.73783 9.90295 2.97649L10.6976 4.01694L11.4923 2.97657C12.4386 1.73786 13.899 1 15.4181 1C18.1408 1 20.3953 3.2729 20.3953 6.11174C20.3953 6.12776 20.3947 6.13498 20.3944 6.13773L20.3878 6.19001L20.3867 6.24269C20.3742 6.84584 20.2584 7.43542 20.0425 7.99778L20.0327 8.02337L20.0243 8.04946C19.4737 9.75823 17.8256 11.9085 14.902 14.4338C12.9229 16.1432 11.0428 17.4189 10.8328 17.5602C10.7819 17.5938 10.7376 17.6047 10.6948 17.6047C10.6477 17.6047 10.6035 17.5914 10.5633 17.5646Z" stroke="#B0B0B0" strokeWidth="2"/>
    </svg>

  )
}
export default HeartIcon;
