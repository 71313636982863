import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import debounce from 'lodash.debounce'
import { toast } from "react-toastify";
import { resetConfirmPassword, checkPasswordValidity } from '../../../marketplaceAppUtils'
import convertComplianceNumber from '../../../../../utils/convertPasswordComplianceNumber'
import PasswordInput from '../../../../common/common-components/passwordInput'
import LoadingSpinner from '../../../../common/common-components/loadingSpinner'

import "../password-reset-page/passwordReset.scss"
import "./passwordResetConfirm.scss";

function PasswordResetConfirmForm(props) {
  let history = useHistory();
  const {userToken} = props;
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordCompliance, setPasswordCompliance] = useState();
  const [passwordRequirements, setPasswordRequirements] = useState([]);
  const [passwordsMatch, setPasswordsMatch] = useState();
  const [forgotPasswordResponse, setForgotPasswordResponse] = useState();
  const [loading, setLoading] = useState(false);

  function redirectToLogin() {
    history.push("/marketplace/login");
  }

  // Verify that passwords match
  useEffect(() => {
    if (newPassword == confirmNewPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [newPassword, confirmNewPassword]);

  function resetUserPassword() {
      setLoading(true)
      resetConfirmPassword(newPassword, userToken).then(
        (response) => {
          setLoading(false)
          if (response.status === 200) {
            setForgotPasswordResponse(200)
            toast(`Password successfully changed!`);
            setTimeout(redirectToLogin, 2000);
          } else {
            setForgotPasswordResponse('error')
            toast(`An error occurred. Please try again later.`);
          }
        }
      );
    }

  // Validate password with AIP Standards and store requirements
  async function validatePassword(password) {
    const passwordValidity = await checkPasswordValidity(password);
    if (passwordValidity) {
      const passwordValidityJSON = await passwordValidity.json();
      setPasswordCompliance(passwordValidityJSON.aip_standards.aip_percentage);
      setPasswordRequirements(passwordValidityJSON.aip_standards.aip_pw_rules);
    }
  }

  // debounce setPassword Field
  const debounceSetPassword = (password) => {
    setNewPassword(password);
    validatePassword(password);
  };
  const debouncedPassword = debounce(debounceSetPassword, 500);

  return (
    <div className="password-reset-page-container">
      <div className="password-reset-page">
        <div className="password-reset-form-container">
          <div className="password-reset-page-form">
            <div className="password-reset-page-form-heading">
              Password Reset
            </div>
            {loading && <LoadingSpinner loading />}
                <div>
                  <div className="password-container">
                    <PasswordInput
                      setPasswordField={debouncedPassword}
                      placeholder="Create New Password"
                      debounce={true} />
                    <PasswordInput
                      setPasswordField={setConfirmNewPassword}
                      placeholder="Confirm New Password"
                      debounce={true}
                      formSubmit={resetUserPassword}
                      />
                    <div className="password-requirements">
                      <div
                        className={`password-compliance-bar ${convertComplianceNumber(passwordCompliance).class}`} />
                      {(passwordRequirements.length > 0 ||
                        passwordsMatch == false) && (
                          <ul>
                            {passwordsMatch == false && (
                              <li className="requirement">Passwords must match.</li>
                            )}
                            {passwordRequirements.map((requirement, index) => (
                              <li className="requirement" key={index}>
                                {" "}
                                {requirement}{" "}
                              </li>
                            ))}
                          </ul>
                        )}
                      {passwordRequirements.length == 0 &&
                        passwordsMatch == true &&
                        newPassword.length > 0 && (
                          <div className="password-requirements-met">
                            All password requirements met.
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {forgotPasswordResponse == 200 ? (
                  <span className="confirm-message">You've successfully reset your password!</span>
                ) : (
                  <button
                  className={(passwordRequirements.length == 0 && passwordsMatch == true && newPassword.length > 0) ? 'password-reset-page-form-button' : 'password-reset-page-form-button disabled'}
                  alt="Click to submit"
                  onClick={() => resetUserPassword()}
                  type="button"
                  disabled={passwordRequirements.length == 0 &&
                    passwordsMatch == true &&
                    newPassword.length > 0
                    ? false
                    : true}
                >
                    Submit
                  </button>
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetConfirmForm;
