import React from 'react'

const EyeClosedIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M41.47,14.86l-2.76,2.77A22,22,0,0,1,44.63,25,21.94,21.94,0,0,1,24.92,37.27a21.42,21.42,0,0,1-5.2-.66L17,39.38a27.25,27.25,0,0,0,7.93,1.21A27.89,27.89,0,0,0,50,25,27.64,27.64,0,0,0,41.47,14.86Z" />
      <path d="M46.7,3.23h0L43.5,0,32.94,10.59a27.21,27.21,0,0,0-7.87-1.17h-.18A27.83,27.83,0,0,0,0,25,27.75,27.75,0,0,0,8.45,35.08L0,43.51l3.2,3.2L46.7,3.23ZM11.2,32.33A21.91,21.91,0,0,1,5.37,25a21.93,21.93,0,0,1,9-9.45,12.35,12.35,0,0,0,.47,13.18Zm7.59-7.59a4.71,4.71,0,1,1,6-6Z" />
      <path d="M25,34.13A12.36,12.36,0,0,0,37.36,21.77a11.74,11.74,0,0,0-.27-2.53L22.47,33.86A12.41,12.41,0,0,0,25,34.13Z" />
    </svg>
  )
}
export default EyeClosedIcon
