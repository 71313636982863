export default function validateLetterInput(evt) {
  var theEvent = evt || window.event;
  
  // Handle paste
  if (theEvent.type === "paste") {
    key = e.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  var letters = /^[A-Za-z]+$/;
  if (!letters.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}
