import React from "react";

function AppleStoreIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 100.28">
      <path
        d="M276.11,0H21.16l-2.3,0a32.86,32.86,0,0,0-5,.44A16.65,16.65,0,0,0,9.07,2.05,16,16,0,0,0,5,5a15.57,15.57,0,0,0-3,4.06A16.48,16.48,0,0,0,.49,13.84a31.33,31.33,0,0,0-.45,5c0,.77,0,1.54,0,2.31v58c0,.78,0,1.53,0,2.31a31.33,31.33,0,0,0,.45,5,16.38,16.38,0,0,0,1.56,4.77,16.18,16.18,0,0,0,7,7,16.69,16.69,0,0,0,4.76,1.59,35.59,35.59,0,0,0,5,.44c.77,0,1.53,0,2.3,0H278.83l2.31,0a34.93,34.93,0,0,0,5-.44,17.19,17.19,0,0,0,4.79-1.59,16.23,16.23,0,0,0,7-7,16.69,16.69,0,0,0,1.56-4.77,35.22,35.22,0,0,0,.46-5c0-.78,0-1.53,0-2.31s0-1.82,0-2.74V23.91c0-.92,0-1.83,0-2.74s0-1.54,0-2.31a35.22,35.22,0,0,0-.46-5A16.79,16.79,0,0,0,298,9.07,15.8,15.8,0,0,0,295,5a16.17,16.17,0,0,0-4.05-3A17.15,17.15,0,0,0,286.15.48a32.28,32.28,0,0,0-5-.44l-2.31,0Z"
        style={{fill: "#b6b6b6"}}
      />
      <path d="M21.17,98.09c-.76,0-1.51,0-2.27,0a32,32,0,0,1-4.68-.41,15.18,15.18,0,0,1-4.16-1.37,13.8,13.8,0,0,1-3.5-2.55A13.43,13.43,0,0,1,4,90.23a14.52,14.52,0,0,1-1.36-4.16,31.25,31.25,0,0,1-.41-4.7c0-.53,0-2.29,0-2.29V21.17s0-1.73,0-2.24a31,31,0,0,1,.41-4.7A14.52,14.52,0,0,1,4,10.07,13.51,13.51,0,0,1,6.55,6.56,13.82,13.82,0,0,1,10.06,4a14.66,14.66,0,0,1,4.15-1.36,31.6,31.6,0,0,1,4.7-.42l2.26,0H278.82l2.28,0a30.64,30.64,0,0,1,4.66.4A15.06,15.06,0,0,1,290,4a13.64,13.64,0,0,1,3.49,2.54A13.86,13.86,0,0,1,296,10.07a14.89,14.89,0,0,1,1.34,4.14,32.11,32.11,0,0,1,.43,4.73c0,.71,0,1.47,0,2.23,0,.94,0,1.84,0,2.74V76.38c0,.91,0,1.8,0,2.69s0,1.56,0,2.33a31.3,31.3,0,0,1-.43,4.65A14.5,14.5,0,0,1,296,90.24a13.83,13.83,0,0,1-2.55,3.47,13.47,13.47,0,0,1-3.5,2.56,14.85,14.85,0,0,1-4.19,1.38,31.65,31.65,0,0,1-4.68.41c-.74,0-1.51,0-2.25,0H21.17Z" />
      <path
        d="M62.1,50.89C62,44,67.74,40.64,68,40.48a12.68,12.68,0,0,0-10-5.4c-4.21-.45-8.29,2.51-10.44,2.51s-5.49-2.47-9.05-2.4A13.32,13.32,0,0,0,27.3,42c-4.85,8.4-1.23,20.73,3.41,27.52,2.33,3.32,5,7,8.6,6.9s4.77-2.22,9-2.22,5.38,2.22,9,2.14,6.09-3.34,8.33-6.69a27.33,27.33,0,0,0,3.8-7.76A12,12,0,0,1,62.1,50.89Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M55.25,30.61A12.19,12.19,0,0,0,58,21.86,12.42,12.42,0,0,0,50,26a11.69,11.69,0,0,0-2.87,8.43C50.17,34.68,53.3,32.92,55.25,30.61Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M106.05,68H94.19l-2.85,8.41h-5L97.55,45.32h5.22L114,76.45H108.9ZM95.41,64.16h9.41L100.18,50.5h-.13Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M138.29,65.11c0,7-3.78,11.58-9.48,11.58a7.7,7.7,0,0,1-7.14-4h-.11V84h-4.65V53.76h4.51v3.77h.08a8.07,8.07,0,0,1,7.23-4C134.49,53.52,138.29,58.07,138.29,65.11Zm-4.79,0c0-4.6-2.38-7.62-6-7.62s-6,3.08-6,7.62,2.39,7.63,6,7.63S133.5,69.74,133.5,65.11Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M163.27,65.11c0,7-3.78,11.58-9.47,11.58a7.71,7.71,0,0,1-7.15-4h-.1V84h-4.66V53.76h4.51v3.77h.08a8.07,8.07,0,0,1,7.23-4C159.47,53.52,163.27,58.07,163.27,65.11Zm-4.79,0c0-4.6-2.38-7.62-6-7.62s-5.95,3.08-5.95,7.62,2.39,7.63,5.95,7.63S158.48,69.74,158.48,65.11Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M179.78,67.78c.34,3.09,3.34,5.11,7.44,5.11,3.93,0,6.75-2,6.75-4.81,0-2.41-1.7-3.86-5.73-4.85l-4-1c-5.72-1.38-8.37-4.06-8.37-8.4,0-5.37,4.68-9.06,11.33-9.06s11.09,3.69,11.24,9.06h-4.7c-.29-3.1-2.85-5-6.61-5s-6.32,1.9-6.32,4.66c0,2.2,1.64,3.5,5.65,4.49l3.43.84c6.39,1.51,9.05,4.08,9.05,8.63,0,5.83-4.64,9.47-12,9.47C180,77,175.31,73.41,175,67.78Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M209,48.38v5.38h4.32v3.69H209V70c0,1.94.86,2.85,2.76,2.85a14.75,14.75,0,0,0,1.53-.11v3.67a12.76,12.76,0,0,1-2.58.21c-4.6,0-6.39-1.72-6.39-6.12v-13H201V53.76h3.3V48.38Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M215.77,65.11c0-7.14,4.2-11.63,10.76-11.63S237.3,58,237.3,65.11s-4.17,11.63-10.77,11.63S215.77,72.27,215.77,65.11Zm16.78,0c0-4.9-2.24-7.79-6-7.79s-6,2.91-6,7.79,2.25,7.78,6,7.78S232.55,70,232.55,65.11Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M241.14,53.76h4.44v3.86h.11a5.41,5.41,0,0,1,5.46-4.1,7.64,7.64,0,0,1,1.6.17v4.36a6.62,6.62,0,0,0-2.1-.28c-3,0-4.85,2-4.85,5.22V76.45h-4.66Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M274.23,69.79c-.63,4.12-4.64,6.95-9.78,6.95-6.6,0-10.7-4.43-10.7-11.53s4.12-11.73,10.51-11.73,10.23,4.31,10.23,11.19v1.6h-16v.28c0,3.88,2.44,6.43,6.1,6.43,2.59,0,4.62-1.23,5.24-3.19ZM258.48,63h11.35c-.11-3.47-2.33-5.76-5.57-5.76S258.72,59.58,258.48,63Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M94.83,21.89c4.44,0,7,2.72,7,7.43s-2.58,7.53-7,7.53h-5.4v-15ZM91.75,34.73h2.82c3.13,0,4.93-1.95,4.93-5.38S97.67,24,94.57,24H91.75Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M104.49,31.2c0-3.64,2-5.86,5.33-5.86s5.32,2.22,5.32,5.86-2,5.87-5.32,5.87S104.49,34.86,104.49,31.2Zm8.36,0c0-2.45-1.1-3.88-3-3.88s-3,1.43-3,3.88,1.09,3.88,3,3.88S112.85,33.66,112.85,31.2Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M129.3,36.85H127l-2.33-8.32h-.18l-2.32,8.32h-2.29l-3.11-11.29H119l2,8.61h.17l2.32-8.61h2.14L128,34.17h.17l2-8.61h2.22Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M135,25.56h2.15v1.79h.16a3.39,3.39,0,0,1,3.37-2c2.52,0,3.91,1.51,3.91,4.2v7.31h-2.23V30.1c0-1.82-.79-2.72-2.43-2.72a2.59,2.59,0,0,0-2.7,2.86v6.61H135Z"
        style={{fill: "#fff"}}
      />
      <path d="M148.15,21.15h2.23v15.7h-2.23Z" style={{fill: "#fff"}} />
      <path
        d="M153.47,31.2c0-3.64,2-5.86,5.33-5.86s5.32,2.22,5.32,5.86-2,5.87-5.32,5.87S153.47,34.86,153.47,31.2Zm8.36,0c0-2.45-1.1-3.88-3-3.88s-3,1.43-3,3.88,1.08,3.88,3,3.88S161.83,33.66,161.83,31.2Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M166.47,33.66c0-2,1.51-3.21,4.2-3.38l3.05-.17v-1c0-1.19-.78-1.86-2.31-1.86-1.24,0-2.1.46-2.35,1.25H166.9c.23-1.94,2.06-3.18,4.62-3.18,2.83,0,4.42,1.41,4.42,3.79v7.72H173.8V35.26h-.18A3.8,3.8,0,0,1,170.23,37C168.05,37,166.47,35.72,166.47,33.66Zm7.25-1v-.94l-2.75.17c-1.56.11-2.26.64-2.26,1.63s.88,1.61,2.09,1.61A2.66,2.66,0,0,0,173.72,32.69Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M178.87,31.2c0-3.57,1.83-5.83,4.69-5.83a3.73,3.73,0,0,1,3.46,2h.17v-6.2h2.22v15.7h-2.13V35.06h-.18a3.9,3.9,0,0,1-3.54,2C180.69,37,178.87,34.77,178.87,31.2Zm2.3,0c0,2.39,1.13,3.83,3,3.83s3-1.46,3-3.82-1.18-3.84-3-3.84S181.17,28.82,181.17,31.2Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M198.63,31.2c0-3.64,2-5.86,5.33-5.86s5.32,2.22,5.32,5.86-2,5.87-5.32,5.87S198.63,34.86,198.63,31.2Zm8.36,0c0-2.45-1.1-3.88-3-3.88s-3,1.43-3,3.88,1.09,3.88,3,3.88S207,33.66,207,31.2Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M212.27,25.56h2.14v1.79h.17a3.39,3.39,0,0,1,3.37-2c2.52,0,3.91,1.51,3.91,4.2v7.31h-2.23V30.1c0-1.82-.79-2.72-2.44-2.72a2.58,2.58,0,0,0-2.69,2.86v6.61h-2.23Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M234.44,22.75v2.86h2.45v1.88h-2.45v5.8c0,1.18.49,1.7,1.6,1.7a7.62,7.62,0,0,0,.85-.05V36.8a7.45,7.45,0,0,1-1.21.11c-2.48,0-3.47-.87-3.47-3V27.49h-1.79V25.61h1.79V22.75Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M239.93,21.15h2.21v6.22h.18a3.48,3.48,0,0,1,3.44-2c2.4,0,3.89,1.52,3.89,4.21v7.29h-2.23V30.11c0-1.8-.84-2.72-2.42-2.72a2.64,2.64,0,0,0-2.84,2.86v6.6h-2.23Z"
        style={{fill: "#fff"}}
      />
      <path
        d="M262.64,33.8c-.51,2-2.31,3.27-4.89,3.27-3.24,0-5.22-2.22-5.22-5.83s2-5.9,5.21-5.9,5,2.15,5,5.69v.78h-8v.12c.07,2,1.23,3.24,3,3.24a2.7,2.7,0,0,0,2.68-1.37Zm-7.84-3.64h5.7a2.72,2.72,0,0,0-2.78-2.92A2.89,2.89,0,0,0,254.8,30.16Z"
        style={{fill: "#fff"}}
      />
    </svg>
  );
}
export default AppleStoreIcon;
