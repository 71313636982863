import React,{useState} from 'react'
import  MobileRahalAd from '../../../../common/common-assets/images/iPacket-RLL_WebBannerAd_SRP.gif'
import {Link} from 'react-router-dom'

import './searchRahalAdMobile.scss'
function SearchRahalAdMobile(){
  return(
    <div className="search-rahal-ad-mobile-container">
      <Link  to={'/racing'}>
        <img className="search-rahal-ad-mobile" alt="iPacket is the official and primary sponsor of Grahal Rahal's entry at the Bommarito 500!" src={MobileRahalAd}/>
      </Link>
    </div>
  )
}
export default SearchRahalAdMobile
