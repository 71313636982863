import React from "react";

function HyundaiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path
          fill="#295ba7"
          d="M8.504 113.751h6.803v7.48H27.46v-7.48h6.718v20.993H27.46v-8.839H15.22v8.755H8.504v-20.909zM34.942 113.751h6.629l6.971 8.075 6.886-8.075h6.545l-10.116 12.834v8.159h-6.715v-8.754l-10.2-12.239zM62.823 113.751h6.716v16.234h10.795c1.7 0 2.805-1.697 2.805-3.484v-12.75h6.714v16.745c0 2.634-2.974 4.164-4.675 4.164H62.823v-20.909zM91.298 113.751h22.358c2.635 0 4.76 1.613 4.76 4.889v16.062l-6.715.042v-12.749c0-1.782-.596-2.845-2.891-2.845H98.695v15.641h-7.397v-21.04zM120.457 113.751h19.549c3.912 0 6.93 3.144 6.93 5.566v9.648c-.043 1.699-2.424 5.778-5.398 5.778h-21.08v-20.992h-.001zm6.842 16.32h10.318c1.486 0 2.635-1.105 2.635-2.467v-6.119c0-1.488-1.223-2.377-2.793-2.377h-10.16v10.963zM155.225 113.662h20.953v21.082h-7.482v-5.437h-12.879v5.437h-7.564v-13.686c-.171-4.588 3.911-7.396 6.972-7.396zm.507 7.486v3.396h12.922v-5.357h-10.883c-1.273.001-2.039.768-2.039 1.961zM177.537 113.709h6.715v21.673h-6.715v-21.673zM96.787 52.968c-27.866 0-50.457 11.154-50.457 24.909 0 13.756 22.591 24.911 50.457 24.911 27.864 0 50.456-11.154 50.456-24.911-.001-13.754-22.593-24.909-50.456-24.909zM92 58.512c5.759-2.036 22.869 0 29.515 3.192 2.127 2.04-9.484 8.594-23.309 11.435C78.44 77.125 87.834 61.88 92 58.512zM58.055 88.027c-4.345-3.098-12.853-11.964 1.241-21.181 7.445-4.342 11.61-5.32 16.75-6.382 2.483-.534 4.255.088 2.039 1.95-4.697 4.875-14.447 21.003-15.332 24.551-.711 2.395-2.128 3.012-4.698 1.062zm40.591 10.284c-8.33.884-26.853-1.862-27.209-4.252.356-5.765 29.426-15.337 34.654-10.019 2.218 4.165-2.036 14.004-7.445 14.271zm42.368-16.756c-2.129 4.876-9.307 10.374-24.373 14.097-2.307.528-3.104-.888-1.598-2.218 2.484-1.506 14.449-21.536 15.955-25.527.887-2.04 2.57-1.239 3.635-.443 4.787 3.101 8.42 8.154 6.381 14.091z"
        />
      </g>
    </svg>
  );
}
export default HyundaiIcon;
