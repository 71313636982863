import React from "react";

function BmwIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 1013.718 1013.696"
    >
      <linearGradient
        id="aGradBmw"
        x1="-1120.126"
        x2="-44.679"
        y1="2189.824"
        y2="1150.587"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#dff4ff" />
        <stop offset=".098" stopColor="#d6eaf5" />
        <stop offset=".268" stopColor="#bfd0d9" />
        <stop offset=".488" stopColor="#98a5ac" />
        <stop offset=".747" stopColor="#646b6e" />
        <stop offset="1" stopColor="#2b2b2b" />
      </linearGradient>
      <path
        fill="url(#aGradBmw)"
        d="M506.86 0C226.94 0 0 226.918 0 506.848c0 279.927 226.94 506.849 506.86 506.849 279.94 0 506.857-226.922 506.857-506.849C1013.719 226.918 786.802 0 506.86 0zm0 988.352c-265.939 0-481.495-215.573-481.495-481.504 0-265.927 215.556-481.512 481.495-481.512 265.938 0 481.511 215.584 481.511 481.512 0 265.93-215.573 481.504-481.511 481.504z"
      />
      <path
        fill="#333"
        d="M992.004 506.848c0 267.914-217.228 485.134-485.144 485.134-267.919 0-485.123-217.22-485.123-485.134 0-267.929 217.204-485.133 485.123-485.133 267.916 0 485.144 217.204 485.144 485.133z"
      />
      <radialGradient
        id="bGradBmw"
        cx="-1052.247"
        cy="2101.652"
        r="720.108"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".306" stopColor="#a8a8a8" />
        <stop offset=".646" stopColor="#4f4f4f" />
        <stop offset=".885" stopColor="#161616" />
        <stop offset="1" />
      </radialGradient>
      <path
        fill="url(#bGradBmw)"
        d="M988.371 506.848c0 265.931-215.573 481.504-481.511 481.504-265.939 0-481.495-215.573-481.495-481.504 0-265.927 215.556-481.512 481.495-481.512 265.938 0 481.511 215.585 481.511 481.512z"
      />
      <linearGradient
        id="cGradBmw"
        x1="-818.029"
        x2="-133.081"
        y1="1409.977"
        y2="748.092"
        gradientTransform="matrix(-1 0 0 1 58.906 -545.52)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#dff4ff" />
        <stop offset=".086" stopColor="#d6eaf5" />
        <stop offset=".233" stopColor="#bed0d9" />
        <stop offset=".424" stopColor="#96a4ac" />
        <stop offset=".652" stopColor="#5f686d" />
        <stop offset=".907" stopColor="#1a1d1e" />
        <stop offset="1" />
      </linearGradient>
      <path
        fill="url(#cGradBmw)"
        d="M829.677 506.848c0 178.28-144.53 322.804-322.815 322.804-178.289 0-322.819-144.522-322.819-322.804 0-178.289 144.53-322.815 322.819-322.815 178.284-.001 322.815 144.527 322.815 322.815z"
      />
      <path d="M519.791 175.308l33.533-88.877v88.877h25.469V48.021h-38.275l-34.814 91.136h.331l-34.812-91.136h-38.276v127.287h25.469V86.431l33.533 88.877zM869.313 232.384l-43.514 63.447 68.347-33.615 18.538 22.231-107.569 54.012-21.735-26.315 41.697-62.869-.247-.31-69.357 29.686-21.984-26.138 72.532-96.044 18.542 22.243-45.417 61.159 70.287-31.349z" />
      <path
        fill="#333"
        d="M281.427 208.068c-10.251-9.951-26.069-12.951-40.939-3.733 2.847-7.363 1.691-14.858.187-19.015-6.414-11.662-8.662-13.137-13.899-17.561-17.097-14.324-35.082-2.093-47.93 13.219l-62.116 74.028 97.65 81.925 65.5-78.047c14.971-17.838 17.282-35.523 1.547-50.816zm-126.321 35.7l37.311-44.464c4.33-5.146 14.106-4.939 20.375.341 6.908 5.795 6.929 14.002 2.289 19.54l-36.896 43.95-23.079-19.367zm102.934 7.393l-38.896 46.353-24.355-20.47 39.186-46.711c4.434-5.281 14.312-6.817 20.974-1.229 7.504 6.312 8.246 15.912 3.091 22.057z"
      />
      <radialGradient
        id="dGradBmw"
        cx="-1181.576"
        cy="2174.985"
        r="1730.313"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".31" stopColor="#fdfdfd" />
        <stop offset=".451" stopColor="#f6f6f6" />
        <stop offset=".557" stopColor="#e9e9e9" />
        <stop offset=".646" stopColor="#d7d7d7" />
        <stop offset=".724" stopColor="#bfbfbf" />
        <stop offset=".794" stopColor="#a2a2a2" />
        <stop offset=".859" stopColor="gray" />
        <stop offset=".92" stopColor="#575757" />
        <stop offset=".975" stopColor="#2b2b2b" />
        <stop offset="1" stopColor="#141414" />
      </radialGradient>
      <path
        fill="url(#dGradBmw)"
        d="M520.06 170.39l33.533-88.875v88.875h25.47V43.103h-38.279l-34.811 91.133h.33l-34.812-91.133h-38.278V170.39h25.47V81.515l33.536 88.875z"
      />
      <radialGradient
        id="eGradBmw"
        cx="-1181.653"
        cy="2174.985"
        r="1730.461"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".31" stopColor="#fdfdfd" />
        <stop offset=".451" stopColor="#f6f6f6" />
        <stop offset=".557" stopColor="#e9e9e9" />
        <stop offset=".646" stopColor="#d7d7d7" />
        <stop offset=".724" stopColor="#bfbfbf" />
        <stop offset=".794" stopColor="#a2a2a2" />
        <stop offset=".859" stopColor="gray" />
        <stop offset=".92" stopColor="#575757" />
        <stop offset=".975" stopColor="#2b2b2b" />
        <stop offset="1" stopColor="#141414" />
      </radialGradient>
      <path
        fill="url(#eGradBmw)"
        d="M869.563 223.844l-43.496 63.449 68.347-33.626 18.52 22.242-107.553 54.012-21.736-26.325 41.7-62.861-.245-.319-69.38 29.698-21.96-26.151 72.531-96.033 18.539 22.234-45.41 61.158 70.26-31.337z"
      />
      <radialGradient
        id="fGradBmw"
        cx="-1181.748"
        cy="2175.493"
        r="1731.785"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".31" stopColor="#fdfdfd" />
        <stop offset=".451" stopColor="#f6f6f6" />
        <stop offset=".557" stopColor="#e9e9e9" />
        <stop offset=".646" stopColor="#d7d7d7" />
        <stop offset=".724" stopColor="#bfbfbf" />
        <stop offset=".794" stopColor="#a2a2a2" />
        <stop offset=".859" stopColor="gray" />
        <stop offset=".92" stopColor="#575757" />
        <stop offset=".975" stopColor="#2b2b2b" />
        <stop offset="1" stopColor="#141414" />
      </radialGradient>
      <path
        fill="url(#fGradBmw)"
        d="M276.868 205.563c-10.229-9.951-26.068-12.953-40.916-3.743 2.824-7.364 1.67-14.86.166-18.996-6.415-11.682-8.642-13.137-13.923-17.57-17.096-14.333-35.059-2.095-47.887 13.231l-62.14 74.016 97.653 81.926 65.499-78.059c14.954-17.839 17.283-35.512 1.548-50.805zm-126.316 35.698l37.307-44.453c4.312-5.155 14.086-4.949 20.376.319 6.909 5.806 6.93 14.023 2.268 19.54l-36.873 43.959-23.078-19.365zm102.951 7.393l-38.896 46.352-24.398-20.47 39.207-46.721c4.434-5.269 14.291-6.806 20.953-1.216 7.547 6.32 8.29 15.9 3.134 22.055z"
      />
      <radialGradient
        id="gGradBmw"
        cx="-871.677"
        cy="1935.101"
        r="466.718"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e6e6e6" />
        <stop offset=".104" stopColor="#d6d6d6" />
        <stop offset=".307" stopColor="#adadad" />
        <stop offset=".588" stopColor="#6c6c6c" />
        <stop offset=".933" stopColor="#121212" />
        <stop offset="1" />
      </radialGradient>
      <path
        fill="url(#gGradBmw)"
        d="M194.788 506.853c0-172.358 139.725-312.083 312.073-312.083 172.367 0 312.072 139.724 312.072 312.083 0 172.351-139.705 312.07-312.072 312.07-172.35 0-312.073-139.72-312.073-312.07z"
      />
      <radialGradient
        id="hGradBmw"
        cx="-744.024"
        cy="1872.327"
        r="678.742"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".344" stopColor="#fdfdfd" />
        <stop offset=".481" stopColor="#f6f6f6" />
        <stop offset=".582" stopColor="#eaeaea" />
        <stop offset=".665" stopColor="#d8d8d8" />
        <stop offset=".737" stopColor="#c2c2c2" />
        <stop offset=".802" stopColor="#a6a6a6" />
        <stop offset=".86" stopColor="#848484" />
        <stop offset=".913" stopColor="#5f5f5f" />
        <stop offset=".949" stopColor="#404040" />
        <stop offset=".957" stopColor="#404040" />
        <stop offset="1" stopColor="#404040" />
        <stop offset="1" />
      </radialGradient>
      <path
        fill="url(#hGradBmw)"
        d="M203.76 506.853c0-167.399 135.701-303.112 303.102-303.112s303.12 135.712 303.12 303.112S674.26 809.965 506.861 809.965 203.76 674.253 203.76 506.853z"
      />
      <radialGradient
        id="iGradBmw"
        cx="-943.312"
        cy="2129.614"
        r="1202.06"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".169" stopColor="#fff" />
        <stop offset=".196" stopColor="#e0eff7" />
        <stop offset=".241" stopColor="#b2d9ec" />
        <stop offset=".287" stopColor="#8bc5e2" />
        <stop offset=".335" stopColor="#6bb5da" />
        <stop offset=".384" stopColor="#52a9d4" />
        <stop offset=".436" stopColor="#41a0cf" />
        <stop offset=".491" stopColor="#369bcd" />
        <stop offset=".556" stopColor="#39c" />
        <stop offset=".606" stopColor="#3396c8" />
        <stop offset=".655" stopColor="#328ebc" />
        <stop offset=".705" stopColor="#3180a8" />
        <stop offset=".754" stopColor="#2f6d8c" />
        <stop offset=".803" stopColor="#2d5468" />
        <stop offset=".851" stopColor="#2a373d" />
        <stop offset=".871" stopColor="#292929" />
      </radialGradient>
      <path
        fill="url(#iGradBmw)"
        d="M203.284 506.853H506.86V203.277c-167.669 0-303.576 135.908-303.576 303.576z"
      />
      <radialGradient
        id="jGradBmw"
        cx="-943.312"
        cy="2129.623"
        r="1202.037"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".169" stopColor="#fff" />
        <stop offset=".196" stopColor="#e0eff7" />
        <stop offset=".241" stopColor="#b2d9ec" />
        <stop offset=".287" stopColor="#8bc5e2" />
        <stop offset=".335" stopColor="#6bb5da" />
        <stop offset=".384" stopColor="#52a9d4" />
        <stop offset=".436" stopColor="#41a0cf" />
        <stop offset=".491" stopColor="#369bcd" />
        <stop offset=".556" stopColor="#39c" />
        <stop offset=".606" stopColor="#3396c8" />
        <stop offset=".655" stopColor="#328ebc" />
        <stop offset=".705" stopColor="#3180a8" />
        <stop offset=".754" stopColor="#2f6d8c" />
        <stop offset=".803" stopColor="#2d5468" />
        <stop offset=".851" stopColor="#2a373d" />
        <stop offset=".871" stopColor="#292929" />
      </radialGradient>
      <path
        fill="url(#jGradBmw)"
        d="M506.86 506.853v303.578c167.667 0 303.576-135.933 303.576-303.578H506.86z"
      />
      <radialGradient
        id="kGradBmw"
        cx="-865.303"
        cy="1929.222"
        r="457.773"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e6e6e6" />
        <stop offset=".104" stopColor="#d6d6d6" />
        <stop offset=".307" stopColor="#adadad" />
        <stop offset=".588" stopColor="#6c6c6c" />
        <stop offset=".933" stopColor="#121212" />
        <stop offset="1" />
      </radialGradient>
      <path
        fill="url(#kGradBmw)"
        d="M812.95 501.458H512.242V200.75h-10.766v300.708H200.768v10.767h300.708v300.717h10.766V512.225H812.95z"
      />
      <linearGradient
        id="lGradBmw"
        x1="-599.096"
        x2="-152.677"
        y1="2043.521"
        y2="1612.133"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f0f4ff" />
        <stop offset=".109" stopColor="#ebeff9" />
        <stop offset=".247" stopColor="#dce0ea" />
        <stop offset=".403" stopColor="#c4c7cf" />
        <stop offset=".57" stopColor="#a2a4ab" />
        <stop offset=".747" stopColor="#76777c" />
        <stop offset=".929" stopColor="#414243" />
        <stop offset="1" stopColor="#2b2b2b" />
      </linearGradient>
      <path
        fill="url(#lGradBmw)"
        d="M512.242 209.267c160.142 2.848 289.366 132.062 292.232 292.191h5.359c-2.862-163.099-134.481-294.736-297.593-297.583l.002 5.392z"
      />
      <path
        fill="#c2d7e8"
        d="M208.729 501.418c2.845-160.347 132.256-289.747 292.604-292.604v-5.383c-163.336 2.856-295.12 134.669-297.987 297.986l5.383.001z"
      />
      <linearGradient
        id="mGradBmw"
        x1="-961.44"
        x2="-515.015"
        y1="1679.306"
        y2="1247.911"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f0f4ff" />
        <stop offset=".109" stopColor="#ebeff9" />
        <stop offset=".247" stopColor="#dce0ea" />
        <stop offset=".403" stopColor="#c4c7cf" />
        <stop offset=".57" stopColor="#a2a4ab" />
        <stop offset=".747" stopColor="#76777c" />
        <stop offset=".929" stopColor="#414243" />
        <stop offset="1" stopColor="#2b2b2b" />
      </linearGradient>
      <path
        fill="url(#mGradBmw)"
        d="M501.476 804.433c-160.14-2.844-289.364-132.068-292.211-292.208h-5.381c2.865 163.108 134.483 294.75 297.593 297.595l-.001-5.387z"
      />
      <path
        fill="#12404f"
        d="M804.475 512.225c-2.866 160.14-132.092 289.364-292.232 292.208v5.387c163.11-2.845 294.747-134.485 297.593-297.595h-5.361z"
      />
      <linearGradient
        id="nGradBmw"
        x1="-745.471"
        x2="-513.982"
        y1="1833.135"
        y2="1609.44"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#c2d7e8" />
        <stop offset=".134" stopColor="#bacfe1" />
        <stop offset=".343" stopColor="#a4bacd" />
        <stop offset=".6" stopColor="#8098ac" />
        <stop offset=".894" stopColor="#4e697f" />
        <stop offset="1" stopColor="#3a566d" />
      </linearGradient>
      <path
        fill="url(#nGradBmw)"
        d="M495.724 203.432v292.119h-292.11v5.764h297.862V203.432z"
      />
      <linearGradient
        id="oGradBmw"
        x1="-861.404"
        x2="-419.473"
        y1="1778.32"
        y2="1351.268"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f0f4ff" />
        <stop offset=".097" stopColor="#e8ebf6" />
        <stop offset=".257" stopColor="#d1d4dd" />
        <stop offset=".459" stopColor="#abaeb5" />
        <stop offset=".695" stopColor="#78797d" />
        <stop offset=".958" stopColor="#363637" />
        <stop offset="1" stopColor="#2b2b2b" />
      </linearGradient>
      <path
        fill="url(#oGradBmw)"
        d="M495.229 806.995V518.391h-289.2V512.7h294.892V806.995z"
      />
      <linearGradient
        id="pGradBmw"
        x1="-535.674"
        x2="-380.656"
        y1="1619.907"
        y2="1470.109"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#d9def0" />
        <stop offset=".123" stopColor="#d4daec" />
        <stop offset=".263" stopColor="#c7cfe2" />
        <stop offset=".411" stopColor="#b0bcd1" />
        <stop offset=".566" stopColor="#90a1b8" />
        <stop offset=".725" stopColor="#677f99" />
        <stop offset=".885" stopColor="#355674" />
        <stop offset=".972" stopColor="#173d5d" />
      </linearGradient>
      <path
        fill="url(#pGradBmw)"
        d="M518.409 806.995V518.391h288.602V512.7H512.737V806.995z"
      />
      <linearGradient
        id="qGradBmw"
        x1="-694.251"
        x2="-252.797"
        y1="1940.398"
        y2="1513.809"
        gradientTransform="matrix(1 0 0 -1 1045.93 2135.176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f0f4ff" />
        <stop offset=".109" stopColor="#ebeff9" />
        <stop offset=".247" stopColor="#dce0ea" />
        <stop offset=".403" stopColor="#c4c7cf" />
        <stop offset=".57" stopColor="#a2a4ab" />
        <stop offset=".747" stopColor="#76777c" />
        <stop offset=".929" stopColor="#414243" />
        <stop offset="1" stopColor="#2b2b2b" />
      </linearGradient>
      <path
        fill="url(#qGradBmw)"
        d="M518.409 206.011v288.602h288.602v5.682H512.737V206.011z"
      />
    </svg>
  );
}
export default BmwIcon;
