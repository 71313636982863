import React from 'react'

const GoogleGIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.87 100">
            <path d="M97.87,51.12a57.83,57.83,0,0,0-.79-9.45H50V60.46H77A23.22,23.22,0,0,1,67,75.37v12.5H83C92.46,79.17,97.87,66.33,97.87,51.12Z" fill="#4285f4"/>
            <path d="M50,100c13.5,0,24.79-4.5,33-12.13L67,75.37a30.17,30.17,0,0,1-17,4.84c-13,0-24.08-8.79-28-20.67H5.37V72.42A49.94,49.94,0,0,0,50,100Z" fill="#34a853"/>
            <path d="M22,59.54A29.07,29.07,0,0,1,20.37,50,30.15,30.15,0,0,1,22,40.46V27.58H5.37a49.49,49.49,0,0,0,0,44.84Z" fill="#fbbc05"/>
            <path d="M50,19.79a27.14,27.14,0,0,1,19.17,7.5L83.42,13A47.83,47.83,0,0,0,50,0,49.94,49.94,0,0,0,5.37,27.58L22,40.46C25.92,28.58,37,19.79,50,19.79Z" fill="#ea4335"/>
        </svg>
    )
}

export default GoogleGIcon