import React from "react";

function GooglePlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 300 88.89"
    >
      <defs>
        <linearGradient
          id="aGradGoogle"
          x1="48.44"
          y1="508.97"
          x2="11.15"
          y2="471.68"
          gradientTransform="matrix(1, 0, 0, -1, 0, 528.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00a0ff" />
          <stop offset="0.01" stopColor="#00a1ff" />
          <stop offset="0.26" stopColor="#00beff" />
          <stop offset="0.51" stopColor="#00d2ff" />
          <stop offset="0.76" stopColor="#00dfff" />
          <stop offset="1" stopColor="#00e3ff" />
        </linearGradient>
        <linearGradient
          id="bGradGoogle"
          x1="75.19"
          y1="483.88"
          x2="21.42"
          y2="483.88"
          gradientTransform="matrix(1, 0, 0, -1, 0, 528.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffe000" />
          <stop offset="0.41" stopColor="#ffbd00" />
          <stop offset="0.78" stopColor="orange" />
          <stop offset="1" stopColor="#ff9c00" />
        </linearGradient>
        <linearGradient
          id="cGradGoogle"
          x1="55.17"
          y1="478.78"
          x2="4.6"
          y2="428.21"
          gradientTransform="matrix(1, 0, 0, -1, 0, 528.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff3a44" />
          <stop offset="1" stopColor="#c31162" />
        </linearGradient>
        <linearGradient
          id="dGradGoogle"
          x1="16.22"
          y1="527.94"
          x2="38.8"
          y2="505.35"
          gradientTransform="matrix(1, 0, 0, -1, 0, 528.33)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#32a071" />
          <stop offset="0.07" stopColor="#2da771" />
          <stop offset="0.48" stopColor="#15cf74" />
          <stop offset="0.8" stopColor="#06e775" />
          <stop offset="1" stopColor="#00f076" />
        </linearGradient>
      </defs>
      <rect width="300" height="88.89" rx="11.11" />
      <path
        d="M288.89,1.78a9.33,9.33,0,0,1,9.33,9.33V77.78a9.34,9.34,0,0,1-9.33,9.33H11.11a9.34,9.34,0,0,1-9.33-9.33V11.11a9.33,9.33,0,0,1,9.33-9.33H288.89m0-1.78H11.11A11.14,11.14,0,0,0,0,11.11V77.78A11.14,11.14,0,0,0,11.11,88.89H288.89A11.14,11.14,0,0,0,300,77.78V11.11A11.14,11.14,0,0,0,288.89,0Z"
        fill="#a6a6a6"
      />
      <path
        d="M105.37,22.76a6,6,0,0,1-1.65,4.45,6.46,6.46,0,0,1-4.9,2,6.86,6.86,0,0,1-6.93-7,6.94,6.94,0,0,1,9.67-6.4,5.51,5.51,0,0,1,2.08,1.49l-1.17,1.17a4.48,4.48,0,0,0-3.65-1.58,5.16,5.16,0,0,0-5.18,5.32,5.24,5.24,0,0,0,8.9,3.84,4.19,4.19,0,0,0,1.12-2.7H98.82v-1.6h6.46A5.89,5.89,0,0,1,105.37,22.76Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M115.62,17.19h-6.07v4.23H115V23h-5.47v4.23h6.07v1.64h-7.79V15.56h7.79Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M122.84,28.89h-1.71V17.19H117.4V15.56h9.17v1.63h-3.73Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M133.2,28.89V15.56h1.71V28.89Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M142.51,28.89h-1.72V17.19h-3.72V15.56h9.16v1.63h-3.72Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M163.58,27.17a6.93,6.93,0,0,1-9.78,0,6.84,6.84,0,0,1-2-5,6.81,6.81,0,0,1,2-4.94,6.9,6.9,0,0,1,9.77,0,6.83,6.83,0,0,1,2,4.93A6.88,6.88,0,0,1,163.58,27.17Zm-8.52-1.12a5.13,5.13,0,0,0,7.25,0,5.22,5.22,0,0,0,1.48-3.83,5.18,5.18,0,0,0-1.48-3.82,5.13,5.13,0,0,0-7.25,0,5.22,5.22,0,0,0-1.48,3.82A5.26,5.26,0,0,0,155.06,26.05Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M167.94,28.89V15.56H170l6.48,10.37h.07l-.07-2.57v-7.8h1.71V28.89h-1.78L169.66,18h-.08l.08,2.57v8.31Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit= "10"
        strokeWidth="0.4444444656372071px"
      />
      <path
        d="M151.41,48.34a9.45,9.45,0,1,0,9.49,9.45A9.31,9.31,0,0,0,151.41,48.34Zm0,15.18a5.74,5.74,0,1,1,5.34-5.73A5.47,5.47,0,0,1,151.41,63.52ZM130.72,48.34a9.45,9.45,0,1,0,9.48,9.45A9.31,9.31,0,0,0,130.72,48.34Zm0,15.18a5.75,5.75,0,1,1,5.33-5.73A5.47,5.47,0,0,1,130.72,63.52ZM106.1,51.24v4h9.59a8.34,8.34,0,0,1-2.18,5,9.81,9.81,0,0,1-7.41,2.94,10.67,10.67,0,0,1,0-21.34,10.24,10.24,0,0,1,7.23,2.87l2.83-2.83a14,14,0,0,0-10.06-4,14.68,14.68,0,1,0,0,29.36,13.42,13.42,0,0,0,10.24-4.12,13.24,13.24,0,0,0,3.47-9.38,12.43,12.43,0,0,0-.22-2.5Zm100.68,3.11c-.79-2.11-3.18-6-8.09-6s-8.91,3.83-8.91,9.45a9.25,9.25,0,0,0,9.38,9.45A9.39,9.39,0,0,0,207,63.05l-3.22-2.15a5.38,5.38,0,0,1-4.65,2.62,4.82,4.82,0,0,1-4.59-2.87l12.64-5.22ZM193.89,57.5A5.19,5.19,0,0,1,198.83,52a3.65,3.65,0,0,1,3.51,2Zm-10.27,9.17h4.15V38.89h-4.15Zm-6.8-16.22h-.15a6.54,6.54,0,0,0-5-2.11,9.46,9.46,0,0,0,0,18.9,6.42,6.42,0,0,0,5-2.15h.15v1.36c0,3.62-1.94,5.55-5,5.55A5.22,5.22,0,0,1,167,68.64l-3.62,1.5a9,9,0,0,0,8.38,5.58c4.87,0,9-2.86,9-9.84v-17h-3.93Zm-4.76,13.07a5.75,5.75,0,0,1,0-11.46c2.82,0,5,2.43,5,5.76S174.88,63.52,172.06,63.52Zm54.18-24.63H216.3V66.67h4.15V56.14h5.79c4.59,0,9.11-3.33,9.11-8.63S230.83,38.89,226.24,38.89Zm.1,13.39h-5.89V42.75h5.89a4.77,4.77,0,1,1,0,9.53Zm25.63-4c-3,0-6.11,1.32-7.4,4.25l3.68,1.54A3.93,3.93,0,0,1,252,52c2.14,0,4.32,1.29,4.36,3.57v.29a9.16,9.16,0,0,0-4.32-1.07c-4,0-8,2.18-8,6.25,0,3.72,3.25,6.11,6.9,6.11a5.86,5.86,0,0,0,5.29-2.71h.14v2.14h4V56C260.4,51,256.72,48.29,252,48.29Zm-.5,15.22c-1.36,0-3.25-.68-3.25-2.36,0-2.14,2.35-3,4.39-3a7.37,7.37,0,0,1,3.79.93A5,5,0,0,1,251.47,63.51ZM275,48.89l-4.76,12h-.14l-4.93-12h-4.47l7.4,16.84-4.22,9.36h4.32l11.41-26.2ZM237.64,66.67h4.14V38.89h-4.14Z"
        fill="#fff"
      />
      <path
        d="M23.19,16.75a4.45,4.45,0,0,0-1,3.12V69a4.41,4.41,0,0,0,1,3.12l.16.16L50.89,44.77v-.65L23.35,16.59Z"
        fill="url(#aGradGoogle)"
      />
      <path
        d="M60.06,54l-9.17-9.18v-.65l9.17-9.18.21.12,10.87,6.18c3.11,1.76,3.11,4.65,0,6.42L60.27,53.83Z"
        fill="url(#bGradGoogle)"
      />
      <path
        d="M60.27,53.83l-9.38-9.38L23.19,72.14c1,1.09,2.71,1.22,4.62.14L60.27,53.83"
        fill="url(#cGradGoogle)"
      />
      <path
        d="M60.27,35.06,27.81,16.61c-1.91-1.08-3.6-.94-4.62.14l27.7,27.7Z"
        fill="url(#dGradGoogle)"
      />
      <path
        d="M60.06,53.63,27.81,72c-1.81,1-3.42,1-4.45,0h0l-.17.16h0l.16.16h0c1,.94,2.64,1,4.45,0L60.27,53.83Z"
        isolation= "isolate"
        opacity="0.2"
      />
      <path
        d="M23.19,71.82a4.45,4.45,0,0,1-1-3.12V69a4.41,4.41,0,0,0,1,3.12l.17-.16Z"
        isolation= "isolate"
        opacity="0.12"
      />
      <path
        d="M71.14,47.33l-11.08,6.3.21.2,10.87-6.17c1.56-.89,2.33-2,2.33-3.21h0A4.13,4.13,0,0,1,71.14,47.33Z"
        isolation= "isolate"
        opacity="0.12"
      />
      <path
        d="M27.81,16.94,71.14,41.56a4.13,4.13,0,0,1,2.33,2.89h0c0-1.17-.77-2.33-2.33-3.21L27.81,16.62c-3.11-1.77-5.65-.3-5.65,3.25v.33C22.16,16.64,24.7,15.18,27.81,16.94Z"
        fill="#fff"
        isolation= "isolate"
        opacity= "0.25"
      />
    </svg>
  );
}
export default GooglePlayIcon;
