import React from 'react'
import {connect} from 'react-redux'

//redux
import MODAL_CONSTANTS from './state/modalConstants'
import MODAL_ACTIONS from './state/modalActions'

//styles
import './modalContainer.scss'

//modals
import ContactModal from './contact-modal/contactModal'
import AskQuestionModal from './ask-a-question-modal/askaquestionModal'
import ShareModal from './share-modal/shareModal'
import CarBrandsModal from './car-brands-modal/carBrandsModal'
import LightboxModal from './lightbox-modal/lightbox-modal'
import ModuleModal from './module-modal/moduleModal'
import VideoModal from './video-modal/videoModal'

function ModalContainer(props){
  const { modalList, popModal } = props
  if(modalList.length){
    return(
      <div className="marketplace-modal-container">
        {modalList.map((modal,i)=>{
          return(
            <Modal  popModal={popModal} key={modal.type+i}>
              <ModalContent modal={modal} modalIndex={i}/>
            </Modal>
          )
        })}
        <style>{`body{overflow:hidden;}`}</style>
      </div>
    )
  }
  return <React.Fragment/>
}
function Modal(props){
  const { popModal } = props

  return(
    <div
      className="marketplace-modal"
      onClick={(e)=>{
          if(e.target === e.currentTarget){
            popModal()
          }
      }}
    >
      {props.children}
    </div>
  )
}
function ModalContent(props){
  const {modal,modalIndex, popModal} = props
  let selectedModal = <React.Fragment/> //TODO: Error Modal?
  switch(modal.type){
    case(MODAL_CONSTANTS.CONTACT_MODAL):
      selectedModal = <ContactModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.ASK_QUESTION):
      selectedModal = <AskQuestionModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.SHARE):
    selectedModal = <ShareModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.CAR_BRANDS_MODAL):
      selectedModal = <CarBrandsModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.LIGHTBOX):
      selectedModal = <LightboxModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.MODULE_MODAL):
      selectedModal = <ModuleModal {...modal.props}/>
    break;
    case(MODAL_CONSTANTS.VIDEO_MODAL):
      selectedModal = <VideoModal {...modal.props}/>
    break;
  }
  return selectedModal
}
const mapStateToProps = state =>({
  modalList: state.modal.modalList,

})
const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalContainer)
