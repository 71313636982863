import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import IPacketIcon from '../../common/common-assets/icons/iPacketIcon'
import GooglePlayIcon from '../../common/common-assets/icons/googlePlay'
import AppleStoreIcon from '../../common/common-assets/icons/appleStore'
import FacebookIcon from '../../common/common-assets/icons/facebook'
import InstagramIcon from '../../common/common-assets/icons/instagram'
import LinkedinIcon from '../../common/common-assets/icons/linkedin'
import AutoiPacketLogo from '../../common/common-assets/images/autoipacket_logo_white_gray.png'
import AutoiPacketLegacyLogo from '../../common/common-assets/images/autoipacket_legacy_logo_white_gray_copyright.jpg'

import validateEmail from '../../../utils/validateEmail'
import packageJson from '../../../../package.json'

import './marketplaceAppDefaultFooter.scss'

function MarketplaceAppDefaultFooter({ token }) {
  const history = useHistory()
  let location = useLocation()

  let today = new Date()
  let year = today.getFullYear()

  const [emailField, setEmailField] = useState('')
  const [emailValid, setEmailValid] = useState()

  function formSubmit() {
    if (validateEmail(emailField)) {
      setEmailValid(true)
      history.push({
        pathname: '/marketplace/signup',
        state: {
          emailPropFromFooter: emailField
        }
      })
      setEmailField('')
    } else {
      setEmailValid(false)
    }
  }

  function renderEmailField() {
    return (
      <div className="marketplace-footer-cta-section">
        <div>
          <input
            type="email"
            placeholder="email address"
            value={emailField}
            onChange={(e) => setEmailField(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                formSubmit()
              }
            }}
          />
          <button
            type="image"
            alt="Click here to sign up"
            className="ga-marketplace-footer-sign-up-button"
            onClick={() => formSubmit()}
          >
            SIGN UP
          </button>
        </div>
        <p className={emailValid !== false ? 'hidden' : 'enter-valid-email'}>
          Enter a valid email address
        </p>
      </div>
    )
  }
  //TODO: Update site-logo-header-link classname because it's supposed to be a header class
  return (
    <div className="marketplace-footer-container">
      <div
        className={`marketplace-footer-cta-container ${
          location.pathname == '/marketplace/signup' ? 'cta-hidden' : ''
        }`}
      >
        {/*!token ? (
          <div className="marketplace-footer-cta">
            <div className="marketplace-footer-cta-section">
              <h1>Join iPacket</h1>
              <p>Receive pricing updates, shopping tips & more!</p>
            </div>
            {renderEmailField()}
          </div>
        ) : (
          <></>
        )*/}
      </div>
      <div className="marketplace-footer-info">
        <div className="marketplace-footer-info-section-container">
          <NavLink
            to="/"
            className="sitelogo-header-link ga-marketplace-footer-dealer-center-button"
            onClick={()=>{window.scrollTo(0, 0);}}
          >
            <IPacketIcon />
          </NavLink>
          <div className="marketplace-footer-info-section">
            <b className="company-name">AutoiPacket, LLC</b>
            <p className="address">
              3506 Murdoch Ave.
              <br />
              Parkersburg, WV 26101
            </p>
          </div>
        </div>
        <div className="marketplace-footer-info-section-container">
          <div className="app-store-link-container">
            <b className="marketplace-footer-subtitle">
              Download the iPacket app
            </b>
            <br />
            <br />
            <a
              href="https://apps.apple.com/us/app/ipacket/id1570295337"
              className="ga-marketplace-footer-apple-app-store-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppleStoreIcon />
            </a>
            <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.autoipacket.marketplaceapp"
              className="ga-marketplace-footer-google-play-store-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlayIcon />
            </a>
          </div>
        </div>
        <div className="marketplace-footer-info-section-container terms-and-policies">
          <a
            href="https://www.ipacket.us/dealer-center/privacy-policy"
            className="ga-marketplace-footer-privacy-policy-button"
            target="_blank"
          >
            Privacy Policy
          </a>
          <br />
          <a
            href="https://www.ipacket.us/dealer-center/terms-of-service"
            className="ga-marketplace-footer-terms-of-service-button"
            target="_blank"
          >
            SMS Terms of Service
          </a>
          <br />
          <NavLink to="/buying-smart" className="ga-marketplace-footer-dealer-center-button">
            Consumer Resources
          </NavLink>
          <br />
          <NavLink to="/schedule-demo" className="ga-marketplace-footer-dealer-center-button">
            Become an iPacket Dealer
          </NavLink>
          <br />
          <NavLink to="/news" className="ga-marketplace-footer-dealer-center-button">
            News
          </NavLink>
          <br />
          <NavLink to="/blog" className="ga-marketplace-footer-dealer-center-button">
            Blog
          </NavLink>
        </div>
        <div className="marketplace-footer-info-section-container">
          <b className="marketplace-footer-subtitle">Get In Touch</b>
          <p className="contact-info">
            <a
              href="tel:+18558939340"
              className="ga-marketplace-footer-telephone-button"
            >
              {' '}
              Tel: 855-893-9340
            </a>
            <br />
            <a
              href="mailto:sales@autoipacket.com"
              className="ga-marketplace-footer-email-button"
            >
              sales@autoipacket.com
            </a>
          </p>
          <div className="social-media-links">
            <a
              href="https://www.instagram.com/ipacket.us/"
              className="ga-marketplace-footer-instagram-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <InstagramIcon />
              </div>
            </a>
            <a
              href="https://www.facebook.com/AutoiPacket/"
              className="ga-marketplace-footer-facebook-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <FacebookIcon />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/autoipacket-llc/mycompany/"
              className="ga-marketplace-footer-linkedin-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <LinkedinIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="marketplace-footer-copyright">
        <div>
          <img alt='autoipacket logo' src={AutoiPacketLogo}/>
          <img alt='autoipacket legacy logo' src={AutoiPacketLegacyLogo}/>
        </div>
        <div>
          <span>&copy;</span>
          {year} AutoIPacket, LLC All rights reserved. AIP
        </div>
        <div className="marketplace-footer-version">{packageJson.version}</div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps)(MarketplaceAppDefaultFooter)
