import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../../../api/aipDjapi'
import URL_HISTORY_ACTIONS from '../../../../../state/actions/urlHistoryActions'
import VEHICLE_DISPLAY_PAGE_ACTIONS from '../../vehicle-display-page/state/vehicleDisplayPageActions'

import jwt from 'jsonwebtoken'
import packageJson from '../../../../../../package.json'


const secret = 'secret'

const urlParams = new URLSearchParams(window.location.search);
const urlToken = urlParams.get('t')
const etsAuthEnabled = urlToken?false:true

const jwt_token = urlToken?urlToken:'0'

const SOCKET_STATUS = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
}

const SOCKET_TIMEOUT_DURATION =  60 * 60 * 1000 // 1 Hour = 60 min * 60 sec * 1000 milliseconds
let etsSocket = null
let etsSocketInitializedId = null
let etsSocketTimeout = null

let websocketUrl = 'wss://web-api.autoipacket.com/ws/ets/'

if(window.location.hostname !== 'ipacket.us' && window.location.hostname !== 'www.ipacket.us' && window.location.hostname !== 'replay.autoipacket.com' &&  window.location.hostname !== 'www.replay.autoipacket.com' && window.location.hostname !== 'ipacket.info' && window.location.hostname !== 'www.ipacket.info'){
  websocketUrl = 'wss://djapi.autoipacket-stg.com/ws/ets/'
}

export function initEtsRecording(id,onOpenCallback = null){
  if(!etsSocket && id){
    etsSocket = new WebSocket(websocketUrl)
    etsSocketInitializedId = id
    etsSocket.onopen = (e)=>{
      sendMessageEtsRecording({
        event: 'setup',
        subject_id: id,
        app_version: packageJson.version,
        application: 'vdp-web',
        device_type: 'desktop',
      } )
      if (document.visibilityState === 'hidden') {
        sendMessageEtsRecording({event:'page_inactive'})
      }
      document.addEventListener('visibilitychange', handlePageFocusChangeEtsRecording );
      if(onOpenCallback){
        onOpenCallback(e)
      }
    }
    etsSocket.onmessage = (e) =>{
      console.log(`Message Received:`, JSON.parse(e.data))
    }
  }
  const refEtsSocket = etsSocket
  return refEtsSocket
}

function handlePageFocusChangeEtsRecording  (event) {
  if (document.hidden) {
    sendMessageEtsRecording({event:'page_inactive'})
  } else {
    sendMessageEtsRecording({event:'page_active'})
  }
}

export function closeEtsRecording(reset = false){
  if(etsSocket){
    etsSocket.close()
    etsSocket = null
    document.removeEventListener('visibilitychange', handlePageFocusChangeEtsRecording );
    console.log('CLOSED ETS SOCKET')
    if(reset && etsSocketInitializedId){
      console.log('RESETTING ETS SOCKET')
      initEtsRecording(etsSocketInitializedId)
    }
    else{
      etsSocketInitializedId = null
    }
  }
  else{
    console.log('FAILED TO CLOSE ETS RECORDING')
    console.log("ETS SOCKET NOT INITIALIZED")
  }
}

export function resetEtsRecording(){
  closeEtsRecording(true)
}

function sendMessageEtsRecording(msg){
  if(etsSocket){
    if(etsSocket.readyState === SOCKET_STATUS.OPEN){
      etsSocket.send(JSON.stringify(msg))
      if(etsSocketTimeout){
        clearTimeout(etsSocketTimeout)
      }
      etsSocketTimeout = setTimeout(()=>{
        closeEtsRecording()
      },SOCKET_TIMEOUT_DURATION)
    }
    // Reconnection Logic Goes Here
    // else if(etsSocket.readyState === SOCKET_STATUS.CLOSED){
    //
    // }
    else{
      console.log('FAILED TO SEND MESSAGE', msg)
      console.log(`ETS STATE IS NOT OPEN(${etsSocket.readyState})`)
    }

  }
  else{
    console.log("ETS SOCKET NOT INITIALIZED")
  }
}

export function sendModuleViewEtsRecording(module_id, external){
  sendMessageEtsRecording({event: 'module_view',module_id,external})
}


export function sendGenericActionEtsRecording(action,external_url,focus=false){
  if(external_url){
    sendMessageEtsRecording({event:'generic_action',action,external_url,focus})
  }
  else{
    sendMessageEtsRecording({event:'generic_action',action,focus})
  }
}

export function vdpConnectionStart(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VDP_CONNECTION_START}?packet_share_uuid=${id}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export function vdpConnectionClose(id, session_id){
  let jwt_token = jwt.sign({
    session_id
  },secret)
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VDP_CONNECTION_CLOSE}${jwt_token}?packet_share_uuid=${id}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export function vdpConnectionKeepAlive(id, session_id){
  let jwt_token = jwt.sign({
    session_id
  },secret)
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VDP_CONNECTION_KEEP_ALIVE}${jwt_token}?packet_share_uuid=${id}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export function vdpConnectionAction(id, session_id, new_page){
  let jwt_token = jwt.sign({
    session_id,
    new_page
  },secret)
  console.log("NEW PAGE",new_page)
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VDP_CONNECTION_ACTION}${jwt_token}?packet_share_uuid=${id}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export function getEtsInfo(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.GET_ETS_INFO}/${jwt_token}?share_uuid=${id}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    },
    etsAuthEnabled
  )
}
export function getEtsCustomerInfo(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.GET_ETS_CUSTOMER_INFO}/${id}/${jwt_token}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    },
    etsAuthEnabled
  )
}
export function getEtsVehicleInfo(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.GET_ETS_VEHICLE_INFO}/${id}/${jwt_token}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    },
    etsAuthEnabled
  )
}
export function getEtsUserInfo(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.GET_ETS_USER_INFO}/${id}/${jwt_token}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    },
    etsAuthEnabled
  )
}

export function initiateVdpPrint(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.INITIATE_VDP_PRINT}?id=${id}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export function retrieveVdpPrint(id){
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.RETRIEVE_VDP_PRINT}${id}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export default {
  initEtsRecording,
  sendModuleViewEtsRecording,
  sendGenericActionEtsRecording,
  resetEtsRecording,
  closeEtsRecording,
  vdpConnectionStart,
  vdpConnectionClose,
  vdpConnectionKeepAlive,
  vdpConnectionAction,
  getEtsInfo,
  getEtsCustomerInfo,
  getEtsVehicleInfo,
  getEtsUserInfo,
  initiateVdpPrint,
  retrieveVdpPrint,
  dispatchActions:{
  }
}
