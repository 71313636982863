import React from 'react'
import {connect} from 'react-redux'
import './termsOfService.scss'

const TermsOfServiceList = () => {
    return(
        <ul>
            <li>
                When you opt-in to the service, we will send you an SMS message to confirm your signup to
                receive text notification with the vehicle information for the vehicle(s) you are interested in.
            </li>
            <li>
                You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the
                SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed.
                After this, you will no longer receive SMS messages from us. If you want to join again, just sign up
                as you did the first time and we will start sending SMS messages to you again.
            </li>
            <li>
                If at any time you forget what keywords are supported, just text "HELP" to the short code. After you
                send the SMS message "HELP" to us, we will respond with instructions on how to use our service as well
                as how to unsubscribe.
            </li>
            <li>
                We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T,
                Verizon Wireless, Sprint, T-Mobile, MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile.
                Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular,
                Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless,
                Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone),
                GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular,
                iWireless (Iowa Wireless), Keystone Wireless (lmmix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom),
                Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry
                (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or
                5 Star Wireless).
            </li>
            <p>***carriers are not liable for delayed or undelivered messages***</p>
            <li>
                As always, message and data rates may apply for any messages sent to you from us and to us from you. Message
                frequency varies. If you have any questions about your text plan or data plan, it is best to contact your
                wireless provider. For all questions about the services provided by this short code, you can send an email
                to support@autoipacket.com.
            </li>
            <li>If you have any questions regarding privacy, please read our <a>privacy policy:</a></li>
        </ul>
    )
}
function TermsOfServiceForm(){
  return(
    <div className="tos-form-container">
      <div className="tos-form-text"></div>
      <button className="primary-button tos-form-button">Accept</button>
    </div>

  )
}
function TermsOfService(props) {
    const{formMode}=props
    return(
        <div className="terms-of-service-page">
            <h1 className="tos-header">iPacket SMS Messages Terms of Services</h1>
            <h1 className="tos-last-updated">Last Updated: 01/12/2021</h1>
            <TermsOfServiceList />
            {formMode? <TermsOfServiceForm/>:null}
        </div>
    )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(TermsOfService)
