import MARKETPLACE_APP_CONSTANTS from './marketplaceAppConstants'
import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../api/aipDjapi'
import URL_HISTORY_ACTIONS from '../../../state/actions/urlHistoryActions'

export function checkUserLoginStatus(){
  return(dispatch) =>{
    try{
      let userProfile = JSON.parse(localStorage.getItem('userProfile'))
      if(userProfile && userProfile.token && userProfile.user){
        dispatch({
          type: MARKETPLACE_APP_CONSTANTS.CHECK_USER_LOGIN_STATUS,
          userProfile
        })
      }
      else{
        dispatch({
          type: MARKETPLACE_APP_CONSTANTS.CHECK_USER_LOGIN_STATUS,
        })
      }

    }
    catch(e){
      console.log(e)
      localStorage.removeItem('userProfile')
      window.location.reload()
    }
  }
}

export function logoutUser(){
  localStorage.removeItem('userProfile')
  window.location.href="/"
}

export function createUser(user){
  const form = new FormData()

  form.append('email', user.email)
  form.append('password', user.password)
  form.append('first_name', user.first_name)
  form.append('last_name', user.last_name)
  if(user.zip_code){form.append('zip_code',user.zip_code)}
  if(user.phone_number){form.append('phone_number',user.phone_number)}

  return(
    AIP_DJAPI(
      `${AIP_DJAPI_URLS.CREATE_USER}`,
      {
        method: "PUT",
        headers: {
          Accept: 'application/json',
        },
        body: form
      },
      false,
    )
  )
}

export function getStoreSkin(id){
  return(dispatch)=>{
    AIP_DJAPI(
     `${AIP_DJAPI_URLS.STORE_SKIN}?id=${id}`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then(response =>{return response.json()})
    .then((res)=>{
      if(res){
        dispatch({
          type: MARKETPLACE_APP_CONSTANTS.GET_STORE_SKIN,
          storeSkin: res,
        })

      }
    })
  }
}

export function getStoreConfiguration(id){
  return(dispatch)=>{
    AIP_DJAPI(
     `${AIP_DJAPI_URLS.STORE_CONFIGURATION}?id=${id}`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then(response =>{return response.json()})
    .then((res)=>{
      if(res && res.configuration && res.skin){
        dispatch({
          type: MARKETPLACE_APP_CONSTANTS.GET_STORE_CONFIGURATION,
          storeConfiguration: res.configuration,
        })
        AIP_DJAPI(
         `${AIP_DJAPI_URLS.STORE_SKIN}?id=${id}`,
          {
            method: "GET",
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }
        ).then(skinResponse=>{return skinResponse.json()})
        .then((skinRes)=>{
          if(skinRes){
            dispatch({
              type: MARKETPLACE_APP_CONSTANTS.GET_STORE_SKIN,
              storeSkin: {
                ...res.skin,
                logo_full:skinRes.logo_full,
              },
            })

          }
        })

      }
    })
  }
}

export function getSalespersonInfo(id){
  return AIP_DJAPI(
   `${AIP_DJAPI_URLS.SALESPERSON_INFO}?id=${id}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
export default {
  logoutUser,
  createUser,
  getSalespersonInfo,
  dispatchActions:{
    checkUserLoginStatus,
    getStoreSkin,
    getStoreConfiguration,
    ...URL_HISTORY_ACTIONS.dispatchActions
  }
}
