import React from "react";

function KiaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path
          fill="#cc2229"
          d="M63.94 95.339L77.331 75.74c.513-.77-.103-1.642-1.18-1.642l-10.338-.077c-.77 0-2.309.975-2.976 1.821l-4.668 6.08-6.49 8.491.051-14.674c0-1-1.052-1.642-1.693-1.642H38.184c-.821 0-1.565.308-1.59 1.821-.025 1.488 1.821 1.796 1.821 2.206v39.224c0 .744 1.026 1.872 1.745 1.872l10.158-.051c.719-.026 1.334-1.052 1.334-1.745l.025-16.341 6.49 8.029 6.875 8.466c.564.667 1.667 1.591 2.822 1.591l11.031-.077c1.052 0 .949-1.283.693-1.616A3518.013 3518.013 0 0 1 63.94 95.339zM102.008 74.099H90.285c-1.385 0-1.693.821-1.924 1.693.256 1.59 1.924 2.026 1.924 2.052v39.582c0 1.052.642 1.745 1.54 1.745l4.207-.026 6.259-.051c1.154 0 1.461-.692 1.461-1.668V75.74c0-1-.846-1.641-1.744-1.641z"
        />
        <path
          fill="#cc2229"
          d="M137.691 53.884c-12.467-3.155-26.498-4.925-41.326-4.925-.103 0-.206.025-.333.025-13.442 0-26.269 1.488-37.864 4.13-32.554 7.44-55.334 24.063-55.334 43.277 0 19.215 22.78 35.837 55.333 43.276 11.595 2.643 24.421 4.13 37.864 4.13h.333c14.828 0 28.859-1.744 41.326-4.899 30.912-7.747 52.23-23.908 52.23-42.507.002-18.598-21.316-34.76-52.229-42.507zm0 74.676c-12.287 2.848-26.371 4.464-41.326 4.464-.103 0-.206-.025-.333-.025-13.545 0-26.397-1.36-37.864-3.746-28.706-5.977-48.51-18.47-48.51-32.861 0-14.391 19.804-26.884 48.51-32.861 11.467-2.386 24.319-3.746 37.864-3.771h.333c14.955 0 29.039 1.616 41.326 4.463 27.012 6.208 45.406 18.316 45.406 32.168s-18.394 25.961-45.406 32.169z"
        />
        <path
          fill="#cc2229"
          d="M145.771 75.792c-.383-.873-1.051-1.693-1.924-1.693H127.994c-.564 0-1.334.462-1.334 1.231-.025 2.257 2.336 2.181 2.207 2.514l-13.365 39.428c-.23.667.359 1.898 1.18 1.898l9.312-.077c.82 0 1.975-.692 2.309-1.693l4.412-13.236c1.488-4.413 2.977-8.825 4.438-13.237.078-.179.309-.282.539-.282.359 0 .744.128.82.385l8.646 26.525c.18.59 1.385 1.539 2.025 1.539l9.697.077c.564 0 1.232-1.129 1.053-1.642l-14.162-41.737z"
        />
      </g>
    </svg>
  );
}
export default KiaIcon;
