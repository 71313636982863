import React, {useState, useEffect, useCallback} from 'react'
import validateEmail from '../../../../utils/validateEmail'
import validateZip from '../../../../utils/validateZip'
import validatePhone from '../../../../utils/validatePhone'
import formatPhoneNumber from '../../../../utils/formatPhoneNumber'
import convertComplianceNumber from '../../../../utils/convertPasswordComplianceNumber'
import PasswordInput from '../../../common/common-components/passwordInput'
import { checkPasswordValidity, login, socialLogin } from '../../marketplaceAppUtils'
import { createUser } from '../../state/marketplaceAppActions'
import {Link, NavLink} from 'react-router-dom'
import LoadingSpinner from '../../../common/common-components/loadingSpinner'


import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login'
import { toast } from 'react-toastify'

import { useLocation } from 'react-router'
import debounce from 'lodash.debounce'

import './signupForm.scss'
import AppleIcon from '../../../common/common-assets/icons/apple-icon'
import FacebookIcon1 from '../../../common/common-assets/icons/facebook1-icon'
import GoogleGIcon from '../../../common/common-assets/icons/googleg-icon'

function SignupForm(){

  const location = useLocation();
  const emailPropFromFooter = location.state ? location.state.emailPropFromFooter : null
  const [emailField, setEmailField] = useState(emailPropFromFooter ? emailPropFromFooter : "")
  const [emailValid, setEmailValid] = useState()
  const [passwordField, setPasswordField] = useState("")
  const [passwordValid, setPasswordValid] = useState()
  const [confirmPasswordField, setConfirmPasswordField] = useState("")
  const [passwordRequirements, setPasswordRequirements] = useState([])
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [firstNameField, setFirstNameField] = useState("")
  const [firstNameValid, setFirstNameValid] = useState()
  const [lastNameField, setLastNameField] = useState("")
  const [lastNameValid, setLastNameValid] = useState()
  const [zipCodeField, setZipCodeField] = useState("")
  const [zipCodeValid, setZipCodeValid] = useState()
  const [phoneNumberField, setPhoneNumberField] = useState("")
  const [phoneNumberValid, setPhoneNumberValid] = useState()
  const [signUpValid, setSignUpValid] = useState(false)
  const [passwordCompliance, setPasswordCompliance] = useState(0)
  const [loading, setLoading] = useState(false)
  const googleClientId = '1075399473185-uuaie8kabnv5qg75vl0pak7b903u9lbt.apps.googleusercontent.com'

  // temporary formSubmit logic
  useEffect(() => {
    if (signUpValid === true){
      toast("You have successfully signed up")
      setTimeout(()=>{window.location.href='/marketplace/login'},1000)
    }
    // when clicking through from footer cta, page was loading already scrolled down
    window.scrollTo(0, 0);
  }, [signUpValid])



  async function socialLoginSuccessGoogle(res) {
    //send Google token to iPacket backend
    const socialReturn = await socialLogin(res.accessToken, 'google-oauth2')

    const socialReturnJSON = await socialReturn.json()
    // check login success
    if (socialReturn.status == 200) {
      // get user profile and token
      if(socialReturnJSON){
        console.log(socialReturnJSON)
        // save user profile to local storage
        localStorage.setItem('userProfile', JSON.stringify(socialReturnJSON))

        //perform redirect
        toast("You have successfully logged in.")
        setTimeout(()=>{window.location.href='/'},1000)
      }


    } else {
      toast(socialReturnJSON && socialReturnJSON.errors && socialReturnJSON.errors.detail?socialReturnJSON.errors.detail:"An error occurred when logging in. Please try again later.")
    }


  }

  async function socialLoginSuccessFacebook(res) {
    console.log("Facebook Login Success\n",res)

    //send Google token to iPacket backend
    const socialReturn = await socialLogin(res.accessToken, 'facebook')

    const socialReturnJSON = await socialReturn.json()
    // check login success
    if (socialReturn.status == 200) {
      // get user profile and token
      if(socialReturnJSON){
        console.log(socialReturnJSON)
        // save user profile to local storage
        localStorage.setItem('userProfile', JSON.stringify(socialReturnJSON))

        //perform redirect
        toast("You have successfully logged in.")
        setTimeout(()=>{window.location.href='/'},1000)
      }


    } else {
      toast(socialReturnJSON && socialReturnJSON.errors && socialReturnJSON.errors.detail?socialReturnJSON.errors.detail:"An error occurred when logging in. Please try again later.")
    }

  }

  function socialLoginFailure(res) {
    console.log(`Failed to sign in to Google account: ${res}`)
    console.log(JSON.stringify(res))

  }



  // Verify that passwords match
  useEffect(() => {
    if (passwordField == confirmPasswordField) {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }

  }, [confirmPasswordField, passwordField])


  // Check all fields on the sign up form
  async function validateSignUp() {
    let errors = 0
    // Validate Email
    if (validateEmail(emailField)) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
      errors+=1
    }

    // Validate First Name
    if (firstNameField.length == 0) {
      setFirstNameValid(false)
      errors+=1
    } else {
      setFirstNameValid(true)
    }

    // Validate Last Name
    if (lastNameField.length == 0) {
      setLastNameValid(false)
      errors+=1
    } else {
      setLastNameValid(true)
    }

    // Validate Zip Code
    if (!validateZip(zipCodeField) && zipCodeField.length !== 0) {
      setZipCodeValid(false)
      errors+=1
    } else {
      setZipCodeValid(true)
    }

    // Validate Phone Number
    if (!validatePhone(phoneNumberField) && phoneNumberField.length > 0) {
      setPhoneNumberValid(false)
      errors+=1
    } else {
      setPhoneNumberValid(true)
    }

    if (passwordRequirements != 0 ) {
      setPasswordValid(false)
      errors+=1
    } else {
      setPasswordValid(true)
    }

    return(errors)
  }

  // Submit form if no errors exist
  function formSubmit() {
    setLoading(true)
    validateSignUp().then((e) => {
      if (e == 0 && passwordRequirements.length == 0 && passwordsMatch == true) {
        createUser({
          email:emailField,
          password: passwordField,
          first_name:firstNameField,
          last_name:lastNameField,
          zip_code:zipCodeField.length?zipCodeField:null,
          phone_number:phoneNumberField.length?phoneNumberField:null,
        }).then(response=>{
          if(response.status === 201){
            setSignUpValid(true)
          }
          else{
            setSignUpValid(false)
            setLoading(false)
          }
        })
      } else {
        setSignUpValid(false)
        setLoading(false)
      }
    })
  }




  // Validate password with AIP Standards and store requirements
  async function validatePassword(password) {
    const passwordValidity = await checkPasswordValidity(password)
    if (passwordValidity) {
      const passwordValidityJSON = await passwordValidity.json()
      setPasswordCompliance(passwordValidityJSON.aip_standards.aip_percentage)
      setPasswordRequirements(passwordValidityJSON.aip_standards.aip_pw_rules)
    }
  }

  // debounce setPassword Field
  const debounceSetPassword = (password) => {
    setPasswordField(password)
    validatePassword(password)
  }
  const debouncedPassword = debounce(debounceSetPassword, 500)

  return(
    <div className="signup-login-page-form">
      <div className="signup-login-page-form-heading">
        Create your iPacket buyer profile.
      </div>

      <form autoComplete='off'>
        {/* Email */}
        <p className={emailValid == false ? "invalid-field" : "hidden"}>Please enter valid email</p>
        <input className="signup-login-page-form-field" placeholder="Enter Email" type="email" value={emailField} onChange={(e)=> setEmailField(e.target.value)} />

        {/* Password */}
        <div className='password-container'>
          <p className={passwordValid == false ? "invalid-field" : "hidden"}>Please enter password</p>
          <PasswordInput setPasswordField={debouncedPassword} formSubmit={formSubmit} placeholder="Create Password" debounce={true} />
          <PasswordInput setPasswordField={setConfirmPasswordField} formSubmit={formSubmit} placeholder="Confirm Password" debounce={true} />
          {
            passwordField.length?
            <div className='password-requirements'>
              <div className={`password-compliance-bar ${convertComplianceNumber(passwordCompliance).class}`} />
              Password Requirements:
              {(passwordRequirements.length > 0 || passwordsMatch == false) && (
                <ul>
                  {passwordsMatch == false && <li className='requirement'>Passwords must match.</li>}
                  {passwordRequirements.map((requirement, index) => (<li className='requirement' key={index}> {requirement} </li>))}
                </ul>
              )}
              {(passwordRequirements.length == 0 && passwordsMatch == true && passwordField.length > 0) && (
                <div className='password-requirements-met'>
                  All password requirements met.
                </div>
              )}
            </div>
            :
            null
          }

        </div>


        {/* First Name */}
        <p className={firstNameValid == false ? "invalid-field" : "hidden"}>Please enter first name</p>
        <input className="signup-login-page-form-field" placeholder="Enter First Name"  value={firstNameField} onChange={(e)=> setFirstNameField(e.target.value)} />


        {/* Last Name */}
        <p className={lastNameValid == false ? "invalid-field" : "hidden"}>Please enter last name</p>
        <input className="signup-login-page-form-field" placeholder="Enter Last Name" value={lastNameField} onChange={(e)=> setLastNameField(e.target.value)} />


        {/* Zip Code Optional */}
        <p className={zipCodeValid == false ? "invalid-field" : "hidden"}>Please enter valid zip code</p>
        <input className="signup-login-page-form-field" placeholder="Enter Zip Code (Optional)" value={zipCodeField} onChange={(e)=> setZipCodeField(e.target.value)} maxLength={5} />


        {/* Phone Number Optional */}
        <p className={phoneNumberValid == false ? "invalid-field" : "hidden"}>Please enter valid phone number</p>
        <input className="signup-login-page-form-field" placeholder="Enter Phone Number (Optional)" value={formatPhoneNumber(phoneNumberField)} onChange={(e)=> setPhoneNumberField(e.target.value)} maxLength={10} />

      </form>


      <div className="signup-login-page-form-blurb">
        By signing up, you confirm that you've read and accepted our<br/>
        <span><Link to="/terms-of-service">Terms of Use</Link></span> and <span><a href="https://www.ipacket.us/dealer-center/privacy-policy" target="_blank">Privacy Policy</a></span>.
      </div>

      {
        loading ?
        <div className='signup-login-page-button-loading-spinner'><LoadingSpinner loading /></div> :
        <button className="signup-login-page-form-button" type="image" alt="Click to sign up" onClick={formSubmit} >
          SIGN UP
        </button>
      }

      <hr/>

      <div className="signup-login-page-button-cta-text">Or connect with:</div>

      {/* <button className="signup-login-page-form-button signup-login-page-form-button-apple">
        <AppleIcon/><div>Continue with Apple</div>
      </button> */}

      <FacebookLogin
        appId="326118059332803"
        callback={socialLoginSuccessFacebook}
        render={renderProps => (
          <button onClick={renderProps.onClick} className="signup-login-page-form-button signup-login-page-form-button-facebook">
            <FacebookIcon1/><div>Continue with Facebook</div>
          </button>
        )}
      />
      <GoogleLogin
        clientId={googleClientId}
        onSuccess={socialLoginSuccessGoogle}
        onFailure={socialLoginFailure}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="signup-login-page-form-button signup-login-page-form-button-google">
            <GoogleGIcon/><div>Continue with Google</div>
          </button>
        )}
      />
    </div>
  )
}
export default SignupForm
