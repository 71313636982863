import React from "react";

function LexusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path d="M98.047 56.542c-3.581 4.416-13.795 18.673-14.959 20.519-2.241 3.537-2.439 5.251-2.505 6.393-.065 1.121-.065 3.142 1.758 4.79 3.054 2.724 7.337 2.284 11.138 2.284h31.305c1.34 0 1.736-.396 2.461-1.362 2.504-3.823 3.625-7.975 3.625-11.688 0-7.953-5.162-17.97-18.234-22.496l-1.473 2.109c10.369 4.92 14.654 11.357 14.654 20.364.066 2.417-.572 5.031-2.154 7.448H93.214c-1.823 0-2.087-2.065-1.428-3.23a121.488 121.488 0 0 1 8.721-13.378c3.076-4.108 6.107-7.953 9.293-11.973.857-1.12.285-2.197-1.428-2.548-3.559-.747-7.271-1.274-11.775-1.274-24.977 0-34.117 14.345-34.117 24.978 0 11.534 9.205 24.913 34.183 24.913 13.862 0 22.936-4.461 28.186-10.194h-5.295c-4.592 4.24-12.479 7.251-22.891 7.251-21.156 0-28.954-11.886-28.954-22.124.153-9.139 8.523-21.749 29.482-22.166.791-.022 1.407.703.856 1.384zM173.18 122.381c1.252.176 2.219 1.164 2.219 2.33 0 1.316-1.186 2.371-2.637 2.371h-26.844v-17.553h-7.91v17.598h-14.543c-3.932 0-4.744-2.395-4.744-4.592V109.42h-13.115c-1.297.879-2.549 1.846-3.889 2.658l-7.777 4.789-10.105-7.381h-15.73v4.416h5.689v-2.418l13.379 9.602-9.139 5.732h-9.93v4.416h11.489c1.23-.834 2.395-1.713 3.669-2.482l7.996-4.855 10.149 7.25h11.557l-15.27-11.49 7.844-5.117c1.67-1.1 3.428-2.021 5.141-3.031v11.555c0 4.877 1.252 8.26 11.072 8.26l55.471-.131c4.283.064 7.029-2.9 7.029-6.48 0-3.561-3.164-6.482-7.029-6.482h-20.035c.264-2.438 2.371-4.48 4.502-4.48h20.277l-.064-4.24h-21.508c-6.811 0-12.258 5.646-12.412 12.215-.021.461.285.746.791.746l24.407-.091zm-105.075-.065v-4.504h3.91v4.504h-3.91zm0-12.83H53.254c-4.02 0-7.118 1.055-9.402 2.855-4.108 3.252-5.426 6.678-5.382 8.217h5.36c-2.351 1.758-3.933 3.865-4.767 6.305H21.487c-3.163 0-5.207-2.24-5.207-4.416v-13.115H8.504v13.621c0 4.898 4.658 8.281 12.126 8.281h47.475v-4.416H49.256c.088-2.24 2.482-4.502 4.657-4.502h14.192v-4.504H49.036c-.263-1.318 2.197-3.91 4.086-3.91h14.983v-4.416z" />
      </g>
    </svg>
  );
}
export default LexusIcon;
