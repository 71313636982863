import React, {useState,useEffect} from 'react'
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import Arrow from '../../../common/common-assets/icons/arrowRight'
import './vdpDisclaimer.scss'

function Disclaimer(props){
    const {disclaimerPosition, storeConfigDisclaimers} = props
    const [disclaimers,setDisclaimers] = useState(null)
    const [isOpened, setIsOpened] = useState(true)
    const initialStyle = isOpened
    ? { height: "auto", overflow: "initial" }
    : { height: "0px", overflow: "hidden" };
    
    useEffect(() => {
      if(storeConfigDisclaimers){
        let filteredDisclaimers = storeConfigDisclaimers.filter(disclaimer => disclaimer.position === disclaimerPosition)
        setDisclaimers(filteredDisclaimers)
      }
    }, [storeConfigDisclaimers])

    function handleDisclaimersPositionTwoAccordion(){
      setIsOpened(!isOpened)
        if(isOpened) {
          var list = document.getElementById("disclaimers-list-position-two");
          list.scrollIntoView()
        }
    }

    if(disclaimers && disclaimers.length){
      if(disclaimerPosition === 1){
        return(
          <div className='vdp-disclaimer-upper-container'>
            <div className='collapsible-toggle' onClick={()=>{setIsOpened(!isOpened)}}>
              <div className="vehicle-display-page-vehicle-info-body-title">Disclaimers</div>
              <div className={`arrow ${isOpened ? 'arrow-down' : 'arrow-up'}`}><Arrow /></div>
            </div>
            <Collapse isOpened={isOpened} initialStyle={initialStyle}>
            {disclaimers.map((disclaimer,i)=>{
              return(
                <div className='vdp-disclaimer single-disclaimer-upper'>
                  <b>{disclaimer.title}:</b>
                  <p dangerouslySetInnerHTML={{ __html: disclaimer.text }} />
                </div>
              )
            })}
            </Collapse>
            </div>
        )
      }
      else if(disclaimerPosition === 2){
        return(
          <div className='vdp-disclaimer-lower-container'>
            <div className='collapsible-toggle' onClick={()=>{handleDisclaimersPositionTwoAccordion()}}>
              <div className="vehicle-display-page-vehicle-info-body-title">Disclaimers</div>
              <div className={`arrow ${isOpened ? 'arrow-down' : 'arrow-up'}`}><Arrow /></div>
            </div>
          <Collapse id="disclaimers-list-position-two" isOpened={isOpened} initialStyle={initialStyle}>
            {disclaimers.map((disclaimer,i)=>{
              return(
                <div className='vdp-disclaimer single-disclaimer-lower'>
                  <b>{disclaimer.title}:</b>
                  <p dangerouslySetInnerHTML={{ __html: disclaimer.text }} />
                </div>
              )
            })}
          </Collapse>
          </div>
        )
      }
    }
    return(<React.Fragment/>)

}

const mapStateToProps = (state) => ({
  storeConfigDisclaimers: state.marketplaceApp.storeConfiguration && state.marketplaceApp.storeConfiguration.disclaimers,
});

export default connect(mapStateToProps, null)(Disclaimer);
