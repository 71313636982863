import React from "react";
import { connect } from "react-redux";

import MODULE_TYPES from "../../../common/common-components/modules/moduleTypes";
import MODAL_ACTIONS from "../../modals/state/modalActions";

import ModulePdf from "../../../common/common-components/modules/modulePdf";
import ModuleImage from "../../../common/common-components/modules/moduleImage";
import ModuleHtml5Video from "../../../common/common-components/modules/moduleHtml5Video";
import ModuleiFrame from "../../../common/common-components/modules/moduleiFrame";
import ModuleYoutubeVideo from "../../../common/common-components/modules/moduleYoutubeVideo";
import ModuleHtml from "../../../common/common-components/modules/moduleHtml";
import ModuleHtmlUrl from "../../../common/common-components/modules/moduleHtmlUrl";
import Module3PA from "../../../common/common-components/modules/module3PA";
import ModuleJson from "../../../common/common-components/modules/moduleJson";
import ModuleVehicleDetails from "../../../common/common-components/modules/moduleVehicleDetails";
import NavigationDrawer from '../../../common/common-components/navigationDrawer'
import VideoPlayer from '../../../common/common-components/videoPlayer';


import "./vehicleDisplayPage.scss";

function ModuleDisplay(props) {
    const { module, vehicle, modules, pushModal, mockAdd, etsInfo, downloadBtnPosition,storeConfiguration } = props;

    function ModuleOverlay(props){
      if((storeConfiguration && storeConfiguration.enable_module_education_videos) || !storeConfiguration){
        if(module.module_id === 4||module.module_id===50){
          return(
            <NavigationDrawer drawerToggleLabel ="ServiceHistory-module-drawer">
              <VideoPlayer url="https://player.vimeo.com/video/688877138?h=48c74c3ff4&title=0&byline=0&portrait=0" />
            </NavigationDrawer>
          )
        }
        if(module.module_id === 7){
          return(
            <NavigationDrawer drawerToggleLabel="MSRP-module-drawer">
              <VideoPlayer url="https://player.vimeo.com/video/688611442?h=1bfeb5af67&title=0&byline=0&portrait=0" />
            </NavigationDrawer>
          )
        }
      }
      //No Overlay Detected so return without one
      return(<React.Fragment/>)

    }
    function Module(){
      switch (module.content_type) {
        case MODULE_TYPES._PDF:
          return <ModulePdf module={module} downloadBtnPosition={downloadBtnPosition ? downloadBtnPosition : 'right'} />;
        case MODULE_TYPES._IMAGE:
          return <ModuleImage module={module} downloadBtnPosition={downloadBtnPosition ? downloadBtnPosition : 'right'} />;
        case MODULE_TYPES._HTML5_VIDEO:
          return <ModuleHtml5Video module={module} />;
        case MODULE_TYPES._IFRAME:
          return <ModuleiFrame module={module} />;
        case MODULE_TYPES._YOUTUBE_VIDEO:
          return <ModuleYoutubeVideo module={module} />;
        case MODULE_TYPES._HTML:
          return <ModuleHtml module={module} />;
        case MODULE_TYPES._HTML_URL:
          return <ModuleHtmlUrl module={module} />;
        case MODULE_TYPES._JSON:
          return <ModuleJson module={module} />;
        case MODULE_TYPES._3PA:
          return <Module3PA module={module} redirect />;
        case "vehicle_detail":
          return (
            <ModuleVehicleDetails
              vehicle={vehicle}
              modules={modules}
              pushModal={pushModal}
              mockAdd={mockAdd}
              etsInfo={etsInfo}
              storeConfiguration={storeConfiguration}
            />
          );
        default:
          return <div>Module Unsupported</div>;
      }
    }
    return(
      <div className="module-display-container">
        <ModuleOverlay/>
        <Module/>
      </div>
    )

  }

  const mapDispatchToProps = {
    ...MODAL_ACTIONS.dispatchActions,
    mockAdd: () => {
      return (dispatch) => {
        dispatch({ type: "MOCKADD" });
      };
    },
  };
  export default connect(null, mapDispatchToProps)(ModuleDisplay);
