import React, { useState, useEffect } from "react";
import PasswordResetConfirmForm from "./password-reset-confirm-page/passwordResetConfirm";
import PasswordResetForm from "./password-reset-page/passwordReset";

function PasswordRecovery() {
  const [userToken, setUserToken] = useState();

  //   check url for token
  useEffect(() => {
    let url = window.location;
    const urlToken = new URLSearchParams(url.search).get('token');;

    if (urlToken != undefined) {
      setUserToken(urlToken);
    }
  }, []);

  if (userToken) {
    return <PasswordResetConfirmForm userToken={userToken} />;
  } else {
    return <PasswordResetForm />;
  }
}

export default PasswordRecovery;
