import React from 'react'

function TradeInYourCarIcon(){
  return(
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 115.89">
      <defs>
        <style>
        </style>
      </defs>
      <path className="cls-1" fillRule="evenodd" fill="#113f35" d="M121,124.12l3-6.78h-2.86c-3.17-1.57-4.12-3.62-4.12-6.93h9.45l6-12.57c2.65-3.22,5.15-5,7.21-4.83,11.64.14,23,.14,34.46,0,2.06-.15,4.56,1.61,7.22,4.83l6,13.56,9.59-.14c0,2.9-.83,4.7-3.62,6.08h-2.5l2.64,6v27.49a3.23,3.23,0,0,1-3.23,3.22H178.87a3.24,3.24,0,0,1-3.24-3.23V143.1H138.86v7.68a3.24,3.24,0,0,1-3.23,3.22H124.21a3.24,3.24,0,0,1-3.24-3.23Zm19.42-25.6c-1,.15-2.07,1-3.25,2.35-2.07,4.55-4.29,8.95-6.35,13.5h52.44c-2.22-4.55-4.44-9-6.5-13.5-1.19-1.32-2.22-2.2-3.25-2.35Zm8.24,30.71a2.37,2.37,0,0,0-2.36,2.43,2.63,2.63,0,0,0,2.65,2.53h16.16a2.6,2.6,0,0,0,2.65-2.28,2.48,2.48,0,0,0-2.48-2.68Zm33.92-4.47a5.12,5.12,0,1,0,5.3,5.12A5.1,5.1,0,0,0,182.55,124.76Zm-51.24,0a5.12,5.12,0,1,1-5.3,5.12A5.1,5.1,0,0,1,131.31,124.76Z" transform="translate(-117 -38.11)"/>
      <path className="cls-2" fill="#1ed760" d="M163.27,39.45a3.24,3.24,0,0,1,1.15,5.29,3.19,3.19,0,0,1-3.32.74,15.93,15.93,0,0,0-16.71,3.81A16.58,16.58,0,0,0,140,64.12l.85-.86a3.16,3.16,0,0,1,4.46,0,3.31,3.31,0,0,1,0,4.55l-6.51,6.58a3.17,3.17,0,0,1-4.47,0l-6.46-6.58a3.33,3.33,0,0,1,0-4.55,3.17,3.17,0,0,1,4.47,0l1.21,1.23a23,23,0,0,1,6.27-19.75A22.17,22.17,0,0,1,163.27,39.45Z" transform="translate(-117 -38.11)"/>
      <path className="cls-3" fill="#113f35" d="M168.93,74.1a16.53,16.53,0,0,0,4.35-14.82l-.85.86a3.17,3.17,0,0,1-4.47,0,3.29,3.29,0,0,1,0-4.54L174.49,49A3.17,3.17,0,0,1,179,49l6.47,6.58a3.29,3.29,0,0,1,0,4.54,3.17,3.17,0,0,1-4.47,0l-1.21-1.22a22.92,22.92,0,0,1-6.29,19.72A22.17,22.17,0,0,1,150.08,84a3.22,3.22,0,0,1-1.15-5.28,3.16,3.16,0,0,1,3.32-.74A16,16,0,0,0,168.93,74.1Z" transform="translate(-117 -38.11)"/>
    </svg>
  )
}
export default TradeInYourCarIcon
