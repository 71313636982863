import React,{useState} from 'react'
import {connect} from 'react-redux'
import './buyingSmart.scss'
import Jordan from '../../../common/common-assets/images/image23.jpg'
import Tyler from '../../../common/common-assets/images/image29.png'
import Seve from '../../../common/common-assets/images/seve_video_placeholder.png'
import Sean from '../../../common/common-assets/images/sean_video_placeholder.png'


import VideoPlayerIcon from '../../../common/common-assets/icons/videoPlayerIcon'


//TODO: Move this to Common Components
function VideoPlayer(props){
  const{placeholderUrl,videoUrl,iframe} = props
  const [showVideo,setShowVideo] = useState(false)

  if(placeholderUrl && videoUrl){
    return(
      <div className="video-player-container">
        {
            !showVideo ?
            <div
                className="image-placeholder video-player"
                style={{backgroundImage:`url(${placeholderUrl})`, backgroundSize:`cover`, backgroundPosition:`center`}}
                onClick={()=>setShowVideo(true)}
            >
              <div className="video-player-overlay">
                <VideoPlayerIcon/>
              </div>
            </div>
            :
            <div className="image-placeholder video-player">
              {
                iframe ?
                <iframe
                  ref={(r)=>{
                      try{
                        r.play()
                      }
                      catch(e){
                        //
                      }
                    }
                  }
                  src={videoUrl}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                /> :
                <video
                  ref={(r)=>{
                      try{
                        r.play()
                      }
                      catch(e){
                        //
                      }
                    }
                  }
                  src={videoUrl}
                  controls
                />
              }
            </div>
        }
      </div>
    )
  }
}

const BuyingSmartIntroduction = () => {
    return(
        <div className="buying-smart-introduction">
            <h1>Integrity, Transparency, Trust.</h1>
            <p>
              When you’re shopping for a vehicle, you have so many choices, and so many
              questions. And you want to buy the right car at a fair price, from someone you can trust.
              Dealers who use iPacket are committed to delivering you the most transparent and
              informed shopping experience possible.
            </p>
        </div>
    )
}

const BuyingSmartMainContent = () => {
    return(
        <div className="buying-smart-main-content">
            <span>
                <div className="main-content-column">
                    <h1>A new era of car buying. </h1>
                    <p>
                      iPacket provides total transparency. This is buying a car with every possible form of
                      documentation, verification and proof right at your fingertips. Dealers who use iPacket
                      are making a commitment to provide you with the most complete shopping experience
                      possible, and delivering everything you need to be fully informed.
                    </p>
                </div>
                <div className="main-content-column">
                    <VideoPlayer placeholderUrl={Tyler} videoUrl={'https://player.vimeo.com/video/688393224?h=cf1a3d4675&title=0&byline=0&portrait=0&autoplay=1'} iframe={true}/>
                </div>
            </span>
            <span className="alternate-row">
                <div className="main-content-column">
                  <VideoPlayer placeholderUrl={Jordan} videoUrl={'https://player.vimeo.com/video/688598506?h=4584d2777a&title=0&byline=0&portrait=0&autoplay=1'} iframe={true}/>
                </div>
                <div className="main-content-column">
                    <h1>Receipt of Integrity.</h1>
                    <p>
                      The reconditioning receipts on a vehicle are some of the most important documents to
                      review when evaluating a purchase. After a full inspection, mechanical issues should be
                      clearly identified and repaired, and parts replaced to ensure the vehicle runs like new.
                      Dealers that use iPacket provide receipts to prove work was done, and done correctly.
                    </p>
                </div>
            </span>
            <span>
                <div className="main-content-column">
                    <h1>No guesswork, no speculation, all the facts. </h1>
                    <p>
                      When it comes to evaluating a vehicle purchase, documents like a vehicle history
                      report, reconditioning receipts, and the original MSRP are vital to truly understanding a
                      vehicle’s value. With the help of our integrity-driven dealer network, iPacket is
                      committed to ensuring you the most transparent vehicle shopping experience ever.
                    </p>
                </div>
                <div className="main-content-column">
                  <VideoPlayer placeholderUrl={Seve} videoUrl={'https://player.vimeo.com/video/688601920?h=06f421688b&title=0&byline=0&portrait=0&autoplay=1'} iframe={true}/>
                </div>
            </span>
            <span className="alternate-row">
                <div className="main-content-column">
                  <VideoPlayer placeholderUrl={Sean} videoUrl={'https://player.vimeo.com/video/684269979?h=11023f0cf9&title=0&byline=0&portrait=0&autoplay=1'} iframe={true}/>
                </div>
                <div className="main-content-column">
                    <h1>Dealerships you can trust.</h1>
                    <p>
                      Car dealerships that make iPacket a part of their business make integrity a part of their
                      business. They make complete transparency a part of their business. A vehicle
                      purchase is often the second largest financial decision you will make. You need a dealer
                      you can trust. If you’re shopping at an iPacket dealer, that’s precisely what you’ll have.
                    </p>
                </div>
            </span>

        </div>
    )
}

function BuyingSmart(props) {
    return(
        <div className="buying-smart-page">
            <h1 className="buying-smart-header">Education Center</h1>
            <BuyingSmartIntroduction />
            <BuyingSmartMainContent />

        </div>
    )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(BuyingSmart)
