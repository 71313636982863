import React from 'react'

function SearchPageSkin(props){
  const{storeSkin,children} = props
  let skinStyles = {}
  skinStyles.header= `
    .primary-button{
      background-color:  ${storeSkin.color_nav_btn_active_font};
    }
    .primary-button:hover{
      background-color:  ${storeSkin.color_nav_primary};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header span{
      color: ${storeSkin.color_nav_primary};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header h1{
      color: ${storeSkin.color_nav_primary};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .sort-by-field-tooltip svg{
      fill: ${storeSkin.color_nav_btn_active_font};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .sort-by-field-tooltip .tooltip-container .tool-tip{
      background-color:  ${storeSkin.color_nav_btn_active_font};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header .marketplace-search-page-header-filter-toggle:hover{
      color: ${storeSkin.color_nav_btn_active_font};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header .marketplace-search-page-header-filter-toggle:hover svg{
      fill: ${storeSkin.color_nav_btn_active_font};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header .marketplace-search-page-header-filter-toggle-selected{
      color: ${storeSkin.color_nav_btn_active_font};
    }
    .marketplace-search-page-container .marketplace-search-page .marketplace-search-page-header-container .marketplace-search-page-header .marketplace-search-page-header-filter-toggle-selected svg{
      fill: ${storeSkin.color_nav_btn_active_font};
    }
  `
  skinStyles.sidebar = `
    .search-sidebar-sub-menu{
      border-top-color: ${storeSkin.color_nav_primary};
    }
    .search-filter-clear-menu .search-filter-clear-menu-items .search-filter-clear-menu-item{
      color: ${storeSkin.color_nav_primary};
    }
    .search-filter-clear-menu .search-filter-clear-menu-items .search-filter-clear-menu-item:hover{
      background-color:  ${storeSkin.color_nav_btn_active_font};
      color: white;
    }
  `
  skinStyles.body=`
    .search-vehicle-tile-container .search-vehicle-tile .search-vehicle-tile-info-container .search-vehicle-tile-info .title-info-company-store-container span{
      color: ${storeSkin.color_nav_btn_active_font};
    }
    .search-vehicle-tile-container .search-vehicle-tile .search-vehicle-tile-info-container .search-vehicle-tile-info svg{
      fill: ${storeSkin.color_nav_btn_active_font};
    }
  `
  let skinStyleString = ''
  Object.keys(skinStyles).map(style=>{
    skinStyleString += skinStyles[style]
  })
  return(
    <>
      {children}
      <style>{skinStyleString}</style>
    </>
  )
}
export default SearchPageSkin
