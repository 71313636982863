import React, { useEffect, useState, useRef } from 'react'
import LoadingSpinner from '../loadingSpinner';
import './styles/modulePdf.scss'
import { Document, Page, Outline, PDFDownloadLink } from 'react-pdf/dist/esm/entry.webpack';
import ModuleSkeleton from './moduleSkeleton'
import DownloadIcon from '../../common-assets/icons/downloadIcon';

const options = {
  cMapUrl: '/cmaps/',
}

function ModulePdf(props){
  let {module, downloadBtnPosition} = props
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scaleIndex, setScaleIndex] = useState(7);
  const [initialPdfWidth, setInitialPdfWidth] = useState(null);
  const modulePdfElement = useRef(null)
  const setupPdfSizing = (view)=>{
    setInitialPdfWidth(modulePdfElement.current.clientWidth*.96);
  }

  const scaleValues = [.25,.33,.5,.67,.75,.8,.9,1,1.1,1.25,1.5,1.75,2,2.5,3,4,5]
  const decreaseScale = ()=>{
    if(scaleIndex>0){
      setScaleIndex(scaleIndex-1)
    }
  }
  const increaseScale = ()=>{
    if(scaleIndex<scaleValues.length-1){
      setScaleIndex(scaleIndex+1)
    }
  }
  const savePdfAsBlob = async (pdfUrl) => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    return blob
  }
  const downloadPdf = async () => {
    const fetchedBlob = await savePdfAsBlob(module.url)
    const downloadBlob = new Blob([fetchedBlob], { type: 'application/pdf;base64' })
    const data = window.URL.createObjectURL(downloadBlob)
    const link = document.createElement('a')
    link.href = data
    link.download = `${module.module_name}.${module.content_type}`
    link.click()
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 100)
  }
  return (
    <div className="module-pdf" ref={modulePdfElement}>
      <div className="module-viewer-container">
        <div className="module-viewer-header">
          <div className={`download-icon
            ${downloadBtnPosition}`
              } onClick={downloadPdf}>
            <DownloadIcon />
          </div>
          <div className="module-viewer-header-name">
            {module.label}
          </div>
          <div className="module-viewer-header-tools">
            <button onClick={()=>{decreaseScale()}}>-</button>
            <div>{`${Math.round(scaleValues[scaleIndex]*100)} %`}</div>
            <button onClick={()=>{increaseScale()}}>+</button>
          </div>
        </div>
        <div className="module-viewer-content-container">
          <Document
            file={module.url}
            loading={<LoadingSpinner loading />}
            onLoadSuccess={(pdf)=>{
              setNumPages(pdf.numPages)
              pdf.getPage(1).then(page => {
                setupPdfSizing(page.view)
              });
            }}
            options={options}
          >
            <Pages scale={scaleValues[scaleIndex]} numPages={numPages} width={initialPdfWidth}/>
          </Document>
        </div>
      </div>
    </div>)
}
function Pages(props){
  const{numPages,scale, width} = props

  let pages = []
  for(let i = 1; i< numPages+1; i++){
    pages.push(
      <Page scale={scale} width={width} pageNumber={i} loading={<React.Fragment/>} />
    )
  }
  return(
    <React.Fragment>
      {pages}
    </React.Fragment>
  )
}

export default ModulePdf;
