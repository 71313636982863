import React from "react";

const VDI_Car = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 80 40.76">
      <path d="M63.42,34.3H37.6a2.15,2.15,0,1,1,0-4.3H63.42a2.15,2.15,0,0,1,0,4.3ZM33,10.63,44.12,4.45a2.13,2.13,0,0,1,1.05-.27h5.34v6.45ZM24.8,14.94H72.94l8.61-2.15.59.19,12,3.19a2.15,2.15,0,0,1,1.26.92H93.71a2.15,2.15,0,1,0,0,4.3h2v6.46A2.16,2.16,0,0,1,93.54,30H91.39a2.15,2.15,0,0,0,0,4.3h2.15A6.46,6.46,0,0,0,100,27.85V18.24A6.45,6.45,0,0,0,95.21,12l-12-3.19a2.24,2.24,0,0,1-.87-.47l-7.7-6.84A6.47,6.47,0,0,0,70.39-.13H45.17A6.4,6.4,0,0,0,42,.69L24.26,10.56a2.48,2.48,0,0,1-.66.24L5.3,14.12c-.21,0-.41.09-.62.15a6.46,6.46,0,0,0-4.43,8l2.11,7.37a6.45,6.45,0,0,0,6.2,4.68H9.62a2.15,2.15,0,1,0,0-4.3H8.56A2.14,2.14,0,0,1,6.5,28.44l-2-7H8.12a2.15,2.15,0,0,0,2.15-2.15,2.12,2.12,0,0,0-.64-1.53L24.37,15ZM77.14,9.45l-4.73,1.18H54.81V4.18H70.39a2.14,2.14,0,0,1,1.43.54ZM23.61,40.76a9.69,9.69,0,1,0-9.68-9.68A9.68,9.68,0,0,0,23.61,40.76Zm0-4.3A5.38,5.38,0,1,1,29,31.08,5.38,5.38,0,0,1,23.61,36.46Zm53.8,4.3a9.69,9.69,0,1,0-9.69-9.68A9.69,9.69,0,0,0,77.41,40.76Zm0-4.3a5.38,5.38,0,1,1,5.38-5.38A5.38,5.38,0,0,1,77.41,36.46Z" />
    </svg>
  );
};
export default VDI_Car;
