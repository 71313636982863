import React, { useState, useEffect } from "react";
import './vehicleDisplayPageInsights.scss'
import { Collapse } from "react-collapse";
import formatPhoneNumber from '../../../../utils/formatPhoneNumber'
import ArrowIcon from "../../../common/common-assets/icons/moduleArrowIcon";
import IPacketIcon from '../../../common/common-assets/icons/iPacketIcon'
import PersonIcon from '../../../common/common-assets/icons/personIcon'
import CarIcon from '../../../common/common-assets/images/car_icon.png'
import HeatmapIcon from '../../../common/common-assets/icons/heatmapIcon'

import {
  getEtsInfo,
  getEtsCustomerInfo,
  getEtsVehicleInfo,
  getEtsUserInfo,
} from "./state/vehicleDisplayPageActions.js";

import moment from 'moment';


function InsightsHeader(){
  return(
    <div className="vehicle-display-page-insights-header-container">
      <div className="vehicle-display-page-insights-header">
        <div className="vehicle-display-page-insights-header-left">
          <IPacketIcon/>
        </div>
        <div className="vehicle-display-page-insights-header-right">
          INSIGHT REPORT
          <HeatmapIcon/>
        </div>
      </div>
    </div>
  )
}

function InsightsHeatmapSection(props){
  const [isOpened, setIsOpened] = useState(true)
  return(
    <div className="vehicle-display-page-insights-section-container">
      <div className="vehicle-display-page-insights-section">
        <div className="vehicle-display-page-insights-section-collapse-button" onClick={()=>{
          setIsOpened(!isOpened)
        }}>
          <div>Heatmap</div>
          <div className={`vehicle-display-page-insights-section-collapse-button-icon ${isOpened ?'vehicle-display-page-insights-section-collapse-button-icon-open' : ''}`}>
            <ArrowIcon/>
          </div>
        </div>
        <Collapse className="vehicle-display-page-insights-section-collapse" isOpened={isOpened} >
          {props.children}
        </Collapse>
      </div>
    </div>
  )
}
function InsightsShopperSection(props){
  const { id,etsInfo,viewTimes } = props
  const [shopperInfo, setShopperInfo] = useState()
  const [loading, setLoading] = useState(true)
  const [day, setDay] = useState(0)
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)
  useEffect(()=>{
    if(id){
      getEtsCustomerInfo(id).then((response)=>{
        if(response.status===200){
          return(response.json())
        }
        else{
          setLoading(false)
        }
      }).then((res)=>{
        if(res){
          setShopperInfo(res)
        }
        setLoading(false)
      })
    }
  },[])
  if(shopperInfo){
    let localeData = moment.updateLocale('en', {
     relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        ss: '%d seconds',
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
     }
    });
    let firstOpenedRelativeTime = etsInfo.sessions.length? moment.utc(etsInfo.sessions[0].session_start_ts).local().fromNow():''
    let lastOpenedRelativeTime = etsInfo.sessions.length? moment.utc(etsInfo.sessions[etsInfo.sessions.length-1].session_end_ts).local().fromNow():'';

    function Calculate_total_view_time (total_view_seconds) {
      let total = total_view_seconds;
      let day = 86400;
      let hour = 3600;
      let minute = 60;

      let daysOut = Math.floor(total / day);
      let hoursOut = Math.floor((total - daysOut * day) / hour);
      let minutesOut = Math.floor((total - daysOut * day - hoursOut * hour) / minute);
      let secondsOut = total - daysOut * day - hoursOut * hour - minutesOut * minute;

      setDay(daysOut);
      setHour(hoursOut);
      setMinute(minutesOut);
      setSecond(secondsOut);

    }
    if(etsInfo.total_packet_share_view_seconds){
      if(day === 0 && hour === 0 && minute === 0 && second === 0){
        Calculate_total_view_time(etsInfo.total_packet_share_view_seconds)
      }
    }

    return(
      <div className="vehicle-display-page-insights-section-container">
        <div className="vehicle-display-page-insights-section">
          <div className="vehicle-display-page-insights-section-heading">
            <h1>SHOPPER</h1>
          </div>
          <div className="vehicle-display-page-insights-section-body">
            <div className="insights-section">
              <div className="insights-section-profile">
                <div className="insights-section-profile-img">
                  <PersonIcon/>
                </div>
                <div className="insights-section-profile-info">
                  <h1>{shopperInfo.name}</h1>
                  {shopperInfo.phone && shopperInfo.phone.length?<p>{formatPhoneNumber(shopperInfo.phone)}</p>:null}
                  {shopperInfo.email && shopperInfo.email.length?<p>{shopperInfo.email}</p>:null}
                  <p>Shared via <span>{shopperInfo.share_type_label}</span></p>
                  {/*<p><span>Sent</span> August 1 2022(1 Day Ago)</p>*/}
                  {etsInfo.sessions.length?<p><span>First Opened</span> {moment.utc(etsInfo.sessions[0].session_start_ts).local().format('llll')}({firstOpenedRelativeTime})</p>:<React.Fragment/>}
                  {etsInfo.sessions.length?<p><span>Last Opened</span> {moment.utc(etsInfo.sessions[etsInfo.sessions.length-1].session_end_ts).local().format('llll')}({lastOpenedRelativeTime})</p>:<React.Fragment/>}
                </div>
              </div>
              <div className="insights-section-metrics">
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Shopper Focus</div>
                  <div className="insights-section-metric-body">
                    <ol>
                      {viewTimes.slice(0,5).map((viewTime)=>{
                        return(
                          //  etsInfo.module_view_stats['22'].view_seconds>300?"5:00+": moment.utc(etsInfo.module_view_stats['22'].view_seconds*1000).format('mm:ss')
                          <li><div className="insights-shopper-section-view-time-label" title={viewTime.label}>{viewTime.label}</div>({viewTime.view_seconds>300?" 5:00+":" "+moment.utc(viewTime.view_seconds*1000).format('m:ss')+" "})</li>
                        )
                      })}
                    </ol>
                  </div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Total Views</div>
                  <div className="insights-section-metric-body">{etsInfo.sessions.length}</div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Total Session Duration</div>
                  <div className="insights-section-metric-body">
                    <div className="insights-section-metric-body-timer">
                      {day === 0 ? null :  <div className="insights-section-metric-body-timer-unit"> <p>{day}</p><b>D</b></div>}
                      {hour === 0 ? null :  <div className="insights-section-metric-body-timer-unit"> <p>{hour}</p><b>H</b></div>}
                      {minute === 0 ? null :  <div className="insights-section-metric-body-timer-unit"> <p>{minute}</p><b>M</b></div>}
                      {second === 0 ? null :  <div className="insights-section-metric-body-timer-unit"> <p>{second}</p><b>S</b></div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(<React.Fragment/>)
}
function InsightsVehicleSection(props){
  const { id } = props
  const [vehicleInfo, setVehicleInfo] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
    if(id){
      getEtsVehicleInfo(id).then((response)=>{
        if(response.status===200){
          return(response.json())
        }
        else{
          setLoading(false)
        }
      }).then((res)=>{
        if(res){
          setVehicleInfo(res)
        }
        else{
          setLoading(false)
        }
      })
    }
  },[])
  if(vehicleInfo){
    const currency_unit = vehicleInfo.currency_unit ? vehicleInfo.currency_unit.symbol : '$'
    let enNumFormatter = Intl.NumberFormat('en-US')
    console.log('VEHICLE INFO', vehicleInfo)
    return(
      <div className="vehicle-display-page-insights-section-container">
        <div className="vehicle-display-page-insights-section">
          <div className="vehicle-display-page-insights-section-heading">
            <h1>VEHICLE</h1>
          </div>
          <div className="vehicle-display-page-insights-section-body">
            <div className="insights-section">
              <div className="insights-section-profile">
                <div className="insights-section-profile-img">
                  <img src={vehicleInfo.thumbnail?vehicleInfo.thumbnail:CarIcon}/>
                </div>
                <div className="insights-section-profile-info">
                  <h1>{`${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}`}</h1>
                  <h2>{vehicleInfo.price === 0 ? `Contact store for price` : typeof vehicleInfo.price === 'string' ? vehicleInfo.price :`${currency_unit}${enNumFormatter.format(vehicleInfo.price)}`}</h2>
                  <p><span>Stock #</span> {vehicleInfo.stock}</p>
                  <p>{vehicleInfo.vin}</p>
                  <p><span>Location</span> {vehicleInfo.store.store_full_name}</p>
                </div>
              </div>
              <div className="insights-section-metrics">
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Packet Shares</div>
                  <div className="insights-section-metric-body">{vehicleInfo.share_count}</div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Total Client Views</div>
                  <div className="insights-section-metric-body">{vehicleInfo.view_count}</div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Price Changes</div>
                  <div className="insights-section-metric-body">{vehicleInfo.price_change_count}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(<React.Fragment/>)
}
function InsightsSenderSection(props){
  const { id ,salespersonInfo} = props
  const [senderInfo, setSenderInfo] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    if(id){
      getEtsUserInfo(id).then((response)=>{
        if(response.status===200){
          return(response.json())
        }
        else{
          setLoading(false)
        }
      }).then((res)=>{
        if(res){
          setSenderInfo(res)
          setLoading(false)
        }
        else{
          setLoading(false)
        }
      })
    }
  },[])

  if(senderInfo && !loading){
    return(
      <div className="vehicle-display-page-insights-section-container">
        <div className="vehicle-display-page-insights-section">
          <div className="vehicle-display-page-insights-section-heading">
            <h1>SENDER</h1>
          </div>
          <div className="vehicle-display-page-insights-section-body">
            <div className="insights-section">
              <div className="insights-section-profile">
                <div className="insights-section-profile-img">
                  {salespersonInfo && salespersonInfo.image ? <img src={salespersonInfo.image}/> : <PersonIcon/> }
                </div>
                <div className="insights-section-profile-info">
                  <h1>{senderInfo.full_name}</h1>
                  <p>{senderInfo.store_name}</p>
                  {senderInfo.phone_number?<p>{formatPhoneNumber(senderInfo.phone_number)}</p>:<React.Fragment/>}
                  <p>{senderInfo.email}</p>
                  <p>Member Since {moment(senderInfo.joined_date).format('ll')}</p>
                </div>
              </div>
              <div className="insights-section-metrics">
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Lifetime Packet Shares</div>
                  <div className="insights-section-metric-body">{senderInfo.share_count}</div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Total Client Views</div>
                  <div className="insights-section-metric-body">{senderInfo.view_count}</div>
                </div>
                <div className="insights-section-metric">
                  <div className="insights-section-metric-heading">Packet View Alerts</div>
                  <div className="insights-section-metric-body">{senderInfo.view_alert_enabled?<span>Active</span>:"Inactive"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else{
    return(<React.Fragment/>)
  }
}
function InsightsShopperFocusSection(props){
  const { etsInfo, viewTimes } = props
  const [isOpened, setIsOpened] = useState(false)
  if(viewTimes && etsInfo){
    return(
      <div className="vehicle-display-page-insights-section-container">
        <div className="vehicle-display-page-insights-section">
          <div className="vehicle-display-page-insights-section-collapse-button" onClick={()=>{
            setIsOpened(!isOpened)
          }}>
            <div>Shopper Focus Breakdown</div>
            <div className={`vehicle-display-page-insights-section-collapse-button-icon ${isOpened ?'vehicle-display-page-insights-section-collapse-button-icon-open' : ''}`}>
              <ArrowIcon/>
            </div>
          </div>
          <Collapse isOpened={isOpened} >
            <div className="vehicle-display-page-insights-total-view-time-section">
              <div className="vehicle-display-page-insights-total-view-time-section-items">
                {viewTimes.map(viewTime=>{
                  return(
                    <div className="vehicle-display-page-insights-total-view-time-section-item">
                      <div className="vehicle-display-page-insights-total-view-time-section-data">
                        <div className="vehicle-display-page-insights-total-view-time-section-data-label">
                          {viewTime.label}
                          <p>{`${viewTime.view_seconds>300?" 5:00+":moment.utc(viewTime.view_seconds*1000).format('m:ss')}`}</p>
                        </div>
                      </div>
                      <div className="vehicle-display-page-insights-total-view-time-section-graph-container">
                        <div className="vehicle-display-page-insights-total-view-time-section-graph" style={{width: `${(viewTime.view_seconds/(viewTimes[0].view_seconds>300?300:viewTimes[0].view_seconds))*100}%`}}>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    )
  }
  return(
    <React.Fragment/>
  )
}

function InsightsViewingSessionsSection(props){
  const {etsInfo} = props
  const [isOpened, setIsOpened] = useState(true)

  const ViewSession = (props)=>{
    const {session} = props
    const [sessionIsOpened, setSessionIsOpened] = useState(false)

    let orderedActions = []
    session.generic_actions.map(action=>{
      if(action.action==='landing_open'){
        orderedActions.push({
          ...action,
          render:()=>{
            return(
              <div className="vehicle-display-page-insights-view-session-item">
                Viewed Vehicle Details - {moment.utc(action.ts).local().format('llll')}
              </div>
            )
          }
        })
      }
    })
    session.module_views.map(view=>{
      if(view.module_id===22){
        orderedActions.push({
          ...view,
          render:()=>{
            return(
              <div className="vehicle-display-page-insights-view-session-item">
                {`Viewed Vehicle Images - ${moment.utc(view.ts).local().format('llll')}`}
              </div>
            )
          }
        })
      }
      else{
        orderedActions.push({
          ...view,
          render:()=>{
            return(
              <div className="vehicle-display-page-insights-view-session-item">
                {console.log('HERE',view)}
                {`Viewed ${(etsInfo.module_view_stats[view.module_id+'']&& etsInfo.module_view_stats[view.module_id+''].label)?etsInfo.module_view_stats[view.module_id+''].label:('ID-'+view.module_id)+' Module'} - ${moment.utc(view.ts).local().format('llll')}`}
              </div>
            )
          }
        })
      }
    })

    return(
      <div className="vehicle-display-page-insights-view-session">
        <div className="vehicle-display-page-insights-view-session-toggle" onClick={()=>{setSessionIsOpened(!sessionIsOpened)}}>
          <h1>{moment.utc(session.session_start_ts).local().format('llll')}</h1>
          <div className={`vehicle-display-page-insights-view-session-toggle-icon ${sessionIsOpened?'vehicle-display-page-insights-view-session-toggle-icon-open':''}`}>
            <ArrowIcon/>
          </div>
        </div>
        <Collapse isOpened={sessionIsOpened}>
          <div className="vehicle-display-page-insights-view-session-items">
            <div className="vehicle-display-page-insights-view-session-item">
              Page Opened - {moment.utc(session.session_start_ts).local().format('llll')}
            </div>
            {orderedActions.map(action=>{
              return(
                action.render()
              )
            })}
            <div className="vehicle-display-page-insights-view-session-item">
              Page Closed - {moment.utc(session.session_end_ts).local().format('llll')}
            </div>
          </div>
        </Collapse>
      </div>
    )
  }


  if(etsInfo){
    return(
      <div className="vehicle-display-page-insights-section-container">
        <div className="vehicle-display-page-insights-section">
          <div className="vehicle-display-page-insights-section-collapse-button" onClick={()=>{
            setIsOpened(!isOpened)
          }}>
            <div>Viewing Sessions</div>
            <div className={`vehicle-display-page-insights-section-collapse-button-icon ${isOpened ?'vehicle-display-page-insights-section-collapse-button-icon-open' : ''}`}>
              <ArrowIcon/>
            </div>
          </div>
          <Collapse isOpened={isOpened} >
            <div className="vehicle-display-page-insights-view-sessions-container">
              <div className="vehicle-display-page-insights-view-sessions">

                {
                  etsInfo.sessions.map( (session, i)=>{
                    return(<ViewSession session={session} key={session.session_start_ts}/>)
                  }).reverse()
                }
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    )
  }
  return(
    <React.Fragment/>
  )
}

function VehicleDisplayPageInsights(props){
  const {vehicle, modules, salespersonInfo} = props
  const { id } = props
  const [etsInfo, setEtsInfo] = useState()
  const [moduleDictionary, setModuleDictionary] = useState()
  const [viewTimes, setViewTimes] = useState()


  const processEtsInfo=(info)=>{
    const {module_view_stats} = info
    // let times = modules.filter(module=>{
    //   if(module.module_id && module_view_stats[module.module_id+'']){
    //     info.module_view_stats[module.module_id+'']={
    //       ...info.module_view_stats[module.module_id+''],
    //       ...module
    //     }
    //     return true
    //   }
    //   return false
    // }).map((module, i)=>{
    //     return(
    //       {
    //         ...module_view_stats[module.module_id+''],
    //         ...module
    //       }
    //     )
    // }).sort((a,b)=>{
    //   return b.view_seconds - a.view_seconds
    // })
    let times = Object.keys(info.module_view_stats).map(key=>{
      return(module_view_stats[key])
    }).sort((a,b)=>{
      return b.view_seconds - a.view_seconds
    })
    setEtsInfo(info)
    setViewTimes(times)
  }

  useEffect(()=>{
    if(id){
      getEtsInfo(id).then((response)=>{
        if(response.status===200){
          return(response.json())
        }
        else{
          setLoading(false)
        }
      }).then((res)=>{
        if(res){
         processEtsInfo(res)
        }
        else{
          setLoading(false)
        }
      })
    }
  },[])
  return(
    <div className="vehicle-display-page-insights-container">
      <div className="vehicle-display-page-insights">
          <InsightsHeader id={id} />
          {(etsInfo && viewTimes) &&
            <React.Fragment>
              <InsightsShopperSection id={id} etsInfo={etsInfo} viewTimes={viewTimes}/>
              <InsightsVehicleSection id={id}/>
              <InsightsHeatmapSection>
                {React.cloneElement(props.children,{etsInfo, viewTimes})}
              </InsightsHeatmapSection>
              <InsightsViewingSessionsSection etsInfo={etsInfo} moduleDictionary={moduleDictionary}/>
              <InsightsSenderSection id={id} salespersonInfo={salespersonInfo}/>
              <InsightsShopperFocusSection etsInfo={etsInfo} viewTimes={viewTimes}/>


            </React.Fragment>
          }

      </div>
    </div>
  )
}
export default VehicleDisplayPageInsights
