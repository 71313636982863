import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { getVehicleImages } from '../../marketplace-app/marketplaceAppUtils'

import './vehicleSlideshow.scss'

class VehicleSlideshow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicle_images: [],
      index: 0,
      slideshowActive: false,
    }
    this.changeImage = this.changeImage.bind(this)
    this.resetImage = this.resetImage.bind(this)
  }

  // Change image index every 3 seconds and change state for classname change
  changeImage() {
    let { vehicle } = this.props
    let { vehicle_images, index, slideshowActive } = this.state
    if (slideshowActive) {
      if (vehicle.vehicle_id && vehicle_images.length === 0) {
        this.interval = setTimeout(() => {
          this.getImages(vehicle.vin)
        }, 500)
      } else if (vehicle_images.length === 1) {
        this.setState({ index: 0 })
      } else {
        this.interval = setTimeout(() => {
          if (index < vehicle_images.length) {
            this.setState({ index: this.state.index + 1 }, () => {
              this.changeImage()
            })
          } else {
            this.setState({ index: 0 }, () => {
              this.changeImage()
            })
          }
        }, 2000)
      }
    }
  }

  resetImage() {
    clearTimeout(this.interval)
    this.setState({ index: 0 })
  }

  getImages() {
      getVehicleImages(this.props.vehicle.vin).then((response)=>{
        if(response.status === 200){
          return(response.json())
        }
        else {
            this.setState({ vehicle_images: [] }, () => {
                this.changeImage()
                })
        }
    }).then((res)=>{
    if(res){
      this.setState({ vehicle_images: res.vehicle_images }, () => {
    this.changeImage()
    })
    }
  })
  }

  componentWillUnmount() {
    this.mounted = false
    this.setState({
      vehicle_images: [],
      index: 0,
    })
  }

  render() {
    return (
      <TransitionGroup
        className="slideshow-transition"
        onMouseEnter={() => {
          this.setState({ slideshowActive: true }, () => {
            this.changeImage()
          })
        }}
        onMouseLeave={() => {
          this.setState({ slideshowActive: false }, () => {
            this.resetImage()
          })
        }}
      >
        <CSSTransition key={this.state.index} classNames="fade" timeout={2000}>
          <img src={this.state.vehicle_images[this.state.index]} />
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default VehicleSlideshow
