import React, {useState} from 'react'

import EyeOpenIcon from '../common-assets/icons/openEyeIcon.js'
import EyeClosedIcon from '../common-assets/icons/closedEyeIcon.js'

import './passwordInput.scss'

function PasswordInput(props){

    const {passwordField, setPasswordField, formSubmit, placeholder} = props
    const [passwordVisible, setPasswordVisible] = useState(false)

    return(
<div className="passwordInputContainer">
<input
  type={passwordVisible ? 'text' : 'password'}
  name="password"
  placeholder={placeholder}
  value={passwordField}
  className="signup-login-page-form-field"
  onChange={(e)=>setPasswordField(e.target.value)}
  onKeyPress={e => {
    if (e.key === 'Enter') {
      formSubmit()
    }
  }}
/>
<i
  onClick={() => setPasswordVisible(!passwordVisible)}
>
  {passwordVisible ? <EyeOpenIcon /> : <EyeClosedIcon /> }
</i>
</div>
    )
}
export default PasswordInput
