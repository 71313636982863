import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import './marketplaceAppDefaultHeader.scss'
import './marketplaceAppCustomVdpHeader.scss'
import IPacketIcon from '../../common/common-assets/icons/iPacketIcon'
import ProfileIcon from '../../common/common-assets/icons/profileIcon'
import SavePacketButton from '../../common/common-components/savePacketButton'
import ArrowLeft from '../../common/common-assets/icons/arrowLeft'
import MenuIcon from '../../common/common-assets/icons/menuIcon'
import {NavLink,Link, useLocation} from 'react-router-dom'
import MARKETPLACE_APP_ACTIONS from '../state/marketplaceAppActions'



function MarketplaceAppCustomVdpHeader(props){
  const {storeSkin,storeConfiguration} = props
  if(storeSkin && storeConfiguration){
    return(
      <React.Fragment>
        <div className="marketplace-header-container">
          <div className="marketplace-header-nav-container">
            <div className="marketplace-header-nav">
              <div className="marketplace-header-nav-left">
                <img src={storeSkin.logo_full}/>
              </div>
            </div>
          </div>
        </div>

        <div className="marketplace-header-container-clear marketplace-header-container-clear-custom-vdp"/>
      </React.Fragment>
    )
  }
  return(<React.Fragment/>)
}



const mapStateToProps = state => ({
  storeSkin: state.marketplaceApp.storeSkin,
  storeConfiguration: state.marketplaceApp.storeConfiguration,
})
export default connect(mapStateToProps)(MarketplaceAppCustomVdpHeader)
