import SAVED_VEHICLES_PAGE_CONSTANTS from "./savedVehiclePageConstants";
import AIP_DJAPI, {AIP_DJAPI_URLS} from "../../../../../api/aipDjapi";
import SEARCH_PAGE_CONSTANTS from "../../search-page/state/searchPageConstants";
import { store } from "../../../../..";
import debounce from 'lodash.debounce'



export function getSavedVehicles(){
    const { savedVehiclePage } = store.getState()
    const { page } = savedVehiclePage
    return (dispatch)=>{
        debounceGetSavedVehicles(true,dispatch)
        AIP_DJAPI(
            `${AIP_DJAPI_URLS.USERS}liked-vehicles/?items_per_page=${24}&page=${page}`,
            {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            },
            true
        ).then((response)=>{
            if(response.status===200){
                return response.json()
            }
        }).then((res)=>{
            if(res){
                dispatch({
                    type: SAVED_VEHICLES_PAGE_CONSTANTS.GET_SAVED_VEHICLES,
                    ...res,
                })
                updateSavedVehiclesLoading(false)(dispatch)
            }
        })
    }
}

function addLikedVehicle(vin){
    return AIP_DJAPI(
        `${AIP_DJAPI_URLS.USERS}like-vehicle/${vin}/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        },
        true
    ).then((response)=>{
        if(response.status===200){
            return response.json()
        }
    })
}

function deleteLikedVehicle(vin){
    return AIP_DJAPI(
        `${AIP_DJAPI_URLS.USERS}like-vehicle/${vin}/`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        },
        true
    ).then((response)=>{
        if(response.status===200){
            return response.json()
        }
    })
}

export function saveVehicle(vehicle){
    return(dispatch)=>{
        addLikedVehicle(vehicle.vin)
        dispatch({
            type: SAVED_VEHICLES_PAGE_CONSTANTS.ADD_SAVED_VEHICLE,
            vehicle,
        })
    }
}

export function removeLikedVehicle(vehicle){
    const { savedVehiclePage } = store.getState()
    const { page, savedVehicles } = savedVehiclePage
    return(dispatch)=>{
        deleteLikedVehicle(vehicle.vin)
        dispatch({
            type: SAVED_VEHICLES_PAGE_CONSTANTS.DELETE_SAVED_VEHICLE,
            vehicle,
        })
        if (savedVehicles.length === 1 && page > 1){
            updatePage(page - 1)(dispatch)
        }
    }
}

export function updateSortByField(sortByValue){
    return(dispatch)=>{
      dispatch({
        type: SEARCH_PAGE_CONSTANTS.UPDATE_SORT_BY_FIELD,
        sortByValue
      })
    }
}

export function updatePage(page){
    return(dispatch)=>{
        dispatch({
            type: SAVED_VEHICLES_PAGE_CONSTANTS.UPDATE_SAVED_VEHICLES_PAGE,
            page
        })
        getSavedVehicles()(dispatch)
    }
}

const debounceGetSavedVehicles = debounce((updateFilters, dispatch)=>{
    updateSavedVehiclesLoading(true)(dispatch)
  },500)

export function updateSavedVehiclesLoading(loading) {
    return(dispatch) => {
      dispatch({
        type: SAVED_VEHICLES_PAGE_CONSTANTS.UPDATE_SAVED_LOADING,
        loading: loading
      })
    }
  }


export default{
    dispatchActions: {
        getSavedVehicles,
        saveVehicle,
        removeLikedVehicle,
        updateSortByField,
        updatePage,
        updateSavedVehiclesLoading,
    }
}
