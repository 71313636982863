import React from 'react'

const VDI_Engine = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.29 50">
        <path d="M72.38,16.66a1.88,1.88,0,0,0-1.69-1.06H64a1.88,1.88,0,0,0-1.88,1.88v4H59.4v-4a1.89,1.89,0,0,0-1.87-1.88l-4.15,0L50.79,9.71a1.88,1.88,0,0,0-1.72-1.12H39V3.76h5.32a1.88,1.88,0,0,0,0-3.76H21.94a1.88,1.88,0,0,0,0,3.76h6.5V8.59H19.58a1.89,1.89,0,0,0-1.64.95L16.05,12.9H14.76a6.19,6.19,0,0,0-6.19,6.18v4.11H4.23a1.84,1.84,0,0,0-.47.06V13.15a1.88,1.88,0,0,0-3.76,0V36.34a1.88,1.88,0,1,0,3.76,0V26.89a1.84,1.84,0,0,0,.47.06H8.57v9.13a6.27,6.27,0,0,0,6.27,6.26h6L28,49.45a1.88,1.88,0,0,0,1.33.55h28.2a1.88,1.88,0,0,0,1.88-1.88V42.7h2.68v3.56A1.88,1.88,0,0,0,64,48.13h6.87a1.89,1.89,0,0,0,1.69-1c.11-.23,2.77-5.7,2.77-15.47A36.46,36.46,0,0,0,72.38,16.66ZM32.2,3.77h3V8.59h-3Zm37.37,40.6H65.84V40.81a1.93,1.93,0,0,0-.55-1.34A1.88,1.88,0,0,0,64,38.92h0l-6.44,0a1.88,1.88,0,0,0-1.88,1.88v5.42H30.1L23,39.13a1.9,1.9,0,0,0-1.33-.55H14.84a2.5,2.5,0,0,1-2.5-2.5v-17a2.42,2.42,0,0,1,2.42-2.42h2.39a1.87,1.87,0,0,0,1.64-1l1.89-3.35H47.85l2.58,5.86a1.87,1.87,0,0,0,1.71,1.12l3.5,0v4a1.88,1.88,0,0,0,1.88,1.88H64a1.88,1.88,0,0,0,1.88-1.88v-4h3.6a35.47,35.47,0,0,1,2.08,12.26A38.19,38.19,0,0,1,69.57,44.37Z"/>
        <path d="M42.44,26.09H38.16l4-8a1.89,1.89,0,1,0-3.37-1.69L33.43,27.15A1.86,1.86,0,0,0,33.51,29a1.89,1.89,0,0,0,1.6.89h0l4,0L35.17,36.5l-.5-2a1.88,1.88,0,1,0-3.65.93l1.62,6.33a1.89,1.89,0,0,0,1.82,1.41,1.84,1.84,0,0,0,.47-.06l6.33-1.61a1.88,1.88,0,0,0-.94-3.65l-1.88.48,5.62-9.43a1.92,1.92,0,0,0,0-1.9A1.9,1.9,0,0,0,42.44,26.09Z"/>
    </svg>
  )
}
export default VDI_Engine