import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import SEARCH_PAGE_ACTIONS from '../state/searchPageActions'
import MARKETPLACE_APP_ACTIONS from '../../../state/marketplaceAppActions'
import { updateSiteTitle } from '../../../marketplaceAppUtils'
import validateNumberInput from '../../../../../utils/validateNumberInput'
import formatLongNumber from '../../../../../utils/formatLongNumber'
import CancelIcon from '../../../../common/common-assets/icons/cancelicon'
import './searchFilterMenu.scss'

function SearchFilterMenu(props) {
  const {
    filters,
    defaultFilters,
    vehicles,
    total_vehicle_count,
    search,
    zipcode,
    updateSearchQuery,
    updateSelectedField,
    selectedLocation,
    selectedDistance,
    selectedVehicleCondition,
    selectedMake,
    selectedModel,
    selectedBodyStyle,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    selectedMinMonthlyPayment,
    selectedMaxMonthlyPayment,
    selectedMinMileage,
    selectedMaxMileage,
    selectedExteriorColor,
    selectedInteriorColor,
    clearSearchPage,
    updateZipcode,
    getSearchPage,
    stashUrlHistory,
    externalSrp
  } = props

  const history = useHistory()
  const [zipCodeField, setZipCodeField] = useState(zipcode ? zipcode : '')

  const updateUrl = (priorityValues = {}) => {
    let pathMake = priorityValues.selectedMake
      ? priorityValues.selectedMake
      : selectedMake
    let pathModel = priorityValues.selectedModel
      ? priorityValues.selectedModel
      : selectedModel
    let path = `/search${externalSrp ? '-ext' : ''}${'/' + zipCodeField}${
      '/' +
      (selectedVehicleCondition.item.label === 'Used & CPO' ||
      selectedVehicleCondition.item.label === 'New'
        ? selectedVehicleCondition.item.label
        : 'New & Used')
    }${
      '/' +
      (pathMake.item.key === 'Any'
        ? 'All Makes'
        : pathMake.item.label
        ? pathMake.item.label
        : pathMake.item.key)
    }${
      '/' +
      (pathModel.item.key === 'Any' || pathMake.item.key === 'Any'
        ? 'All Models'
        : pathModel.item.label
        ? pathModel.item.label
        : pathModel.item.key)
    }`
    path += location.search
    path = encodeURI(path)
    window.history.replaceState(null, null, path)
    stashUrlHistory(window.location)
    if (zipCodeField) {
      updateSiteTitle(
        `${
          pathMake.item.key === 'Any'
            ? ''
            : (pathMake.item.label ? pathMake.item.label : pathMake.item.key) +
              ' '
        }Cars Near ${zipCodeField}`
      )
    }
  }

  const debounceReloadSearchPage = debounce(() => {
    if (zipCodeField.length > 4 && zipCodeField !== zipcode) {
      updateZipcode(zipCodeField)
      getSearchPage(true)
      updateUrl()
    }
  }, 750)

  useEffect(() => {
    console.log('Updated')
    debounceReloadSearchPage()
  }, [zipCodeField])

  useEffect(() => {
    updateUrl()
  }, [selectedMake, selectedModel, selectedVehicleCondition])

  let updateZipCodeField = (newZip) => {
    if (isNaN(newZip) || newZip.length > 5) {
      return
    }
    setZipCodeField(newZip)
  }

  let modelItems = []
  let mileageFilters = []
  const mileageDistances = [10, 25, 50, 75, 100, 200, 300, 400, 500]
  mileageFilters = mileageDistances.map((distance) => {
    return { key: '' + distance }
  })

  let minYears =
    selectedMaxYear.item.key !== 'Any'
      ? filters.year.buckets.filter(
          (year) => parseInt(year.key) <= parseInt(selectedMaxYear.item.key)
        )
      : filters.year.buckets
  let maxYears =
    selectedMinYear.item.key !== 'Any'
      ? filters.year.buckets.filter(
          (year) => parseInt(year.key) >= parseInt(selectedMinYear.item.key)
        )
      : filters.year.buckets

  return (
    <div className="search-sidebar-sub-menu search-filter-menu-container">
      <div className="search-filter-menu">
        {(() => {
          if (filters && defaultFilters && vehicles) {
            return (
              <React.Fragment>
                <div className="search-sidebar-sub-menu-title search-filter-clear-menu-title">
                  Search
                </div>
                <div className="marketplace-input-wrapper">
                  <div className="marketplace-input-container">
                    <input
                      value={search}
                      onChange={(e) => {
                        updateSearchQuery(e.target.value)
                      }}
                      placeholder="Search"
                    />
                    <span
                      className={
                        search && search.length > 0
                          ? 'marketplace-input-cancel-icon visible'
                          : 'marketplace-input-cancel-icon hidden'
                      }
                      onClick={() => updateSearchQuery('')}
                    >
                      <CancelIcon />
                    </span>
                  </div>
                </div>
                <hr />
                <div className="search-sidebar-sub-menu-title search-filter-clear-menu-title">
                  Zipcode
                </div>
                <div className="marketplace-input-wrapper">
                  <div className="marketplace-input-container">
                    <input
                      value={zipCodeField}
                      onChange={(e) => {
                        updateZipCodeField(e.target.value)
                      }}
                      placeholder="Zip Code"
                    />
                    <span
                      className={
                        zipCodeField && zipCodeField.length > 0
                          ? 'marketplace-input-cancel-icon visible'
                          : 'marketplace-input-cancel-icon hidden'
                      }
                      onClick={() => setZipCodeField('')}
                    >
                      <CancelIcon />
                    </span>
                  </div>
                </div>
                <hr />
                <div className="search-filter-menu-filter-items-container">
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Location
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown search-filter-menu-filter-item-dropdown-">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.state.buckets}
                        name={'Locations'}
                        defaultKey={'Any Locations'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedLocation.item}
                        selectedField={'selectedLocation'}
                        filter={'state'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Distance
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={mileageFilters}
                        name={'Distance'}
                        defaultKey={'Nationwide'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedDistance.item}
                        selectedField={'selectedDistance'}
                        filter={'miles'}
                        createLabel={(val) => {
                          return val + ` miles`
                        }}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Vehicle Condition
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.condition.buckets}
                        name={'Condition'}
                        defaultKey={'Any Condition'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedVehicleCondition.item}
                        selectedField={'selectedVehicleCondition'}
                        filter={'condition'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Make
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={defaultFilters.make.buckets}
                        name={'Make'}
                        defaultKey={'Any Make'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedMake.item}
                        selectedField={'selectedMake'}
                        filter={'make'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Model
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.model.buckets}
                        name={'Model'}
                        defaultKey={'Any Model'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedModel.item}
                        selectedField={'selectedModel'}
                        filter={'model'}
                        disabled={selectedMake.item.key === 'Any'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Color
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.exterior_color.buckets}
                        name={'Exterior Color'}
                        defaultKey={'Any Color'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedExteriorColor.item}
                        selectedField={'selectedExteriorColor'}
                        filter={'exterior_color'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Interior Color
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.interior_color.buckets}
                        name={'Interior Color'}
                        defaultKey={'Any Color'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedInteriorColor.item}
                        selectedField={'selectedInteriorColor'}
                        filter={'interior_color'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Body Style
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={filters.type.buckets}
                        name={'Body Style'}
                        defaultKey={'Any Body Style'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedBodyStyle.item}
                        selectedField={'selectedBodyStyle'}
                        filter={'type'}
                      />
                    </div>
                  </div>
                  <div className="search-filter-menu-filter-item-dropdown-container">
                    <div className="search-filter-menu-filter-item-dropdown-title">
                      Years
                    </div>
                    <div className="search-filter-menu-filter-item-dropdown">
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={minYears}
                        name={'Years Min'}
                        defaultKey={'Min'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedMinYear.item}
                        selectedField={'selectedMinYear'}
                        filter={'year'}
                      />
                      <SearchFilterMenuSelect
                        updateUrl={updateUrl}
                        items={maxYears}
                        name={'Years Max'}
                        defaultKey={'Max'}
                        updateSelectedField={updateSelectedField}
                        selectedItem={selectedMaxYear.item}
                        selectedField={'selectedMaxYear'}
                        filter={'year'}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="search-filter-menu-filter-items-container">
                  <div className="search-filter-menu-filter-item-range-container">
                    <div className="search-filter-menu-filter-item-range-title">
                      Price
                    </div>
                    <SearchFilterMenuInputRange
                      name="Price"
                      updateSelectedField={updateSelectedField}
                      selectedItemMin={selectedMinPrice.item}
                      selectedItemMax={selectedMaxPrice.item}
                      selectedFieldMin={'selectedMinPrice'}
                      selectedFieldMax={'selectedMaxPrice'}
                      filter={'price'}
                    />
                    {/*<div className="search-filter-menu-filter-item-range">
                        <MultiThumbSlider minRange={0} maxRange={100000} minStart={0} maxStart={100000} step={100} currency={true}/>
                      </div>*/}
                  </div>
                </div>
                <hr />
                {/*
                      <div className="search-filter-menu-filter-items-container">
                        <div className="search-filter-menu-filter-item-range-container">
                          <div className="search-filter-menu-filter-item-range-title">
                            Monthly Payment
                          </div>
                          <div className="search-filter-menu-filter-item-range">
                            <MultiThumbSlider minRange={0} maxRange={100000} minStart={0} maxStart={100000} step={100}/>
                          </div>
                        </div>
                      </div>
                      <hr/>
                    */}
                <div className="search-filter-menu-filter-items-container">
                  <div className="search-filter-menu-filter-item-range-container">
                    <div className="search-filter-menu-filter-item-range-title">
                      Mileage
                    </div>
                    <SearchFilterMenuInputRange
                      name="Mileage"
                      updateSelectedField={updateSelectedField}
                      selectedItemMin={selectedMinMileage.item}
                      selectedItemMax={selectedMaxMileage.item}
                      selectedFieldMin={'selectedMinMileage'}
                      selectedFieldMax={'selectedMaxMileage'}
                      filter={'distance'}
                    />
                  </div>
                </div>
              </React.Fragment>
            )
          } else {
            console.log('VEHICLES', vehicles, 'FILTERS', defaultFilters)
          }
          //Loading Animation Goes Here
          return <React.Fragment />
        })()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.searchPage
})
const mapDispatchToProps = {
  ...MARKETPLACE_APP_ACTIONS.dispatchActions,
  ...SEARCH_PAGE_ACTIONS.dispatchActions
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchFilterMenu)

function SearchFilterMenuSelect(props) {
  const {
    selectedItem,
    defaultKey,
    name,
    filter,
    disabled,
    updateSelectedField,
    selectedField,
    createLabel,
    updateUrl
  } = props
  let { items } = props
  if (selectedItem.optionsAtTimeOfSelection) {
    items = selectedItem.optionsAtTimeOfSelection
  }
  let options = []
  if (items && items.length && name !== 'Make') {
    options = items.map((item) => {
      let labelValue = item.label ? item.label : item.key
      let label = createLabel ? createLabel(labelValue) : labelValue
      return (
        <option key={item.key} value={item.key.toLowerCase()}>
          {label}
        </option>
      )
    })
  }

  options.unshift(
    <option key={defaultKey} value={'Any'}>
      {defaultKey}
    </option>
  )

  if (items && items.length && name === 'Make') {
    let makesPopular = [];
    let makesContinued = [];
    let alphabetRestarted = false;
    let lastChar = null;

    items.forEach(make => {
      const currentChar = make.key.charAt(0).toLowerCase();
      if (lastChar === null) {
        lastChar = currentChar;
      }
      if (lastChar > currentChar) {
        alphabetRestarted = true;
      }
      if (!alphabetRestarted) {
        makesPopular.push(make);
      } else {
        makesContinued.push(make);
      }
      lastChar = currentChar;
    });

    const optgroup1 =
      (<optgroup label="Makes">
        <option key={defaultKey} value={'Any'}>{defaultKey}</option>
        {makesPopular.map(make => (<option key={make.key} value={make.key.toLowerCase()}>{make.key}</option>))}
      </optgroup>)

    const optgroup2 =
      (<optgroup label="Makes Continued...">
        {makesContinued.map(make => (<option key={make.key} value={make.key.toLowerCase()}>{make.key}</option>))}
      </optgroup>)

    options = [optgroup1, optgroup2]
  }

  return (
    <select
      onChange={(e) => {
        //Need to Find Value Index to Pass Along Label Because Nothing can be simple
        let selectedItem = {
          key: 'Any'
        }
        if (e.target.value !== 'Any') {
          selectedItem = items.find((item, i) => {
            if (e.target.value === item.key.toLowerCase()) {
              return { ...item }
            }
          })
          selectedItem.optionsAtTimeOfSelection = items
        }
        updateSelectedField({ item: { ...selectedItem }, selectedField })
        // let priorityValues = {}
        // priorityValues[selectedField] = {item:{...selectedItem}}
        // updateUrl(priorityValues)
      }}
      name={name}
      disabled={disabled || (!items && !items.length)}
      value={selectedItem.key.toLowerCase()}
      className={name.includes('Years') ? 'years-section' : null}
    >
      {options}
    </select>
  )
}
function SearchFilterMenuInputRange(props) {
  const {
    updateSelectedField,
    name,
    selectedFieldMin,
    selectedFieldMax,
    selectedItemMin,
    selectedItemMax,
    filter
  } = props

  const [minErrorFlag, setMinErrorFlag] = useState(false)
  const [maxErrorFlag, setMaxErrorFlag] = useState(false)
  const [minFieldValue, setMinFieldValue] = useState('')
  const [maxFieldValue, setMaxFieldValue] = useState('')

  // for when we click a breadcrumb and the range inputs are reset and need cleared out bc nothing is easy
  useEffect(() => {
    if(selectedItemMin.key == 'Any'){
      setMinFieldValue("")
    }
    if(selectedItemMax.key == 'Any'){
      setMaxFieldValue("")
    }
  }, [selectedItemMin.key, selectedItemMax.key])

  // when updating minValue filter
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setMinErrorFlag(false)
      if (minFieldValue.length) {
        if (
          parseInt(minFieldValue) <=
          (selectedItemMax.key === 'Any'
            ? parseInt(minFieldValue)
            : parseInt(selectedItemMax.key))
        ) {
          updateSelectedField({
            item: { key: minFieldValue },
            selectedField: selectedFieldMin
          })
        } else {
          setMinErrorFlag(true)
        }
      } else {
        updateSelectedField({
          item: { key: 'Any' },
          selectedField: selectedFieldMin
        })
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [minFieldValue])

  // when updating maxValue filter
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setMaxErrorFlag(false)
      if (maxFieldValue.length) {
        if (
          parseInt(maxFieldValue) >=
          (selectedItemMin.key === 'Any'
            ? parseInt(maxFieldValue)
            : parseInt(selectedItemMin.key))
        ) {
          updateSelectedField({
            item: { key: maxFieldValue },
            selectedField: selectedFieldMax
          })
        } else {
          setMaxErrorFlag(true)
        }
      } else {
        updateSelectedField({
          item: { key: 'Any' },
          selectedField: selectedFieldMax
        })
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [maxFieldValue])

  return (
    <div className="multi-thumb-slider-container">
      <div className="multi-thumb-slider-input-container">
        <div className="single-input-container">
          {filter == 'price' && (
            <div className="dollar-sign">
              <div>$</div>
            </div>
          )}
          <input
            id="multi-thumb-slider-input-price-space"
            className={filter == 'price' ? 'price' : ''}
            // value ternary needed due to interaction of clicking breadcrumbs/resetting this value with formatLongNumber() on the minVal
            value={minFieldValue.length ? formatLongNumber(minFieldValue) : minFieldValue}
            onBlur={()=>{
              setMinErrorFlag(false)
              if(minFieldValue.length){
                if(parseInt(minFieldValue)<=(selectedItemMax.key==='Any'?parseInt(minFieldValue):parseInt(selectedItemMax.key))){
                  updateSelectedField({item:{key:minFieldValue},selectedField:selectedFieldMin})
                }
                else{
                  setMinErrorFlag(true)
                }
              }
              else{
                updateSelectedField({item:{key:'Any'},selectedField:selectedFieldMin})
              }
            }}
            onChange={(e) => {
              setMinFieldValue(e.target.value.replace(/,/g, ''))
            }}
            onKeyPress={(e) => validateNumberInput(e)}
          />
        </div>
        <span className="multi-thumb-slider-to-field">to</span>
        <div className="single-input-container">
          {filter == 'price' && (
            <div className="dollar-sign">
              <div>$</div>
            </div>
          )}
          <input
            className={filter == 'price' ? 'price' : ''}
            // value ternary needed due to interaction of clicking breadcrumbs/resetting this value with formatLongNumber() on the minVal
            value={maxFieldValue.length ? formatLongNumber(maxFieldValue) : maxFieldValue}
            onBlur={()=>{
              setMaxErrorFlag(false)
              if(maxFieldValue.length){
                if(parseInt(maxFieldValue)>=(selectedItemMin.key==='Any'?parseInt(maxFieldValue):parseInt(selectedItemMin.key))){
                  updateSelectedField({item:{key:maxFieldValue},selectedField:selectedFieldMax})
                }
                else{
                  setMaxErrorFlag(true)
                }
              }
              else{
                updateSelectedField({item:{key:'Any'},selectedField:selectedFieldMax})
              }
            }}
            onChange={(e) => {
              setMaxFieldValue(e.target.value.replace(/,/g, ''))
            }}
            onKeyPress={(e) => validateNumberInput(e)}
          />
        </div>
      </div>
      <div>
        {minErrorFlag ? `Min ${name} must be less than Max ${name}` : ''}
        {maxErrorFlag ? `Max ${name} must be greater than Min ${name}` : ''}
      </div>
    </div>
  )
}
