import React from 'react'
import '../stylesheets/videoPlayerIcon.scss'

function VideoPlayerIcon(){
  return(
    <svg className="videoPlayerIcon" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 72 72">
      <circle cx="36" cy="36" r="34.5" fill="black" fillOpacity="0" stroke="white" strokeWidth="3" />
      <path d="M48.8129 33.4404C50.7266 34.6103 50.7266 37.3897 48.8129 38.5596L32.0647 48.798C30.0657 50.02 27.5 48.5814 27.5 46.2384L27.5 25.7616C27.5 23.4186 30.0657 21.98 32.0647 23.202L48.8129 33.4404Z" fill="white"/>
    </svg>
  )
}
export default VideoPlayerIcon
