import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import {connect} from 'react-redux'
import formatPhoneNumber from '../../../../utils/formatPhoneNumber'
import validateZip from '../../../../utils/validateZip'
import validatePhone from '../../../../utils/validatePhone'
import { updateUserSettings, changePassword, getUserProfile, checkPasswordValidity } from '../../marketplaceAppUtils'
import convertComplianceNumber from '../../../../utils/convertPasswordComplianceNumber'
import './accountSettings.scss'
import LoadingSpinner from '../../../common/common-components/loadingSpinner'
import PasswordInput from '../../../common/common-components/passwordInput'
import debounce from 'lodash.debounce'



function AccountSettings(props) {
    const {forceEditing} = props
    const [firstNameField, setFirstNameField] = useState("")
    const [firstNameValid, setFirstNameValid] = useState()
    const [lastNameField, setLastNameField] = useState("")
    const [lastNameValid, setLastNameValid] = useState()
    const [zipCodeField, setZipCodeField] = useState("")
    const [zipCodeValid, setZipCodeValid] = useState()
    const [phoneNumberField, setPhoneNumberField] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState()
    const [editingAccount, setEditingAccount] = useState(forceEditing)
    const [userEmail, setUserEmail] = useState('')
    const [userToken, setUserToken] = useState('')
    const [userProfile, setUserProfile]= useState({})
    const [loading, setLoading] = useState(!forceEditing)
    const [changingPassword, setChangingPassword] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [newPasswordValid, setNewPasswordValid] = useState()
    const [passwordCompliance, setPasswordCompliance] = useState()
    const [passwordRequirements, setPasswordRequirements] = useState([])
    const [passwordsMatch, setPasswordsMatch] = useState()

    // Set initial account settings
    useEffect(() => {
        getProfile(props.token)

    }, [props.token])

    // Get profile function used on page load and profile update
    function getProfile(token) {
        console.log(token)
        if (!forceEditing && token) {
            getUserProfile(token).then(response => {
                if (response.status === 200) {
                    response.json().then(res => {
                        setFirstNameField(res?.first_name)
                        setLastNameField(res?.last_name)
                        setZipCodeField(res?.zip_code)
                        setPhoneNumberField(res?.phone_number)
                        setUserEmail(res?.email)
                        setUserToken(props.token)
                        setUserProfile(res)
                        setLoading(false)

                        const userProfile = {user: res, token: token}

                        // Conditional that verifies that res contains the user settings before setting
                        if (res && res.first_name) {
                            localStorage.setItem('userProfile', JSON.stringify(userProfile))
                        }
                    })
                }
            })
        }

    }

    // Submit form if no errors exist
    function formSubmit() {
        let isActive = true
        validateAccountSettings().then((e) => {
            if (isActive) {
                if (e == 0) {
                    const form = new FormData()
                    form.append('first_name', firstNameField)
                    form.append('last_name', lastNameField)
                    form.append('zip_code', zipCodeField)
                    form.append('phone_number', phoneNumberField)
                    updateUserSettings(form).then(response => {
                        if (response.status === 200) {
                            response.json().then(() => {
                                getProfile(userToken, true)
                            })

                            toast('Account settings successfully changed!')
                            setEditingAccount(false)
                            if(forceEditing){
                              window.location.href="/"
                            }
                        } else {
                            toast('Account settings change failed.')
                            setEditingAccount(false)
                        }

                    })
                }
            }
        })
        return(() => {isActive = false})
    }

    // Check all fields on the account settings form
    async function validateAccountSettings() {
        let errors = 0
        // Validate First Name
        if (firstNameField) {
            if (firstNameField.length == 0) {
                setFirstNameValid(false)
                errors+=1
            } else {
                setFirstNameValid(true)
            }
        } else {
            setFirstNameValid(false)
            errors+=1
        }


        // Validate Last Name
        if (lastNameField) {
            if (lastNameField.length == 0) {
                setLastNameValid(false)
                errors+=1
            } else {
                setLastNameValid(true)
            }
        } else {
            setLastNameValid(false)
            errors+=1
        }


        // Validate Zip Code
        if (zipCodeField) {
            if (!validateZip(zipCodeField) && zipCodeField.length > 0) {
                setZipCodeValid(false)
                errors+=1
            } else {
                setZipCodeValid(true)
            }
        }

        // Validate Phone Number
        if (phoneNumberField) {
            if (!validatePhone(phoneNumberField) && phoneNumberField.length > 0) {
                setPhoneNumberValid(false)
                errors+=1
            } else {
                setPhoneNumberValid(true)
            }
        }


        return(errors)
    }



    // Reset password and toast on success
    function startChangingPassword() {
        validatePassword('')
        setChangingPassword(true)
    }

    // Verify that passwords match
    useEffect(() => {
        if (newPassword == confirmNewPassword) {
        setPasswordsMatch(true)
        } else {
        setPasswordsMatch(false)
        }

    }, [newPassword, confirmNewPassword])

    function resetUserPassword() {
        if (confirmNewPassword == newPassword) {
            changePassword(newPassword, confirmNewPassword, userToken).then(response => {
                if (response.status === 200) {
                    toast(`Password successfully changed!`)
                    setChangingPassword(false)
                    setEditingAccount(false)
                } else {
                    toast(`An error occurred. Please try again later`)
                }
            })
        } else {
            setNewPasswordValid(false)
        }

    }

    // Cancel the edit and reset user settings to the original
    function cancelEdit() {
        setEditingAccount(false)
        setFirstNameField(userProfile?.first_name)
        setLastNameField(userProfile?.last_name)
        setZipCodeField(userProfile?.zip_code)
        setPhoneNumberField(userProfile?.phone_number)
        setUserEmail(userProfile?.email)
        setChangingPassword(false)
    }

    // Validate password with AIP Standards and store requirements
    async function validatePassword(password) {
        const passwordValidity = await checkPasswordValidity(password)
        if (passwordValidity) {
        const passwordValidityJSON = await passwordValidity.json()
        setPasswordCompliance(passwordValidityJSON.aip_standards.aip_percentage)
        setPasswordRequirements(passwordValidityJSON.aip_standards.aip_pw_rules)
        }
    }

    // debounce setPassword Field
    const debounceSetPassword = (password) => {
        setNewPassword(password)
        validatePassword(password)
    }
    const debouncedPassword = debounce(debounceSetPassword, 500)


    return(
        <div className="account-settings-container">
            <div className='account-settings-page'>
                <div className="account-settings-form-heading">
                    {forceEditing?"Please Confirm Account Information":"Account Settings"}
                </div>

                <div className='account-settings-form'>

                    {loading &&
                        <div>
                            <LoadingSpinner loading />
                        </div>
                    }

                    <form autoComplete='off'>
                        <fieldset disabled={editingAccount ? "" : "disabled"} className='account-settings-fieldset'>

                            {/* Email Address */}
                            <div className='account-settings-form-label'>Email Address</div>
                            <input className="account-settings-form-field"disabled={"disabled"}  value={userEmail} type='text' />

                            {/* First Name */}
                            <p className={firstNameValid == false ? "invalid-field" : "hidden"}>Please enter first name</p>
                            <div className='account-settings-form-label'>First Name</div>
                            <input className="account-settings-form-field" placeholder="Enter First Name"  value={firstNameField || ''} onChange={(e)=> setFirstNameField(e.target.value)} type='text' />

                            {/* Last Name */}
                            <p className={lastNameValid == false ? "invalid-field" : "hidden"}>Please enter last name</p>
                            <div className='account-settings-form-label'>Last Name</div>
                            <input className="account-settings-form-field" placeholder="Enter Last Name" value={lastNameField || ''} onChange={(e)=> setLastNameField(e.target.value)} type='text' />

                            {/* Zip Code Optional */}
                            <p className={zipCodeValid == false ? "invalid-field" : "hidden"}>Please enter valid zip code</p>
                            <div className='account-settings-form-label'>Zip Code</div>
                            <input className="account-settings-form-field" placeholder="Enter Zip Code (Optional)" value={zipCodeField || ''} onChange={(e)=> setZipCodeField(e.target.value)} disabled={editingAccount ? false : true} type='text' maxLength={5} />

                            {/* Phone Number Optional */}
                            <p className={phoneNumberValid == false ? "invalid-field" : "hidden"}>Please enter valid phone number</p>
                            <div className='account-settings-form-label'>Phone Number</div>
                            <input className="account-settings-form-field" placeholder="Enter Phone Number (Optional)" value={phoneNumberField && phoneNumberField.length === 10 ? formatPhoneNumber(phoneNumberField) : phoneNumberField} onChange={(e)=> setPhoneNumberField(e.target.value)} maxLength={10} disabled={editingAccount ? false : true}  type='text' />

                            {/* Reset Password */}

                            <div >
                                {forceEditing?null:(!changingPassword ? (
                                    <button className={editingAccount ? 'primary-button full-width' : 'primary-button full-width disabled'} type="image" alt="Change password" onClick={() => startChangingPassword()} type='button'>
                                        Change Password
                                    </button>) : (
                                    <div className='password-container'>
                                        <div className='account-settings-form-label'>New Password</div>
                                        <PasswordInput setPasswordField={debouncedPassword} formSubmit={formSubmit} placeholder='Create New Password' debounce={true} />
                                        <div className='account-settings-form-label'>Confirm New Password</div>
                                        <PasswordInput setPasswordField={setConfirmNewPassword} formSubmit={formSubmit} placeholder='Confirm New Password' debounce={true} />
                                        <div className='password-requirements'>
                                            <div className={`password-compliance-bar ${convertComplianceNumber(passwordCompliance).class}`} />
                                            Password Requirements:
                                            {(passwordRequirements.length > 0 || passwordsMatch == false) && (
                                            <ul>
                                                {passwordsMatch == false && <li className='requirement'>Passwords must match.</li>}
                                                {passwordRequirements.map((requirement, index) => (<li className='requirement' key={index}> {requirement} </li>))}
                                            </ul>
                                            )}
                                            {(passwordRequirements.length == 0 && passwordsMatch == true && newPassword.length > 0) && (
                                            <div className='password-requirements-met'>
                                                All password requirements met.
                                            </div>
                                            )}
                                        </div>
                                        <button className={(passwordRequirements.length == 0 && passwordsMatch == true && newPassword.length > 0) ? 'primary-button full-width' : 'primary-button full-width disabled'} type="image" alt="Confirm change password" onClick={() => resetUserPassword()} type='button' disabled={(passwordRequirements.length == 0 && passwordsMatch == true && newPassword.length > 0) ? false : true}>
                                            Confirm Change Password
                                        </button>
                                        <button className='primary-button full-width cancel' type="image" alt="Change password" onClick={() => setChangingPassword(false)} type='button'>
                                            Cancel Change Password
                                        </button>
                                    </div>

                                ))}
                            </div>
                        </fieldset>
                    </form>

                    {editingAccount ? (
                        <div>
                            <button className='primary-button full-width' type="image" alt="Confirm accont settings" onClick={formSubmit} >
                                Confirm Account Changes
                            </button>
                            {forceEditing?null:
                              <button className='primary-button full-width cancel' type="image" alt="Cancel accont settings" onClick={() => cancelEdit()} type='button'>
                                Cancel Account Changes
                              </button>
                            }
                        </div>
                    ) : (
                        <button className='primary-button full-width' onClick={() => setEditingAccount(true)}>
                            Edit Account Settings
                        </button>
                    )}
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    user: state.userProfile.user,
    token: state.userProfile.token,
})

export default connect(mapStateToProps)(AccountSettings)
