//List of Supported Module Content Types
const MODULE_TYPES= {
  _HTML5_VIDEO: 'html5_video',
  _YOUTUBE_VIDEO: 'youtube_video',
  _3PA: '3pa',
  _PDF: 'pdf',
  _IMAGE: 'image',
  _HTML: 'html',
  _HTML_URL: 'html_url',
  _JSON: 'json',
  _IFRAME: 'iframe'
}
export default MODULE_TYPES
