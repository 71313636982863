import React from 'react'
import {connect} from 'react-redux'
import LandingPageSearchCta from './landingPageSearchCta'
import LandingPageExplanation from './landingPageExplanation'
function LandingPage(props){
  return(
    <div className="marketplace-landing-page">
      <div className="marketplace-landing-page-subsection">
        <LandingPageSearchCta/>
      </div>
      <div className="marketplace-landing-page-subsection">        <LandingPageExplanation/>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(LandingPage)
