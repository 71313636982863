import React from 'react'
import {connect} from 'react-redux'
import './privacyPolicy.scss'

const Introduction = () => {
    return(
        <div className="privacy-page-paragraph">
            <p>
                The website AutoiPacket.com, including all resources available from the AutoiPacket.com domain 
                name (the “Site”) is owned and operated by AutoIPacket LLC doing business as AutoiPacket 
                (“AutoiPacket”). This Privacy Policy (the “Statement”) describes the Site’s collection, 
                disclosure, and use of consumer information practices in connection with the Site. The Statement 
                applies to any portions of the Site that AutoiPacket co-brands with any affiliates, licensors, 
                suppliers, service providers, distribution partners, advertisers, and/or other third-parties 
                (collectively, “Affiliates”). Please be aware you may have arrived at a co-branded version of 
                the Site from an Affiliate’s website. This Statement is to be read and understood in conjunction 
                with the Terms of Service. Although this Statement will describe how consumer information is 
                shared with Affiliates, who may independently collect, store, and use information obtained from 
                you, this Statement should not be interpreted as making any representations regarding how the 
                Affiliates treat such information. For information on the collection, use, and disclosure 
                practices of Affiliates, please review the privacy policies of those Affiliates.
            </p>
        </div>
    )
}

const ContactInfo = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Contact Information</h1>
            <p>
                Consumers who visit the Site for personal use (“consumer” or “you” or “your”) will not receive 
                unsolicited emails (“spam”), phone calls, or mailings from AutoiPacket. You may be asked to 
                provide contact information (email, phone, and/or mailing address) through the Site in order 
                to connect you (a “Request”) with a clearly identified automotive product or service (“Seller”). 
                AutoiPacket will deliver your contact information to Seller in connection with a Request and 
                AutoiPacket will not share such information with any other party. Upon the Seller’s request, 
                AutoiPacket may send you a single automated response to your Request. To opt-out from Seller 
                communications, please contact the Seller directly. To the extent an Affiliate manages certain 
                channels of communications (e.g., toll-free telephone numbers), the Affiliates is under an 
                obligation to not use your contact information for any purpose beyond an accounting between 
                Affiliate, AutoiPacket and Seller.
            </p>
        </div>
    )
}

const ConsumerInfo = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Consumer Nonpublic Personal Information</h1>
            <p>
                AutoiPacket treats consumer nonpublic personal information (“NPI”) with utmost respect. AutoiPacket 
                warrants that: (1) it guards NPI with physical, electronic, and procedural safeguards; (2) it does 
                not disclose NPI, except as is reasonably necessary to fulfill the purposes for which such information 
                was provided, and (3) it complies with all requirements of Title V of the Gramm-Leach-Bliley Act, 
                15 U.S.C §§ 6801 through 6810 (“GLB Act”), and the Federal Trade Commission’s implementing regulation, 
                Regulation P, 16 C.F.R. Part 313 (“Regulation P”) as both relate to the disclosure and protection of NPI. 
                AutoiPacket expressly reserves the right to release any NPI under the following circumstances: (a) in 
                connection with any transfer of ownership and/or sale of all or a part of the Site, (b) to protect the 
                property or safety of users of the Site and/or others, (c) to investigate and/or take action against 
                illegal activity, unauthorized use, and/or suspected abuse on or of the Site, (d) to enforce the Terms 
                of Service Agreement, and (e) when required by legal process or law.
            </p>
        </div>
    )
}

const PassiveInfoCollection = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Passive Information Collection</h1>
            <p>
                The Site automatically collects non-personally identifiable information, which may include the type and 
                version of your browser, your service provider, your IP address, location-based information, your usage 
                and navigation of the Site, and any means you may have used to locate the Site (“Web Analytics”). 
                Web Analytics does NOT include your name, e-mail, telephone number or other information described in 
                the “Contact Information” section of this Statement. Web Analytics may be collected through cookies, 
                web beacons, or other technologies.
            </p>
        </div>
    )
}

const UseOfInfo = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Use of Information</h1>
            <p>
                AutoiPacket may use any collected information to help diagnose problems with equipment, administer and 
                operate the Site, compile statistical data for analysis, research, optimization, performance and development.
            </p>
        </div>
    )
}

const EmailsToIpacket = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Emails to AutoiPacket</h1>
            <p>
                AutoiPacket will not, in the ordinary course of business, release, publish, or disseminate the content of 
                private electronic messages transmitted to AutoiPacket. However, we may occasionally release information 
                concerning such communications when release is appropriate to comply with law (including disclosure in 
                response to a request from a law enforcement agency), or to protect the rights, property, or safety of 
                visitors to the Site, AutoiPacket, Affiliates, and/or others. 
            </p>
        </div>
    )
}

const DataDeletion = () => {
    return(
        <div className="privacy-page-paragraph">
            <h1>Data Deletion or Disclosure</h1>
            <span>
                <p>
                    As of January 1 2020, California law permits residents of California to request certain details about 
                    how their personal information is shared with third-parties or affiliated companies for direct marketing purposes. 
                    California residents may take advantage of their disclosure and deletion rights by <a>contacting us.</a>
                </p>
                <p> 
                    You may request, up to two times each year, that we disclose to you the categories and specific pieces of personal 
                    information that we have collected about you, the categories of sources from which your personal information is 
                    collected, the business or commercial purpose for collecting your personal information, and the categories of 
                    personal information that we disclosed for a business purpose.
                </p> 
                <p>
                    When taking advantage of your disclosure and deletion rights:
                </p>
                <p>
                    We will verify your identity for security purposes before accepting the deletion.We’ll start working on your 
                    disclosure or deletion request after we receive it, but some of your data may remain in our system if we are 
                    legally required, or while we are legally permitted to retain it.Instead of deleting certain data, we may de-identify 
                    or disassociate it, such that it no longer appears associated with you.Once your data is deleted, you can’t recover 
                    any data, or regain access. You’ll need to set up a new account if you want to access AutoiPacket again.
                </p>
            </span>
        </div>
    )
}

function PrivacyPolicy(props) {
    return(
        <div className="privacy-policy-page">
            <h1 className="privacy-policy-header">Privacy Policy</h1>
            <h1 className="privacy-policy-last-updated">Last Updated: 01/12/2021</h1>
            <Introduction />
            <ContactInfo />
            <ConsumerInfo />
            <PassiveInfoCollection />
            <UseOfInfo />
            <EmailsToIpacket />
            <DataDeletion />

        </div>
    )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(PrivacyPolicy)