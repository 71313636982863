import React from 'react'

const FacebookIcon1 = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M100,50A50,50,0,1,0,42.19,99.39V64.45H29.49V50h12.7V39c0-12.53,7.46-19.45,18.88-19.45a76.81,76.81,0,0,1,11.2,1v12.3H66c-6.21,0-8.15,3.86-8.15,7.81V50H71.68L69.46,64.45H57.81V99.39A50,50,0,0,0,100,50Z"/>
        <path 
            d="M69.46,64.45,71.68,50H57.81V40.62c0-3.95,1.94-7.81,8.15-7.81h6.31V20.51a76.81,76.81,0,0,0-11.2-1c-11.42,0-18.88,6.92-18.88,19.45V50H29.49V64.45h12.7V99.39a50.3,50.3,0,0,0,15.62,0V64.45Z"
            fill="transparent"
        />
    </svg>
    )
}

export default FacebookIcon1