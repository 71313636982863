import React, { Component } from "react";
import Slider from "react-slick";

// redux
import { connect } from "react-redux";
import MODAL_ACTIONS from "../../marketplace-app/modals/state/modalActions";
import { getVehicleImages } from "../../marketplace-app/marketplaceAppUtils";

// components
import LoadingSpinner from "./loadingSpinner";

// assets
import ArrowLeft from "../common-assets/icons/arrowLeft";
import ArrowRight from "../common-assets/icons/arrowRight";

// style
import "./tileMiniSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MainNextArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    right: "10px",
    top: "100px",
    zIndex: "2",
    borderRadius: "10px",
  };

  return (
    <div
      className={className}
      id="next-arrow"
      style={{ ...style, ...newStyle }}
      onClick={onClick}
    >
      <ArrowRight />
    </div>
  );
}

function MainPrevArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    left: "10px",
    top: "100px",
    zIndex: "2",
    borderRadius: "10px",
  };

  return (
    <div
      className={className}
      style={{ ...style, ...newStyle }}
      onClick={onClick}
      id="prev-arrow"
    >
      <ArrowLeft />
    </div>
  );
}

class TileMiniSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainNav: null,
      images: [],
      loadingImages: false,
      hasOnlyOneImgToLoad: false, // to distinguish cars with >1 images from cars with only 1 image
      firstClickSlideDirection: null,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.initiateSlideshow = this.initiateSlideshow.bind(this);
    this.loadSliderImages = this.loadSliderImages.bind(this);
  }

  previous() {
    this.slider.slickGoTo(this.state.images.length-1)
  }
  next() {
    if(this.state.images.length > 1){
      this.slider.slickGoTo(1)
    }
  }

  componentDidMount() {
    const { vehicle } = this.props;
    this.setState({ images: [vehicle.thumbnail_url] });

    this.setState({
      mainNav: this.slider,
    });
  }

  componentDidUpdate() {
    if (this.state.loadingImages === true) {
      this.loadSliderImages();
      this.setState({ loadingImages: false })
    }
  }

  loadSliderImages() {
    const { images } = this.state;
    const { vehicle } = this.props;
    if (vehicle.vin && images.length === 1) {
      getVehicleImages(vehicle.vin)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            this.setState({ images: [vehicle.thumbnail_url] });
          }
        })
        .then((res) => {
          if (res) {
            this.setState({ images: res.vehicle_images });
            if (res.vehicle_images.length == 1) {
              this.setState({ hasOnlyOneImgToLoad: true });
            }
            this.state.firstClickSlideDirection == "prev" ? this.previous() : this.next()
          }
        });
    } else return null;
  }

  initiateSlideshow(event, direction) {
    const { activateSlideshow } = this.props;
    event.preventDefault();
    this.setState({ loadingImages: true});
    activateSlideshow();
    this.setState({ firstClickSlideDirection: direction });
  }

  render() {
    const { vehicle } = this.props;
    const { images, loadingImages, hasOnlyOneImgToLoad } = this.state;

    //Settings for Image Slider
    const mainSettings = {
      dots: false,
      fade: true,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <MainNextArrow />,
      prevArrow: <MainPrevArrow />,
    };

    return (
      <div className="tile-minislider-container">
        <div className="slider-main-image-container">
          <div className="slider-main-image">
            {images && (
              <Slider
                ref={(slider) => {
                  if (slider && !this.slider) {
                    this.slider = slider;
                    this.setState({ mainNav: this.slider });
                  }
                }}
                {...mainSettings}
              >
                {hasOnlyOneImgToLoad === false &&
                  images.length > 1 &&
                  images.map((image, index) => (
                    <div key={index}>
                      <img src={image} alt="vehicle" />
                      <div className="img-count-main-image-overlay">
                        <span>
                          {index + 1}/{images.length}
                        </span>
                      </div>
                    </div>
                  ))}
                {hasOnlyOneImgToLoad === false && images.length === 1 && (
                  <>
                    <img src={vehicle.thumbnail_url} alt="vehicle" />
                    <div
                      className="start-slider-image-overlay left"
                      onClick={(event) => this.initiateSlideshow(event, "prev")}
                    >
                      <ArrowLeft />
                    </div>
                    <div
                      className="start-slider-image-overlay right"
                      onClick={(event) => this.initiateSlideshow(event, "next")}
                    >
                      <ArrowRight />
                    </div>
                  </>
                )}
                {hasOnlyOneImgToLoad === true && (
                  <>
                    <img src={vehicle.thumbnail_url} alt="vehicle" />
                    <div className="start-slider-image-overlay left">
                      <ArrowLeft />
                    </div>
                    <div className="start-slider-image-overlay right">
                      <ArrowRight />
                    </div>
                    <div className="img-count-main-image-overlay">
                      <span>1/1</span>
                    </div>
                  </>
                )}
                {loadingImages && <LoadingSpinner loading />}
              </Slider>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions,
};

const mapStateToProps = (state) => ({
  modals: state.modal.modalList,
});

export default connect(mapStateToProps, mapDispatchToProps)(TileMiniSlider);
