import React from 'react'
import TradeInYourCarIcon from '../common-assets/icons/tradeInYourCarIcon'
import './tradeInYourCar.scss'

function TradeInYourCar(){
    return(
      <a href="#">
        <div className="trade-in-your-car-container">
          <div>
              <TradeInYourCarIcon />
              <span>Trade In Your Car</span>
              <p>Let's start with a free original window sticker!</p>
              <div className="get-your-sticker-btn">
                  <span>GET YOUR FREE STICKER</span>
              </div>
          </div>
        </div>
      </a>
    )
}

export default TradeInYourCar
