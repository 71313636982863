import React, { useState, useEffect } from "react";
// icons
import CloseIcon from "../../../common/common-assets/icons/closeicon";
// redux
import { connect } from "react-redux";
import MODAL_ACTIONS from "../state/modalActions";
import VEHICLE_DISPLAY_PAGE_ACTIONS from '../../pages/vehicle-display-page/state/vehicleDisplayPageActions'

// utils
import validateLetterInput from "../../../../utils/validateLetterInput";
import validateEmail from "../../../../utils/validateEmail";
import { vehicleShare } from "../../marketplaceAppUtils";
// components
import ShareSentModal from "./shareSent";
import LoadingSpinner from "../../../common/common-components/loadingSpinner";
import { toast } from 'react-toastify'
// style
import "./share.scss";

function ShareModal(props) {
  const { closeModal,user, etsGenericActionSubmitEvent, vehicle} = props;
  const [firstNameField, setFirstNameField] = useState("");
  const [lastNameField, setLastNameField] = useState("");
  const [senderEmailField, setSenderEmailField] = useState("");
  const [senderEmailFieldValid, setSenderEmailFieldValid] = useState();
  const [recipientEmailField, setRecipientEmailField] = useState("");
  const [recipientEmailFieldValid, setRecipientEmailFieldValid] = useState();
  const [messageField, setMessageField] = useState("");
  const [loading, setLoading] = useState(false);
  const [shareSent, setShareSent] = useState();
  const [shareLink, setShareLink] = useState();

  const yearMakeModel = `${vehicle.year} ${vehicle.make} ${vehicle.model}`


  useEffect(()=>{
    if (user) {
        setFirstNameField(props.user?.first_name)
        setLastNameField(props.user?.last_name)
        setSenderEmailField(props.user?.email)
    }
  },[])

  async function shareListing() {
    let errors = 0;
    if (validateEmail(senderEmailField) === false){
      errors +=1
      setSenderEmailFieldValid(false)
    } else (setSenderEmailFieldValid(true))

    if (validateEmail(recipientEmailField) === false){
      errors +=1
      setRecipientEmailFieldValid(false)
    } else (setRecipientEmailFieldValid(true))

    if (errors == 0) {
      const share = {
        send: true,
        first_name: firstNameField,
        last_name: lastNameField,
        sender_email: senderEmailField,
        email: recipientEmailField,
        message: messageField,
        vin: props.vehicle.vin,
      };

      const stringifyInquiry = JSON.stringify(share);
      etsGenericActionSubmitEvent && VEHICLE_DISPLAY_PAGE_ACTIONS.sendGenericActionEtsRecording(etsGenericActionSubmitEvent)
      vehicleShare(stringifyInquiry).then((response) => {
        setLoading(true);
        if (response.status == 200) {
          response.json().then((res) => {
            setLoading(false);
            setShareSent(true);
          });
        } else {
          setLoading(false);
          setShareSent(false);
        }
      });
    }
  }

  function getShareableLink() {
    const share = {
      send: false,
      vin: props.vehicle.vin,
    };

    const stringifyInquiry = JSON.stringify(share);
    etsGenericActionSubmitEvent && VEHICLE_DISPLAY_PAGE_ACTIONS.sendGenericActionEtsRecording(etsGenericActionSubmitEvent)
    vehicleShare(stringifyInquiry).then((response) => {
      setLoading(true);
      if (response.status == 200) {
        response.json().then((res) => {
          setShareLink(res.url);
          navigator.clipboard.writeText(res.url);
          setLoading(false);
        });
      } else {
        toast("An error occurred, please try again later")
        setLoading(false);
      }
    });
  }

  const handleShareURLCopy = () => {
    if (window.location.origin.includes('info')) return window.location.origin + `/${vehicle.vin}`
    if (window.location.pathname.includes('vdp')) return window.location.origin + `/vehicle/v3/vdp/${vehicle.vin}`
    if (window.location.pathname.includes('vehicle')) return window.location.origin + `/vehicle/${vehicle.vin}`
  }

  return shareSent === true ? (
    <ShareSentModal sharedSuccess="You've shared this listing!" />
  ) : shareSent === false ? (
    <ShareSentModal sharedSuccess="Unable to share listing, please try again later" />
  ) : (
    <div className="share-modal">
      {loading && <LoadingSpinner loading />}
      <div className="modal-title">Share this Listing.</div>
      <div className="close-modal" onClick={() => closeModal()}>
        <CloseIcon />
      </div>

      <form>
        <div className="form-row">
          <div className="form-input">
            <input
              type="text"
              placeholder="Your First Name (Optional)"
              className="input"
              onChange={(e) => setFirstNameField(e.target.value)}
              onKeyPress={(e) => validateLetterInput(e)}
              value={firstNameField}
            />
          </div>

          <div className="form-input">
            <input
              type="text"
              placeholder="Your Last Name (Optional)"
              className="input"
              onChange={(e) => setLastNameField(e.target.value)}
              onKeyPress={(e) => validateLetterInput(e)}
              value={lastNameField}
            />
          </div>
        </div>

        <div className="form-row single">
          {senderEmailFieldValid == false && (
            <div className="error">Please enter valid email address</div>
          )}

          <input
            type="text"
            placeholder="Your Email"
            className="input"
            value={senderEmailField}
            onChange={(e) => setSenderEmailField(e.target.value)}
          />
        </div>

        <div className="form-row single">
          {recipientEmailFieldValid == false && (
            <div className="error">Please enter valid email address</div>
          )}

          <input
            type="text"
            placeholder="Recipient's Email"
            className="input"
            value={recipientEmailField}
            onChange={(e) => setRecipientEmailField(e.target.value)}
          />
        </div>

        <div className="form-row single">
          <textarea
            placeholder="Include Message (Optional)"
            className="input"
            value={messageField}
            onChange={(e) => setMessageField(e.target.value)}
          />
        </div>

        <div className="form-row submit-row">
          <input
            onClick={shareListing}
            type="button"
            value="Share this Listing"
            className="primary-button"
          />
          {!shareLink ? (
            <a onClick={() => { navigator.clipboard.writeText(handleShareURLCopy()); toast.success('Link copied to clipboard'); }}>
              <span className="shareable-link">
                Share Link
              </span>
              {/*
                <span className="shareable-link" onClick={getShareableLink}>
                  Get Shareable Link
                </span>
                */}
            </a>
          ) : (
            <div className="copy-link-container">
              <input type="text" value={shareLink} className="copy-url" />
              <span>Link copied!</span>
            </div>
          )}
        </div>
        <div className="message-bottom">
          <div className="message">
            We value your privacy.{" "}
            <a href="https://www.ipacket.us/privacy-policy" target="_blank">
              <div className="privacy">iPacket Privacy Statement.</div>
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.userProfile.user,
  vehicle: state.modal.modalList[0].props.vehicle,
});

const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
