import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../../../api/aipDjapi'


function submitOptIn(mobileNumber){
  return AIP_DJAPI(
    AIP_DJAPI_URLS.HEARTBEAT,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export default {
  submitOptIn
}
