import React from 'react'

const VDI_Interior = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 51.23">
      <path d="M.69,43.09a7.18,7.18,0,0,0,1.92,3.6,14.88,14.88,0,0,0,4.76,3.84.66.66,0,0,0,.38.08H30.43a.33.33,0,0,0,.21-.08,15.91,15.91,0,0,0,4.76-3.84,7.33,7.33,0,0,0,1.92-3.6c.34-2.21-.58-5.55-3.09-6.22-.25-2.34-.42-4-.46-4.89,0-.5-.08-1-.17-1.54s-.16-1.05-.25-1.55c0-.25-.08-.46-.12-.62v-.09A37.14,37.14,0,0,1,33,18.53a6.33,6.33,0,0,0-1.55-4.68,4.15,4.15,0,0,0-3-1.37H24V10.76h1.12A2.46,2.46,0,0,0,27,9.93a1.77,1.77,0,0,0,.38-1.55L25.88,2.24A2.24,2.24,0,0,0,23.62.61H14.56A2.24,2.24,0,0,0,12.3,2.24L11,8.38a1.77,1.77,0,0,0,.38,1.55,2.36,2.36,0,0,0,1.88.83h1.13v1.72H10.21a4.25,4.25,0,0,0-2.8,1.33,6.12,6.12,0,0,0-1.54,4.6,25.27,25.27,0,0,1-.71,8.85l0,.13c-.09.33-.21.71-.34,1.17-.37,1.42-.58,2.5-.62,2.71a21.15,21.15,0,0,0,0,5.47H4C1.32,37.37.31,40.84.69,43.09Zm3.17,2.55a5.29,5.29,0,0,1-1.54-2.75c-.25-1.63.42-4.1,2.09-4.43a3.4,3.4,0,0,1,3.34,1.88V48.9A14.62,14.62,0,0,1,3.86,45.64ZM28.76,49H9.42V40.8a.36.36,0,0,0,.08-.21H28.72V49ZM11.84,26H26.29V39H11.84ZM35.65,42.84a6,6,0,0,1-1.54,2.76,13.4,13.4,0,0,1-3.68,3.13V40.55a3.58,3.58,0,0,1,2.8-2.13c.08,0,.12,0,.21,0h.16C35.19,38.71,35.9,41.3,35.65,42.84ZM28.34,14.19A2.38,2.38,0,0,1,30.1,15a4.53,4.53,0,0,1,1.08,3.43c-.29,4.64-.21,8,.29,10.15v.08a3.36,3.36,0,0,1,.13.59l.25,1.42A8.52,8.52,0,0,1,32,32.11c0,.88.2,2.5.45,4.72a5.21,5.21,0,0,0-3,2.13H27.92V14.19ZM12.64,8.93a.32.32,0,0,1-.05-.13l1.34-6.14a.64.64,0,0,1,.63-.33h9.1c.38,0,.59.16.63.33L25.63,8.8a.2.2,0,0,1-.05.13.7.7,0,0,1-.58.2H13.18A.74.74,0,0,1,12.64,8.93ZM16,10.81h6.18v1.71H16Zm10.36,3.38V24.3H11.84V14.19ZM5.83,31.57c0-.21.21-1.22.58-2.55.13-.46.25-.8.29-1.05l0-.12a27.87,27.87,0,0,0,.75-9.44A4.48,4.48,0,0,1,8.63,15a2.72,2.72,0,0,1,1.58-.83V38.92H8.88a5.31,5.31,0,0,0-3-2.09A21,21,0,0,1,5.83,31.57Z"
            strokeWidth="1.2258447182302314px"
      />
    </svg>
  )
}
export default VDI_Interior