import React from 'react'
import {connect} from 'react-redux'
import LoadingSpinner from '../../../../common/common-components/loadingSpinner'
import Pagination from '../../../../common/common-components/paginator'
import VehicleTile from '../../search-page/components/searchVehicleTile'
import SAVED_VEHICLES_PAGE_ACTIONS from '../state/savedVehiclePageActions'
import './savedVehicleDisplay.scss'

function SavedVehiclesDisplay(props){
  const {savedVehicles, savedVehiclesCount, page, saveVehicle, loading, removeLikedVehicle, updatePage, isUserLoggedIn} = props

  const handleSaveVehicle = (vehicle) => {
    savedVehicles.find(foundVehicle=>foundVehicle.vin === vehicle.vin) ? removeLikedVehicle(vehicle) : saveVehicle(vehicle)
  }

  if(!loading){
    if(savedVehicles.length > 0){
      return(
    <div>
      <div className="saved-vehicle-display-container">
        {savedVehicles.map(vehicle=>{
          return(
            <VehicleTile
              vehicle={vehicle}
              key={vehicle.vin}
              saveVehicle={handleSaveVehicle}
              fillIcon={savedVehicles.includes(vehicle)}
              isUserLoggedIn={isUserLoggedIn}
            />
          )
        })}
      </div>
      <Pagination
          className="pagination-bar"
          currentPage={page}
          totalCount={savedVehiclesCount==='>10,000'?24*100:savedVehiclesCount}
          pageSize={24}
          onPageChange={(pageNum) => {
            updatePage(pageNum)
            setTimeout(()=> {window.scrollTo({top: 0, left:0, behavior:'smooth'})}, 500)
          }}
        />
    </div>
  )
    } else {
      return(
        <div className="no-search-results-found">
          No Results Found
        </div>
      )
    }
  } else {
    return(
      <LoadingSpinner loading />
    )
  }
}
const mapStateToProps = state =>({
  ...state.savedVehiclePage,
  isUserLoggedIn: state.userProfile.user && state.userProfile.token ? true : false,
})
const mapDispatchToProps = {
  ...SAVED_VEHICLES_PAGE_ACTIONS.dispatchActions,
}
export default connect(mapStateToProps,mapDispatchToProps)(SavedVehiclesDisplay)
