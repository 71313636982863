import React from 'react'

// icons
import CloseIcon from '../../../common/common-assets/icons/closeicon'

// style
import './askaquestion.scss'

// redux
import {connect} from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'


function QuestionSentModal(props) {
    const { closeModal, dealerName } = props

    return (
        <div className='question-modal'>
            <div className='modal-title'>Your question has been sent!</div>
            <div className='close-modal' onClick={() => closeModal()}>
                <CloseIcon />
            </div>

            <div className='message'>
                TIP: Be on the lookout for a call or email from {dealerName[0].toLowerCase().includes('a'||'e'||'i'||'o'||'u') ? 'an' : 'a'}<br />
                {dealerName ? dealerName : ''} team member. If you do not<br />
                receive any communication, you may want to check<br />
                your spam folder!
            </div>

            <div className='form-row button' onClick={() => closeModal()}>
                <div className='close-button'>
                    Close
                </div>
            </div>

        </div>
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
}


export default connect(null, mapDispatchToProps)(QuestionSentModal)
