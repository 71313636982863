import React from "react";
import { connect } from "react-redux";
import MODAL_ACTIONS from "../../modals/state/modalActions";
import ModuleDisplay from "../../pages/vehicle-display-page/vdpModuleDisplay";
import CloseIcon from '../../../common/common-assets/icons/closeicon'

import "./moduleModal.scss";

function ModuleModal(props) {
  const { vehicle, module, closeModal, storeConfiguration } = props;
  console.log('STORE CONFIGURATION', storeConfiguration,props)
  return (
    <div className="module-modal-container">
      <div className='close-modal' onClick={() => closeModal()}>
        <CloseIcon />
      </div>
      <ModuleDisplay module={module} downloadBtnPosition={'left'} vehicle={vehicle} storeConfiguration={storeConfiguration} className="module-display" />
    </div>
  );
}

const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions,
};

const mapStateToProps = (state) => ({
    module: state.modal.modalList[0].props.module,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleModal);
