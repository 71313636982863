import React, { Component, useEffect, useState } from 'react'

// redux
import { connect } from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'

// components
import ImageSlider from '../../../common/common-components/imageSlider'

// assets
import CloseIcon from '../../../common/common-assets/icons/closeicon'

// styles
import './lightbox-modal.scss'

function LightBoxModal(props){
  const {closeModal} = props


  return(
    <div className="slider-container-light-box">
        <div className='close-modal' onClick={() => closeModal()}>
            <CloseIcon />
        </div>
            <div className='image-container'>
            {
                props.images.map((image, index) => {
                    return (
                        <img
                            key={index}
                            alt="Vehicle"
                            src={image}
                        />
                    )
                })
            }
            </div>
    </div>
  )
}


export default LightBoxModal
