import React from 'react'

const HeatmapIcon = ()=> {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="32"
      height="32"
    >
      <path
        // fill="#3278c8"
        d="M45,0A45,45,0,1,0,90,45,45,45,0,0,0,45,0ZM63.54,55.75a18.48,18.48,0,0,1-6.6,9.49,19.76,19.76,0,0,1-7.78,3.62s-.06,0-.13,0c3.95-1.58,5.8-4.59,6.11-8.82-.26.14-.41.25-.57.32a15.22,15.22,0,0,1-1.89.76A1.66,1.66,0,0,1,50.59,60a6.47,6.47,0,0,1,.29-5.05A10.52,10.52,0,0,0,51.94,50a3.8,3.8,0,0,0-.2-1.08,9.25,9.25,0,0,1-.39,1,8.5,8.5,0,0,1-.82,1.44,1.63,1.63,0,0,1-2.47.3,5,5,0,0,1-2.19-4.08c0-1.54.09-3.08.14-4.61l0-.51-.11-.06c-.22.28-.46.54-.67.83A11.3,11.3,0,0,0,43.46,48a2.5,2.5,0,0,1-.75,1.6,1.48,1.48,0,0,1-2.14-.05,7.49,7.49,0,0,1-.77-1.13c-.08-.13-.13-.27-.25-.51a4.47,4.47,0,0,0-.5,2,13.13,13.13,0,0,0,1.19,5.59,3.52,3.52,0,0,1,0,2.61,3,3,0,0,1-4.35,1.53c-.33-.17-.64-.39-1.06-.66A13.86,13.86,0,0,0,37,65.14a9.66,9.66,0,0,0,5,3.93c-.31,0-.62-.08-.92-.14l-.93-.22A19.12,19.12,0,0,1,26.84,56.42a17.44,17.44,0,0,1-1.07-5.9,41.56,41.56,0,0,1,1.07-11c.22-.89.59-1.75.89-2.63l.12,0c.21.63.4,1.26.65,1.87a7.45,7.45,0,0,0,3.56,4,13,13,0,0,1,3.46,2.41l.22.15a.72.72,0,0,0,0-.21,17.93,17.93,0,0,1-1.28-8.06,18.82,18.82,0,0,1,2.24-7.7A27.32,27.32,0,0,1,44.1,21s.08,0,0,0c0,1.29-.16,2.62-.1,3.94a5.63,5.63,0,0,0,2.12,4.39,10.06,10.06,0,0,1,3.76,6.52c.14.87.14,1.76.21,2.73a6.79,6.79,0,0,0,2.15-3.41,11.6,11.6,0,0,0,.37-4.17c.29.34.61.65.87,1a15.66,15.66,0,0,1,2.31,5.79,40,40,0,0,1,.79,8.79c0,.25,0,.5,0,.8A3.57,3.57,0,0,0,59.08,46a5.51,5.51,0,0,0,1.18-2.91,12,12,0,0,0-.17-3.88.56.56,0,0,1,.09-.38c.38.46.78.9,1.12,1.39a15.94,15.94,0,0,1,2.59,6.63A19.41,19.41,0,0,1,63.54,55.75Z"
      />
    </svg>
  )
}

export default HeatmapIcon
