import React from 'react'

const PrintIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" >
      <path d="M15.65 27.55H28q.7 0 1.175-.5.475-.5.475-1.2V21.2L34 25.55V14.2l-4.35 4.35v-4.7q0-.7-.475-1.175Q28.7 12.2 28 12.2H15.65q-.7 0-1.175.475Q14 13.15 14 13.85v12q0 .7.475 1.2t1.175.5ZM4 44V7q0-1.15.9-2.075Q5.8 4 7 4h34q1.15 0 2.075.925Q44 5.85 44 7v26q0 1.15-.925 2.075Q42.15 36 41 36H12Zm3-7.25L10.75 33H41V7H7ZM7 7v29.75Z"/>
    </svg>
  )
}
export default PrintIcon
