import React from 'react'
import './styles/moduleYoutubeVideo.scss'
function ModuleYoutubeVideo(props){
  let{module} = props
  return (
    <div className="module-youtube-video-container">
      <div className="module-youtube-video">
          <iframe src={module.url} frameBorder="0" allowFullScreen/>
      </div>
    </div>
  )
}
export default ModuleYoutubeVideo
