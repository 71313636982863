import REDUCER_CONSTANTS from '../constants/reducerConstants'

export function stashUrlHistory(userHistory) {
  return function(dispatch) {
    dispatch({
      type: REDUCER_CONSTANTS.STASH_URL_HISTORY,
      user_history: userHistory,
    })
  }
}

export default { REDUCER_CONSTANTS,
dispatchActions:{
  stashUrlHistory
} }
