import React from "react";
import { useHistory } from "react-router";
import ModuleArrow from "../common-assets/icons/moduleArrowIcon";
import "./vehicleNotFound.scss";

function VehicleNotFound(props) {
  let history = useHistory();

  const {externalVdp} = props;
  function goToHome() {
    history.push("/");
  }

  return (
    <div className="not-found-page-container">
      <div className="vehicle-display-page-sidebar-header">
        {!externalVdp && <div className="vehicle-display-page-sidebar-back-container">
          <span>
            <ModuleArrow />
          </span>
          <span onClick={() => goToHome()}>Begin Search</span>
        </div>}
      </div>
      <div className="not-found-container">
        <h1>Vehicle Not Found</h1>
      </div>
    </div>
  );
}

export default (VehicleNotFound);
