import React,{useState} from 'react'
import {connect} from 'react-redux'
import './searchFilterClearMenu.scss'
import MultiThumbSlider from '../../../../common/common-components/multiThumbSlider'
import CancelIcon from '../../../../common/common-assets/icons/cancelicon'
import SEARCH_PAGE_ACTIONS from '../state/searchPageActions'
import MaskedInput from 'react-text-mask'


function SearchFilterClearMenu(props){
  const {
    search,
    updateSelectedField,
    updateSearchQuery,
    activeSearch,
    selectedLocation,
    selectedDistance,
    selectedVehicleCondition,
    selectedMake,
    selectedModel,
    selectedBodyStyle,
    selectedInteriorColor,
    selectedExteriorColor,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    selectedMinMonthlyPayment,
    selectedMaxMonthlyPayment,
    selectedMinMileage,
    selectedMaxMileage,
  } = props
  const filterItems = ()=>{
    let items = []

    if(search && activeSearch.length){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSearchQuery('')}}>
          <div className='search-title'>{activeSearch}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedLocation && selectedLocation.item && selectedLocation.item.key !== 'Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedLocation'})}}>
          <div className='search-title'>{selectedLocation.item.label?selectedLocation.item.label:selectedLocation.item.key}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedDistance && selectedDistance.item && selectedDistance.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedDistance'})}}>
          <div className='search-title'>{(selectedDistance.item.label?selectedDistance.item.label: selectedDistance.item.key) + ' Mi'}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedVehicleCondition && selectedVehicleCondition.item && selectedVehicleCondition.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedVehicleCondition'})}}>
          <div className='search-title'>{selectedVehicleCondition.item.label? selectedVehicleCondition.item.label:selectedVehicleCondition.item.key}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedMake && selectedMake.item && selectedMake.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedMake'})}}>
          <div className='search-title'>{selectedMake.item.label ? selectedMake.item.label : selectedMake.item.key}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
      if(selectedModel&& selectedModel.item && selectedModel.item.key!=='Any'){
        items.push(
          <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedModel'})}}>
            <div className='search-title'>{selectedModel.item.label ? selectedModel.item.label : selectedModel.item.key}</div>
            <div className='cancel-icon'><CancelIcon /></div>
          </div>
        )
      }
    }
    if(selectedBodyStyle&& selectedBodyStyle.item && selectedBodyStyle.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedBodyStyle'})}}>
          <div className='search-title'>{selectedBodyStyle.item.label ? selectedBodyStyle.item.label : selectedBodyStyle.item.key}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedInteriorColor&& selectedInteriorColor.item && selectedInteriorColor.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedInteriorColor'})}}>
          <div className='search-title'>{'Interior Color '+(selectedInteriorColor.item.label ? selectedInteriorColor.item.label : selectedInteriorColor.item.key)}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }

    if(selectedExteriorColor&& selectedExteriorColor.item && selectedExteriorColor.item.key!=='Any'){
      items.push(
        <div className="search-filter-clear-menu-item" onClick={()=>{updateSelectedField({item:{key:'Any'},selectedField:'selectedExteriorColor'})}}>
          <div className='search-title'>{(selectedExteriorColor.item.label ? selectedExteriorColor.item.label : selectedExteriorColor.item.key)}</div>
          <div className='cancel-icon'><CancelIcon /></div>
        </div>
      )
    }
    // if(selectedMinYear && selectedMinYear.item && selectedMaxYear && selectedMaxYear.item && (selectedMinYear.item.key!=='Any' || selectedMaxYear.item.key!=='Any')){
    //
    // }
    //
    // if(selectedMinPrice && selectedMinPrice.item && selectedMinPrice.item.key!=='Any'){
    //   filterParams+=`&min_price=${selectedMinPrice.item.key}`
    // }
    //
    // if(selectedMaxPrice && selectedMaxPrice.item && selectedMaxPrice.item.key!=='Any'){
    //   filterParams+=`&max_price=${selectedMaxPrice.item.key}`
    // }
    //
    // if(selectedMinMileage && selectedMinMileage.item && selectedMinMileage.item.key!=='Any'){
    //   filterParams+=`&min_miles=${selectedMinMileage.item.key}`
    // }
    //
    // if(selectedMaxMileage && selectedMaxMileage.item && selectedMaxMileage.item.key!=='Any'){
    //   filterParams+=`&max_miles=${selectedMaxMileage.item.key}`
    // }
    return items
  }
  let items = filterItems()
  return(
    <div className="search-sidebar-sub-menu search-filter-clear-menu">
      <div className="search-sidebar-sub-menu-title search-filter-clear-menu-title">Filters</div>
      {items.length?
        <div className="search-filter-clear-menu-items">
          {items}
        </div>
        :
        <div className="search-filter-clear-menu-none-selected">
          No Filters Selected
        </div>
      }

    </div>
  )
}
const mapStateToProps = state =>({
  ...state.searchPage,
})
const mapDispatchToProps= {
  ...SEARCH_PAGE_ACTIONS.dispatchActions
}
export default connect(mapStateToProps,mapDispatchToProps)(SearchFilterClearMenu)
