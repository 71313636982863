import React from 'react';
import { connect } from 'react-redux'
import MarketplaceAppContainer from '../marketplace-app/marketplaceAppContainer'
import '../common/stylesheets/index.scss'

function AppContainer(props){

  return(
    <MarketplaceAppContainer/>
  )
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps)(AppContainer)
