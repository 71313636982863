import React from "react";

function LinkedinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M12.13,2.21C4.79,2.21,0,7,0,13.35S4.65,24.49,11.85,24.49H12c7.47,0,12.12-4.95,12.12-11.14S19.46,2.21,12.13,2.21Z" />
      <path d="M34.57,97.79H56v-36a14.56,14.56,0,0,1,.71-5.23c1.55-3.85,5.07-7.84,11-7.84,7.75,0,10.86,5.91,10.86,14.58v34.5H100v-37c0-19.81-10.57-29-24.68-29C64,31.78,58.85,38,56,42.43V33.3H34.57c.28,6.05,0,64.49,0,64.49Z" />
      <rect x="1.26" y="33.3" width="21.44" height="64.49" />
    </svg>
  );
}
export default LinkedinIcon;
