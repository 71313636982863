import React, {useState, useEffect} from 'react'
import './contactModal.scss'

function ContactModal(props){
  return(
    <div className="contact-modal-container">
      Contact Modal
    </div>
  )
}
export default ContactModal
