import React, { useState, useEffect, useRef } from "react";
import lodash from "lodash";
import moment from 'moment';
import { toast } from 'react-toastify'

import MODAL_ACTIONS from "../../../marketplace-app/modals/state/modalActions";
import ExpandFullscreen from "../../../common/common-assets/icons/expandFullscreen";
import VDI_Car from '../../../common/common-assets/icons/vdi_car'
import VDI_Drive from '../../../common/common-assets/icons/vdi_drive'
import VDI_Engine from '../../../common/common-assets/icons/vdi_engine'
import VDI_Color from '../../../common/common-assets/icons/vdi_color'
import VDI_Vin from '../../../common/common-assets/icons/vdi_vin'
import VDI_Interior from '../../../common/common-assets/icons/vdi_interior'
import ClickCopy from '../../../common/common-assets/icons/clickCopy'

function ModuleVehicleDetails(props) {
  const { vehicle, modules, pushModal, etsInfo,storeConfiguration } = props;
  const [isOpened, setIsOpened] = useState(false);
  const [showButton, setShowButton] = useState(true); // state for Read More/Less render
  const containerRef = useRef(null); // ref for the dealer notes container
  const notesShowHide = isOpened ? "Less" : "More"; // btn text display depending on collapsed state
  const [CPOVideoModules] = useState(modules.filter((module) => module.content_type=== 'youtube_video' || module.content_type=== 'html5_video'));
  const showVideosSection = storeConfiguration ? storeConfiguration.enable_vehicle_details_video_section : true
  const vehicle_details_dealer_notes_enabled = storeConfiguration ? storeConfiguration.vehicle_details_dealer_notes_enabled : false
  useEffect(() => {
    // ------- for Dealer Notes Section--------
    // for determining display/no display of Read More/Less btn based on screen resizing
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if applicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  const copyVin = () => {
    toast("VIN copied to clipboard")
    navigator.clipboard.writeText(vehicle.vin)
  }

  const copyStock = () => {
    toast("Stock # copied to clipboard")
    navigator.clipboard.writeText(vehicle.stock_no)
  }

  return (
    <div className="module-vehicle-details">
      <div className="vehicle-display-page-vehicle-info-body-title">Vehicle Details</div>
      <div className="module-vehicle-details-breakdown">
        {vehicle.engine_descrip ? 
          <div className="module-vehicle-details-breakdown-item-container">
            <div className="module-vehicle-details-breakdown-item engine">
              <div className="module-vehicle-details-breakdown-item-image">
                <VDI_Engine />
              </div>
                <div className="module-vehicle-details-breakdown-item-text">
                  <h1>Engine:</h1>
                  {/* <br /> */}
                  {vehicle.engine_descrip}
                </div>
            </div>
          </div>
        : <React.Fragment/>}
        {vehicle.body_type ?
          <div className="module-vehicle-details-breakdown-item-container">
            <div className="module-vehicle-details-breakdown-item bodyStyle">
              <div className="module-vehicle-details-breakdown-item-image">
                <VDI_Car />
              </div>
              <div className="module-vehicle-details-breakdown-item-text">
                <h1>Body Style:</h1>
                {/* <br /> */}
                {vehicle.body_type}
              </div>
            </div>
          </div>
        : <React.Fragment/> }
        {vehicle.ext_color ? 
          <div className="module-vehicle-details-breakdown-item-container">
            <div className="module-vehicle-details-breakdown-item extColor">
              <div className="module-vehicle-details-breakdown-item-image">
                <VDI_Color />
              </div>
              <div className="module-vehicle-details-breakdown-item-text">
                <h1>Exterior Color:</h1>
                {/* <br /> */}
                {vehicle.ext_color}
              </div>
            </div>
          </div>
        : <React.Fragment/> }
        {vehicle.int_color ?
          <div className="module-vehicle-details-breakdown-item-container">
            <div className="module-vehicle-details-breakdown-item intColor">
              <div className="module-vehicle-details-breakdown-item-image">
                <VDI_Interior />
              </div>
              <div className="module-vehicle-details-breakdown-item-text">
                <h1>Interior Color:</h1>
                {/* <br /> */}
                {vehicle.int_color}
              </div>
            </div>
          </div>
        : <React.Fragment/> }
        {vehicle.trans_descrip ?
          <div className="module-vehicle-details-breakdown-item-container">
            <div className="module-vehicle-details-breakdown-item drivetrain">
              <div className="module-vehicle-details-breakdown-item-image">
                <VDI_Drive />
              </div>
              <div className="module-vehicle-details-breakdown-item-text">
                <h1>Transmission:</h1>
                {/* <br /> */}
                {vehicle.trans_descrip}
              </div>
            </div>
          </div>
        : <React.Fragment/> }
        <div className="module-vehicle-details-breakdown-item-container">
          <div className="module-vehicle-details-breakdown-item vin">
            <div className="module-vehicle-details-breakdown-item-image">
              <VDI_Vin />
            </div>
            <div className="module-vehicle-details-breakdown-item-text">
              <h1>VIN:</h1>
              <div className="module-vehicle-details-breakdown-item-text-content">{vehicle.vin}</div>
              <div className="module-vehicle-details-breakdown-item-text-copy" onClick={() => copyVin()}>
                <ClickCopy/>
              </div>
            </div>
          </div>
        </div>
        <div className="module-vehicle-details-breakdown-item-container">
          <div className="module-vehicle-details-breakdown-item">
            <div className="module-vehicle-details-breakdown-item-image">
              <VDI_Vin />
            </div>
            <div className="module-vehicle-details-breakdown-item-text">
              <h1>Stock #:</h1>
              <div className="module-vehicle-details-breakdown-item-text-content">{vehicle.stock_no}</div>
              <div className="module-vehicle-details-breakdown-item-text-copy" onClick={() => copyStock()}>
                <ClickCopy/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="cpo-videos-container">
        { showVideosSection && CPOVideoModules.length > 0 ? (
          <React.Fragment>
            <hr />
            <div className="vehicle-display-page-vehicle-info-body-title">Videos</div>
          </React.Fragment>
        ) : null}
        {showVideosSection && CPOVideoModules.map((module, i) => {
            if (
              module.content_type.includes("youtube_video") ||
              module.content_type.includes("html5_video")
            ) {
              return (
                <div
                  key={i}
                  className="vehicle-display-page-vehicle-info-modules-container vehicle-display-page-vehicle-details-video"
                >
                  <div className="vehicle-display-page-vehicle-info-modules"
                    onClick={() => {
                      pushModal({
                        type: MODAL_ACTIONS.MODAL_CONSTANTS.MODULE_MODAL,
                        props: { module, vehicle, storeConfiguration },
                      });
                    }}
                  >
                    <div
                      className={`vehicle-display-page-vehicle-info-modules-overlay${(etsInfo && etsInfo.module_view_stats[module.module_id])?' vehicle-display-page-vehicle-info-modules-overlay-heatmap':''}`}
                      style={{backgroundImage:`url(${module.thumb_img})`, backgroundSize:`cover`, backgroundPosition:`center`}}
                    >

                      <div
                        className="expand-module-svg"
                      >
                        <ExpandFullscreen />
                      </div>
                      {(etsInfo && etsInfo.module_view_stats[module.module_id])&&
                        <div className="vehicle-display-page-vehicle-info-modules-heatmap-view">
                          {function(){
                            return(
                              etsInfo.module_view_stats[module.module_id].view_seconds>300?"5:00+": moment.utc(etsInfo.module_view_stats[module.module_id].view_seconds*1000).format('mm:ss')
                            )
                          }()}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              );
            } else return null;
          })}
      </div>

      {vehicle_details_dealer_notes_enabled && vehicle.seller_notes ? (
        <React.Fragment>
          <hr/>
          <div className="vehicle-display-page-vehicle-info-body-title">Dealer Notes</div>
          <div
            className={`module-vehicle-details-dealer-notes ${
              !isOpened ? "clamp" : ""
            }`}
            ref={containerRef}
            dangerouslySetInnerHTML={{ __html: vehicle.seller_notes }}
          >
          </div>
          {showButton && (
            <div className="dealer-notes-toggle">
              <button
                className="secondary-button"
                type="image"
                alt="Click to show or hide dealer notes"
                onClick={() => setIsOpened(!isOpened)}
              >
                Read {notesShowHide}
              </button>
            </div>
          )}
        </React.Fragment>
      ) : null }
    </div>
  );
}

export default ModuleVehicleDetails;
