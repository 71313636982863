import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import {
  getVehiclePage,
  getVehicleModules,
  getVehicleImages,
  putTracking,
} from "../../marketplaceAppUtils.js";
import MODAL_ACTIONS from "../../modals/state/modalActions";
import MODAL_CONSTANTS from '../../modals/state/modalConstants'

import SAVED_VEHICLES_PAGE_ACTIONS from '../saved-vehicle-page/state/savedVehiclePageActions'
import MARKETPLACE_APP_ACTIONS from '../../state/marketplaceAppActions'
import VEHICLE_DISPLAY_PAGE_ACTIONS from './state/vehicleDisplayPageActions'
import VEHICLE_DISPLAY_PAGE_CONSTANTS from './state/vehicleDisplayPageConstants'

import LoadingSpinner from "../../../common/common-components/loadingSpinner";
import VehicleDisplayPageInfo from "./vehicleDisplayPageInfo";
import VehicleDisplayPageSideBar from "./vehicleDisplayPageSideBar";
import VehicleNotFound from "../../../common/common-components/vehicleNotFound.js";
import RecommendedSlider from "../../../common/common-components/recommendedSlider.js";
import VehicleDisplayPageSkin from './vehicleDisplayPageSkin'
import IPacketFolderIconFallback from '../../../common/common-assets/images/fallback_ipacket_folder.png'
import VehicleDisplayPageInsights from './vehicleDisplayPageInsights'
import VehicleDisplayPageCustomHeader from './vehicleDisplayPageCustomHeader'
import Disclaimer from "./vdpDisclaimer.js";

import "./vehicleDisplayPage.scss";

const KEEP_ALIVE_POLL_INTERVAL = 10000 //10 seconds
let vdpConnectionPoll = null

//Forced to do this because of ETS - Khris


function VehicleDisplayPageRenderContainer(props){
  return(
    <div className="vehicle-display-page-container">
      {props.externalVdp === true ? <Disclaimer disclaimerPosition={1} /> : null}
      <div className="vehicle-display-page">
        {(props.externalVdp && props.storeSkin)?
          <VehicleDisplayPageSkin storeSkin={props.storeSkin}/>
          :
          <React.Fragment/>
        }

        {props.modules && (
          <VehicleDisplayPageSideBar {...props}/>
        )}
        {props.modules && (
          <VehicleDisplayPageInfo module={props.modules[props.selectedModuleIndex]} {...props}/>
        )}
      </div>
      {/* recommended vehicles slider here for screen widths >1425px */}
      {/*props.showRecommendationSlider ?
        <RecommendedSlider
          title="Similar Vehicles Near Me"
          subheading="We've rounded up cars that could be your perfect match."
          lat={props.vehicle.store.lat}
          long={props.vehicle.store.long}
          vin={props.vehicle.vin}
          make={props.vehicle.make}
          model={props.vehicle.model}
          forceSameLocation={true}
          pageLocation="vdp"
        />
        :
        <React.Fragment/>
      */}

    </div>
  )
}

function VehicleDisplayPage(props) {
  const {id, pushModal, mockAdd, urlHistoryStash, removeLikedVehicle, saveVehicle, user, isUserLoggedIn, savedVehicles, externalVdp,sharePage,getStoreSkin,getStoreConfiguration,storeSkin,storeConfiguration,insightsView,modalList } = props;
  let history = useHistory();
  const [vehicle, setVehicle] = useState();
  const [modules, setModules] = useState();
  const [vehicleImages, setVehicleImages] = useState();
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
  const [loadingVehicle, setLoadingVehicle] = useState(true);
  const [loadingVehicleModules, setLoadingVehicleModules] = useState(true);
  const [error, setError] = useState(null);
  const [isOpened, setIsOpened] = useState(true);
  const [session_id, setSessionId] = useState(null);
  const [etsInfo, setEtsInfo] = useState(null)
  const [salespersonInfo, setSalespersonInfo] = useState(null)
  const [moduleHashChecked, setModuleHashChecked] = useState(false)
  const [setupGtm,setSetupGtm] = useState(false)
  const loading = loadingVehicleModules && loadingVehicle;
  // console.log("EXTERNAL__VDP", externalVdp)
  function vdpConnectionPageClose(){
    vdpConnectionClose(id,session_id)
  }
  useEffect(() => {
    if (id) {
      if(externalVdp){
        // getStoreSkin(id)
        getStoreConfiguration(id)
      }
      if(externalVdp && !insightsView){
        VEHICLE_DISPLAY_PAGE_ACTIONS.initEtsRecording(id,()=>{

        })
        putTracking(id)
      }
      getVehiclePage(id)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setLoadingVehicle(false);
            setError("No Vehicle Found");
            return <VehicleNotFound externalVdp={externalVdp} />
          }
        })
        .then((res) => {
          if (res) {
            setLoadingVehicle(false);
            setVehicle(res);
          }
        });

      getVehicleModules(id)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setLoadingVehicleModules(false);
            setError("No Vehicle Found");
          }
        })
        .then((res) => {
          if (res) {
            setLoadingVehicleModules(false);
            setModules([
              { label: "Vehicle Details", content_type: "vehicle_detail" },
              ...res.modules,
            ]);

          }
        });

      getVehicleImages(id)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setError("No Vehicle Found");
          }
        })
        .then((res) => {
          if (res) {
            setVehicleImages(res.vehicle_images);
          } else {
            setVehicleImages([IPacketFolderIconFallback]);
          }
      });
      MARKETPLACE_APP_ACTIONS.getSalespersonInfo(id).then((response)=>{
        if(response.status === 200) {
          return response.json()
        }
      })
      .then((res)=>{
        if(res){
          setSalespersonInfo(res)
        }
      })

    }
  }, []);

  useEffect(()=>{
      if(!moduleHashChecked && vehicle && modules){
        if(window.location.hash){
          //This hash check is overcomplicated. Please update to improved system once secondary group name field is added to return - Khris
          let foundHash = window.location.hash.toLowerCase()
          let foundModuleHasMatch = null
          switch(foundHash) {
            case "#msrp":
              let validModuleNames = ['msrp','msrp472']
              foundModuleHasMatch = modules.find(module=>{
                return(module.module_name && validModuleNames.includes(module.module_name.toLowerCase()))
              })
              break;
            default:
              foundModuleHasMatch = modules.find(module=> (module.module_name &&('#'+module.module_name.toLowerCase() === foundHash)))
          }
          if(foundModuleHasMatch){
            if(module.content_type !== '3pa'){
              pushModal({
                type: MODAL_ACTIONS.MODAL_CONSTANTS.MODULE_MODAL,
                props: { module:foundModuleHasMatch, vehicle, storeConfiguration },
              });
            }
          }
          else{
            sendGenericActionEts("landing_open",null,true)
          }
        }
        else{
          sendGenericActionEts("landing_open",null,true)
        }
        setModuleHashChecked(true)
      }
    },[vehicle,modules])

  useEffect(()=>{
    if(selectedModuleIndex===0){
      sendGenericActionEts("landing_open",null,true)
    }
    else{
      sendModuleViewEts(modules[selectedModuleIndex])
    }
  },[selectedModuleIndex])

  useEffect(()=>{
    if(modules && externalVdp){
      if(modalList.length){
        if(modalList[modalList.length-1].type === MODAL_CONSTANTS.MODULE_MODAL){
          sendModuleViewEts(modalList[modalList.length-1].props.module)
        }
        else if(modalList[modalList.length-1].type === MODAL_CONSTANTS.LIGHTBOX){
          sendModuleViewEts({module_id:22})
        }
        else if(modalList[modalList.length-1].type === MODAL_CONSTANTS.SHARE){
          sendGenericActionEts("consumer_share_open")
        }
      }
      else{
        if(selectedModuleIndex===0){
          sendGenericActionEts("landing_open",null,true)
        }
        else{
          sendModuleViewEts(modules[selectedModuleIndex])
        }
      }
    }
  },[modalList.length])
  useEffect(()=>{
    if(storeConfiguration && storeConfiguration.enable_ontargeting && vehicle &&  !setupGtm){
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WKTGNQ8');
      window.vdpInfo = {
        enable_ontargeting:true,
        vin: vehicle.vin
      }
      window.vehicle_vin = vehicle.vin
      setSetupGtm(true)
    }
  },[storeConfiguration,vehicle])
  // const vdpConnectionPostAction = (action) =>{
  //   let new_page = null
  //   if(action && action.action === VEHICLE_DISPLAY_PAGE_CONSTANTS.MODULE_VIEW){
  //     new_page = JSON.stringify(action)
  //   }
  //   VEHICLE_DISPLAY_PAGE_ACTIONS.vdpConnectionAction(id,session_id,new_page).then((response)=>{
  //     if(response.status === 204){}
  //     else{}
  //   })
  // }
  const sendModuleViewEts = (module)=>{
    console.log(module)
    if(module && module.module_id){
      VEHICLE_DISPLAY_PAGE_ACTIONS.sendModuleViewEtsRecording(module.module_id,module.content_type==='3pa')
    }
  }

  const sendGenericActionEts = (action, external_url = null,focus = false) =>{
    VEHICLE_DISPLAY_PAGE_ACTIONS.sendGenericActionEtsRecording(action,external_url,focus)
  }
  const handleSaveVehicle = (vehicle) => {
    savedVehicles.find(foundVehicle=>foundVehicle.vin === vehicle.vin) ? removeLikedVehicle(vehicle) : saveVehicle(vehicle)
  }

  if (loadingVehicle || !vehicle) {
    return (
      <div>
        <LoadingSpinner loading />
      </div>
    );
  } else if (!loadingVehicle && error) {
    return <VehicleNotFound externalVdp={externalVdp} />;
  } else if (!loadingVehicle && !error) {
    console.log('ID CHECK',id)
    return (
      <React.Fragment>
        {
          (vehicle && !insightsView && externalVdp) ?
            <VehicleDisplayPageCustomHeader id={id}storeSkin={storeSkin} storeConfiguration={storeConfiguration} salespersonInfo={salespersonInfo} vehicle={vehicle} pushModal={pushModal} sendGenericActionEts={sendGenericActionEts}/>
          :
          <React.Fragment/>
        }


        {
          (insightsView && vehicle && modules) ?
            <VehicleDisplayPageInsights id={id} vehicle={vehicle} modules={modules} salespersonInfo={salespersonInfo}>
              <VehicleDisplayPageRenderContainer
                history={history}
                urlHistoryStash={urlHistoryStash}
                vehicle={vehicle}
                modules={modules}
                loading={loading}
                selectedModuleIndex={selectedModuleIndex}
                setSelectedModuleIndex={setSelectedModuleIndex}
                isOpened={isOpened}
                setIsOpened={(bool)=>{
                    console.log("called")
                    setIsOpened(bool)
                  }}
                showBackToResult={sharePage?false:true}
                etsInfo={etsInfo}
                vehicleImages={vehicleImages}
                pushModal={pushModal}
                mockAdd={mockAdd}
                saveVehicle={handleSaveVehicle}
                user={user}
                isUserLoggedIn={isUserLoggedIn}
                storeSkin={storeSkin}
                storeConfiguration={storeConfiguration}
                externalVdp={externalVdp}
                salespersonInfo={salespersonInfo}
                id={id}
              />

            </VehicleDisplayPageInsights>
            :
            <React.Fragment/>
        }
        {
          !insightsView ?
            <VehicleDisplayPageRenderContainer
              history={history}
              urlHistoryStash={urlHistoryStash}
              vehicle={vehicle}
              modules={modules}
              loading={loading}
              selectedModuleIndex={selectedModuleIndex}
              setSelectedModuleIndex={setSelectedModuleIndex}
              isOpened={isOpened}
              setIsOpened={setIsOpened}
              showBackToResult={sharePage?false:true}
              etsInfo={etsInfo}
              vehicleImages={vehicleImages}
              pushModal={pushModal}
              mockAdd={mockAdd}
              saveVehicle={handleSaveVehicle}
              user={user}
              isUserLoggedIn={isUserLoggedIn}
              sendModuleViewEts={sendModuleViewEts}
              sendGenericActionEts={sendGenericActionEts}
              storeSkin={storeSkin}
              storeConfiguration={storeConfiguration}
              externalVdp={externalVdp}
              showRecommendationSlider={true}
              salespersonInfo={salespersonInfo}
              id={id}
            />
            :
            <React.Fragment/>
        }
      </React.Fragment>
    );
  }
}



const mapStateToProps = (state) => ({
  urlHistoryStash: state.stashUrlHistory.user_history,
  user: state.userProfile.user,
  isUserLoggedIn: state.userProfile.user && state.userProfile.token ? true : false,
  savedVehicles: state.savedVehiclePage.savedVehicles,
  storeSkin: state.marketplaceApp.storeSkin,
  storeConfiguration: state.marketplaceApp.storeConfiguration,
  modalList: state.modal.modalList,
});

const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions,
  ...SAVED_VEHICLES_PAGE_ACTIONS.dispatchActions,
  ...MARKETPLACE_APP_ACTIONS.dispatchActions,
  mockAdd: () => {
    return (dispatch) => {
      dispatch({ type: "MOCKADD" });
    };
  },
};
export default connect(mapStateToProps, mapDispatchToProps)(VehicleDisplayPage);
