import MARKETPLACE_APP_CONSTANTS from './marketplaceAppConstants'

const initialState = {
  storeSkin: null,
  storeConfiguration: null,
}

function marketplaceAppReducer(state=initialState, action){
  switch(action.type){
    case MARKETPLACE_APP_CONSTANTS.GET_STORE_SKIN:
      return{
        ...state,
        storeSkin:action.storeSkin,
      }
    case MARKETPLACE_APP_CONSTANTS.GET_STORE_CONFIGURATION:
      return{
        ...state,
        storeConfiguration:action.storeConfiguration,
      }
    default:
      return state
  }
}
export default marketplaceAppReducer
