import MODAL_CONSTANTS from './modalConstants'

export function pushModal(modal){
  return (dispatch) =>{
    dispatch({
      type:MODAL_CONSTANTS.PUSH_MODAL,
      modal,
    })
  }
}
export function popModal(numModals = 1){
  return (dispatch) =>{
    dispatch({
      type: MODAL_CONSTANTS.POP_MODAL,
      numModals,
    })
  }
}
export function closeModal(){
  return (dispatch) =>{
    dispatch({
      type: MODAL_CONSTANTS.CLOSE_MODAL,
    })
  }
}

export default { MODAL_CONSTANTS,
  dispatchActions:{
    pushModal,
    popModal,
    closeModal,
  }
}
