import React from 'react'

const BlogPage = ()=>{
  return(
    <div className="blog-page-container marketplace-iframe-only-page">
      <iframe src={'https://www.ipacket.us/dealer-center/blog'} frameBorder="0" allowFullScreen/>
    </div>
  )
}
export default BlogPage
