import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../api/aipDjapi.js'

const urlParams = new URLSearchParams(window.location.search);
const hush = urlParams.get('hush')

//TODO: Move all action function to marketplaceAppActions File instead

export function getVehiclePage(vin){

  return AIP_DJAPI(
   `${AIP_DJAPI_URLS.VEHICLE_PAGE}?id=${vin}${hush?`&hush=${hush}`:''}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export function getVehicleModules(vin){
  return AIP_DJAPI(
   `${AIP_DJAPI_URLS.VEHICLE_MODULES}?id=${vin}${hush?`&hush=${hush}`:''}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export function  getVehicleImages(vin){
  return AIP_DJAPI(
   `${AIP_DJAPI_URLS.VEHICLE_IMAGES}?id=${vin}${hush?`&hush=${hush}`:''}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}

export function putTracking(vin){
  let original_referer = (document.referrer && !(document.referrer.indexOf(location.protocol + "//" + location.host) === 0)) ? document.referrer: null
  let body = {}

  if(original_referer){
    body['original_referer'] = original_referer
  }
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source')
  if(source){
    body['source'] = source
  }
  if(Object.keys(body).length){
    body = JSON.stringify(body);
  }
  else{
    body = null;
  }
  console.log('BODY', body)
  return AIP_DJAPI(
   `${AIP_DJAPI_URLS.TRACKING}?id=${vin}${hush?`&hush=${hush}`:''}`,
    {
      method: "PUT",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body
    }
  )
}

export function login(username, password) {
  const form = new FormData()

  form.append('username', username)
  form.append('password', password)

  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.LOGIN}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
      },
      body: form
    },
    false,
  )
}

export function socialLogin(token, backend) {
  const form = new FormData()

  form.append('access_token', token)

  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.SOCIAL_LOGIN}${backend}/`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
      },
      body: form
    },
    false
  )
}

export function forgotPassword(email) {
  const form = new FormData()

  form.append('username', email)
  form.append('delivery_method', 'email')
  form.append('platform', 'web')

  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.FORGOT_PASSWORD}`,
    {
      method: 'POST',
      body: form
    }
  )
}

export function vehicleInquiry(inquiry, id) {
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VEHICLE_INQUIRY}${id}/`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: inquiry
    },
  )
}

export function vehicleShare(share) {
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.VEHICLE_SHARE}/`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: share
    },
  )
}

export function updateUserSettings(userSettings) {
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.UPDATE_USER_SETTINGS}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
      },
      body: userSettings
    },
  )
}

export function changePassword(password, confirm, token) {
  const form = new FormData()

  form.append('password', password)
  form.append('confirm', confirm)
  form.append('is_temp_pw', false)
  form.append('send_email', false)

  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.CHANGE_PASSWORD}`,
    {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
      body: form
    },
  )
}

export function resetConfirmPassword(password, token) {
  const form = new FormData()

  form.append('password', password)
  form.append('token', token)

  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.RESET_CONFIRM_PASSWORD}`,
    {
      method: "POST",
      body: form
    },
  )
}

export function getUserProfile(token) {
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.GET_USER_PROFILE}`,
    {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token
      },

    },
  )
}

export function checkPasswordValidity(password) {
  const form = new FormData()

  form.append('password', password)


  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.PASSWORD_VALIDATION}`,
    {
      method: "POST",
      headers: {
        Accept: 'application/json',
      },
      body: form
    }
  )
}

export function updateSiteTitle(newTitle){
  console.log(newTitle)
  const defaultTitle = "iPacket"
  if(newTitle && newTitle.length){
    document.title = newTitle
  }
  else{
    document.title = defaultTitle
  }
}

export default {
  getVehiclePage,
  getVehicleModules,
  getVehicleImages,
  putTracking,
  login,
  socialLogin,
  vehicleInquiry,
  updateUserSettings,
  changePassword,
  resetConfirmPassword,
  getUserProfile,
  checkPasswordValidity,
  updateSiteTitle,
}
