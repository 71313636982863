import React from 'react'
import './savePacketButton.scss'
import HeartIcon from '../common-assets/icons/hearticon.js'
function SavePacketButton(props){
  const {onClick, fillIcon, count } = props
  if(count || count === 0){
    return(
      <div onClick={onClick} className={`save-packet-button ${fillIcon?"save-packet-button-fill-icon":""}`}>
        <div className="save-packet-button-icon"><HeartIcon/></div>
        {count}
      </div>
    )
  }
  return(<React.Fragment/>)
}
export default SavePacketButton
