import SEARCH_PAGE_CONSTANTS from './searchPageConstants'
import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../../../api/aipDjapi'
import { store } from '../../../../../index'
import debounce from 'lodash.debounce'



export function getSearchPageDefaultFilters(){
  return(dispatch)=>{
    AIP_DJAPI(
      AIP_DJAPI_URLS.SEARCH_PAGE,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then((response)=>{
      if(response.status===200){
        return response.json()
      }
    }).then((res)=>{
      if(res){
        dispatch({
          type: SEARCH_PAGE_CONSTANTS.GET_VEHICLE_SEARCH_DEFAULT_FILTERS,
          ...res,
        })
      }
    })
  }
}

export function getSearchPage(updateFilters=false){
  const { searchPage } = store.getState()
  const {
    search,
    filters,
    selectedLocation,
    selectedDistance,
    selectedVehicleCondition,
    selectedMake,
    selectedModel,
    selectedBodyStyle,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    selectedMinMonthlyPayment,
    selectedMaxMonthlyPayment,
    selectedMinMileage,
    selectedMaxMileage,
    selectedExteriorColor,
    selectedInteriorColor,
    page,
    zipcode,
    sortByValue
  } = searchPage


  return (dispatch)=>{
    let filterParams = ""
    if(zipcode && zipcode.length){
      filterParams+= `&zip=${zipcode}`
      //en-us
    }

    if(selectedLocation && selectedLocation.item && selectedLocation.item.key !== 'Any'){
      filterParams += `&state=${selectedLocation.item.key}`
    }

    if(selectedDistance && selectedDistance.item && selectedDistance.item.key!=='Any'){
      filterParams += `&distance_unit=mi&distance=${selectedDistance.item.key}`
    }

    if(selectedVehicleCondition && selectedVehicleCondition.item && selectedVehicleCondition.item.key!=='Any'){
      if(selectedVehicleCondition.item.key==='used&cpo'){
        filterParams += `&condition=u&condition=cpo`
      }
      else{
        filterParams += `&condition=${selectedVehicleCondition.item.key}`
      }
    }

    if(selectedMake && selectedMake.item && selectedMake.item.key!=='Any'){
      filterParams += `&make=${selectedMake.item.key}`
      if(selectedModel&& selectedModel.item && selectedModel.item.key!=='Any'){
        filterParams += `&model=${selectedModel.item.key}`
      }
    }

    if(selectedInteriorColor && selectedInteriorColor.item && selectedInteriorColor.item.key!=='Any'){
      filterParams += `&interior_color=${selectedInteriorColor.item.key}`
    }

    if(selectedExteriorColor && selectedExteriorColor.item && selectedExteriorColor.item.key!=='Any'){
      filterParams += `&exterior_color=${selectedExteriorColor.item.key}`
    }

    if(selectedBodyStyle&& selectedBodyStyle.item && selectedBodyStyle.item.key!=='Any'){ filterParams += `&type=${selectedBodyStyle.item.key}`}
    if(selectedMinYear && selectedMinYear.item && selectedMaxYear && selectedMaxYear.item && (selectedMinYear.item.key!=='Any' || selectedMaxYear.item.key!=='Any')){
      let min = selectedMinYear.item.key ==='Any' ? parseInt(filters.year.buckets[filters.year.buckets.length-1].key): parseInt(selectedMinYear.item.key)
      let max = selectedMaxYear.item.key ==='Any'? parseInt(filters.year.buckets[0].key): parseInt(selectedMaxYear.item.key)
      for(let i = min;i<=max;i++){
        filterParams += `&year=${i}`
      }
    }

    if(selectedMinPrice && selectedMinPrice.item && selectedMinPrice.item.key!=='Any'){
      filterParams+=`&min_price=${selectedMinPrice.item.key}`
    }

    if(selectedMaxPrice && selectedMaxPrice.item && selectedMaxPrice.item.key!=='Any'){
      filterParams+=`&max_price=${selectedMaxPrice.item.key}`
    }

    if(selectedMinMileage && selectedMinMileage.item && selectedMinMileage.item.key!=='Any'){
      filterParams+=`&min_miles=${selectedMinMileage.item.key}`
    }

    if(selectedMaxMileage && selectedMaxMileage.item && selectedMaxMileage.item.key!=='Any'){
      filterParams+=`&max_miles=${selectedMaxMileage.item.key}`
    }

    let sortByParams = ''

    if (sortByValue !== 'Recommended') {
      sortByParams = `&sort_by=${sortByValue}`

      /* sortByValue.map((sort, index) => {
        if (index == 0) {
          sortByParams+=`${sort}`
        } else {
          sortByParams+=`,${sort}`
        }
      })
      */
    }

    let url = `${AIP_DJAPI_URLS.SEARCH_PAGE}?search=${search}&items_per_page=${24}&page=${page}&distance_unit=mi${filterParams}${sortByParams}`

    AIP_DJAPI(
      url,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then((response)=>{
      if(response.status===200){
        return response.json()
      }
    }).then((res)=>{
      if(res){
        dispatch({
          type: SEARCH_PAGE_CONSTANTS.GET_VEHICLE_SEARCH_FILTER,
          ...res,
          updateFilters,
        })
        updateSearchPageLoading(false)(dispatch)
      }
    })
  }
}

const debounceGetSearchPage = debounce((updateFilters, dispatch)=>{
  updateSearchPageLoading(true)(dispatch)
  getSearchPage(updateFilters)(dispatch)
},500)


export function updateSearchQuery(search){
  console.log('sdss')
  return (dispatch)=>{
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.UPDATE_SEARCH_QUERY,
      search
    })
    debounceGetSearchPage(true,dispatch)
  }
}

export function updateSelectedField(field,searchFlag=true){
  return(dispatch)=>{
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.UPDATE_SELECTED_FIELD,
      ...field,
    })
    if(searchFlag){
      debounceGetSearchPage(true,dispatch)
    }
  }
}

export function updateSortByField(sortByValue,searchFlag=true){
  return(dispatch)=>{
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.UPDATE_SORT_BY_FIELD,
      sortByValue
    })
    if(searchFlag){
      debounceGetSearchPage(false,dispatch)
    }
  }
}

export function updatePage(page){
  return(dispatch)=>{
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.UPDATE_PAGE,
      page
    })
    debounceGetSearchPage(false,dispatch)
  }
}

export function clearSearchPage(searchFlag=true){
  return(dispatch)=>{
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.CLEAR_SEARCH_PAGE,
    })
    if(searchFlag){
      debounceGetSearchPage(false,searchFlag)
    }
  }
}

export function updateZipcode(zipcode){
  localStorage.setItem('locationData',JSON.stringify({"zipcode": zipcode}))
  return(dispatch)=>{
    dispatch({
      type:SEARCH_PAGE_CONSTANTS.UPDATE_ZIP_CODE,
      zipcode: zipcode,
    })
  }
}

export function updateSearchPageLoading(loading) {
  return(dispatch) => {
    dispatch({
      type: SEARCH_PAGE_CONSTANTS.UPDATE_SEARCH_LOADING,
      loading: loading
    })
  }
}
export default{
  dispatchActions:{
    getSearchPageDefaultFilters,
    getSearchPage,
    updateSearchQuery,
    updateSelectedField,
    updateSortByField,
    updatePage,
    clearSearchPage,
    updateZipcode,
    updateSearchPageLoading,
  }
}
