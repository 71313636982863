import MARKETPLACE_APP_CONSTANTS from './marketplaceAppConstants'

const initialState = {
  loginStatusChecked: false,
  profileUpdateRequired:false,
  termsOfServiceRequired: false,
}

function userProfileReducer(state=initialState, action){
  switch(action.type){
    case MARKETPLACE_APP_CONSTANTS.CHECK_USER_LOGIN_STATUS:
      let userProfile = {}
      if(action.userProfile){
        userProfile = action.userProfile
      }
      return{
        ...state,
        ...userProfile,
        loginStatusChecked: true,
      }
    case MARKETPLACE_APP_CONSTANTS.PROFILE_UPDATE_REQUIRED:
      return{
        ...state,
        profileUpdateRequired: action.profileUpdateRequired,
      }
    case MARKETPLACE_APP_CONSTANTS.TERMS_OF_SERVICE_REQUIRED:
      return{
        ...state,
        termsOfServiceRequired: action.termsOfServiceRequired,
      }
    default:
      return state
  }
}
export default userProfileReducer
