import React, { useEffect, useState, useRef } from 'react'
import DownloadIcon from '../../common-assets/icons/downloadIcon'
import './styles/moduleImage.scss'

function ModuleImage(props) {
  let { module, downloadBtnPosition } = props
  const [scaleIndex, setScaleIndex] = useState(7)
  const [initialWidth, setInitialWidth] = useState(null)
  const moduleImgElement = useRef(null)
  const scaleValues = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    4, 5
  ]
  const decreaseScale = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1)
    }
  }
  const increaseScale = () => {
    if (scaleIndex < scaleValues.length - 1) {
      setScaleIndex(scaleIndex + 1)
    }
  }
  useEffect(() => {
    console.log("SETUP PDF SIZING")
    setInitialWidth(moduleImgElement.current.clientWidth * .96)
  }, [])
  return (
    <div className="module-image" ref={moduleImgElement}>
      <div className="module-viewer-container">
        <div className="module-viewer-header">
          <div className="module-viewer-header-name">{module.label}</div>
          <div className="module-viewer-header-tools">
            <button
              onClick={() => {
                decreaseScale()
              }}
              type="image"
              alt="Click to zoom out"
            >
              -
            </button>
            <div>{`${Math.round(scaleValues[scaleIndex] * 100)} %`}</div>
            <button
              onClick={() => {
                increaseScale()
              }}
              type="image"
              alt="Click to zoom in"
            >
              +
            </button>
          </div>
          <a className={`download-icon
            ${downloadBtnPosition}`
              } href={module.url} target="_blank">
            <DownloadIcon />
          </a>
        </div>
        <div className="module-viewer-content-container">
          <img
            src={module.url}
            alt={module.module_name}
            style={{ width: initialWidth * scaleValues[scaleIndex] }}
          />
        </div>
      </div>
    </div>
  )
}

export default (ModuleImage);
