import React from 'react'
import {connect} from 'react-redux'
import SearchFilterMenu from './searchFilterMenu'
import SearchFilterClearMenu from './searchFilterClearMenu'

import TradeInYourCar from '../../../../common/common-components/tradeInYourCar'

import './searchSidebar.scss'

function SearchSidebar(props){
  const {filters,vehicles, showMobileFilterMenu, toggleMobileFilterMenu,externalSrp} = props
  return(
    <div
      className={`search-sidebar ${showMobileFilterMenu?'search-sidebar-mobile':''}`}
      onClick={(e)=>{
        if(e.target === e.currentTarget){
          toggleMobileFilterMenu()

        }
      }}
    >
      {showMobileFilterMenu?
        <button
          className="primary-button search-sidebar-mobile-close"
          onClick={(e)=>{
            toggleMobileFilterMenu()
            e.preventDefault()
          }}
        >
          Close
        </button>
        :null}
      {
        (()=>{
          if(filters && vehicles){
            return(
              <React.Fragment>
                <SearchFilterClearMenu/>
                <SearchFilterMenu externalSrp={externalSrp}/>
                {/*
                  <br />
                  <TradeInYourCar />
                */}
                              </React.Fragment>
            )
          }
        })()
      }
    </div>
  )
}
const mapStateToProps = state =>({
  ...state.searchPage,
})

export default connect(mapStateToProps)(SearchSidebar)
