import React, {useState, useRef} from 'react'
import {connect} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import './landingPageExplanation.scss'
import CorvetteVideo from '../../../common/common-assets/images/corvette_video.png'
import CarHighlight from '../../../common/common-assets/images/LandingPageImage2.png'
import FinancialSolutonsImage from '../../../common/common-assets/images/financial-solutions-image.jpg'
import GooglePlayIcon from '../../../common/common-assets/icons/googlePlay'
import AppleStoreIcon from '../../../common/common-assets/icons/appleStore'
import PhoneImage from '../../../common/common-assets/images/phone.png'
import VideoPlaceHolder from '../../../common/common-assets/images/Anthony-Dias-iPacket.jpg'
import VideoPlayerIcon from '../../../common/common-assets/icons/videoPlayerIcon'
import CompanyPhoto from '../../../common/common-assets/images/ipacket-group-photo_2.jpeg'
import RahalAd from '../../../common/common-assets/images/iPacket-RLL_HomePage_Banner.png'


function LandingPageExplanation(props){
  const[showVideo, setShowVideo] = useState(false)
  const handleClick = () => setShowVideo(true)

  return(
    <div className="landing-page-explanation">
      <div className="landing-page-explanation-section landing-page-explanation-section-1">
        <h1>EXCLUSIVELY IPACKET</h1>
        <h2>What is iPacket? Why is it important?</h2>
        <div className="landing-page-explanation-tile-board">
          <div className="landing-page-explanation-tile-board-row">
            <div className="landing-page-explanation-tile">
              <img src={RahalAd} alt="Image comparing Dealer A, who throws a car on the lot at a cheap price, but without fixing it up; and Dealer B, who has a car priced slightly higher, but with extensive work done and an iPacket report of all the receipts."/>
            </div>
            {/* <div className="landing-page-explanation-tile">
              <div className="explanation-tile-section-1-blurb">
                <h1>
                  Drive The Truth.
                </h1>
                <p>Calling all INDYCAR fans! iPacket joined Rahal Letterman Lanigan Racing as the official vehicle marketplace and season sponsor. iPacket and the Bobby Rahal Automotive group have had a longstanding relationship for over 7 years making this partnership a true honor. Keep your eyes peeled for iPacket this INDYCAR season!</p>
                  <Link  to={'/racing'}>
                    <button type="image" className="ga-marketplace-dealer-center-button" alt="Click to learn more">
                      LEARN MORE
                    </button>
                  </Link>
              </div>
            </div> */}
          </div>
          <div className="landing-page-explanation-tile-board-row">
            <div className="landing-page-explanation-tile">
              <div className="explanation-tile-section-1-blurb">
                <h1>
                  Created from the wheels up for you.
                </h1>
                <p>iPacket was developed in a dealership by a dealer for a driver like you. With a decade of experience with exclusive dealer partners, we know what customers want: complete transparency along with accurate and trustworthy data, right at your fingertips.</p>
                  <Link to="/buying-smart">
                    <button type="image" className="ga-marketplace-education-center-button"alt="Click to learn more">
                      LEARN MORE
                    </button>
                  </Link>
              </div>
            </div>
            { !showVideo ?
              <div
                className="landing-page-explanation-tile"
                style={{backgroundImage:`url(${VideoPlaceHolder})`,backgroundPosition: 'center', cursor:'pointer'}}
                onClick={handleClick}
              >
                <div className="landing-page-explanation-tile-video-overlay">
                  <VideoPlayerIcon/>
                </div>
              </div>
              :
              <div className="landing-page-explanation-tile">
                <iframe
                  ref={(r)=>{
                      try{
                        r.play()
                      }
                      catch(e){
                        //
                      }
                    }
                  }
                  src="https://player.vimeo.com/video/668768376?h=0973fb265e&title=0&byline=0&portrait=0&autoplay=1"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            }
          </div>
        </div>
      </div>
      <div className="landing-page-explanation-section landing-page-explanation-section-2">
        <div className="landing-page-explanation-tile-board">
          <div className="landing-page-explanation-tile-board-row">
            <div className="landing-page-explanation-tile">
              <div className="explanation-tile-section-2-blurb">
                <h1>
                  Become an iPacket Dealer
                </h1>
                <p>Join our integrity driven dealer network! iPacket dealer solutions create and manage Salesperson and Car Dealership branded Digital Vehicle Presentations that provide a complete transparency for your vehicle shoppers!</p>
                <a href='https://www.ipacket.us/dealer-center/' target="_blank">
                    <button type="image" alt="Click to get started" className="ga-marketplace-education-center-button">
                      LEARN MORE
                    </button>
                </a>
              </div>
            </div>
            <div className="landing-page-explanation-tile" style={{backgroundImage:`url(${CompanyPhoto})`, backgroundSize:'cover',backgroundPosition: 'center'}}>
            </div>
          </div>
          <div className="landing-page-explanation-tile-board-row">
            <div className="landing-page-explanation-tile" style={{backgroundImage:`url(${PhoneImage})`, backgroundSize:'cover',backgroundPosition: 'center'}}>
            </div>
            <div className="landing-page-explanation-tile">
              <div className="explanation-tile-section-2-blurb">
                <h1>
                  iPacket app
                </h1>
                <p>Download the iPacket App and put the most powerful and comprehensive search tool in the palm of your hand, with access to thousands of vehicles from every major brand, and dealerships across the US. Search and shop with security and confidence.</p>
                <a target="_blank" className="ga-marketplace-apple-app-store-button" rel="noopener noreferrer" href="https://apps.apple.com/us/app/ipacket/id1570295337">
                  <AppleStoreIcon/>
                </a>
                <a target="_blank" className="ga-marketplace-google-play-store-button" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.autoipacket.marketplaceapp">
                  <GooglePlayIcon/>
                </a>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(LandingPageExplanation)
