import REDUCER_CONSTANTS from '../constants/reducerConstants'

const initState = {
  user_history: [],
}

export function stashUrlHistory(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    case REDUCER_CONSTANTS.STASH_URL_HISTORY:
      let urls = [...state.user_history]
      if (urls.length == 0) {
        urls.push({
          ...action.user_history
        })
      } else if (urls.length > 0 && urls[urls.length - 1].href !== action.user_history.href) {
        urls.push({
          ...action.user_history

        })
      } else if (urls.length > 0 && urls[urls.length - 1].href == action.user_history.href) {
        urls = [...urls]
      }
      return {
        ...state,
        user_history: [...urls],
      }

    default:
      return state
  }
}
