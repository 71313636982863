import React from 'react'

// icons
import CloseIcon from '../../../common/common-assets/icons/closeicon'

// style
import '../video-modal/videoModal.scss'

// redux
import {connect} from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'

function VideoModal(props) {
    const { closeModal, videoUrl } = props
    return (
        <div className='video-modal'>
            <div className='video-modal-close' onClick={() => closeModal()}>
                <CloseIcon/>
            </div>
            <div className='video-modal-wrap'>
                <iframe
                    ref={(r)=>{
                        try{
                            r.play()
                        }
                        catch(e){
                            //
                        }
                    }}
                    src={videoUrl}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
}

export default connect(null, mapDispatchToProps)(VideoModal)
