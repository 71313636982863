import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import SEARCH_PAGE_ACTIONS from './state/searchPageActions'
import MARKETPLACE_APP_ACTIONS from '../../state/marketplaceAppActions'
import SAVED_VEHICLES_PAGE_ACTIONS from '../saved-vehicle-page/state/savedVehiclePageActions'
import SearchSidebar from './components/searchSidebar'
import SearchVehicleDisplay from './components/searchVehicleDisplay'
import SearchPageSkin from './components/searchPageSkin'
import SearchRahalAdMobile from './components/searchRahalAdMobile'
import parseURL from '../../../../utils/parseURL'
import SortByField from './components/sortByField'
import FilterIcon from '../../../common/common-assets/icons/filterIcon'
import InfoIcon from '../../../common/common-assets/icons/infoIcon'
import ToolTip from '../../../common/common-components/tooltip'
import './searchPage.scss'

function SearchPage(props){
  const {
    getSearchPageDefaultFilters,
    getSearchPage,
    total_vehicle_count,
    urlZipcode,
    urlMake,
    urlModel,
    urlCondition,
    updateSelectedField,
    updateSearchQuery,
    updateZipcode,
    sortByValue,
    externalSrp,
    storeSkin,
    selectedBodyStyle,
    selectedInteriorColor,
    selectedExteriorColor,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    selectedMinMileage,
    selectedMaxMileage,
  } = props
  let [showMobileFilterMenu, setShowMobileFilterMenu] = useState(false)
  const [shortenedVehicleCount, setShortenedVehicleCount] = useState(0)
  const [breadcrumbs, setBreadcrumbs] = useState({urlZipcode, urlMake, urlModel})
  useEffect(()=>{
    getSearchPageDefaultFilters()
    if(urlZipcode){
      updateZipcode(urlZipcode)
    }
    if(urlMake && decodeURI(urlMake) !=='All Makes'){
      updateSelectedField({item:{key:decodeURI(urlMake)}, selectedField:'selectedMake'})
      if(urlModel && decodeURI(urlModel) !=='All Models'){
        updateSelectedField({item:{key:decodeURI(urlModel)}, selectedField:'selectedModel'})
      }
    }
    if(urlCondition){
      if(decodeURI(urlCondition)==='New'){
        updateSelectedField({item:{key:'n',label:'New'}, selectedField:'selectedVehicleCondition'})
      }
      else if(decodeURI(urlCondition)==='Used & CPO'){
        updateSelectedField({item:{key:'used&cpo',label:'Used & CPO'}, selectedField:'selectedVehicleCondition'})
      }
    }
    getSearchPage(true)
  },[])

  useEffect(() => {
    if (total_vehicle_count) {
      if (total_vehicle_count > 10000 || total_vehicle_count == '>10,000') {
        setShortenedVehicleCount('10,000+')
      } else {
        setShortenedVehicleCount(total_vehicle_count)
      }
    }
  }, [total_vehicle_count])

  useEffect(() =>{

    const params = parseURL(window.location.pathname)
    let make = decodeURI(params.pathSegments[3])
    let model = decodeURI(params.pathSegments[4])
    let zip = params.pathSegments[1]
    setBreadcrumbs({zip, make, model})
  }, [window.location.pathname])

  function clearBreadcrumb (crumb) {
      const lowPriorityFiltersArr = [
      'selectedBodyStyle',
      'selectedInteriorColor',
      'selectedExteriorColor',
      'selectedMinYear',
      'selectedMaxYear',
      'selectedMinPrice',
      'selectedMaxPrice',
      'selectedMinMileage',
      'selectedMaxMileage'
      ]
      lowPriorityFiltersArr.forEach(filter => {
        updateSelectedField({item:{key:'Any'},selectedField:filter})
      });
      updateSearchQuery('')
      if (crumb === "make") {
        const higherPriorityFiltersArr = [
          'selectedMake',
          'selectedModel',
          'selectedVehicleCondition',
          ]
        higherPriorityFiltersArr.forEach(filter => {
          updateSelectedField({item:{key:'Any'},selectedField:filter})
        });
      }
      if (crumb === "model") {
        updateSelectedField({item:{key:'Any'},selectedField:"selectedModel"})
      }
  }

  const lowPriorityReduxFiltersArr = [
    selectedBodyStyle,
    selectedInteriorColor,
    selectedExteriorColor,
    selectedMinYear,
    selectedMaxYear,
    selectedMinPrice,
    selectedMaxPrice,
    selectedMinMileage,
    selectedMaxMileage
    ]
  const makeBreadcrumbIsNotClickable = lowPriorityReduxFiltersArr.every(filter => filter.item.key === 'Any') && breadcrumbs.model === "All Models"
  const modelBreadcrumbIsNotClickable = lowPriorityReduxFiltersArr.every(filter => filter.item.key === 'Any')

  return(
    <div className="marketplace-search-page-container">
      {(externalSrp && storeSkin)? <SearchPageSkin storeSkin={storeSkin}/>: <React.Fragment/>}
      <div className="marketplace-search-page">
        <div className="marketplace-search-page-header-container">
          <div className="marketplace-search-page-header">
            <div className="store-information">
              <p>
                <span> / </span>
                <span onClick={() => clearBreadcrumb("make")}>{`${breadcrumbs.zip?breadcrumbs.zip:'Any Location'}`}</span>
                <span> / </span>
                <span style={{ pointerEvents: makeBreadcrumbIsNotClickable ? 'none' : 'auto' }} onClick={() => clearBreadcrumb("model")}>{`${breadcrumbs.make?decodeURI(breadcrumbs.make):'Any Make'}`}</span>
                <span> / </span>
                <span style={{ pointerEvents: modelBreadcrumbIsNotClickable ? 'none' : 'auto' }} onClick={() => clearBreadcrumb()}>{`${breadcrumbs.model?decodeURI(breadcrumbs.model):'Any Model'}`}</span>
              </p>
              {total_vehicle_count > 0 && <h1>Results{` (${shortenedVehicleCount})`}</h1>}
              {total_vehicle_count === 0 && <h1>Results{` (${total_vehicle_count})`}</h1>}
            </div>
            <div className='marketplace-search-page-header-filters-sort-field'>
              <div
                className={`marketplace-search-page-header-filter-toggle ${showMobileFilterMenu?"marketplace-search-page-header-filter-toggle-selected":""}`}
                onClick={()=>{
                  setShowMobileFilterMenu(!showMobileFilterMenu)
                }}
              >
                Filters
                <FilterIcon/>
              </div>
              <div className='sort-by-field-container'>
                <div className={sortByValue === "Recommended" ? 'sort-by-field-tooltip' : 'hide-tooltip'}>
                  <ToolTip
                    onHover
                    tip={
                      <React.Fragment>
                        Recommended Sort is our way of empowering you to Drive the Truth.
                        With industry leading technology behind it,
                        this ordering showcases vehicles based on the quality
                        and amount of information available on each listing. 
                        In short: the higher you see it on our page,
                        the more you will be able to know about it.
                      </React.Fragment>
                    }
                    position="right-tip"
                  >
                    <InfoIcon />
                  </ToolTip>
                </div>
                <SortByField />
              </div>
            </div>
          </div>
          {/* {externalSrp?<React.Fragment/>:<SearchRahalAdMobile/>} */}
          <React.Fragment/>
        </div>
        <div className="marketplace-search-page-body-container">
          <div className="marketplace-search-page-body">
            <SearchSidebar
              externalSrp={externalSrp}
              showMobileFilterMenu={showMobileFilterMenu}
              toggleMobileFilterMenu={()=>{
                setShowMobileFilterMenu(!showMobileFilterMenu)
              }}
            />
            <SearchVehicleDisplay externalSrp={externalSrp} />
          </div>
        </div>

      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  ...state.searchPage,
  storeSkin: state.marketplaceApp.storeSkin,
})
//Automatically Imports ALL Actions Requiring Dispatch to this component
const mapDispatchToProps= {
  ...SEARCH_PAGE_ACTIONS.dispatchActions,
  ...MARKETPLACE_APP_ACTIONS.dispatchActions,
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SearchPage))
