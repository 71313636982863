import React, {useEffect, useState, useRef} from 'react'
import InputMask from 'react-input-mask'
import { Link, NavLink } from 'react-router-dom'

import CUSTOMER_OPT_IN_ACTIONS from './state/customerTextOptInActions'

import './customerTextOptInPage.scss'

export default function CustomerTextOptInPage(props){
  const { dealerName } = props
  const [mobileNumberField, setMobileNumberField] = useState('')
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false)

  const mobileNumberInputRef = useRef(null)
  // useEffect(()=>{
  //   mobileNumberInputRef.current.focus()
  // },[])
  const handleUpdateMobileNumberField = (e)=>{
    if(e.target.value){
      let rawMobileNumber = e.target.value.replace('+1', '');
      rawMobileNumber =  rawMobileNumber.replace(/\D/g, '');
      setMobileNumberField(rawMobileNumber)
    }
  }
  const handleSubmit = (e) =>{
    e.preventDefault()
    CUSTOMER_OPT_IN_ACTIONS.submitOptIn(mobileNumberField).then(response=>{
      setSubmissionSuccessful(true)
    })

  }
  return(
    <div className="customer-text-opt-in-page-container">
      <div className="customer-text-opt-in-page">
      <div className="customer-text-opt-in-form-container">
        {
          submissionSuccessful?
            <div className="customer-text-opt-in-success-container">
              <div className="customer-text-opt-in-success">
                <span>{`${dealerName} can now send you text messages`}</span>
                <br/>
                <div className="customer-text-opt-in-success-button" onClick={()=>{window.location.href='/'}}>Return to Home</div>
              </div>
            </div>
          :
          null

        }
        <div className={`customer-text-opt-in-form-header${submissionSuccessful?' hidden':''}`}>
          {`SIGN UP FOR YOUR PERSONALIZED VEHICLE PRESENTATION FROM` } <br/><span>{`${dealerName}`}</span>
        </div>
        <form className={`customer-text-opt-in-form${submissionSuccessful?' hidden':''}`} onSubmit={handleSubmit}>
          <div className="customer-text-opt-in-form-input-container">
            <InputMask autoFocus inputRef={mobileNumberInputRef} className=""  value={mobileNumberField} mask="+1-999-999-9999" onChange={(e)=>{handleUpdateMobileNumberField(e)}}  />
          </div>
          <div className="customer-text-opt-in-form-disclaimer">
            {`By submitting this form and signing up for texts, you consent to receive marketing text messages from "${dealerName}" at the number provided.`}
            <br/>
            {`Consent is not a condition of purchase. Msg & data rates may apply.`}
            <br/>
            <a href="https://www.ipacket.us/dealer-center/terms-of-service?__hstc=181257784.cf434faef8da86b02053b878ba6258c8.1704901942985.1709857757426.1709906773771.11&__hssc=181257784.50.1709906773771&__hsfp=3665073738&_ga=2.46505634.449452082.1709838050-849184643.1683559142" target="_blank">Click here to review our Privacy Policy or SMS Terms of Service</a>
          </div>
          <input className="customer-text-opt-in-form-submit" type="submit"  value={`Confirm`} disabled={!mobileNumberField.length} />
        </form>
      </div>

      </div>
    </div>
  )
}
