import React from 'react'
import {connect} from 'react-redux'
import './sortByField.scss'
import SEARCH_PAGE_ACTIONS from '../state/searchPageActions'

function SortByField(props){
    const {sortByValue,updateSortByField} = props
    return(
        <div className="sort-by-field">
            <select value={sortByValue} onChange={(e)=>updateSortByField(e.target.value)}>
              <optgroup label="Most Recommended">
                <option>Recommended</option>
              </optgroup>
              <optgroup label="Distance">
                <option value={'distance'}>Nearest</option>
              </optgroup>
              <optgroup label="Year">
                <option value={'-year'}>Newest Model Year</option>
                <option value={'year'}>Oldest Model Year</option>
              </optgroup>
              <optgroup label="Price">
                <option value={'-price'}>Most Expensive</option>
                <option value={'price'}>Least Expensive</option>
              </optgroup>
              <optgroup label="Mileage">
                <option value={'-miles'}>Highest Miles</option>
                <option value={'miles'}>Lowest Miles</option>
              </optgroup>
              <optgroup label="Make">
                <option value={'make'}>Make [A-Z]</option>
                <option value={'-make'}>Make [Z-A]</option>
              </optgroup>
              <optgroup label="Model">
                <option value={'model'}>Model [A-Z]</option>
                <option value={'-model'}>Model [Z-A]</option>
              </optgroup>
            </select>
        </div>
    )
}

const mapStateToProps = state =>({
    ...state.searchPage,
})
const mapDispatchToProps= {
    ...SEARCH_PAGE_ACTIONS.dispatchActions
}
export default connect(mapStateToProps,mapDispatchToProps)(SortByField)