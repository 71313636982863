import React from 'react'

function VideoPlayer(props){
    const {url} = props
    if(url){
      return(
        <iframe title={url} src={url} frameborder="0" allowFullScreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"/>
      )
    }
    return(<React.Fragment/>)
  }
  export default VideoPlayer
