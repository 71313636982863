
  export default function convertComplianceNumber(complianceNumber) {
    const compliance = {
      text: "",
      class: "",
    };
    if (complianceNumber <= 33) {
      compliance.text = "Poor";
      compliance.class = "password-compliance-poor";
    } else if (complianceNumber > 33 && complianceNumber <= 66) {
      compliance.text = "Poor";
      compliance.class = "password-compliance-good";
    } else if (complianceNumber > 66 && complianceNumber <= 99) {
      compliance.text = "Great";
      compliance.class = "password-compliance-great";
    } else if (complianceNumber >= 100) {
      compliance.text = "Perfect";
      compliance.class = "password-compliance-perfect";
    }
    return compliance;
  };
