import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import './marketplaceAppDefaultHeader.scss'
import IPacketIcon from '../../common/common-assets/icons/iPacketIcon'
import ProfileIcon from '../../common/common-assets/icons/profileIcon'
import SavePacketButton from '../../common/common-components/savePacketButton'
import ArrowLeft from '../../common/common-assets/icons/arrowLeft'
import MenuIcon from '../../common/common-assets/icons/menuIcon'
import {NavLink,Link, useLocation} from 'react-router-dom'
import MARKETPLACE_APP_ACTIONS from '../state/marketplaceAppActions'



function MarketplaceAppDefaultHeader(props){



  const{savedVehiclesCount, user, isUserLoggedIn, savedVehicles} = props
  const location = useLocation()
  let [mobileNavOpen, setMobileNavOpen] = useState(false)
  let [mobileAccountNavOpen, setMobileAccountNavOpen] = useState(false)

  function toggleMobileNav(){
    setMobileNavOpen(!mobileNavOpen)
    setMobileAccountNavOpen(false)

  }
  function toggleMobileAccountNav(){
    setMobileAccountNavOpen(!mobileAccountNavOpen)
    setMobileNavOpen(false)
  }

  function closeMobileMenus(e) {
    if (refContainer && !refContainer.current.contains(e.target)) {
      setMobileNavOpen(false)
      setMobileAccountNavOpen(false)
    }
  }

  const refContainer = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', closeMobileMenus, false)
  }, [])


  function MobileNavDropdownToggle(props){
    return(
      <div
        className={`marketplace-header-mobile-dropdown-toggle ${mobileNavOpen?'marketplace-header-mobile-dropdown-toggle-selected':''}`}
        onClick={()=>{
          toggleMobileNav()
        }}
      >
        <MenuIcon/>
      </div>
    )
  }
  function MobileAccountNavDropdownToggle(props){
    return(
      <div
        className={`marketplace-header-mobile-dropdown-toggle ${mobileAccountNavOpen?'marketplace-header-mobile-dropdown-toggle-selected':''}`}
        onClick={()=>{
          toggleMobileAccountNav()
        }}
      >
        <ProfileIcon/>
      </div>
    )
  }

  let navLinks = [
    {
      label: "Shop New Cars",
      to: '/marketplace/new-cars',
      className:'ga-marketplace-header-shop-new-button'
    },
    {
      label: "Shop Used Cars",
      to: '/marketplace/used-cars',
      className:'ga-marketplace-header-shop-used-button'

    },
    {
      label: "Education Center",
      to: "/buying-smart",
      className:'ga-marketplace-header-education-center-button'

    },
    {
      label: "About Us",
      to: "/about-us",
      className:'ga-marketplace-header-about-us-button'

    },
  ]

  return(
    <React.Fragment>
      <div className="marketplace-header-container" ref={refContainer}>

        <div className="marketplace-header-cta-container">
          <div className="marketplace-header-cta">
            <NavLink to="/">Dealer Center</NavLink>
          </div>
        </div>

        <div className="marketplace-header-nav-container">
          <div className="marketplace-header-nav">
            <div className="marketplace-header-nav-left">
                <NavLink to="/" className="ga-marketplace-header-home-page-button">
                  <IPacketIcon/>
                </NavLink>
            </div>
            <div className="marketplace-header-nav-center">
              {navLinks.map(navLink=>{
                return(
                  <NavLink
                    to={navLink.to}
                    activeClassName="header-nav-link-active"
                    className={navLink.className}
                  >
                    {navLink.label}
                  </NavLink>
                )
              })}
            </div>
            <div className="marketplace-header-nav-right">
              <div className="marketplace-header-save-packet-button-container">
              {
                savedVehicles ?
                <NavLink to="/savedvehicle">
                  <SavePacketButton fillIcon={true} count={isUserLoggedIn ? savedVehiclesCount : null}/>
                </NavLink> :
                null
              }
              </div>
              {user?<ProfileButton user={user}/>:<SignUpLoginButtons/> }
              {/*<MobileAccountNavDropdownToggle/>*/}
              <MobileNavDropdownToggle/>
              <div className="aip-marketplace-weglot-container"></div>
            </div>
          </div>
        </div>
        {
          mobileNavOpen?
          <div className="marketplace-header-mobile-nav-container">
            {navLinks.map(navLink=>{
              return(
                <NavLink
                  to={navLink.to}
                  className="marketplace-header-mobile-nav-item"
                  activeClassName="header-nav-link-active"
                  onClick={()=>{
                    toggleMobileNav()
                  }}
                >
                  {navLink.label}
                </NavLink>
              )
            })}
          </div>
          :
          null
        }
        {
          mobileAccountNavOpen?
          <div>
            <div
              className="marketplace-header-mobile-nav-container"
              onClick={()=>{toggleMobileAccountNav()}}
            >
              {
                isUserLoggedIn?
                <>
                  <div className='account-menu-dropdown-username-email-container-mobile'>
                   <div className='account-menu-dropdown-username-mobile'>
                      <p>{user.first_name} {user.last_name}</p>
                   </div>
                   <p className='account-menu-dropdown-email-mobile'>{user.email}</p>
                  </div>
                  <NavLink className="marketplace-header-mobile-nav-item" to='/account-settings'>
                    Account Settings
                  </NavLink>
                  {
                    savedVehicles ?
                    <NavLink className="marketplace-header-mobile-nav-item" to="/savedvehicle">
                      Saved Vehicles <SavePacketButton fillIcon={true} count={isUserLoggedIn ? savedVehiclesCount : null}/>
                    </NavLink> :
                    null
                  }
                  <div className='marketplace-header-mobile-nav-item' onClick={MARKETPLACE_APP_ACTIONS.logoutUser}>
                      Logout
                  </div>
                </>
                :
                <>
                  {/*
                    <NavLink className="marketplace-header-mobile-nav-item ga-marketplace-header-sign-up-button"to="/marketplace/signup">Sign Up</NavLink>
                    <NavLink className="marketplace-header-mobile-nav-item ga-marketplace-header-log-in-button" to="/marketplace/login">Log In</NavLink>
                  */}
                  <a className="marketplace-header-mobile-nav-item ga-marketplace-header-dealer-log-in-button" href="https://dpapp.autoipacket.com/login" target="_blank">Dealer Login</a>
                </>
              }

            </div>
          </div>
          :
          null
        }

      </div>

      <div className="marketplace-header-container-clear"/>
    </React.Fragment>
  )
}


function ProfileButton(props){
  const [menuOpen, setMenuOpen] = useState(false)

  function handleClick(e) {
    if (refContainer && !refContainer.current.contains(e.target)) {
      setMenuOpen(false)
    }
  }

  const refContainer = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
  }, [])

  return(
    <div className="marketplace-header-profile" ref={refContainer}>
      <div className={menuOpen ? 'marketplace-header-profile-items active' : 'marketplace-header-profile-items'} onClick={() => setMenuOpen(!menuOpen)}>
        <ProfileIcon/>
      </div>
      {menuOpen && <AccountMenuDropdown user={props.user} />}
    </div>
  )
}

function AccountMenuDropdown(props) {
  const {user} = props
    return (
        <div className='account-menu-dropdown'>
          <div title={user.first_name+``+user.last_name+`-`+user.email} className='account-menu-dropdown-username-email-container'>
            <div className='account-menu-dropdown-username'>
              <p>{user.first_name} {user.last_name}</p>
            </div>
            <p className='account-menu-dropdown-email'>{user.email}</p>
          </div>
          <Link to='/account-settings'>
            <div className='account-menu-option'>
                    Account Settings
            </div>
          </Link>
            <div className='account-menu-option' onClick={MARKETPLACE_APP_ACTIONS.logoutUser}>
                Logout
            </div>
        </div>

    )

}

function SignUpLoginButtons(){
  return(
    <div className="marketplace-header-login-signup">
      {/*
        <NavLink className="marketplace-header-login-signup-link" to="/marketplace/signup" title="Sign Up">Sign Up</NavLink>
          <div className="vr"/>
        <NavLink className="marketplace-header-login-signup-link" to="/marketplace/login" title="Log In">Log In</NavLink>
        <div className="vr"/>
      */}
      <a className="primary-button" href="https://dpapp.autoipacket.com/login" target="_blank" title="Dealer Login">Dealer Login</a>

    </div>

  )
}



const mapStateToProps = state => ({
  savedVehiclesCount: state.savedVehiclePage.savedVehiclesCount,
  user: state.userProfile.user,
  isUserLoggedIn: state.userProfile.user && state.userProfile.token,
  savedVehicles: state.savedVehiclePage.savedVehicles,
})
export default connect(mapStateToProps)(MarketplaceAppDefaultHeader)
