import React from 'react'

function LogoutPage(props){

  localStorage.removeItem('userProfile')
  window.location.href="/"

  return(
    <React.Fragment/>
  )
}
const mapStateToProps = state => ({})
export default LogoutPage
