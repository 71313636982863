import SEARCH_PAGE_CONSTANTS from './searchPageConstants'

const defaultSelectedValue = {
  item: {key: 'Any'},
}

const initialState = {
  search:"",
  activeSearch:"",
  page: 1,
  sortByValue: "Recommended",
  filters: null,
  defaultFilters: null,
  selectedLocation:{...defaultSelectedValue},
  selectedDistance:{item: {key: '100'}},
  selectedVehicleCondition:{...defaultSelectedValue},
  selectedMake:{...defaultSelectedValue},
  selectedModel:{...defaultSelectedValue},
  selectedBodyStyle:{...defaultSelectedValue},
  selectedMinYear:{...defaultSelectedValue},
  selectedMaxYear:{...defaultSelectedValue},
  selectedMinPrice:{...defaultSelectedValue},
  selectedMaxPrice:{...defaultSelectedValue},
  selectedMinMonthlyPayment:{...defaultSelectedValue},
  selectedMaxMonthlyPayment:{...defaultSelectedValue},
  selectedMinMileage:{...defaultSelectedValue},
  selectedMaxMileage:{...defaultSelectedValue},
  selectedInteriorColor:{...defaultSelectedValue},
  selectedExteriorColor:{...defaultSelectedValue},
  zipcode:'',
  loading:true,
}

function searchPageReducer(state=initialState, action){
  switch(action.type){

    case SEARCH_PAGE_CONSTANTS.GET_VEHICLE_SEARCH_FILTER:
      let getVehicleSearchFilter = {
        ...state,
        activeSearch: state.search,
        vehicles:[...action.results],
        total_vehicle_count: action.count,
      }
      if(action.updateFilters){
        getVehicleSearchFilter.filters={...action.aggregations}
      }
      return getVehicleSearchFilter
    case SEARCH_PAGE_CONSTANTS.GET_VEHICLE_SEARCH_DEFAULT_FILTERS:
      return{
        ...state,
        defaultFilters:{...action.aggregations}
      }
    case SEARCH_PAGE_CONSTANTS.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        search: action.search,
      }

    case SEARCH_PAGE_CONSTANTS.UPDATE_SELECTED_FIELD:
      let updateSelectedField = {...state, page: 1}
      updateSelectedField[action.selectedField] = {
        item: action.item,
      }
      if(action.selectedField === 'selectedMake'){
        updateSelectedField.selectedModel = defaultSelectedValue
      }
      return updateSelectedField

    case SEARCH_PAGE_CONSTANTS.UPDATE_PAGE:
      return {
        ...state,
        page: action.page
      }
    case SEARCH_PAGE_CONSTANTS.UPDATE_SORT_BY_FIELD:
      return {
        ...state,
        page: 1,
        sortByValue: action.sortByValue
      }
    case SEARCH_PAGE_CONSTANTS.CLEAR_SEARCH_PAGE:
      return {
        ...initialState,
        defaultFilters: state.defaultFilters,
      }
    case SEARCH_PAGE_CONSTANTS.UPDATE_ZIP_CODE:
      return {
        ...state,
        zipcode: action.zipcode
      }
    case SEARCH_PAGE_CONSTANTS.UPDATE_SEARCH_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state
  }
}

export default searchPageReducer
