import React from "react";

function AudiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="2500"
      height="1555"
      viewBox="0 0 158.48 98.561"
    >
      <defs>
        <path id="a" d="M0 0h158.48v98.561H0z" />
      </defs>
      <clipPath id="b">
        <use overflow="visible" xlinkHref="#a" />
      </clipPath>
      <path
        fill="#fff"
        d="M7.7 46.679c5.043 5.508 12.292 8.96 20.349 8.96a27.464 27.464 0 0 0 17.107-5.943 27.472 27.472 0 0 0 17.107 5.943 27.464 27.464 0 0 0 17.107-5.943 27.475 27.475 0 0 0 17.108 5.943 27.461 27.461 0 0 0 17.106-5.943 27.46 27.46 0 0 0 17.106 5.943c8.057 0 15.306-3.452 20.35-8.957l-10.064-.003a21.228 21.228 0 0 1-10.285 2.64c-3.693 0-7.204-.973-10.247-2.642l-13.708.001a21.177 21.177 0 0 1-10.273 2.633 21.09 21.09 0 0 1-10.247-2.631l-13.693-.002a21.184 21.184 0 0 1-10.274 2.633c-3.725 0-7.209-.952-10.248-2.631l-13.704-.002a21.34 21.34 0 0 1-10.248 2.64c-3.732 0-7.238-.959-10.285-2.644L7.7 46.679z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M2.202 37.719A27.575 27.575 0 0 0 7.7 46.682l10.064-.003a21.37 21.37 0 0 1-8.669-8.961l-6.893.001zm34.224 0a27.809 27.809 0 0 0 4.216 7.469 21.576 21.576 0 0 1-2.345 1.489l13.704.001a20.938 20.938 0 0 1-2.331-1.491 27.772 27.772 0 0 0 4.216-7.47h-6.889a21.215 21.215 0 0 1-1.842 2.968 21.331 21.331 0 0 1-1.842-2.968l-6.887.002zm34.214 0a27.758 27.758 0 0 0 4.217 7.469 21.252 21.252 0 0 1-2.334 1.494l13.693-.003a21.043 21.043 0 0 1-2.332-1.491 27.721 27.721 0 0 0 4.217-7.47h-6.889a21.215 21.215 0 0 1-1.842 2.968 21.426 21.426 0 0 1-1.842-2.968l-6.888.001zm34.214 0a27.836 27.836 0 0 0 4.216 7.469 21.064 21.064 0 0 1-2.334 1.494l13.708-.003a21.901 21.901 0 0 1-2.345-1.491 27.826 27.826 0 0 0 4.216-7.47h-6.889a21.122 21.122 0 0 1-1.842 2.968 21.331 21.331 0 0 1-1.842-2.968l-6.888.001zm44.792 0a21.351 21.351 0 0 1-8.67 8.957l10.064.003a27.542 27.542 0 0 0 5.498-8.958l-6.892-.002z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M.715 24.279a27.908 27.908 0 0 0-.255 3.77c0 3.403.616 6.662 1.742 9.671l6.893-.001A21.215 21.215 0 0 1 6.78 28.05c0-1.286.114-2.545.332-3.768l-6.397-.003zm34.213 0a28.076 28.076 0 0 0-.254 3.771c0 3.394.622 6.655 1.752 9.669h6.889a21.147 21.147 0 0 1-2.321-9.669c0-1.289.114-2.55.332-3.773l-6.398.002zm34.214 0a27.913 27.913 0 0 0-.254 3.771c0 3.394.622 6.655 1.752 9.669h6.889a21.159 21.159 0 0 1-2.32-9.669c0-1.289.113-2.55.332-3.773l-6.399.002zm34.215 0a27.912 27.912 0 0 0-.255 3.771c0 3.394.622 6.655 1.752 9.669h6.889a21.147 21.147 0 0 1-2.321-9.669c0-1.289.114-2.55.333-3.773l-6.398.002zm48.271 0c.219 1.226.332 2.484.332 3.771a21.2 21.2 0 0 1-2.314 9.667l6.893.002a27.505 27.505 0 0 0 1.742-9.669c0-1.279-.087-2.538-.256-3.77l-6.397-.001zm-104.63 13.44a21.156 21.156 0 0 0 2.321-9.669c0-1.289-.114-2.55-.333-3.773l6.398.003c.167 1.233.254 2.491.254 3.771 0 3.394-.622 6.655-1.752 9.669l-6.888-.001zm34.214 0a21.156 21.156 0 0 0 2.32-9.669c0-1.289-.113-2.55-.332-3.773l6.398.003c.168 1.233.255 2.491.255 3.771 0 3.394-.622 6.655-1.752 9.669l-6.889-.001zm34.214 0a21.143 21.143 0 0 0 2.32-9.669c0-1.289-.113-2.55-.332-3.773l6.397.003c.168 1.233.255 2.491.255 3.771 0 3.394-.622 6.655-1.752 9.669l-6.888-.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M7.112 24.279C8.888 14.326 17.579 6.78 28.049 6.78c4.686 0 9.074 1.565 12.593 4.13a27.41 27.41 0 0 0-5.714 13.37l6.397-.001c.585-3.28 1.92-6.29 3.831-8.867a21.082 21.082 0 0 1 3.83 8.864l6.398.003A27.405 27.405 0 0 0 49.67 10.91a21.104 21.104 0 0 1 12.578-4.124c4.718 0 9.079 1.526 12.608 4.124a27.4 27.4 0 0 0-5.714 13.37l6.398-.001c.584-3.28 1.92-6.29 3.83-8.867a21.07 21.07 0 0 1 3.83 8.864l6.398.003a27.405 27.405 0 0 0-5.714-13.369 21.111 21.111 0 0 1 12.579-4.124 21.18 21.18 0 0 1 12.607 4.124 27.408 27.408 0 0 0-5.713 13.37l6.397-.001a21.09 21.09 0 0 1 3.83-8.867 21.095 21.095 0 0 1 3.83 8.864l6.397.003a27.413 27.413 0 0 0-5.713-13.369c3.518-2.565 7.906-4.13 12.592-4.13 10.471 0 19.162 7.546 20.938 17.502l6.396-.003C156.186 10.824 144.651.46 130.69.46c-6.463 0-12.4 2.221-17.106 5.944A27.458 27.458 0 0 0 96.478.46 27.476 27.476 0 0 0 79.37 6.404 27.451 27.451 0 0 0 62.263.459a27.473 27.473 0 0 0-17.107 5.944A27.471 27.471 0 0 0 28.049.459C14.089.459 2.553 10.823.715 24.279h6.397z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M117.619 7.666a24.416 24.416 0 0 0-4.034 3.244V6.403a27.58 27.58 0 0 1 2.339-1.66l1.695 2.923zM5.88 18.27a24.233 24.233 0 0 1 9.219-10.7L13.39 4.671A27.665 27.665 0 0 0 2.741 17.053L5.88 18.27z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M9.623 17.411a21.35 21.35 0 0 1 6.297-6.841l-1.628-2.469a24.202 24.202 0 0 0-7.32 7.999l2.651 1.311z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M49.19 7.666a24.457 24.457 0 0 0-4.034 3.244V6.403a27.421 27.421 0 0 1 2.339-1.66l1.695 2.923zM40.642 10.91a27.597 27.597 0 0 0-3.688 6.143l3.139 1.217a24.205 24.205 0 0 1 5.062-7.359h-4.513v-.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M49.67 10.91a27.51 27.51 0 0 1 5.041 10.016l-3.327.611c-1.131-4.08-3.323-7.723-6.228-10.627h4.514z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M37.346 50.41a24.44 24.44 0 0 0 7.81-5.222h-4.514a21.677 21.677 0 0 1-4.387 2.474l1.091 2.748z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M42.729 51.414a27.962 27.962 0 0 0 2.428-1.718v-4.507a24.511 24.511 0 0 1-4.086 3.274l1.658 2.951zM45.156 45.188a24.547 24.547 0 0 0 2.194-2.485l2.87 1.771c-.179.241-.361.479-.545.714h-4.519z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M45.156 40.687a21.114 21.114 0 0 1-3.54-7.503l-2.921.468a24.056 24.056 0 0 0 6.461 11.536v-4.501z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M47.762 36.052a21.24 21.24 0 0 1-2.605 4.634v4.501a24.103 24.103 0 0 0 5.422-8.201l-2.817-.934z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M49.67 10.91c.157-.115.303-.229.463-.34l-1.628-2.469a24.086 24.086 0 0 0-3.35 2.809h4.515zM45.156 10.91a24.023 24.023 0 0 0-3.971 5.189l2.651 1.311c.402-.696.84-1.356 1.319-1.999V10.91h.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M83.404 7.666a24.457 24.457 0 0 0-4.034 3.244V6.403a27.264 27.264 0 0 1 2.339-1.66l1.695 2.923zM74.856 10.91a27.56 27.56 0 0 0-3.688 6.143l3.139 1.217a24.205 24.205 0 0 1 5.062-7.359h-4.513v-.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M83.884 10.91a27.496 27.496 0 0 1 5.042 10.016l-3.326.611c-1.131-4.08-3.324-7.723-6.229-10.627h4.513z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M83.884 10.91c.158-.115.303-.229.464-.34L82.72 8.101a24.145 24.145 0 0 0-3.35 2.809h4.514z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M71.56 50.41a24.445 24.445 0 0 0 7.811-5.222h-4.514a21.685 21.685 0 0 1-4.388 2.474l1.091 2.748z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M79.37 10.91a24.023 24.023 0 0 0-3.971 5.189l2.651 1.311c.402-.696.841-1.356 1.319-1.999V10.91h.001zM76.942 51.414c.842-.53 1.65-1.104 2.428-1.718v-4.507a24.511 24.511 0 0 1-4.086 3.274l1.658 2.951zM79.37 45.188a24.547 24.547 0 0 0 2.194-2.485l2.87 1.771c-.18.241-.367.479-.551.714H79.37z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M79.37 40.687a21.131 21.131 0 0 1-3.54-7.503l-2.921.468a24.056 24.056 0 0 0 6.461 11.536v-4.501z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M81.976 36.052a21.24 21.24 0 0 1-2.605 4.634v4.501a24.118 24.118 0 0 0 5.422-8.201l-2.817-.934z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M118.099 10.91c.156-.115.303-.229.463-.34l-1.628-2.469a24.145 24.145 0 0 0-3.35 2.809h4.515z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M105.775 50.41a24.44 24.44 0 0 0 7.81-5.222h-4.515a21.677 21.677 0 0 1-4.387 2.474l1.092 2.748z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M113.584 10.91a24.023 24.023 0 0 0-3.971 5.189l2.651 1.311c.402-.696.84-1.356 1.319-1.999V10.91h.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M113.584 40.687a21.114 21.114 0 0 1-3.54-7.503l-2.921.468a24.056 24.056 0 0 0 6.461 11.536v-4.501z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M111.168 51.414c.841-.53 1.64-1.104 2.416-1.718v-4.507a24.464 24.464 0 0 1-4.074 3.274l1.658 2.951z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M116.19 36.075a21.15 21.15 0 0 1-2.605 4.612v4.501a24.03 24.03 0 0 0 5.423-8.179l-2.818-.934zM109.07 10.91a27.597 27.597 0 0 0-3.688 6.143l3.14 1.217a24.223 24.223 0 0 1 5.062-7.359h-4.514v-.001z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M118.099 10.91a27.523 27.523 0 0 1 5.04 10.016l-3.327.611c-1.131-4.08-3.322-7.723-6.228-10.627h4.515z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M113.584 45.188a24.67 24.67 0 0 0 2.194-2.485l2.869 1.771c-.178.241-.365.479-.549.714h-4.514zM152.863 44.474a27.749 27.749 0 0 1-7.518 6.957l-1.642-2.94a24.295 24.295 0 0 0 6.29-5.788l2.87 1.771z"
        clipPath="url(#b)"
      />
      <path
        fill="#fff"
        d="M138.925 47.669a21.32 21.32 0 0 0 11.537-11.754l2.792.967a24.228 24.228 0 0 1-13.276 13.549l-1.053-2.762z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M108.91 44.991l2.744-1.953.14.179-2.195 1.971h-.529l-.16-.197z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M107.007 42.21l2.963-1.604.117.195-2.757 1.936-.323-.527z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M105.458 39.218l3.136-1.231.093.207-2.974 1.587-.255-.563z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M104.285 36.06l3.263-.84.067.217-3.145 1.212-.185-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M103.505 32.782l3.342-.436.039.223-3.269.82-.112-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M103.617 22.725l3.274.791-.043.222-3.346-.406.115-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M103.169 26.064l3.347.386-.016.226-3.37.005.039-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M103.132 29.433l3.369-.025.012.226-3.345.416-.036-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M104.466 19.465l3.156 1.183-.071.216-3.27-.81.185-.589zM70.252 19.465l3.155 1.183-.07.216-3.271-.81.186-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M69.402 22.725l3.275.791-.043.222-3.346-.406.114-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M68.955 26.064l3.347.386-.016.226-3.37.005.039-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M68.917 29.433l3.369-.025.013.226-3.345.416-.037-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M69.292 32.782l3.34-.436.04.223-3.269.82-.111-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M74.694 44.991l2.746-1.953.14.179-2.194 1.971h-.53l-.162-.197z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M70.07 36.06l3.263-.84.066.217-3.144 1.212-.185-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M71.243 39.218l3.137-1.231.092.207-2.973 1.587-.256-.563z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M72.792 42.21l2.963-1.604.117.195-2.758 1.936-.322-.527z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M36.039 19.465l3.154 1.183-.07.216-3.271-.81.187-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M35.188 22.725l3.275.791-.043.222-3.346-.406.114-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M34.741 26.064l3.347.386-.016.226-3.37.005.039-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M34.704 29.433l3.369-.025.012.226-3.344.416-.037-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M35.078 32.782l3.34-.436.04.223-3.269.82-.111-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M35.856 36.06l3.264-.84.066.217-3.144 1.212-.186-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M37.029 39.218l3.137-1.231.092.207-2.973 1.587-.256-.563z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M38.579 42.21l2.963-1.604.117.195-2.759 1.936-.321-.527z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M40.481 44.991l2.745-1.953.14.179-2.195 1.971h-.529l-.161-.197z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M154.789 25.593l3.33-.645.081 1.029h-3.384l-.027-.384z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M154.822 30.151l3.39-.009-.112 1.025-3.323-.634.045-.382z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M154 34.634l3.332.626-.302.986L153.884 35l.116-.366z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M152.352 38.884l3.156 1.239-.481.912-2.858-1.813.183-.338z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M148.939 12.077l.402.473-2.39 1.772-.153-.186 2.141-2.059z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M150.75 14.42l.341.518-2.589 1.468-.127-.204 2.375-1.782z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M152.261 16.966l.276.556-2.748 1.141-.102-.218 2.574-1.479z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M153.451 19.677l.207.585-2.867.799-.075-.229 2.735-1.155z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M154.302 22.513l.133.606-2.942.443-.046-.236 2.855-.813z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M154.8 25.432l.059.618-2.975.08-.017-.239 2.933-.459z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M154.941 28.39l-.018.62-2.963-.282.012-.24 2.969-.098z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M154.718 31.342l-.093.614-2.906-.641.041-.237 2.958.264z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M154.138 34.246l-.167.598-2.806-.991.07-.23 2.903.623zM117.901 3.605l.524-.268 1.377 3.043-.233.141-1.668-2.916z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M120.527 2.402l.549-.212 1.05 3.171-.246.115-1.353-3.074z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M123.263 1.48l.569-.153.712 3.263-.256.089-1.025-3.199z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M126.081.849l.581-.093.369 3.32-.266.061-.684-3.288z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M128.949.516l.588-.031.019 3.34-.269.033-.338-3.342z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M131.835.485l.59.03-.33 3.324-.272.005.012-3.359z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M134.71.755l.582.092-.674 3.271-.271-.024.363-3.339z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M137.542 1.325l.569.152-1.014 3.183-.267-.053.712-3.282z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M140.297 2.188l.55.21-1.34 3.06-.26-.081 1.05-3.189z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M117.54 7.704l.297-.175 1.498 2.536-.151.088-1.644-2.449z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M118.623 7.043l.306-.159 1.363 2.612-.156.079-1.513-2.532z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M119.738 6.441l.314-.143 1.226 2.679-.16.071-1.38-2.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M120.884 5.897l.322-.126 1.083 2.74-.164.062-1.241-2.676z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M114.724 12.1l.403.472-1.543 1.148v-.508l1.14-1.112z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M116.536 14.442l.341.518-2.588 1.468-.128-.203 2.375-1.783z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M118.047 16.988l.277.556-2.749 1.142-.103-.218 2.575-1.48z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M119.238 19.7l.206.585-2.867.798-.076-.229 2.737-1.154z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M120.087 22.536l.135.606-2.943.442-.047-.235 2.855-.813z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M120.587 25.455l.059.617-2.976.081-.016-.24 2.933-.458z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M120.726 28.412l-.017.62-2.962-.282.012-.24 2.967-.098z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M120.504 31.365l-.092.614-2.907-.642.041-.237 2.958.265z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M119.923 34.269l-.166.597-2.806-.991.07-.229 2.902.623z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M120.575 25.593l3.329-.645.082 1.029h-3.384l-.027-.384z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M120.608 30.151l3.391-.009-.113 1.025-3.324-.634.046-.382z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M119.786 34.634l3.333.626-.303.986L119.669 35l.117-.366z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M118.138 38.884l3.156 1.239-.482.912-2.856-1.813.182-.338z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M139.318 50.67l.156-.053 1.324 3.102-.39.146-1.09-3.195z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M140.491 50.188l.154-.061 1.484 3.029-.382.166-1.256-3.134z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M141.636 49.646l.152-.068 1.641 2.946-.374.186-1.419-3.064z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M142.753 49.045l.147-.077 1.793 2.857-.363.205-1.577-2.985z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M122.698 47.784l.358.135-.898 2.788-.653-.257 1.193-2.666z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M124.449 48.405l.367.104-.651 2.855-.673-.198.957-2.761z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M126.246 48.873l.377.071-.401 2.901-.687-.139.711-2.833z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M128.078 49.181l.38.038-.146 2.925-.696-.079.462-2.884z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M129.929 49.328l.383.005.109 2.926-.701-.017.209-2.914z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M131.787 49.313l.382-.028.364 2.906-.7.043-.046-2.921z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M133.636 49.138l.378-.062.616 2.863-.694.104-.3-2.905z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M135.463 48.801l.37-.094.864 2.797-.682.165-.552-2.868z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M137.253 48.306l.362-.126 1.103 2.712-.664.223-.801-2.809z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M106.55 29.944l2.913-.013.028.348-2.853.513-.088-.848z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M106.55 26.145l2.88.442-.027.349-2.898.06.045-.851z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M107.146 22.391l2.775.888-.082.34-2.871-.393.178-.835z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M108.32 18.778l2.603 1.31-.134.324-2.774-.838.305-.796z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M83.687 3.605l.525-.268 1.376 3.044-.234.14-1.667-2.916z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M86.312 2.402l.551-.212 1.05 3.171-.247.115-1.354-3.074z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M89.048 1.48l.57-.153.714 3.263-.258.089-1.026-3.199z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M91.867.849l.582-.093.368 3.32-.265.061-.685-3.288z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M94.735.516l.589-.031.018 3.34-.269.033-.338-3.342z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M97.623.485l.587.03-.33 3.324-.271.005.014-3.359z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M100.498.755l.582.092-.676 3.271-.272-.024.366-3.339z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M103.328 1.325l.57.152-1.015 3.183-.266-.052.711-3.283z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M106.083 2.188l.551.21-1.342 3.06-.259-.081 1.05-3.189z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M83.325 7.704l.297-.175 1.499 2.536-.151.088-1.645-2.449z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M84.408 7.043l.306-.159 1.365 2.612-.157.079-1.514-2.532z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M85.525 6.441l.313-.143 1.226 2.679-.16.071-1.379-2.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M86.67 5.897l.322-.126 1.084 2.74-.164.062-1.242-2.676zM86.361 25.593l3.33-.645.081 1.029h-3.384l-.027-.384z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M86.394 30.151l3.391-.009-.113 1.025-3.323-.634.045-.382z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M85.572 34.634l3.332.626-.302.986L85.457 35l.115-.366z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M83.924 38.884l3.157 1.239-.482.912-2.858-1.813.183-.338z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M80.511 12.077l.402.473-1.543 1.148v-.508l1.141-1.113z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M82.321 14.42l.342.518-2.589 1.468-.128-.204 2.375-1.782z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M83.833 16.966l.275.556-2.748 1.141-.101-.218 2.574-1.479z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M85.022 19.677l.207.585-2.867.799-.074-.229 2.734-1.155z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M85.875 22.513l.132.606-2.944.443-.045-.236 2.857-.813z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M86.373 25.432l.058.618-2.975.08-.018-.239 2.935-.459z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M86.512 28.39l-.016.62-2.964-.282.012-.24 2.968-.098z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M86.291 31.342l-.093.614-2.907-.641.042-.237 2.958.264z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M85.71 34.246l-.167.598-2.807-.991.071-.23 2.903.623z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M72.335 29.944l2.914-.013.027.348-2.851.513-.09-.848z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M72.336 26.145l2.88.442-.028.349-2.897.06.045-.851z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M72.931 22.391l2.775.888-.082.34-2.87-.393.177-.835z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M74.106 18.778l2.602 1.31-.133.324-2.775-.838.306-.796z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M70.866 50.689l.158-.052 1.323 3.102-.391.145-1.09-3.195z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M72.039 50.208l.155-.061 1.485 3.029-.383.166-1.257-3.134z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M73.187 49.666l.15-.069 1.641 2.946-.374.186-1.417-3.063z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M74.302 49.064l.147-.076 1.792 2.857-.362.205-1.577-2.986z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M54.27 47.784l.357.135-.898 2.788-.652-.257 1.193-2.666z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M56.02 48.405l.368.104-.651 2.855-.674-.198.957-2.761z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M57.818 48.873l.376.071-.401 2.901-.687-.139.712-2.833z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M59.649 49.181l.381.038-.145 2.925-.698-.079.462-2.884z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M61.501 49.328l.383.005.109 2.926-.701-.017.209-2.914z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M63.359 49.313l.382-.028.363 2.906-.7.043-.045-2.921z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M65.208 49.138l.378-.062.616 2.863-.694.104-.3-2.905z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M67.035 48.801l.371-.094.863 2.797-.682.165-.552-2.868z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M68.825 48.306l.362-.126 1.104 2.712-.666.223-.8-2.809z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M52.146 25.593l3.33-.645.082 1.029h-3.384l-.028-.384z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M52.179 30.151l3.391-.009-.112 1.025-3.324-.634.045-.382z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M51.357 34.634l3.333.626-.303.986L51.241 35l.116-.366z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M49.71 38.884l3.156 1.239-.482.912-2.856-1.813.182-.338z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M46.296 12.077l.403.473-1.543 1.148v-.508l1.14-1.113z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M48.107 14.42l.342.518-2.589 1.468-.128-.204 2.375-1.782z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M49.619 16.966l.276.556-2.749 1.141-.102-.218 2.575-1.479z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M50.809 19.677l.206.585-2.867.799-.075-.229 2.736-1.155z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M51.66 22.513l.133.606-2.943.443-.046-.236 2.856-.813z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M52.159 25.432l.058.618-2.975.08-.017-.239 2.934-.459z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M52.298 28.39l-.017.62-2.963-.282.012-.24 2.968-.098z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M52.076 31.342l-.093.614-2.906-.641.041-.237 2.958.264z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M51.496 34.246l-.167.598-2.807-.991.071-.23 2.903.623z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M38.121 29.944l2.914-.013.027.348-2.852.513-.089-.848z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M38.122 26.145l2.88.442-.028.349-2.897.06.045-.851z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M38.717 22.391l2.776.888-.082.34-2.871-.393.177-.835z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M39.893 18.778l2.602 1.31-.135.324-2.774-.838.307-.796z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M36.652 50.689l.158-.052 1.323 3.102-.39.145-1.091-3.195z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M37.825 50.208l.155-.061 1.484 3.029-.382.166-1.257-3.134z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M38.972 49.666l.151-.069 1.641 2.946-.374.186-1.418-3.063z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M40.088 49.064l.147-.076 1.793 2.857-.363.205-1.577-2.986z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M20.056 47.784l.358.135-.898 2.788-.654-.257 1.194-2.666z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M21.806 48.405l.368.104-.651 2.855-.673-.198.956-2.761z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M23.604 48.873l.376.071-.4 2.901-.688-.139.712-2.833z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M25.436 49.181l.38.038-.146 2.925-.696-.079.462-2.884z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M27.288 49.328l.382.005.11 2.926-.702-.017.21-2.914z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M29.145 49.313l.382-.028.364 2.906-.701.043-.045-2.921z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M30.994 49.138l.378-.062.616 2.863-.694.104-.3-2.905z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M32.821 48.801l.371-.094.863 2.797-.682.165-.552-2.868z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M34.612 48.306l.361-.126 1.104 2.712-.665.223-.8-2.809zM1.825 19.465l3.154 1.183-.07.216-3.271-.81.187-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M.974 22.725l3.275.791-.043.222-3.346-.406.114-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M.526 26.064l3.348.386-.016.226-3.37.005.038-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M.49 29.433l3.369-.025.012.226-3.345.416-.036-.617z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M.863 32.782l3.342-.436.039.223-3.269.82-.112-.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M1.643 36.06l3.263-.84.066.217-3.144 1.212-.185-.589z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M2.816 39.218l3.136-1.231.092.207-2.973 1.587-.255-.563z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M4.365 42.21l2.963-1.604.116.195-2.757 1.936-.322-.527z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M6.267 44.991l2.745-1.953.14.179-2.501 2.259-.384-.485z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M3.907 29.944l2.914-.013.028.348-2.853.513-.089-.848z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M3.908 26.145l2.88.442-.028.349-2.897.06.045-.851z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M4.503 22.391l2.776.888-.082.34-2.871-.393.177-.835z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M5.678 18.778l2.603 1.31-.135.324-2.773-.838.305-.796zM14.897 7.704l.298-.175 1.498 2.536-.151.088-1.645-2.449z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M15.98 7.043l.307-.159 1.363 2.612-.156.079-1.514-2.532z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M17.096 6.441l.314-.143 1.226 2.679-.16.071-1.38-2.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M18.242 5.897l.321-.126 1.083 2.74-.163.062-1.241-2.676z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M15.259 3.605l.525-.268 1.376 3.044-.233.14-1.668-2.916z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M17.884 2.402l.55-.212 1.05 3.171-.246.115-1.354-3.074z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M20.621 1.48l.568-.153.714 3.263-.257.089-1.025-3.199z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M23.438.849l.582-.093.368 3.32-.264.061-.686-3.288z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M26.307.516l.588-.031.019 3.34-.27.033-.337-3.342z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M29.194.485l.588.03-.329 3.324-.272.005.013-3.359z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M32.069.755l.582.092-.676 3.271-.271-.024.365-3.339z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M34.899 1.325l.57.152-1.014 3.183-.267-.052.711-3.283z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M37.655 2.188l.551.21-1.341 3.06-.261-.081 1.051-3.189z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M49.111 7.704l.298-.175 1.498 2.536-.151.088-1.645-2.449z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M50.194 7.043l.306-.159 1.364 2.612-.156.079-1.514-2.532z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M51.31 6.441l.314-.143 1.225 2.679-.16.071-1.379-2.607z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M52.456 5.897l.321-.126 1.083 2.74-.163.062-1.241-2.676z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M49.473 3.605l.525-.268 1.376 3.044-.233.14-1.668-2.916z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M52.098 2.402l.55-.212 1.05 3.171-.246.115-1.354-3.074z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M54.834 1.48l.569-.153.714 3.263-.258.089-1.025-3.199z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M57.652.849l.582-.093.369 3.32-.266.061-.685-3.288z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M60.521.516l.588-.031.019 3.34-.27.033-.337-3.342z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M63.408.485l.588.03-.329 3.324-.272.005.013-3.359z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M66.282.755l.583.092-.676 3.271-.271-.024.364-3.339z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M69.113 1.325l.57.152-1.014 3.183-.267-.052.711-3.283z"
        clipPath="url(#b)"
      />
      <path
        fill="#5e5e5e"
        d="M71.869 2.188l.551.21-1.341 3.06-.261-.081 1.051-3.189zM88.484 47.784l.357.135-.897 2.788-.653-.257 1.193-2.666z"
        clipPath="url(#b)"
      />
      <path
        fill="#707070"
        d="M90.234 48.405l.369.104-.652 2.855-.672-.198.955-2.761z"
        clipPath="url(#b)"
      />
      <path
        fill="#828282"
        d="M92.033 48.873l.375.071-.401 2.901-.687-.139.713-2.833z"
        clipPath="url(#b)"
      />
      <path
        fill="#949494"
        d="M93.864 49.181l.381.038-.146 2.925-.697-.079.462-2.884z"
        clipPath="url(#b)"
      />
      <path
        fill="#a6a6a6"
        d="M95.716 49.328l.383.005.109 2.926-.702-.017.21-2.914z"
        clipPath="url(#b)"
      />
      <path
        fill="#b8b8b8"
        d="M97.574 49.313l.381-.028.364 2.906-.7.043-.045-2.921z"
        clipPath="url(#b)"
      />
      <path
        fill="#c9c9c9"
        d="M99.422 49.138l.378-.062.616 2.863-.694.104-.3-2.905z"
        clipPath="url(#b)"
      />
      <path
        fill="#dbdbdb"
        d="M101.25 48.801l.371-.094.863 2.797-.683.165-.551-2.868z"
        clipPath="url(#b)"
      />
      <path
        fill="#ededed"
        d="M103.04 48.306l.361-.126 1.104 2.712-.666.223-.799-2.809z"
        clipPath="url(#b)"
      />
      <path
        fill="#545454"
        d="M105.091 50.689l.159-.052 1.323 3.102-.39.145-1.092-3.195z"
        clipPath="url(#b)"
      />
      <path
        fill="#5b5b5b"
        d="M106.265 50.208l.154-.061 1.485 3.029-.383.166-1.256-3.134z"
        clipPath="url(#b)"
      />
      <path
        fill="#626262"
        d="M107.412 49.666l.15-.069 1.641 2.946-.373.186-1.418-3.063z"
        clipPath="url(#b)"
      />
      <path
        fill="#696969"
        d="M108.528 49.064l.147-.076 1.793 2.857-.363.205-1.577-2.986z"
        clipPath="url(#b)"
      />
      <path
        fill="#4d4d4d"
        d="M147.617 10.705a24.181 24.181 0 0 1 6.41 10.833l3.326-.611c-2.083-7.82-7.514-14.272-14.664-17.729l-1.262 3.147a24.09 24.09 0 0 0-10.736-2.511c-3.092 0-6.047.576-8.76 1.626l1.066 2.752a21.228 21.228 0 0 1 7.693-1.432c5.277 0 10.494 1.927 14.693 5.84l2.234-1.915zM107.214 6.345a24.084 24.084 0 0 0-10.736-2.511c-3.093 0-6.047.576-8.76 1.626l1.064 2.752a21.243 21.243 0 0 1 7.695-1.432c4.686 0 9.074 1.565 12.592 4.13h4.515V6.403a27.6 27.6 0 0 0-5.06-3.18l-1.31 3.122zM79.37 10.91V6.403a27.592 27.592 0 0 0-5.059-3.18L72.94 6.315a24.088 24.088 0 0 0-10.677-2.481c-3.093 0-6.047.576-8.76 1.626l1.065 2.752a21.234 21.234 0 0 1 7.694-1.432c4.686 0 9.074 1.565 12.593 4.13h4.515zM45.156 10.91V6.403a27.625 27.625 0 0 0-5.059-3.18l-1.358 3.098A24.09 24.09 0 0 0 28.05 3.833c-3.093 0-6.047.576-8.76 1.626l1.065 2.752a21.24 21.24 0 0 1 7.695-1.432c4.685 0 9.074 1.565 12.593 4.13h4.513v.001zM17.602 49.911l1.181-2.708c-5.615-2.719-9.85-7.833-11.381-14.02l-2.921.468a24.11 24.11 0 0 0 6.395 11.489L8.34 47.357c5.007 5.111 11.987 8.282 19.709 8.282 3.096 0 6.072-.51 8.849-1.45l-1.128-3.186a24.14 24.14 0 0 1-7.721 1.262c-3.745 0-7.289-.845-10.447-2.354zM45.157 49.697a27.471 27.471 0 0 0 17.106 5.941c3.096 0 6.072-.51 8.849-1.45l-1.128-3.186a24.14 24.14 0 0 1-7.721 1.262c-3.756 0-7.275-.829-10.438-2.349l1.171-2.712a21.093 21.093 0 0 1-3.337-2.015h-4.504l.002 4.509zM79.37 45.188v4.507a27.512 27.512 0 0 0 17.119 5.943c3.097 0 6.071-.51 8.849-1.45l-1.128-3.186a24.14 24.14 0 0 1-7.721 1.262c-3.753 0-7.302-.851-10.468-2.369l1.19-2.693a20.964 20.964 0 0 1-3.328-2.015H79.37v.001zM113.584 49.695c4.702 3.72 10.667 5.924 17.13 5.924 3.096 0 6.071-.509 8.849-1.449l-1.128-3.187a24.116 24.116 0 0 1-7.721 1.262c-3.744 0-7.289-.841-10.449-2.347l1.16-2.696a20.945 20.945 0 0 1-3.326-2.015h-4.515v4.508z"
        clipPath="url(#b)"
      />
      <g clipPath="url(#b)">
        <defs>
          <path id="c" d="M45.156 10.91l1.213 1.251-1.213 1.177V10.91z" />
        </defs>
        <clipPath id="d">
          <use overflow="visible" xlinkHref="#c" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M43.804 11.389h1.704v.41h-1.704z"
          clipPath="url(#d)"
          transform="rotate(135.997 44.656 11.595)"
        />
        <path
          fill="#515151"
          d="M44.047 11.64h1.704v.41h-1.704z"
          clipPath="url(#d)"
          transform="rotate(135.997 44.899 11.846)"
        />
        <path
          fill="#555"
          d="M44.289 11.892h1.704v.41h-1.704z"
          clipPath="url(#d)"
          transform="rotate(135.997 45.141 12.097)"
        />
        <path
          fill="#5a5a5a"
          d="M44.532 12.143h1.703v.41h-1.703z"
          clipPath="url(#d)"
          transform="rotate(135.997 45.384 12.349)"
        />
        <path
          fill="#5e5e5e"
          d="M44.775 12.394h1.704v.41h-1.704z"
          clipPath="url(#d)"
          transform="rotate(135.997 45.627 12.6)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="e"
            d="M46.608 12.44l1.561 2.071-2.353 1.774-.66-.873v-1.875l1.452-1.097z"
          />
        </defs>
        <clipPath id="f">
          <use overflow="visible" xlinkHref="#e" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M44.122 13.301h2.904v.504h-2.904z"
          clipPath="url(#f)"
          transform="rotate(143.003 45.572 13.552)"
        />
        <path
          fill="#636363"
          d="M44.434 13.715h2.904v.504h-2.904z"
          clipPath="url(#f)"
          transform="rotate(143.003 45.884 13.967)"
        />
        <path
          fill="#676767"
          d="M44.746 14.13h2.904v.504h-2.904z"
          clipPath="url(#f)"
          transform="rotate(143.003 46.196 14.381)"
        />
        <path
          fill="#6c6c6c"
          d="M45.058 14.544h2.904v.504h-2.904z"
          clipPath="url(#f)"
          transform="rotate(143.003 46.508 14.796)"
        />
        <path
          fill="#707070"
          d="M45.371 14.959h2.904v.504h-2.904z"
          clipPath="url(#f)"
          transform="rotate(142.997 46.822 15.21)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="g"
            d="M48.372 14.818l1.297 2.246-2.551 1.474-1.297-2.247 2.551-1.473z"
          />
        </defs>
        <clipPath id="h">
          <use overflow="visible" xlinkHref="#g" />
        </clipPath>
        <path
          fill="#707070"
          d="M45.763 15.556h2.92v.487h-2.92z"
          clipPath="url(#h)"
          transform="rotate(149.994 47.221 15.8)"
        />
        <path
          fill="#757575"
          d="M46.022 16.005h2.92v.487h-2.92z"
          clipPath="url(#h)"
          transform="rotate(149.994 47.48 16.25)"
        />
        <path
          fill="#797979"
          d="M46.282 16.455h2.919v.487h-2.919z"
          clipPath="url(#h)"
          transform="rotate(150.009 47.74 16.697)"
        />
        <path
          fill="#7e7e7e"
          d="M46.54 16.904h2.92v.487h-2.92z"
          clipPath="url(#h)"
          transform="rotate(150 48.001 17.148)"
        />
        <path
          fill="#828282"
          d="M46.8 17.353h2.919v.488H46.8z"
          clipPath="url(#h)"
          transform="rotate(150.009 48.26 17.595)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="i"
            d="M49.833 17.393l1.014 2.388-2.712 1.151-1.014-2.388 2.712-1.151z"
          />
        </defs>
        <clipPath id="j">
          <use overflow="visible" xlinkHref="#i" />
        </clipPath>
        <path
          fill="#828282"
          d="M47.111 17.972h2.981v.487h-2.981z"
          clipPath="url(#j)"
          transform="rotate(156.999 48.601 18.215)"
        />
        <path
          fill="#878787"
          d="M47.313 18.45h2.981v.487h-2.981z"
          clipPath="url(#j)"
          transform="rotate(156.991 48.8 18.693)"
        />
        <path
          fill="#8b8b8b"
          d="M47.516 18.927h2.981v.487h-2.981z"
          clipPath="url(#j)"
          transform="rotate(156.999 49.007 19.17)"
        />
        <path
          fill="#8f8f8f"
          d="M47.719 19.405H50.7v.487h-2.981z"
          clipPath="url(#j)"
          transform="rotate(156.999 49.21 19.648)"
        />
        <path
          fill="#949494"
          d="M47.921 19.882h2.981v.487h-2.981z"
          clipPath="url(#j)"
          transform="rotate(156.999 49.412 20.127)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="k"
            d="M50.97 20.127l.715 2.494-2.832.812-.715-2.494 2.832-.812z"
          />
        </defs>
        <clipPath id="l">
          <use overflow="visible" xlinkHref="#k" />
        </clipPath>
        <path
          fill="#949494"
          d="M48.148 20.491h2.954v.551h-2.954z"
          clipPath="url(#l)"
          transform="rotate(164.008 49.625 20.765)"
        />
        <path
          fill="#989898"
          d="M48.291 20.989h2.954v.551h-2.954z"
          clipPath="url(#l)"
          transform="rotate(163.996 49.768 21.266)"
        />
        <path
          fill="#9d9d9d"
          d="M48.433 21.488h2.955v.551h-2.955z"
          clipPath="url(#l)"
          transform="rotate(164.003 49.908 21.761)"
        />
        <path
          fill="#a1a1a1"
          d="M48.576 21.987h2.955v.551h-2.955z"
          clipPath="url(#l)"
          transform="rotate(164.003 50.051 22.26)"
        />
        <path
          fill="#a6a6a6"
          d="M48.719 22.486h2.954v.551h-2.954z"
          clipPath="url(#l)"
          transform="rotate(164.008 50.197 22.76)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="m"
            d="M51.765 22.979l.405 2.563-2.91.461-.406-2.563 2.911-.461z"
          />
        </defs>
        <clipPath id="n">
          <use overflow="visible" xlinkHref="#m" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M48.879 23.239h2.935v.488h-2.935z"
          clipPath="url(#n)"
          transform="rotate(171.004 50.347 23.483)"
        />
        <path
          fill="#aaa"
          d="M48.961 23.752h2.935v.487h-2.935z"
          clipPath="url(#n)"
          transform="rotate(170.991 50.425 23.994)"
        />
        <path
          fill="#afafaf"
          d="M49.042 24.264h2.935v.488h-2.935z"
          clipPath="url(#n)"
          transform="rotate(171.004 50.51 24.507)"
        />
        <path
          fill="#b3b3b3"
          d="M49.123 24.776h2.935v.488h-2.935z"
          clipPath="url(#n)"
          transform="rotate(171.004 50.59 25.02)"
        />
        <path
          fill="#b8b8b8"
          d="M49.204 25.289h2.935v.488h-2.935z"
          clipPath="url(#n)"
          transform="rotate(171.004 50.671 25.532)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="o"
            d="M52.206 25.908l.09 2.592-2.944.103-.091-2.592 2.945-.103z"
          />
        </defs>
        <clipPath id="p">
          <use overflow="visible" xlinkHref="#o" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M49.268 25.909h2.978v.569h-2.978z"
          clipPath="url(#p)"
          transform="rotate(178.015 50.758 26.192)"
        />
        <path
          fill="#bcbcbc"
          d="M49.287 26.427h2.978v.568h-2.978z"
          clipPath="url(#p)"
          transform="rotate(178.015 50.776 26.71)"
        />
        <path
          fill="#c1c1c1"
          d="M49.305 26.946h2.978v.568h-2.978z"
          clipPath="url(#p)"
          transform="rotate(178.015 50.794 27.23)"
        />
        <path
          fill="#c5c5c5"
          d="M49.323 27.464h2.979v.569h-2.979z"
          clipPath="url(#p)"
          transform="rotate(178.015 50.811 27.75)"
        />
        <path
          fill="#c9c9c9"
          d="M49.341 27.983h2.978v.569h-2.978z"
          clipPath="url(#p)"
          transform="rotate(178.015 50.83 28.268)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="q"
            d="M52.287 28.868l-.226 2.584-2.936-.257.227-2.584 2.935.257z"
          />
        </defs>
        <clipPath id="r">
          <use overflow="visible" xlinkHref="#q" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M49.324 28.731h2.922v.525h-2.922z"
          clipPath="url(#r)"
          transform="rotate(-175.003 50.785 28.993)"
        />
        <path
          fill="#cecece"
          d="M49.278 29.248H52.2v.525h-2.922z"
          clipPath="url(#r)"
          transform="rotate(-175.003 50.739 29.51)"
        />
        <path
          fill="#d2d2d2"
          d="M49.233 29.764h2.922v.525h-2.922z"
          clipPath="url(#r)"
          transform="rotate(-175.003 50.695 30.026)"
        />
        <path
          fill="#d7d7d7"
          d="M49.188 30.281h2.922v.525h-2.922z"
          clipPath="url(#r)"
          transform="rotate(-175.003 50.649 30.544)"
        />
        <path
          fill="#dbdbdb"
          d="M49.143 30.798h2.922v.525h-2.922z"
          clipPath="url(#r)"
          transform="rotate(-175.003 50.605 31.06)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="s"
            d="M52.007 31.815l-.54 2.538-2.882-.613.54-2.537 2.882.612z"
          />
        </defs>
        <clipPath id="t">
          <use overflow="visible" xlinkHref="#s" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M49.035 31.536h2.92v.483h-2.92z"
          clipPath="url(#t)"
          transform="rotate(-167.994 50.498 31.78)"
        />
        <path
          fill="#e0e0e0"
          d="M48.927 32.043h2.92v.483h-2.92z"
          clipPath="url(#t)"
          transform="rotate(-168.007 50.384 32.285)"
        />
        <path
          fill="#e4e4e4"
          d="M48.819 32.551h2.92v.483h-2.92z"
          clipPath="url(#t)"
          transform="rotate(-167.994 50.283 32.795)"
        />
        <path
          fill="#e9e9e9"
          d="M48.712 33.058h2.92v.483h-2.92z"
          clipPath="url(#t)"
          transform="rotate(-168.001 50.17 33.299)"
        />
        <path
          fill="#ededed"
          d="M48.603 33.566h2.92v.483h-2.92z"
          clipPath="url(#t)"
          transform="rotate(-168.007 50.06 33.807)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="u"
            d="M51.369 34.707l-.844 2.453-2.786-.959.844-2.453 2.786.959z"
          />
        </defs>
        <clipPath id="v">
          <use overflow="visible" xlinkHref="#u" />
        </clipPath>
        <path
          fill="#ededed"
          d="M48.429 34.149h2.927v.582h-2.927z"
          clipPath="url(#v)"
          transform="rotate(-160.997 49.892 34.44)"
        />
        <path
          fill="#f2f2f2"
          d="M48.261 34.64h2.926v.581h-2.926z"
          clipPath="url(#v)"
          transform="rotate(-161.003 49.727 34.933)"
        />
        <path
          fill="#f6f6f6"
          d="M48.091 35.13h2.927v.581h-2.927z"
          clipPath="url(#v)"
          transform="rotate(-161.005 49.553 35.42)"
        />
        <path
          fill="#fbfbfb"
          d="M47.922 35.621h2.927v.581h-2.927z"
          clipPath="url(#v)"
          transform="rotate(-160.997 49.386 35.912)"
        />
        <path
          fill="#fff"
          d="M47.754 36.111h2.927v.582h-2.927z"
          clipPath="url(#v)"
          transform="rotate(-161.005 49.215 36.4)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="w" d="M79.37 10.91l1.213 1.251-1.213 1.177V10.91z" />
        </defs>
        <clipPath id="x">
          <use overflow="visible" xlinkHref="#w" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M78.018 11.389h1.704v.41h-1.704z"
          clipPath="url(#x)"
          transform="rotate(135.997 78.87 11.596)"
        />
        <path
          fill="#515151"
          d="M78.26 11.64h1.705v.41H78.26z"
          clipPath="url(#x)"
          transform="rotate(136.021 79.112 11.846)"
        />
        <path
          fill="#555"
          d="M78.503 11.892h1.704v.41h-1.704z"
          clipPath="url(#x)"
          transform="rotate(135.997 79.355 12.098)"
        />
        <path
          fill="#5a5a5a"
          d="M78.746 12.143h1.703v.41h-1.703z"
          clipPath="url(#x)"
          transform="rotate(135.988 79.598 12.347)"
        />
        <path
          fill="#5e5e5e"
          d="M78.988 12.394h1.704v.41h-1.704z"
          clipPath="url(#x)"
          transform="rotate(135.997 79.84 12.6)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="y"
            d="M80.821 12.44l1.562 2.071-2.353 1.774-.66-.873v-1.875l1.451-1.097z"
          />
        </defs>
        <clipPath id="z">
          <use overflow="visible" xlinkHref="#y" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M78.336 13.301h2.904v.504h-2.904z"
          clipPath="url(#z)"
          transform="rotate(142.987 79.788 13.55)"
        />
        <path
          fill="#636363"
          d="M78.648 13.715h2.904v.504h-2.904z"
          clipPath="url(#z)"
          transform="rotate(143.003 80.096 13.967)"
        />
        <path
          fill="#676767"
          d="M78.96 14.13h2.904v.504H78.96z"
          clipPath="url(#z)"
          transform="rotate(143.003 80.409 14.381)"
        />
        <path
          fill="#6c6c6c"
          d="M79.272 14.544h2.905v.504h-2.905z"
          clipPath="url(#z)"
          transform="rotate(143.016 80.724 14.795)"
        />
        <path
          fill="#707070"
          d="M79.584 14.959h2.905v.504h-2.905z"
          clipPath="url(#z)"
          transform="rotate(143.006 81.037 15.21)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="A"
            d="M82.586 14.818l1.297 2.246-2.552 1.474-1.297-2.247 2.552-1.473z"
          />
        </defs>
        <clipPath id="B">
          <use overflow="visible" xlinkHref="#A" />
        </clipPath>
        <path
          fill="#707070"
          d="M79.977 15.556h2.92v.487h-2.92z"
          clipPath="url(#B)"
          transform="rotate(149.994 81.435 15.8)"
        />
        <path
          fill="#757575"
          d="M80.237 16.005h2.918v.487h-2.918z"
          clipPath="url(#B)"
          transform="rotate(149.994 81.694 16.249)"
        />
        <path
          fill="#797979"
          d="M80.495 16.455h2.919v.487h-2.919z"
          clipPath="url(#B)"
          transform="rotate(150.009 81.954 16.696)"
        />
        <path
          fill="#7e7e7e"
          d="M80.755 16.904h2.92v.487h-2.92z"
          clipPath="url(#B)"
          transform="rotate(149.994 82.213 17.149)"
        />
        <path
          fill="#828282"
          d="M81.014 17.353h2.92v.488h-2.92z"
          clipPath="url(#B)"
          transform="rotate(150.012 82.477 17.599)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="C"
            d="M84.047 17.393l1.015 2.388-2.713 1.151-1.014-2.388 2.712-1.151z"
          />
        </defs>
        <clipPath id="D">
          <use overflow="visible" xlinkHref="#C" />
        </clipPath>
        <path
          fill="#828282"
          d="M81.324 17.972h2.981v.487h-2.981z"
          clipPath="url(#D)"
          transform="rotate(156.999 82.813 18.215)"
        />
        <path
          fill="#878787"
          d="M81.527 18.45h2.98v.487h-2.98z"
          clipPath="url(#D)"
          transform="rotate(156.989 83.02 18.693)"
        />
        <path
          fill="#8b8b8b"
          d="M81.73 18.927h2.98v.487h-2.98z"
          clipPath="url(#D)"
          transform="rotate(156.999 83.22 19.171)"
        />
        <path
          fill="#8f8f8f"
          d="M81.932 19.405h2.981v.487h-2.981z"
          clipPath="url(#D)"
          transform="rotate(156.999 83.424 19.65)"
        />
        <path
          fill="#949494"
          d="M82.135 19.882h2.981v.487h-2.981z"
          clipPath="url(#D)"
          transform="rotate(156.999 83.626 20.128)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="E"
            d="M85.184 20.127l.716 2.494-2.834.812-.714-2.494 2.832-.812z"
          />
        </defs>
        <clipPath id="F">
          <use overflow="visible" xlinkHref="#E" />
        </clipPath>
        <path
          fill="#949494"
          d="M82.362 20.491h2.954v.551h-2.954z"
          clipPath="url(#F)"
          transform="rotate(163.996 83.84 20.768)"
        />
        <path
          fill="#989898"
          d="M82.504 20.99h2.955v.551h-2.955z"
          clipPath="url(#F)"
          transform="rotate(164.003 83.978 21.262)"
        />
        <path
          fill="#9d9d9d"
          d="M82.647 21.488h2.955v.551h-2.955z"
          clipPath="url(#F)"
          transform="rotate(164.003 84.12 21.76)"
        />
        <path
          fill="#a1a1a1"
          d="M82.79 21.987h2.955v.551H82.79z"
          clipPath="url(#F)"
          transform="rotate(164.003 84.264 22.26)"
        />
        <path
          fill="#a6a6a6"
          d="M82.933 22.486h2.955v.551h-2.955z"
          clipPath="url(#F)"
          transform="rotate(164.003 84.407 22.758)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="G"
            d="M85.979 22.979l.405 2.563-2.91.461-.406-2.563 2.911-.461z"
          />
        </defs>
        <clipPath id="H">
          <use overflow="visible" xlinkHref="#G" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M83.093 23.239h2.935v.488h-2.935z"
          clipPath="url(#H)"
          transform="rotate(171.004 84.56 23.483)"
        />
        <path
          fill="#aaa"
          d="M83.174 23.752h2.936v.487h-2.936z"
          clipPath="url(#H)"
          transform="rotate(171.004 84.641 23.995)"
        />
        <path
          fill="#afafaf"
          d="M83.255 24.264h2.935v.488h-2.935z"
          clipPath="url(#H)"
          transform="rotate(171.004 84.722 24.508)"
        />
        <path
          fill="#b3b3b3"
          d="M83.337 24.776h2.935v.488h-2.935z"
          clipPath="url(#H)"
          transform="rotate(171.004 84.804 25.02)"
        />
        <path
          fill="#b8b8b8"
          d="M83.417 25.288h2.936v.488h-2.936z"
          clipPath="url(#H)"
          transform="rotate(171.004 84.886 25.531)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="I"
            d="M86.419 25.908l.092 2.592-2.946.103-.089-2.592 2.943-.103z"
          />
        </defs>
        <clipPath id="J">
          <use overflow="visible" xlinkHref="#I" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M83.482 25.909h2.979v.569h-2.979z"
          clipPath="url(#J)"
          transform="rotate(178.015 84.972 26.192)"
        />
        <path
          fill="#bcbcbc"
          d="M83.5 26.427h2.979v.568H83.5z"
          clipPath="url(#J)"
          transform="rotate(178.015 84.99 26.71)"
        />
        <path
          fill="#c1c1c1"
          d="M83.518 26.946h2.979v.568h-2.979z"
          clipPath="url(#J)"
          transform="rotate(178.015 85.007 27.23)"
        />
        <path
          fill="#c5c5c5"
          d="M83.537 27.464h2.979v.569h-2.979z"
          clipPath="url(#J)"
          transform="rotate(178.015 85.026 27.75)"
        />
        <path
          fill="#c9c9c9"
          d="M83.555 27.983h2.979v.569h-2.979z"
          clipPath="url(#J)"
          transform="rotate(178.015 85.043 28.27)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="K"
            d="M86.501 28.868l-.226 2.584-2.936-.257.226-2.584 2.936.257z"
          />
        </defs>
        <clipPath id="L">
          <use overflow="visible" xlinkHref="#K" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M83.538 28.731h2.922v.526h-2.922z"
          clipPath="url(#L)"
          transform="rotate(-175.003 85 28.992)"
        />
        <path
          fill="#cecece"
          d="M83.493 29.248h2.922v.525h-2.922z"
          clipPath="url(#L)"
          transform="rotate(-175.003 84.953 29.512)"
        />
        <path
          fill="#d2d2d2"
          d="M83.447 29.764h2.923v.525h-2.923z"
          clipPath="url(#L)"
          transform="rotate(-175.003 84.908 30.029)"
        />
        <path
          fill="#d7d7d7"
          d="M83.402 30.281h2.923v.525h-2.923z"
          clipPath="url(#L)"
          transform="rotate(-175.003 84.863 30.544)"
        />
        <path
          fill="#dbdbdb"
          d="M83.356 30.798h2.924v.525h-2.924z"
          clipPath="url(#L)"
          transform="rotate(-175.003 84.818 31.061)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="M"
            d="M86.221 31.815l-.54 2.538-2.881-.613.539-2.537 2.882.612z"
          />
        </defs>
        <clipPath id="N">
          <use overflow="visible" xlinkHref="#M" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M83.25 31.536h2.92v.483h-2.92z"
          clipPath="url(#N)"
          transform="rotate(-167.994 84.715 31.78)"
        />
        <path
          fill="#e0e0e0"
          d="M83.141 32.043h2.921v.483h-2.921z"
          clipPath="url(#N)"
          transform="rotate(-168.007 84.598 32.284)"
        />
        <path
          fill="#e4e4e4"
          d="M83.033 32.551h2.921v.483h-2.921z"
          clipPath="url(#N)"
          transform="rotate(-167.994 84.5 32.793)"
        />
        <path
          fill="#e9e9e9"
          d="M82.926 33.058h2.921v.483h-2.921z"
          clipPath="url(#N)"
          transform="rotate(-168.007 84.383 33.3)"
        />
        <path
          fill="#ededed"
          d="M82.818 33.566h2.92v.483h-2.92z"
          clipPath="url(#N)"
          transform="rotate(-168.001 84.276 33.806)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="O"
            d="M85.583 34.707l-.845 2.453-2.786-.959.846-2.453 2.785.959z"
          />
        </defs>
        <clipPath id="P">
          <use overflow="visible" xlinkHref="#O" />
        </clipPath>
        <path
          fill="#ededed"
          d="M82.643 34.149h2.928v.582h-2.928z"
          clipPath="url(#P)"
          transform="rotate(-160.997 84.108 34.439)"
        />
        <path
          fill="#f2f2f2"
          d="M82.475 34.64h2.927v.581h-2.927z"
          clipPath="url(#P)"
          transform="rotate(-161.005 83.935 34.929)"
        />
        <path
          fill="#f6f6f6"
          d="M82.305 35.13h2.927v.582h-2.927z"
          clipPath="url(#P)"
          transform="rotate(-161.005 83.765 35.42)"
        />
        <path
          fill="#fbfbfb"
          d="M82.137 35.621h2.927v.582h-2.927z"
          clipPath="url(#P)"
          transform="rotate(-160.997 83.6 35.913)"
        />
        <path
          fill="#fff"
          d="M81.967 36.112h2.927v.582h-2.927z"
          clipPath="url(#P)"
          transform="rotate(-160.997 83.43 36.403)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="Q" d="M113.584 10.91l1.213 1.273-1.213 1.178V10.91z" />
        </defs>
        <clipPath id="R">
          <use overflow="visible" xlinkHref="#Q" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M112.224 11.403h1.704v.41h-1.704z"
          clipPath="url(#R)"
          transform="rotate(135.997 113.076 11.61)"
        />
        <path
          fill="#515151"
          d="M112.468 11.656h1.705v.41h-1.705z"
          clipPath="url(#R)"
          transform="rotate(136.021 113.32 11.861)"
        />
        <path
          fill="#555"
          d="M112.714 11.91h1.703v.41h-1.703z"
          clipPath="url(#R)"
          transform="rotate(135.988 113.565 12.113)"
        />
        <path
          fill="#5a5a5a"
          d="M112.958 12.163h1.705v.41h-1.705z"
          clipPath="url(#R)"
          transform="rotate(136.021 113.81 12.369)"
        />
        <path
          fill="#5e5e5e"
          d="M113.203 12.417h1.703v.41h-1.703z"
          clipPath="url(#R)"
          transform="rotate(136.013 114.054 12.62)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="S"
            d="M115.037 12.462l1.56 2.072-2.352 1.773-.661-.895v-1.853l1.453-1.097z"
          />
        </defs>
        <clipPath id="T">
          <use overflow="visible" xlinkHref="#S" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M112.551 13.322h2.904v.504h-2.904z"
          clipPath="url(#T)"
          transform="rotate(143.003 113.998 13.573)"
        />
        <path
          fill="#636363"
          d="M112.864 13.737h2.904v.504h-2.904z"
          clipPath="url(#T)"
          transform="rotate(142.997 114.315 13.99)"
        />
        <path
          fill="#676767"
          d="M113.177 14.151h2.903v.504h-2.903z"
          clipPath="url(#T)"
          transform="rotate(142.991 114.637 14.404)"
        />
        <path
          fill="#6c6c6c"
          d="M113.488 14.565h2.904v.504h-2.904z"
          clipPath="url(#T)"
          transform="rotate(143.003 114.935 14.817)"
        />
        <path
          fill="#707070"
          d="M113.8 14.98h2.904v.504H113.8z"
          clipPath="url(#T)"
          transform="rotate(142.997 115.251 15.233)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="U"
            d="M116.8 14.84l1.297 2.246-2.551 1.474-1.296-2.247 2.55-1.473z"
          />
        </defs>
        <clipPath id="V">
          <use overflow="visible" xlinkHref="#U" />
        </clipPath>
        <path
          fill="#707070"
          d="M114.191 15.578h2.92v.487h-2.92z"
          clipPath="url(#V)"
          transform="rotate(149.994 115.649 15.823)"
        />
        <path
          fill="#757575"
          d="M114.449 16.028h2.92v.487h-2.92z"
          clipPath="url(#V)"
          transform="rotate(150.009 115.909 16.27)"
        />
        <path
          fill="#797979"
          d="M114.71 16.477h2.919v.487h-2.919z"
          clipPath="url(#V)"
          transform="rotate(150.009 116.168 16.718)"
        />
        <path
          fill="#7e7e7e"
          d="M114.97 16.926h2.919v.487h-2.919z"
          clipPath="url(#V)"
          transform="rotate(150.009 116.428 17.167)"
        />
        <path
          fill="#828282"
          d="M115.229 17.376h2.919v.487h-2.919z"
          clipPath="url(#V)"
          transform="rotate(149.986 116.689 17.619)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="W"
            d="M118.261 17.416l1.014 2.387-2.711 1.152-1.014-2.388 2.711-1.151z"
          />
        </defs>
        <clipPath id="X">
          <use overflow="visible" xlinkHref="#W" />
        </clipPath>
        <path
          fill="#828282"
          d="M115.539 17.994h2.98v.487h-2.98z"
          clipPath="url(#X)"
          transform="rotate(156.999 117.028 18.239)"
        />
        <path
          fill="#878787"
          d="M115.741 18.472h2.981v.487h-2.981z"
          clipPath="url(#X)"
          transform="rotate(156.999 117.23 18.715)"
        />
        <path
          fill="#8b8b8b"
          d="M115.944 18.949h2.981v.487h-2.981z"
          clipPath="url(#X)"
          transform="rotate(156.999 117.433 19.193)"
        />
        <path
          fill="#8f8f8f"
          d="M116.147 19.427h2.98v.487h-2.98z"
          clipPath="url(#X)"
          transform="rotate(156.989 117.64 19.67)"
        />
        <path
          fill="#949494"
          d="M116.35 19.905h2.98v.487h-2.98z"
          clipPath="url(#X)"
          transform="rotate(156.999 117.839 20.15)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="Y"
            d="M119.398 20.149l.715 2.494-2.832.813-.715-2.495 2.832-.812z"
          />
        </defs>
        <clipPath id="Z">
          <use overflow="visible" xlinkHref="#Y" />
        </clipPath>
        <path
          fill="#949494"
          d="M116.576 20.513h2.954v.551h-2.954z"
          clipPath="url(#Z)"
          transform="rotate(163.996 118.054 20.79)"
        />
        <path
          fill="#989898"
          d="M116.719 21.012h2.954v.551h-2.954z"
          clipPath="url(#Z)"
          transform="rotate(163.996 118.198 21.29)"
        />
        <path
          fill="#9d9d9d"
          d="M116.861 21.51h2.955v.551h-2.955z"
          clipPath="url(#Z)"
          transform="rotate(164.003 118.333 21.785)"
        />
        <path
          fill="#a1a1a1"
          d="M117.005 22.009h2.954v.551h-2.954z"
          clipPath="url(#Z)"
          transform="rotate(164.008 118.482 22.283)"
        />
        <path
          fill="#a6a6a6"
          d="M117.148 22.508h2.954v.551h-2.954z"
          clipPath="url(#Z)"
          transform="rotate(163.996 118.627 22.786)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aa"
            d="M120.193 23.002l.406 2.562-2.91.461-.406-2.562 2.91-.461z"
          />
        </defs>
        <clipPath id="ab">
          <use overflow="visible" xlinkHref="#aa" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M117.308 23.261h2.936v.487h-2.936z"
          clipPath="url(#ab)"
          transform="rotate(171.004 118.775 23.508)"
        />
        <path
          fill="#aaa"
          d="M117.389 23.773h2.935v.488h-2.935z"
          clipPath="url(#ab)"
          transform="rotate(171.004 118.856 24.017)"
        />
        <path
          fill="#afafaf"
          d="M117.471 24.286h2.935v.487h-2.935z"
          clipPath="url(#ab)"
          transform="rotate(171.004 118.938 24.53)"
        />
        <path
          fill="#b3b3b3"
          d="M117.551 24.798h2.935v.488h-2.935z"
          clipPath="url(#ab)"
          transform="rotate(171.004 119.018 25.042)"
        />
        <path
          fill="#b8b8b8"
          d="M117.633 25.311h2.935v.487h-2.935z"
          clipPath="url(#ab)"
          transform="rotate(170.991 119.092 25.555)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ac"
            d="M120.634 25.93l.09 2.592-2.943.103-.092-2.592 2.945-.103z"
          />
        </defs>
        <clipPath id="ad">
          <use overflow="visible" xlinkHref="#ac" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M117.697 25.931h2.978v.569h-2.978z"
          clipPath="url(#ad)"
          transform="rotate(178.015 119.185 26.218)"
        />
        <path
          fill="#bcbcbc"
          d="M117.715 26.45h2.978v.568h-2.978z"
          clipPath="url(#ad)"
          transform="rotate(178.015 119.204 26.733)"
        />
        <path
          fill="#c1c1c1"
          d="M117.733 26.968h2.978v.568h-2.978z"
          clipPath="url(#ad)"
          transform="rotate(178.015 119.222 27.251)"
        />
        <path
          fill="#c5c5c5"
          d="M117.752 27.487h2.977v.568h-2.977z"
          clipPath="url(#ad)"
          transform="rotate(178.015 119.24 27.769)"
        />
        <path
          fill="#c9c9c9"
          d="M117.77 28.005h2.978v.569h-2.978z"
          clipPath="url(#ad)"
          transform="rotate(178.015 119.258 28.292)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ae"
            d="M120.715 28.89l-.225 2.584-2.936-.257.227-2.584 2.934.257z"
          />
        </defs>
        <clipPath id="af">
          <use overflow="visible" xlinkHref="#ae" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M117.752 28.753h2.922v.525h-2.922z"
          clipPath="url(#af)"
          transform="rotate(-175.003 119.212 29.017)"
        />
        <path
          fill="#cecece"
          d="M117.706 29.27h2.923v.525h-2.923z"
          clipPath="url(#af)"
          transform="rotate(-175.003 119.168 29.534)"
        />
        <path
          fill="#d2d2d2"
          d="M117.661 29.787h2.922v.525h-2.922z"
          clipPath="url(#af)"
          transform="rotate(-175.003 119.122 30.05)"
        />
        <path
          fill="#d7d7d7"
          d="M117.617 30.303h2.922v.525h-2.922z"
          clipPath="url(#af)"
          transform="rotate(-175.003 119.077 30.567)"
        />
        <path
          fill="#dbdbdb"
          d="M117.572 30.82h2.921v.525h-2.921z"
          clipPath="url(#af)"
          transform="rotate(-175.003 119.03 31.086)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ag"
            d="M120.435 31.838l-.539 2.537-2.883-.612.54-2.538 2.882.613z"
          />
        </defs>
        <clipPath id="ah">
          <use overflow="visible" xlinkHref="#ag" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M117.463 31.558h2.92v.484h-2.92z"
          clipPath="url(#ah)"
          transform="rotate(-167.994 118.932 31.801)"
        />
        <path
          fill="#e0e0e0"
          d="M117.356 32.066h2.919v.483h-2.919z"
          clipPath="url(#ah)"
          transform="rotate(-168 118.823 32.309)"
        />
        <path
          fill="#e4e4e4"
          d="M117.248 32.573h2.919v.483h-2.919z"
          clipPath="url(#ah)"
          transform="rotate(-167.987 118.71 32.813)"
        />
        <path
          fill="#e9e9e9"
          d="M117.14 33.081h2.92v.483h-2.92z"
          clipPath="url(#ah)"
          transform="rotate(-168.007 118.594 33.324)"
        />
        <path
          fill="#ededed"
          d="M117.031 33.588h2.921v.483h-2.921z"
          clipPath="url(#ah)"
          transform="rotate(-168.007 118.488 33.827)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ai"
            d="M119.797 34.729l-.844 2.453-2.786-.959.844-2.453 2.786.959z"
          />
        </defs>
        <clipPath id="aj">
          <use overflow="visible" xlinkHref="#ai" />
        </clipPath>
        <path
          fill="#ededed"
          d="M116.858 34.172h2.926v.581h-2.926z"
          clipPath="url(#aj)"
          transform="rotate(-160.997 118.323 34.46)"
        />
        <path
          fill="#f2f2f2"
          d="M116.688 34.662h2.927v.582h-2.927z"
          clipPath="url(#aj)"
          transform="rotate(-161.005 118.147 34.95)"
        />
        <path
          fill="#f6f6f6"
          d="M116.519 35.153h2.927v.582h-2.927z"
          clipPath="url(#aj)"
          transform="rotate(-160.997 117.983 35.445)"
        />
        <path
          fill="#fbfbfb"
          d="M116.35 35.643h2.927v.582h-2.927z"
          clipPath="url(#aj)"
          transform="rotate(-160.997 117.814 35.935)"
        />
        <path
          fill="#fff"
          d="M116.182 36.134h2.926v.582h-2.926z"
          clipPath="url(#aj)"
          transform="rotate(-160.997 117.647 36.423)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="ak" d="M34.694 26.561h3.369v2.977h-3.369v-2.977z" />
        </defs>
        <clipPath id="al">
          <use overflow="visible" xlinkHref="#ak" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M34.694 26.619h3.36v.538h-3.36z"
          clipPath="url(#al)"
        />
        <path
          fill="#c5c5c5"
          d="M34.694 27.214h3.36v.537h-3.36z"
          clipPath="url(#al)"
        />
        <path
          fill="#c1c1c1"
          d="M34.694 27.81h3.36v.538h-3.36z"
          clipPath="url(#al)"
        />
        <path
          fill="#bcbcbc"
          d="M34.694 28.405h3.36v.538h-3.36z"
          clipPath="url(#al)"
        />
        <path
          fill="#b8b8b8"
          d="M34.694 29h3.36v.538h-3.36z"
          clipPath="url(#al)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="am"
            d="M34.718 29.932l3.344-.411.362 2.955-3.343.41-.363-2.954z"
          />
        </defs>
        <clipPath id="an">
          <use overflow="visible" xlinkHref="#am" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M34.745 29.782h3.316v.541h-3.316z"
          clipPath="url(#an)"
          transform="rotate(173.004 36.403 30.053)"
        />
        <path
          fill="#b3b3b3"
          d="M34.818 30.373h3.316v.541h-3.316z"
          clipPath="url(#an)"
          transform="rotate(172.998 36.477 30.644)"
        />
        <path
          fill="#afafaf"
          d="M34.89 30.964h3.316v.541H34.89z"
          clipPath="url(#an)"
          transform="rotate(173.004 36.548 31.235)"
        />
        <path
          fill="#aaa"
          d="M34.963 31.555h3.316v.541h-3.316z"
          clipPath="url(#an)"
          transform="rotate(173.004 36.62 31.826)"
        />
        <path
          fill="#a6a6a6"
          d="M35.035 32.146h3.316v.541h-3.316z"
          clipPath="url(#an)"
          transform="rotate(172.998 36.695 32.417)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ao"
            d="M35.152 33.275l3.269-.815.72 2.888-3.268.815-.721-2.888z"
          />
        </defs>
        <clipPath id="ap">
          <use overflow="visible" xlinkHref="#ao" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M35.18 32.902h3.324v.556H35.18z"
          clipPath="url(#ap)"
          transform="rotate(166.001 36.842 33.18)"
        />
        <path
          fill="#a1a1a1"
          d="M35.324 33.48h3.324v.556h-3.324z"
          clipPath="url(#ap)"
          transform="rotate(166.001 36.986 33.758)"
        />
        <path
          fill="#9d9d9d"
          d="M35.468 34.058h3.324v.556h-3.324z"
          clipPath="url(#ap)"
          transform="rotate(166.001 37.13 34.336)"
        />
        <path
          fill="#989898"
          d="M35.612 34.636h3.324v.556h-3.324z"
          clipPath="url(#ap)"
          transform="rotate(166.001 37.274 34.914)"
        />
        <path
          fill="#949494"
          d="M35.756 35.213h3.325v.556h-3.325z"
          clipPath="url(#ap)"
          transform="rotate(166.001 37.418 35.49)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aq"
            d="M35.991 36.54l3.145-1.207 1.067 2.779-3.145 1.207-1.067-2.779z"
          />
        </defs>
        <clipPath id="ar">
          <use overflow="visible" xlinkHref="#aq" />
        </clipPath>
        <path
          fill="#949494"
          d="M35.99 35.936h3.386v.572H35.99z"
          clipPath="url(#ar)"
          transform="rotate(159.003 37.683 36.222)"
        />
        <path
          fill="#8f8f8f"
          d="M36.203 36.492h3.387v.572h-3.387z"
          clipPath="url(#ar)"
          transform="rotate(158.999 37.895 36.775)"
        />
        <path
          fill="#8b8b8b"
          d="M36.416 37.048h3.386v.572h-3.386z"
          clipPath="url(#ar)"
          transform="rotate(159.003 38.11 37.334)"
        />
        <path
          fill="#878787"
          d="M36.63 37.604h3.386v.572H36.63z"
          clipPath="url(#ar)"
          transform="rotate(159.003 38.323 37.89)"
        />
        <path
          fill="#828282"
          d="M36.843 38.16h3.386v.572h-3.386z"
          clipPath="url(#ar)"
          transform="rotate(159.003 38.537 38.446)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="as"
            d="M37.221 39.679l2.975-1.582 1.398 2.628-2.975 1.582-1.398-2.628z"
          />
        </defs>
        <clipPath id="at">
          <use overflow="visible" xlinkHref="#as" />
        </clipPath>
        <path
          fill="#828282"
          d="M37.154 38.811h3.383v.637h-3.383z"
          clipPath="url(#at)"
          transform="rotate(151.997 38.847 39.13)"
        />
        <path
          fill="#7e7e7e"
          d="M37.433 39.337h3.383v.637h-3.383z"
          clipPath="url(#at)"
          transform="rotate(151.997 39.126 39.656)"
        />
        <path
          fill="#797979"
          d="M37.713 39.862h3.383v.637h-3.383z"
          clipPath="url(#at)"
          transform="rotate(151.997 39.406 40.182)"
        />
        <path
          fill="#757575"
          d="M37.993 40.388h3.383v.637h-3.383z"
          clipPath="url(#at)"
          transform="rotate(152.006 39.684 40.705)"
        />
        <path
          fill="#707070"
          d="M38.272 40.914h3.383v.637h-3.383z"
          clipPath="url(#at)"
          transform="rotate(151.997 39.965 41.233)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="au"
            d="M38.825 42.644l2.76-1.933 1.707 2.439-2.759 1.932-1.708-2.438z"
          />
        </defs>
        <clipPath id="av">
          <use overflow="visible" xlinkHref="#au" />
        </clipPath>
        <path
          fill="#707070"
          d="M38.692 41.625h3.369v.594h-3.369z"
          clipPath="url(#av)"
          transform="rotate(144.997 40.378 41.923)"
        />
        <path
          fill="#6c6c6c"
          d="M39.033 42.113h3.369v.594h-3.369z"
          clipPath="url(#av)"
          transform="rotate(145.005 40.717 42.411)"
        />
        <path
          fill="#676767"
          d="M39.375 42.601h3.37v.594h-3.37z"
          clipPath="url(#av)"
          transform="rotate(145.005 41.06 42.896)"
        />
        <path
          fill="#636363"
          d="M39.717 43.088h3.369v.594h-3.369z"
          clipPath="url(#av)"
          transform="rotate(144.997 41.403 43.387)"
        />
        <path
          fill="#5e5e5e"
          d="M40.058 43.576h3.369v.594h-3.369z"
          clipPath="url(#av)"
          transform="rotate(144.997 41.744 43.874)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="aw" d="M40.996 45.188l2.286-2.051 1.874 2.051h-4.16z" />
        </defs>
        <clipPath id="ax">
          <use overflow="visible" xlinkHref="#aw" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M40.801 44.122h3.017v.539h-3.017z"
          clipPath="url(#ax)"
          transform="rotate(138.005 42.309 44.392)"
        />
        <path
          fill="#5a5a5a"
          d="M41.174 44.536h3.017v.539h-3.017z"
          clipPath="url(#ax)"
          transform="rotate(138.005 42.681 44.806)"
        />
        <path
          fill="#555"
          d="M41.546 44.951h3.017v.538h-3.017z"
          clipPath="url(#ax)"
          transform="rotate(137.996 43.054 45.22)"
        />
        <path
          fill="#515151"
          d="M41.919 45.364h3.017v.539h-3.017z"
          clipPath="url(#ax)"
          transform="rotate(137.996 43.426 45.634)"
        />
        <path
          fill="#4d4d4d"
          d="M42.291 45.778h3.017v.539h-3.017z"
          clipPath="url(#ax)"
          transform="rotate(137.996 43.799 46.048)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ay"
            d="M35.081 23.212l3.343.411-.362 2.954-3.344-.41.363-2.955z"
          />
        </defs>
        <clipPath id="az">
          <use overflow="visible" xlinkHref="#ay" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M35.029 23.464h3.318v.543h-3.318z"
          clipPath="url(#az)"
          transform="rotate(-173.004 36.688 23.736)"
        />
        <path
          fill="#d7d7d7"
          d="M34.956 24.056h3.319v.543h-3.319z"
          clipPath="url(#az)"
          transform="rotate(-173.004 36.615 24.327)"
        />
        <path
          fill="#d2d2d2"
          d="M34.884 24.646h3.319v.543h-3.319z"
          clipPath="url(#az)"
          transform="rotate(-173.004 36.543 24.918)"
        />
        <path
          fill="#cecece"
          d="M34.811 25.237h3.318v.543h-3.318z"
          clipPath="url(#az)"
          transform="rotate(-172.992 36.473 25.51)"
        />
        <path
          fill="#c9c9c9"
          d="M34.738 25.829h3.319v.543h-3.319z"
          clipPath="url(#az)"
          transform="rotate(-173.004 36.397 26.1)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aA"
            d="M35.873 19.935l3.268.815-.72 2.888-3.269-.815.721-2.888z"
          />
        </defs>
        <clipPath id="aB">
          <use overflow="visible" xlinkHref="#aA" />
        </clipPath>
        <path
          fill="#ededed"
          d="M35.746 20.371h3.325v.552h-3.325z"
          clipPath="url(#aB)"
          transform="rotate(-166.001 37.408 20.647)"
        />
        <path
          fill="#e9e9e9"
          d="M35.602 20.949h3.325v.552h-3.325z"
          clipPath="url(#aB)"
          transform="rotate(-166.001 37.264 21.225)"
        />
        <path
          fill="#e4e4e4"
          d="M35.458 21.527h3.325v.552h-3.325z"
          clipPath="url(#aB)"
          transform="rotate(-166.007 37.12 21.804)"
        />
        <path
          fill="#e0e0e0"
          d="M35.314 22.105h3.325v.552h-3.325z"
          clipPath="url(#aB)"
          transform="rotate(-166.001 36.976 22.38)"
        />
        <path
          fill="#dbdbdb"
          d="M35.17 22.682h3.325v.552H35.17z"
          clipPath="url(#aB)"
          transform="rotate(-166.001 36.832 22.958)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aC"
            d="M37.058 16.779l3.145 1.208-1.067 2.779-3.145-1.207 1.067-2.78z"
          />
        </defs>
        <clipPath id="aD">
          <use overflow="visible" xlinkHref="#aC" />
        </clipPath>
        <path
          fill="#fff"
          d="M36.836 17.383h3.378v.577h-3.378z"
          clipPath="url(#aD)"
          transform="rotate(-158.999 38.523 17.67)"
        />
        <path
          fill="#fbfbfb"
          d="M36.623 17.939H40v.577h-3.377z"
          clipPath="url(#aD)"
          transform="rotate(-158.999 38.31 18.227)"
        />
        <path
          fill="#f6f6f6"
          d="M36.409 18.495h3.378v.576h-3.378z"
          clipPath="url(#aD)"
          transform="rotate(-158.999 38.096 18.782)"
        />
        <path
          fill="#f2f2f2"
          d="M36.196 19.05h3.377v.577h-3.377z"
          clipPath="url(#aD)"
          transform="rotate(-158.999 37.882 19.338)"
        />
        <path
          fill="#ededed"
          d="M35.982 19.607h3.378v.576h-3.378z"
          clipPath="url(#aD)"
          transform="rotate(-158.999 37.67 19.893)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="aE" d="M68.908 26.561h3.368v2.977h-3.368v-2.977z" />
        </defs>
        <clipPath id="aF">
          <use overflow="visible" xlinkHref="#aE" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M68.908 26.619h3.36v.538h-3.36z"
          clipPath="url(#aF)"
        />
        <path
          fill="#c5c5c5"
          d="M68.908 27.214h3.36v.537h-3.36z"
          clipPath="url(#aF)"
        />
        <path
          fill="#c1c1c1"
          d="M68.908 27.81h3.36v.538h-3.36z"
          clipPath="url(#aF)"
        />
        <path
          fill="#bcbcbc"
          d="M68.908 28.405h3.36v.538h-3.36z"
          clipPath="url(#aF)"
        />
        <path
          fill="#b8b8b8"
          d="M68.908 29h3.36v.538h-3.36z"
          clipPath="url(#aF)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aG"
            d="M68.932 29.932l3.343-.411.363 2.955-3.344.41-.362-2.954z"
          />
        </defs>
        <clipPath id="aH">
          <use overflow="visible" xlinkHref="#aG" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M68.959 29.782h3.316v.541h-3.316z"
          clipPath="url(#aH)"
          transform="rotate(173.004 70.617 30.053)"
        />
        <path
          fill="#b3b3b3"
          d="M69.032 30.373h3.316v.541h-3.316z"
          clipPath="url(#aH)"
          transform="rotate(172.998 70.693 30.643)"
        />
        <path
          fill="#afafaf"
          d="M69.104 30.964h3.316v.541h-3.316z"
          clipPath="url(#aH)"
          transform="rotate(173.004 70.762 31.235)"
        />
        <path
          fill="#aaa"
          d="M69.177 31.555h3.316v.541h-3.316z"
          clipPath="url(#aH)"
          transform="rotate(173.004 70.835 31.826)"
        />
        <path
          fill="#a6a6a6"
          d="M69.25 32.146h3.316v.541H69.25z"
          clipPath="url(#aH)"
          transform="rotate(172.998 70.91 32.416)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aI"
            d="M69.366 33.275l3.269-.815.72 2.888-3.269.815-.72-2.888z"
          />
        </defs>
        <clipPath id="aJ">
          <use overflow="visible" xlinkHref="#aI" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M69.394 32.902h3.325v.557h-3.325z"
          clipPath="url(#aJ)"
          transform="rotate(166.001 71.056 33.181)"
        />
        <path
          fill="#a1a1a1"
          d="M69.538 33.48h3.324v.556h-3.324z"
          clipPath="url(#aJ)"
          transform="rotate(166.001 71.2 33.756)"
        />
        <path
          fill="#9d9d9d"
          d="M69.682 34.058h3.325v.556h-3.325z"
          clipPath="url(#aJ)"
          transform="rotate(166.001 71.344 34.337)"
        />
        <path
          fill="#989898"
          d="M69.826 34.636h3.325v.556h-3.325z"
          clipPath="url(#aJ)"
          transform="rotate(166.007 71.49 34.913)"
        />
        <path
          fill="#949494"
          d="M69.97 35.213h3.324v.556H69.97z"
          clipPath="url(#aJ)"
          transform="rotate(166.001 71.633 35.49)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aK"
            d="M70.205 36.54l3.146-1.207 1.066 2.779-3.145 1.207-1.067-2.779z"
          />
        </defs>
        <clipPath id="aL">
          <use overflow="visible" xlinkHref="#aK" />
        </clipPath>
        <path
          fill="#949494"
          d="M70.204 35.937h3.387v.571h-3.387z"
          clipPath="url(#aL)"
          transform="rotate(158.999 71.894 36.22)"
        />
        <path
          fill="#8f8f8f"
          d="M70.417 36.492h3.387v.572h-3.387z"
          clipPath="url(#aL)"
          transform="rotate(159.003 72.11 36.78)"
        />
        <path
          fill="#8b8b8b"
          d="M70.63 37.048h3.387v.572H70.63z"
          clipPath="url(#aL)"
          transform="rotate(158.999 72.32 37.333)"
        />
        <path
          fill="#878787"
          d="M70.844 37.604h3.386v.571h-3.386z"
          clipPath="url(#aL)"
          transform="rotate(158.991 72.54 37.893)"
        />
        <path
          fill="#828282"
          d="M71.058 38.16h3.386v.571h-3.386z"
          clipPath="url(#aL)"
          transform="rotate(158.999 72.747 38.446)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aM"
            d="M71.436 39.679l2.974-1.582 1.398 2.628-2.975 1.582-1.397-2.628z"
          />
        </defs>
        <clipPath id="aN">
          <use overflow="visible" xlinkHref="#aM" />
        </clipPath>
        <path
          fill="#828282"
          d="M71.368 38.81h3.383v.637h-3.383z"
          clipPath="url(#aN)"
          transform="rotate(152.006 73.058 39.13)"
        />
        <path
          fill="#7e7e7e"
          d="M71.648 39.337h3.382v.637h-3.382z"
          clipPath="url(#aN)"
          transform="rotate(151.994 73.338 39.655)"
        />
        <path
          fill="#797979"
          d="M71.927 39.862h3.382v.637h-3.382z"
          clipPath="url(#aN)"
          transform="rotate(151.997 73.62 40.183)"
        />
        <path
          fill="#757575"
          d="M72.206 40.388h3.382v.637h-3.382z"
          clipPath="url(#aN)"
          transform="rotate(151.997 73.9 40.71)"
        />
        <path
          fill="#707070"
          d="M72.486 40.914h3.382v.637h-3.382z"
          clipPath="url(#aN)"
          transform="rotate(151.994 74.176 41.232)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aO"
            d="M73.039 42.644l2.76-1.933 1.708 2.439-2.76 1.932-1.708-2.438z"
          />
        </defs>
        <clipPath id="aP">
          <use overflow="visible" xlinkHref="#aO" />
        </clipPath>
        <path
          fill="#707070"
          d="M72.906 41.625h3.369v.594h-3.369z"
          clipPath="url(#aP)"
          transform="rotate(144.997 74.593 41.923)"
        />
        <path
          fill="#6c6c6c"
          d="M73.247 42.113h3.37v.594h-3.37z"
          clipPath="url(#aP)"
          transform="rotate(145.015 74.93 42.41)"
        />
        <path
          fill="#676767"
          d="M73.589 42.6h3.369v.594h-3.369z"
          clipPath="url(#aP)"
          transform="rotate(144.997 75.276 42.899)"
        />
        <path
          fill="#636363"
          d="M73.931 43.088H77.3v.594h-3.369z"
          clipPath="url(#aP)"
          transform="rotate(144.997 75.618 43.387)"
        />
        <path
          fill="#5e5e5e"
          d="M74.272 43.576h3.369v.594h-3.369z"
          clipPath="url(#aP)"
          transform="rotate(144.997 75.96 43.874)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="aQ" d="M75.21 45.188l2.286-2.051 1.874 2.051h-4.16z" />
        </defs>
        <clipPath id="aR">
          <use overflow="visible" xlinkHref="#aQ" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M75.015 44.123h3.017v.539h-3.017z"
          clipPath="url(#aR)"
          transform="rotate(137.996 76.522 44.394)"
        />
        <path
          fill="#5a5a5a"
          d="M75.387 44.537h3.017v.539h-3.017z"
          clipPath="url(#aR)"
          transform="rotate(137.996 76.895 44.808)"
        />
        <path
          fill="#555"
          d="M75.76 44.951h3.017v.538H75.76z"
          clipPath="url(#aR)"
          transform="rotate(137.996 77.268 45.221)"
        />
        <path
          fill="#515151"
          d="M76.132 45.364h3.017v.539h-3.017z"
          clipPath="url(#aR)"
          transform="rotate(137.996 77.64 45.635)"
        />
        <path
          fill="#4d4d4d"
          d="M76.505 45.777h3.017v.539h-3.017z"
          clipPath="url(#aR)"
          transform="rotate(137.996 78.013 46.049)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aS"
            d="M69.294 23.212l3.344.411-.363 2.954-3.343-.41.362-2.955z"
          />
        </defs>
        <clipPath id="aT">
          <use overflow="visible" xlinkHref="#aS" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M69.243 23.464h3.319v.543h-3.319z"
          clipPath="url(#aT)"
          transform="rotate(-173.004 70.901 23.737)"
        />
        <path
          fill="#d7d7d7"
          d="M69.17 24.056h3.319v.543H69.17z"
          clipPath="url(#aT)"
          transform="rotate(-173.004 70.83 24.328)"
        />
        <path
          fill="#d2d2d2"
          d="M69.098 24.646h3.318v.543h-3.318z"
          clipPath="url(#aT)"
          transform="rotate(-172.998 70.76 24.917)"
        />
        <path
          fill="#cecece"
          d="M69.025 25.237h3.318v.543h-3.318z"
          clipPath="url(#aT)"
          transform="rotate(-172.992 70.69 25.51)"
        />
        <path
          fill="#c9c9c9"
          d="M68.952 25.829h3.319v.543h-3.319z"
          clipPath="url(#aT)"
          transform="rotate(-173.004 70.611 26.1)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aU"
            d="M70.086 19.935l3.269.815-.72 2.888-3.269-.815.72-2.888z"
          />
        </defs>
        <clipPath id="aV">
          <use overflow="visible" xlinkHref="#aU" />
        </clipPath>
        <path
          fill="#ededed"
          d="M69.959 20.371h3.325v.552h-3.325z"
          clipPath="url(#aV)"
          transform="rotate(-166.001 71.621 20.646)"
        />
        <path
          fill="#e9e9e9"
          d="M69.816 20.949h3.325v.553h-3.325z"
          clipPath="url(#aV)"
          transform="rotate(-166.001 71.477 21.226)"
        />
        <path
          fill="#e4e4e4"
          d="M69.672 21.527h3.325v.552h-3.325z"
          clipPath="url(#aV)"
          transform="rotate(-166.007 71.335 21.804)"
        />
        <path
          fill="#e0e0e0"
          d="M69.527 22.105h3.326v.552h-3.326z"
          clipPath="url(#aV)"
          transform="rotate(-166.001 71.19 22.382)"
        />
        <path
          fill="#dbdbdb"
          d="M69.384 22.682h3.325v.552h-3.325z"
          clipPath="url(#aV)"
          transform="rotate(-166.001 71.046 22.957)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="aW"
            d="M71.272 16.779l3.145 1.208-1.066 2.779-3.146-1.207 1.067-2.78z"
          />
        </defs>
        <clipPath id="aX">
          <use overflow="visible" xlinkHref="#aW" />
        </clipPath>
        <path
          fill="#fff"
          d="M71.049 17.383h3.379v.577h-3.379z"
          clipPath="url(#aX)"
          transform="rotate(-159.003 72.739 17.67)"
        />
        <path
          fill="#fbfbfb"
          d="M70.836 17.939h3.379v.577h-3.379z"
          clipPath="url(#aX)"
          transform="rotate(-159.003 72.526 18.226)"
        />
        <path
          fill="#f6f6f6"
          d="M70.623 18.495h3.378v.576h-3.378z"
          clipPath="url(#aX)"
          transform="rotate(-158.999 72.309 18.781)"
        />
        <path
          fill="#f2f2f2"
          d="M70.41 19.05h3.377v.577H70.41z"
          clipPath="url(#aX)"
          transform="rotate(-158.999 72.095 19.339)"
        />
        <path
          fill="#ededed"
          d="M70.196 19.607h3.378v.577h-3.378z"
          clipPath="url(#aX)"
          transform="rotate(-158.999 71.882 19.893)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="aY" d="M103.123 26.561h3.369v2.977h-3.369v-2.977z" />
        </defs>
        <clipPath id="aZ">
          <use overflow="visible" xlinkHref="#aY" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M103.123 26.619h3.359v.538h-3.359z"
          clipPath="url(#aZ)"
        />
        <path
          fill="#c5c5c5"
          d="M103.123 27.214h3.359v.537h-3.359z"
          clipPath="url(#aZ)"
        />
        <path
          fill="#c1c1c1"
          d="M103.123 27.81h3.359v.538h-3.359z"
          clipPath="url(#aZ)"
        />
        <path
          fill="#bcbcbc"
          d="M103.123 28.405h3.359v.538h-3.359z"
          clipPath="url(#aZ)"
        />
        <path
          fill="#b8b8b8"
          d="M103.123 29h3.359v.538h-3.359z"
          clipPath="url(#aZ)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ba"
            d="M103.146 29.932l3.344-.411.363 2.955-3.344.41-.363-2.954z"
          />
        </defs>
        <clipPath id="bb">
          <use overflow="visible" xlinkHref="#ba" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M103.173 29.782h3.317v.541h-3.317z"
          clipPath="url(#bb)"
          transform="rotate(173.004 104.831 30.055)"
        />
        <path
          fill="#b3b3b3"
          d="M103.246 30.373h3.317v.541h-3.317z"
          clipPath="url(#bb)"
          transform="rotate(172.998 104.908 30.645)"
        />
        <path
          fill="#afafaf"
          d="M103.318 30.964h3.317v.541h-3.317z"
          clipPath="url(#bb)"
          transform="rotate(173.004 104.976 31.237)"
        />
        <path
          fill="#aaa"
          d="M103.391 31.555h3.316v.541h-3.316z"
          clipPath="url(#bb)"
          transform="rotate(172.998 105.053 31.825)"
        />
        <path
          fill="#a6a6a6"
          d="M103.464 32.146h3.316v.541h-3.316z"
          clipPath="url(#bb)"
          transform="rotate(173.004 105.122 32.416)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bc"
            d="M103.581 33.275l3.268-.815.721 2.888-3.27.815-.719-2.888z"
          />
        </defs>
        <clipPath id="bd">
          <use overflow="visible" xlinkHref="#bc" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M103.607 32.902h3.325v.557h-3.325z"
          clipPath="url(#bd)"
          transform="rotate(166.007 105.272 33.179)"
        />
        <path
          fill="#a1a1a1"
          d="M103.752 33.48h3.325v.556h-3.325z"
          clipPath="url(#bd)"
          transform="rotate(166.007 105.416 33.757)"
        />
        <path
          fill="#9d9d9d"
          d="M103.896 34.058h3.324v.556h-3.324z"
          clipPath="url(#bd)"
          transform="rotate(166.001 105.559 34.335)"
        />
        <path
          fill="#989898"
          d="M104.041 34.636h3.323v.556h-3.323z"
          clipPath="url(#bd)"
          transform="rotate(166.001 105.701 34.914)"
        />
        <path
          fill="#949494"
          d="M104.184 35.213h3.325v.556h-3.325z"
          clipPath="url(#bd)"
          transform="rotate(166.007 105.848 35.49)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="be"
            d="M104.419 36.54l3.145-1.207 1.067 2.779-3.145 1.207-1.067-2.779z"
          />
        </defs>
        <clipPath id="bf">
          <use overflow="visible" xlinkHref="#be" />
        </clipPath>
        <path
          fill="#949494"
          d="M104.417 35.937h3.387v.572h-3.387z"
          clipPath="url(#bf)"
          transform="rotate(158.999 106.106 36.221)"
        />
        <path
          fill="#8f8f8f"
          d="M104.632 36.492h3.386v.571h-3.386z"
          clipPath="url(#bf)"
          transform="rotate(158.991 106.329 36.782)"
        />
        <path
          fill="#8b8b8b"
          d="M104.845 37.048h3.386v.572h-3.386z"
          clipPath="url(#bf)"
          transform="rotate(158.999 106.533 37.335)"
        />
        <path
          fill="#878787"
          d="M105.058 37.604h3.387v.571h-3.387z"
          clipPath="url(#bf)"
          transform="rotate(158.999 106.747 37.889)"
        />
        <path
          fill="#828282"
          d="M105.271 38.16h3.387v.572h-3.387z"
          clipPath="url(#bf)"
          transform="rotate(159.003 106.964 38.449)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bg"
            d="M105.65 39.679l2.975-1.582 1.397 2.628-2.975 1.582-1.397-2.628z"
          />
        </defs>
        <clipPath id="bh">
          <use overflow="visible" xlinkHref="#bg" />
        </clipPath>
        <path
          fill="#828282"
          d="M105.581 38.81h3.383v.637h-3.383z"
          clipPath="url(#bh)"
          transform="rotate(152.006 107.272 39.131)"
        />
        <path
          fill="#7e7e7e"
          d="M105.861 39.337h3.383v.637h-3.383z"
          clipPath="url(#bh)"
          transform="rotate(151.997 107.557 39.659)"
        />
        <path
          fill="#797979"
          d="M106.14 39.862h3.383v.637h-3.383z"
          clipPath="url(#bh)"
          transform="rotate(152.006 107.831 40.182)"
        />
        <path
          fill="#757575"
          d="M106.421 40.388h3.383v.637h-3.383z"
          clipPath="url(#bh)"
          transform="rotate(152.006 108.111 40.708)"
        />
        <path
          fill="#707070"
          d="M106.7 40.913h3.383v.637H106.7z"
          clipPath="url(#bh)"
          transform="rotate(152.006 108.39 41.234)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bi"
            d="M107.253 42.644l2.76-1.933 1.707 2.439-2.76 1.932-1.707-2.438z"
          />
        </defs>
        <clipPath id="bj">
          <use overflow="visible" xlinkHref="#bi" />
        </clipPath>
        <path
          fill="#707070"
          d="M107.12 41.625h3.369v.594h-3.369z"
          clipPath="url(#bj)"
          transform="rotate(144.997 108.809 41.923)"
        />
        <path
          fill="#6c6c6c"
          d="M107.462 42.113h3.369v.594h-3.369z"
          clipPath="url(#bj)"
          transform="rotate(145.005 109.147 42.408)"
        />
        <path
          fill="#676767"
          d="M107.803 42.6h3.369v.595h-3.369z"
          clipPath="url(#bj)"
          transform="rotate(144.997 109.492 42.899)"
        />
        <path
          fill="#636363"
          d="M108.145 43.088h3.369v.594h-3.369z"
          clipPath="url(#bj)"
          transform="rotate(144.997 109.833 43.387)"
        />
        <path
          fill="#5e5e5e"
          d="M108.486 43.576h3.369v.594h-3.369z"
          clipPath="url(#bj)"
          transform="rotate(144.997 110.175 43.874)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="bk" d="M109.425 45.188l2.285-2.051 1.874 2.051h-4.159z" />
        </defs>
        <clipPath id="bl">
          <use overflow="visible" xlinkHref="#bk" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M109.229 44.123h3.017v.538h-3.017z"
          clipPath="url(#bl)"
          transform="rotate(138.005 110.736 44.393)"
        />
        <path
          fill="#5a5a5a"
          d="M109.601 44.536h3.017v.539h-3.017z"
          clipPath="url(#bl)"
          transform="rotate(138.005 111.109 44.807)"
        />
        <path
          fill="#555"
          d="M109.974 44.95h3.017v.539h-3.017z"
          clipPath="url(#bl)"
          transform="rotate(138.005 111.482 45.22)"
        />
        <path
          fill="#515151"
          d="M110.347 45.364h3.017v.539h-3.017z"
          clipPath="url(#bl)"
          transform="rotate(137.996 111.855 45.636)"
        />
        <path
          fill="#4d4d4d"
          d="M110.72 45.777h3.017v.538h-3.017z"
          clipPath="url(#bl)"
          transform="rotate(138.005 112.227 46.048)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bm"
            d="M103.509 23.212l3.344.411-.363 2.954-3.344-.41.363-2.955z"
          />
        </defs>
        <clipPath id="bn">
          <use overflow="visible" xlinkHref="#bm" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M103.458 23.464h3.318v.543h-3.318z"
          clipPath="url(#bn)"
          transform="rotate(-172.998 105.12 23.734)"
        />
        <path
          fill="#d7d7d7"
          d="M103.385 24.056h3.319v.543h-3.319z"
          clipPath="url(#bn)"
          transform="rotate(-173.004 105.044 24.329)"
        />
        <path
          fill="#d2d2d2"
          d="M103.311 24.646h3.32v.543h-3.32z"
          clipPath="url(#bn)"
          transform="rotate(-173.004 104.97 24.917)"
        />
        <path
          fill="#cecece"
          d="M103.239 25.237h3.32v.543h-3.32z"
          clipPath="url(#bn)"
          transform="rotate(-172.998 104.901 25.51)"
        />
        <path
          fill="#c9c9c9"
          d="M103.166 25.829h3.319v.543h-3.319z"
          clipPath="url(#bn)"
          transform="rotate(-173.004 104.825 26.101)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bo"
            d="M104.3 19.935l3.27.815-.721 2.888-3.268-.815.719-2.888z"
          />
        </defs>
        <clipPath id="bp">
          <use overflow="visible" xlinkHref="#bo" />
        </clipPath>
        <path
          fill="#ededed"
          d="M104.174 20.371h3.325v.552h-3.325z"
          clipPath="url(#bp)"
          transform="rotate(-166.001 105.836 20.646)"
        />
        <path
          fill="#e9e9e9"
          d="M104.03 20.949h3.325v.552h-3.325z"
          clipPath="url(#bp)"
          transform="rotate(-166.001 105.692 21.224)"
        />
        <path
          fill="#e4e4e4"
          d="M103.886 21.527h3.325v.552h-3.325z"
          clipPath="url(#bp)"
          transform="rotate(-166.007 105.55 21.805)"
        />
        <path
          fill="#e0e0e0"
          d="M103.742 22.105h3.325v.552h-3.325z"
          clipPath="url(#bp)"
          transform="rotate(-166.001 105.403 22.38)"
        />
        <path
          fill="#dbdbdb"
          d="M103.598 22.682h3.325v.552h-3.325z"
          clipPath="url(#bp)"
          transform="rotate(-166.001 105.26 22.957)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bq"
            d="M105.486 16.779l3.145 1.208-1.067 2.779-3.145-1.207 1.067-2.78z"
          />
        </defs>
        <clipPath id="br">
          <use overflow="visible" xlinkHref="#bq" />
        </clipPath>
        <path
          fill="#fff"
          d="M105.264 17.383h3.378v.577h-3.378z"
          clipPath="url(#br)"
          transform="rotate(-158.999 106.948 17.67)"
        />
        <path
          fill="#fbfbfb"
          d="M105.051 17.939h3.377v.577h-3.377z"
          clipPath="url(#br)"
          transform="rotate(-158.991 106.745 18.225)"
        />
        <path
          fill="#f6f6f6"
          d="M104.838 18.495h3.378v.577h-3.378z"
          clipPath="url(#br)"
          transform="rotate(-158.999 106.522 18.78)"
        />
        <path
          fill="#f2f2f2"
          d="M104.623 19.051h3.379v.577h-3.379z"
          clipPath="url(#br)"
          transform="rotate(-159.003 106.313 19.337)"
        />
        <path
          fill="#ededed"
          d="M104.41 19.607h3.378v.576h-3.378z"
          clipPath="url(#br)"
          transform="rotate(-158.999 106.095 19.893)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bs"
            d="M149.827 42.891a24.424 24.424 0 0 0 2.444-3.851l2.861 1.816a24.552 24.552 0 0 1-1.145 1.977c-.477.75-.856 1.285-1.299 1.874l-2.861-1.816z"
          />
        </defs>
        <clipPath id="bt">
          <use overflow="visible" xlinkHref="#bs" />
        </clipPath>
        <path
          fill="#707070"
          d="M149.668 43.217h3.561v.641h-3.561z"
          clipPath="url(#bt)"
          transform="rotate(32 151.448 43.538)"
        />
        <path
          fill="#6f6f6f"
          d="M150.015 42.662h3.562v.64h-3.562z"
          clipPath="url(#bt)"
          transform="rotate(32 151.789 42.978)"
        />
        <path
          fill="#6e6e6e"
          d="M150.362 42.106h3.562v.64h-3.562z"
          clipPath="url(#bt)"
          transform="rotate(31.99 152.13 42.421)"
        />
        <path
          fill="#6d6d6d"
          d="M150.709 41.551h3.562v.641h-3.562z"
          clipPath="url(#bt)"
          transform="rotate(31.997 152.483 41.869)"
        />
        <path
          fill="#6b6b6b"
          d="M151.057 40.995h3.561v.641h-3.561z"
          clipPath="url(#bt)"
          transform="rotate(32.013 152.837 41.314)"
        />
        <path
          fill="#6a6a6a"
          d="M151.404 40.44h3.562v.641h-3.562z"
          clipPath="url(#bt)"
          transform="rotate(31.997 153.178 40.758)"
        />
        <path
          fill="#696969"
          d="M151.75 39.884h3.562v.641h-3.562z"
          clipPath="url(#bt)"
          transform="rotate(31.997 153.524 40.202)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bu"
            d="M152.268 39.042a24.321 24.321 0 0 0 1.679-4.241l3.152 1.248a24.828 24.828 0 0 1-.754 2.156 27.135 27.135 0 0 1-.926 2.084l-3.151-1.247z"
          />
        </defs>
        <clipPath id="bv">
          <use overflow="visible" xlinkHref="#bu" />
        </clipPath>
        <path
          fill="#696969"
          d="M152.267 39.072h3.503v.625h-3.503z"
          clipPath="url(#bv)"
          transform="rotate(21.001 153.999 39.376)"
        />
        <path
          fill="#686868"
          d="M152.503 38.459h3.503v.625h-3.503z"
          clipPath="url(#bv)"
          transform="rotate(21.001 154.234 38.763)"
        />
        <path
          fill="#676767"
          d="M152.738 37.846h3.502v.625h-3.502z"
          clipPath="url(#bv)"
          transform="rotate(21.001 154.509 38.168)"
        />
        <path
          fill="#656565"
          d="M152.973 37.233h3.504v.625h-3.504z"
          clipPath="url(#bv)"
          transform="rotate(20.997 154.708 37.534)"
        />
        <path
          fill="#646464"
          d="M153.208 36.619h3.502v.625h-3.502z"
          clipPath="url(#bv)"
          transform="rotate(21.001 154.98 36.942)"
        />
        <path
          fill="#636363"
          d="M153.444 36.007h3.504v.625h-3.504z"
          clipPath="url(#bv)"
          transform="rotate(20.997 155.179 36.308)"
        />
        <path
          fill="#626262"
          d="M153.679 35.393h3.502v.625h-3.502z"
          clipPath="url(#bv)"
          transform="rotate(21.001 155.45 35.715)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bw"
            d="M153.945 34.806c.414-1.411.729-3.06.855-4.48l3.329.635a24.22 24.22 0 0 1-.337 2.259 26.51 26.51 0 0 1-.518 2.221l-3.329-.635z"
          />
        </defs>
        <clipPath id="bx">
          <use overflow="visible" xlinkHref="#bw" />
        </clipPath>
        <path
          fill="#626262"
          d="M153.914 34.5h3.574v.63h-3.574z"
          clipPath="url(#bx)"
          transform="rotate(10.003 155.659 34.803)"
        />
        <path
          fill="#616161"
          d="M154.029 33.851h3.573v.63h-3.573z"
          clipPath="url(#bx)"
          transform="rotate(10.003 155.815 34.167)"
        />
        <path
          fill="#606060"
          d="M154.142 33.203h3.574v.63h-3.574z"
          clipPath="url(#bx)"
          transform="rotate(10.003 155.887 33.506)"
        />
        <path
          fill="#5e5e5e"
          d="M154.258 32.555h3.574v.63h-3.574z"
          clipPath="url(#bx)"
          transform="rotate(10.003 156.003 32.858)"
        />
        <path
          fill="#5d5d5d"
          d="M154.371 31.906h3.574v.63h-3.574z"
          clipPath="url(#bx)"
          transform="rotate(10.003 156.116 32.21)"
        />
        <path
          fill="#5c5c5c"
          d="M154.486 31.258h3.574v.63h-3.574z"
          clipPath="url(#bx)"
          transform="rotate(10.003 156.23 31.562)"
        />
        <path
          fill="#5b5b5b"
          d="M154.6 30.61h3.574v.63H154.6z"
          clipPath="url(#bx)"
          transform="rotate(10.003 156.345 30.914)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="by"
            d="M154.798 30.33a24.298 24.298 0 0 0 0-4.561h3.391c.062.711.092 1.391.092 2.283 0 .889-.035 1.543-.092 2.278h-3.391z"
          />
        </defs>
        <clipPath id="bz">
          <use overflow="visible" xlinkHref="#by" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M154.815 29.698h3.505v.611h-3.505z"
          clipPath="url(#bz)"
          transform="rotate(-.997 156.67 30.024)"
        />
        <path
          fill="#5a5a5a"
          d="M154.803 29.038h3.505v.611h-3.505z"
          clipPath="url(#bz)"
          transform="rotate(-.997 156.658 29.363)"
        />
        <path
          fill="#585858"
          d="M154.792 28.378h3.505v.611h-3.505z"
          clipPath="url(#bz)"
          transform="rotate(-.997 156.647 28.702)"
        />
        <path
          fill="#575757"
          d="M154.78 27.718h3.506v.611h-3.506z"
          clipPath="url(#bz)"
          transform="rotate(-1.003 156.948 28.093)"
        />
        <path
          fill="#565656"
          d="M154.768 27.059h3.506v.61h-3.506z"
          clipPath="url(#bz)"
          transform="rotate(-1.003 156.931 27.43)"
        />
        <path
          fill="#555"
          d="M154.757 26.399h3.506v.611h-3.506z"
          clipPath="url(#bz)"
          transform="rotate(-.997 156.572 26.713)"
        />
        <path
          fill="#545454"
          d="M154.745 25.739h3.506v.611h-3.506z"
          clipPath="url(#bz)"
          transform="rotate(-.997 156.56 26.052)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bA"
            d="M154.798 25.773a24.434 24.434 0 0 0-.855-4.48l3.33-.635c.195.687.352 1.35.519 2.225.167.874.255 1.523.337 2.255l-3.331.635z"
          />
        </defs>
        <clipPath id="bB">
          <use overflow="visible" xlinkHref="#bA" />
        </clipPath>
        <path
          fill="#545454"
          d="M154.645 24.81h3.555v.678h-3.555z"
          clipPath="url(#bB)"
          transform="rotate(-11 156.425 25.15)"
        />
        <path
          fill="#525252"
          d="M154.52 24.169h3.556v.678h-3.556z"
          clipPath="url(#bB)"
          transform="rotate(-11 156.257 24.506)"
        />
        <path
          fill="#515151"
          d="M154.396 23.527h3.556v.679h-3.556z"
          clipPath="url(#bB)"
          transform="rotate(-11 156.133 23.865)"
        />
        <path
          fill="#505050"
          d="M154.271 22.886h3.555v.679h-3.555z"
          clipPath="url(#bB)"
          transform="rotate(-11 156.051 23.226)"
        />
        <path
          fill="#4f4f4f"
          d="M154.147 22.245h3.554v.678h-3.554z"
          clipPath="url(#bB)"
          transform="rotate(-11.008 155.884 22.59)"
        />
        <path
          fill="#4e4e4e"
          d="M154.022 21.604h3.554v.678h-3.554z"
          clipPath="url(#bB)"
          transform="rotate(-10.993 155.775 21.941)"
        />
        <path
          fill="#4d4d4d"
          d="M153.897 20.963h3.556v.678h-3.556z"
          clipPath="url(#bB)"
          transform="rotate(-11.008 155.66 21.309)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bC"
            d="M147.208 10.294l1.803 1.867-2.119 2.046-1.803-1.866 2.119-2.047z"
          />
        </defs>
        <clipPath id="bD">
          <use overflow="visible" xlinkHref="#bC" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M144.855 11.241h2.938v.527h-2.938z"
          clipPath="url(#bD)"
          transform="rotate(136.005 146.323 11.503)"
        />
        <path
          fill="#515151"
          d="M145.216 11.615h2.937v.526h-2.937z"
          clipPath="url(#bD)"
          transform="rotate(135.988 146.685 11.88)"
        />
        <path
          fill="#555"
          d="M145.576 11.988h2.938v.527h-2.938z"
          clipPath="url(#bD)"
          transform="rotate(136.005 147.044 12.25)"
        />
        <path
          fill="#5a5a5a"
          d="M145.937 12.361h2.937v.526h-2.937z"
          clipPath="url(#bD)"
          transform="rotate(135.988 147.406 12.626)"
        />
        <path
          fill="#5e5e5e"
          d="M146.297 12.734h2.938v.527h-2.938z"
          clipPath="url(#bD)"
          transform="rotate(136.005 147.765 12.996)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bE"
            d="M149.25 12.44l1.562 2.071-2.354 1.774-1.561-2.072 2.353-1.773z"
          />
        </defs>
        <clipPath id="bF">
          <use overflow="visible" xlinkHref="#bE" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M146.765 13.3h2.904v.504h-2.904z"
          clipPath="url(#bF)"
          transform="rotate(143.003 148.21 13.551)"
        />
        <path
          fill="#636363"
          d="M147.077 13.714h2.905v.504h-2.905z"
          clipPath="url(#bF)"
          transform="rotate(143.006 148.53 13.965)"
        />
        <path
          fill="#676767"
          d="M147.389 14.128h2.905v.505h-2.905z"
          clipPath="url(#bF)"
          transform="rotate(143.016 148.84 14.38)"
        />
        <path
          fill="#6c6c6c"
          d="M147.702 14.543h2.904v.504h-2.904z"
          clipPath="url(#bF)"
          transform="rotate(143.003 149.147 14.795)"
        />
        <path
          fill="#707070"
          d="M148.014 14.957h2.904v.504h-2.904z"
          clipPath="url(#bF)"
          transform="rotate(142.997 149.465 15.21)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bG"
            d="M151.014 14.818l1.298 2.246-2.553 1.474-1.297-2.247 2.552-1.473z"
          />
        </defs>
        <clipPath id="bH">
          <use overflow="visible" xlinkHref="#bG" />
        </clipPath>
        <path
          fill="#707070"
          d="M148.405 15.556h2.92v.487h-2.92z"
          clipPath="url(#bH)"
          transform="rotate(149.994 149.862 15.802)"
        />
        <path
          fill="#757575"
          d="M148.665 16.006h2.92v.487h-2.92z"
          clipPath="url(#bH)"
          transform="rotate(149.994 150.122 16.251)"
        />
        <path
          fill="#797979"
          d="M148.925 16.455h2.918v.487h-2.918z"
          clipPath="url(#bH)"
          transform="rotate(149.994 150.381 16.698)"
        />
        <path
          fill="#7e7e7e"
          d="M149.183 16.904h2.92v.487h-2.92z"
          clipPath="url(#bH)"
          transform="rotate(149.994 150.64 17.15)"
        />
        <path
          fill="#828282"
          d="M149.442 17.353h2.92v.488h-2.92z"
          clipPath="url(#bH)"
          transform="rotate(150.012 150.908 17.6)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bI"
            d="M152.475 17.393l1.015 2.388-2.713 1.151-1.014-2.388 2.712-1.151z"
          />
        </defs>
        <clipPath id="bJ">
          <use overflow="visible" xlinkHref="#bI" />
        </clipPath>
        <path
          fill="#828282"
          d="M149.752 17.972h2.981v.487h-2.981z"
          clipPath="url(#bJ)"
          transform="rotate(156.999 151.244 18.22)"
        />
        <path
          fill="#878787"
          d="M149.956 18.45h2.98v.487h-2.98z"
          clipPath="url(#bJ)"
          transform="rotate(156.989 151.449 18.693)"
        />
        <path
          fill="#8b8b8b"
          d="M150.158 18.927h2.98v.487h-2.98z"
          clipPath="url(#bJ)"
          transform="rotate(156.999 151.647 19.172)"
        />
        <path
          fill="#8f8f8f"
          d="M150.361 19.405h2.981v.487h-2.981z"
          clipPath="url(#bJ)"
          transform="rotate(156.999 151.853 19.652)"
        />
        <path
          fill="#949494"
          d="M150.563 19.882h2.981v.487h-2.981z"
          clipPath="url(#bJ)"
          transform="rotate(156.999 152.055 20.13)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bK"
            d="M153.612 20.127l.716 2.494-2.833.812-.715-2.494 2.832-.812z"
          />
        </defs>
        <clipPath id="bL">
          <use overflow="visible" xlinkHref="#bK" />
        </clipPath>
        <path
          fill="#949494"
          d="M150.79 20.49h2.954v.551h-2.954z"
          clipPath="url(#bL)"
          transform="rotate(164.008 152.267 20.764)"
        />
        <path
          fill="#989898"
          d="M150.933 20.989h2.954v.551h-2.954z"
          clipPath="url(#bL)"
          transform="rotate(163.996 152.412 21.268)"
        />
        <path
          fill="#9d9d9d"
          d="M151.076 21.488h2.954v.551h-2.954z"
          clipPath="url(#bL)"
          transform="rotate(163.996 152.555 21.766)"
        />
        <path
          fill="#a1a1a1"
          d="M151.219 21.987h2.954v.551h-2.954z"
          clipPath="url(#bL)"
          transform="rotate(163.996 152.698 22.265)"
        />
        <path
          fill="#a6a6a6"
          d="M151.361 22.486h2.955v.551h-2.955z"
          clipPath="url(#bL)"
          transform="rotate(164.014 152.84 22.759)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bM"
            d="M154.407 22.979l.405 2.563-2.91.461-.405-2.563 2.91-.461z"
          />
        </defs>
        <clipPath id="bN">
          <use overflow="visible" xlinkHref="#bM" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M151.521 23.239h2.935v.488h-2.935z"
          clipPath="url(#bN)"
          transform="rotate(171.004 152.988 23.482)"
        />
        <path
          fill="#aaa"
          d="M151.603 23.752h2.935v.487h-2.935z"
          clipPath="url(#bN)"
          transform="rotate(170.991 153.06 23.996)"
        />
        <path
          fill="#afafaf"
          d="M151.684 24.264h2.935v.488h-2.935z"
          clipPath="url(#bN)"
          transform="rotate(171.004 153.15 24.507)"
        />
        <path
          fill="#b3b3b3"
          d="M151.766 24.776h2.935v.488h-2.935z"
          clipPath="url(#bN)"
          transform="rotate(171.004 153.232 25.02)"
        />
        <path
          fill="#b8b8b8"
          d="M151.846 25.289h2.935v.488h-2.935z"
          clipPath="url(#bN)"
          transform="rotate(171.004 153.313 25.532)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bO"
            d="M154.848 25.908l.091 2.592-2.945.103-.09-2.592 2.944-.103z"
          />
        </defs>
        <clipPath id="bP">
          <use overflow="visible" xlinkHref="#bO" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M151.911 25.909h2.978v.568h-2.978z"
          clipPath="url(#bP)"
          transform="rotate(178.015 153.4 26.191)"
        />
        <path
          fill="#bcbcbc"
          d="M151.928 26.427h2.978v.568h-2.978z"
          clipPath="url(#bP)"
          transform="rotate(178.015 153.417 26.71)"
        />
        <path
          fill="#c1c1c1"
          d="M151.947 26.946h2.978v.568h-2.978z"
          clipPath="url(#bP)"
          transform="rotate(178.015 153.436 27.228)"
        />
        <path
          fill="#c5c5c5"
          d="M151.965 27.464h2.978v.568h-2.978z"
          clipPath="url(#bP)"
          transform="rotate(178.015 153.454 27.747)"
        />
        <path
          fill="#c9c9c9"
          d="M151.983 27.983h2.978v.569h-2.978z"
          clipPath="url(#bP)"
          transform="rotate(178.015 153.471 28.27)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bQ"
            d="M154.929 28.868l-.226 2.584-2.936-.257.227-2.584 2.935.257z"
          />
        </defs>
        <clipPath id="bR">
          <use overflow="visible" xlinkHref="#bQ" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M151.966 28.731h2.922v.525h-2.922z"
          clipPath="url(#bR)"
          transform="rotate(-175.003 153.428 28.99)"
        />
        <path
          fill="#cecece"
          d="M151.922 29.247h2.921v.526h-2.921z"
          clipPath="url(#bR)"
          transform="rotate(-175.003 153.38 29.514)"
        />
        <path
          fill="#d2d2d2"
          d="M151.876 29.764h2.921v.525h-2.921z"
          clipPath="url(#bR)"
          transform="rotate(-175.003 153.337 30.026)"
        />
        <path
          fill="#d7d7d7"
          d="M151.83 30.281h2.922v.525h-2.922z"
          clipPath="url(#bR)"
          transform="rotate(-175.003 153.29 30.545)"
        />
        <path
          fill="#dbdbdb"
          d="M151.784 30.798h2.923v.525h-2.923z"
          clipPath="url(#bR)"
          transform="rotate(-175.003 153.246 31.063)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bS"
            d="M154.649 31.815l-.54 2.538-2.881-.613.539-2.537 2.882.612z"
          />
        </defs>
        <clipPath id="bT">
          <use overflow="visible" xlinkHref="#bS" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M151.678 31.536h2.919v.483h-2.919z"
          clipPath="url(#bT)"
          transform="rotate(-168 153.146 31.78)"
        />
        <path
          fill="#e0e0e0"
          d="M151.568 32.043h2.921v.483h-2.921z"
          clipPath="url(#bT)"
          transform="rotate(-168.007 153.024 32.282)"
        />
        <path
          fill="#e4e4e4"
          d="M151.461 32.551h2.92v.483h-2.92z"
          clipPath="url(#bT)"
          transform="rotate(-167.994 152.932 32.794)"
        />
        <path
          fill="#e9e9e9"
          d="M151.354 33.058h2.92v.483h-2.92z"
          clipPath="url(#bT)"
          transform="rotate(-168.007 152.807 33.302)"
        />
        <path
          fill="#ededed"
          d="M151.245 33.566h2.92v.483h-2.92z"
          clipPath="url(#bT)"
          transform="rotate(-168.007 152.699 33.81)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bU"
            d="M154.011 34.707l-.844 2.453-2.787-.959.846-2.453 2.785.959z"
          />
        </defs>
        <clipPath id="bV">
          <use overflow="visible" xlinkHref="#bU" />
        </clipPath>
        <path
          fill="#ededed"
          d="M151.071 34.149h2.927v.582h-2.927z"
          clipPath="url(#bV)"
          transform="rotate(-160.997 152.535 34.441)"
        />
        <path
          fill="#f2f2f2"
          d="M150.903 34.64h2.927v.581h-2.927z"
          clipPath="url(#bV)"
          transform="rotate(-161.005 152.36 34.928)"
        />
        <path
          fill="#f6f6f6"
          d="M150.733 35.13h2.927v.582h-2.927z"
          clipPath="url(#bV)"
          transform="rotate(-161.005 152.19 35.419)"
        />
        <path
          fill="#fbfbfb"
          d="M150.565 35.621h2.927v.582h-2.927z"
          clipPath="url(#bV)"
          transform="rotate(-160.997 152.029 35.913)"
        />
        <path
          fill="#fff"
          d="M150.395 36.111h2.927v.582h-2.927z"
          clipPath="url(#bV)"
          transform="rotate(-161.005 151.853 36.4)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="bW" d="M129.416.459h2.55v3.374h-2.55V.459z" />
        </defs>
        <clipPath id="bX">
          <use overflow="visible" xlinkHref="#bW" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M129.388.474h.538v3.36h-.538z"
          clipPath="url(#bX)"
        />
        <path
          fill="#a1a1a1"
          d="M129.899.474h.538v3.36h-.538z"
          clipPath="url(#bX)"
        />
        <path
          fill="#9d9d9d"
          d="M130.409.474h.538v3.36h-.538z"
          clipPath="url(#bX)"
        />
        <path
          fill="#989898"
          d="M130.919.474h.537v3.36h-.537z"
          clipPath="url(#bX)"
        />
        <path
          fill="#949494"
          d="M131.429.474h.537v3.36h-.537z"
          clipPath="url(#bX)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="bY"
            d="M126.54.744l2.536-.266.353 3.355-2.537.267-.352-3.356z"
          />
        </defs>
        <clipPath id="bZ">
          <use overflow="visible" xlinkHref="#bY" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M125.283 2.142h3.426v.45h-3.426z"
          clipPath="url(#bZ)"
          transform="rotate(83.996 126.996 2.365)"
        />
        <path
          fill="#b3b3b3"
          d="M125.79 2.088h3.426v.451h-3.426z"
          clipPath="url(#bZ)"
          transform="rotate(84.013 127.507 2.312)"
        />
        <path
          fill="#afafaf"
          d="M126.297 2.034h3.426v.452h-3.426z"
          clipPath="url(#bZ)"
          transform="rotate(84.013 128.015 2.26)"
        />
        <path
          fill="#aaa"
          d="M126.805 1.981h3.426v.451h-3.426z"
          clipPath="url(#bZ)"
          transform="rotate(83.996 128.517 2.204)"
        />
        <path
          fill="#a6a6a6"
          d="M127.312 1.928h3.426v.451h-3.426z"
          clipPath="url(#bZ)"
          transform="rotate(83.996 129.025 2.151)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ca"
            d="M123.708 1.328l2.495-.531.701 3.301-2.494.53-.702-3.3z"
          />
        </defs>
        <clipPath id="cb">
          <use overflow="visible" xlinkHref="#ca" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M122.6 2.653h3.406v.513H122.6z"
          clipPath="url(#cb)"
          transform="rotate(78.018 124.307 2.91)"
        />
        <path
          fill="#c5c5c5"
          d="M123.099 2.547h3.407v.512h-3.407z"
          clipPath="url(#cb)"
          transform="rotate(77.989 124.802 2.801)"
        />
        <path
          fill="#c1c1c1"
          d="M123.598 2.441h3.406v.512h-3.406z"
          clipPath="url(#cb)"
          transform="rotate(78.007 125.294 2.694)"
        />
        <path
          fill="#bcbcbc"
          d="M124.097 2.335h3.407v.512h-3.407z"
          clipPath="url(#cb)"
          transform="rotate(77.989 125.8 2.59)"
        />
        <path
          fill="#b8b8b8"
          d="M124.596 2.229h3.406v.512h-3.406z"
          clipPath="url(#cb)"
          transform="rotate(78.007 126.292 2.481)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cc"
            d="M120.954 2.204l2.425-.788 1.042 3.209-2.425.788-1.042-3.209z"
          />
        </defs>
        <clipPath id="cd">
          <use overflow="visible" xlinkHref="#cc" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M120.044 3.485h3.397v.45h-3.397z"
          clipPath="url(#cd)"
          transform="rotate(72.002 121.735 3.708)"
        />
        <path
          fill="#d7d7d7"
          d="M120.529 3.326h3.397v.451h-3.397z"
          clipPath="url(#cd)"
          transform="rotate(71.994 122.235 3.555)"
        />
        <path
          fill="#d2d2d2"
          d="M121.014 3.169h3.396v.451h-3.396z"
          clipPath="url(#cd)"
          transform="rotate(72.013 122.716 3.395)"
        />
        <path
          fill="#cecece"
          d="M121.499 3.011h3.397v.451h-3.397z"
          clipPath="url(#cd)"
          transform="rotate(72.002 123.19 3.234)"
        />
        <path
          fill="#c9c9c9"
          d="M121.984 2.853h3.396v.452h-3.396z"
          clipPath="url(#cd)"
          transform="rotate(72.013 123.686 3.08)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ce"
            d="M118.306 3.364l2.329-1.038 1.372 3.082-2.33 1.037-1.371-3.081z"
          />
        </defs>
        <clipPath id="cf">
          <use overflow="visible" xlinkHref="#ce" />
        </clipPath>
        <path
          fill="#ededed"
          d="M117.531 4.542h3.344v.571h-3.344z"
          clipPath="url(#cf)"
          transform="rotate(65.996 119.203 4.825)"
        />
        <path
          fill="#e9e9e9"
          d="M117.997 4.334h3.344v.571h-3.344z"
          clipPath="url(#cf)"
          transform="rotate(65.996 119.669 4.618)"
        />
        <path
          fill="#e4e4e4"
          d="M118.464 4.127h3.344v.571h-3.344z"
          clipPath="url(#cf)"
          transform="rotate(66.009 120.139 4.414)"
        />
        <path
          fill="#e0e0e0"
          d="M118.929 3.919h3.344v.571h-3.344z"
          clipPath="url(#cf)"
          transform="rotate(65.996 120.6 4.202)"
        />
        <path
          fill="#dbdbdb"
          d="M119.395 3.712h3.344v.571h-3.344z"
          clipPath="url(#cf)"
          transform="rotate(66.009 121.07 3.999)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cg"
            d="M115.792 4.793l2.209-1.275 1.688 2.922-2.209 1.275-1.688-2.922z"
          />
        </defs>
        <clipPath id="ch">
          <use overflow="visible" xlinkHref="#cg" />
        </clipPath>
        <path
          fill="#fff"
          d="M115.145 5.857h3.403v.519h-3.403z"
          clipPath="url(#ch)"
          transform="rotate(60.007 116.844 6.117)"
        />
        <path
          fill="#fbfbfb"
          d="M115.586 5.602h3.403v.519h-3.403z"
          clipPath="url(#ch)"
          transform="rotate(60.007 117.285 5.862)"
        />
        <path
          fill="#f6f6f6"
          d="M116.028 5.347h3.403v.519h-3.403z"
          clipPath="url(#ch)"
          transform="rotate(60.007 117.728 5.607)"
        />
        <path
          fill="#f2f2f2"
          d="M116.471 5.092h3.403v.518h-3.403z"
          clipPath="url(#ch)"
          transform="rotate(59.987 118.175 5.352)"
        />
        <path
          fill="#ededed"
          d="M116.912 4.837h3.403v.519h-3.403z"
          clipPath="url(#ch)"
          transform="rotate(60.007 118.611 5.097)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ci"
            d="M132.306.478l2.537.266-.352 3.356-2.536-.267.351-3.355z"
          />
        </defs>
        <clipPath id="cj">
          <use overflow="visible" xlinkHref="#ci" />
        </clipPath>
        <path
          fill="#949494"
          d="M130.731 1.964h3.327v.484h-3.327z"
          clipPath="url(#cj)"
          transform="rotate(95.998 132.395 2.204)"
        />
        <path
          fill="#8f8f8f"
          d="M131.237 2.017h3.328v.485h-3.328z"
          clipPath="url(#cj)"
          transform="rotate(95.998 132.9 2.256)"
        />
        <path
          fill="#8b8b8b"
          d="M131.745 2.07h3.327v.485h-3.327z"
          clipPath="url(#cj)"
          transform="rotate(95.998 133.41 2.31)"
        />
        <path
          fill="#878787"
          d="M132.253 2.124h3.328v.485h-3.328z"
          clipPath="url(#cj)"
          transform="rotate(95.998 133.916 2.363)"
        />
        <path
          fill="#828282"
          d="M132.76 2.177h3.327v.485h-3.327z"
          clipPath="url(#cj)"
          transform="rotate(95.998 134.424 2.417)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ck"
            d="M135.179.797l2.496.531-.702 3.3-2.495-.53.701-3.301z"
          />
        </defs>
        <clipPath id="cl">
          <use overflow="visible" xlinkHref="#ck" />
        </clipPath>
        <path
          fill="#828282"
          d="M133.44 2.306h3.343v.436h-3.343z"
          clipPath="url(#cl)"
          transform="rotate(102.006 135.114 2.524)"
        />
        <path
          fill="#7e7e7e"
          d="M133.94 2.412h3.342v.435h-3.342z"
          clipPath="url(#cl)"
          transform="rotate(102.006 135.616 2.632)"
        />
        <path
          fill="#797979"
          d="M134.438 2.518h3.342v.435h-3.342z"
          clipPath="url(#cl)"
          transform="rotate(101.993 136.104 2.733)"
        />
        <path
          fill="#757575"
          d="M134.938 2.624h3.343v.435h-3.343z"
          clipPath="url(#cl)"
          transform="rotate(102.006 136.612 2.842)"
        />
        <path
          fill="#707070"
          d="M135.436 2.73h3.342v.435h-3.342z"
          clipPath="url(#cl)"
          transform="rotate(101.988 137.109 2.95)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cm"
            d="M138.003 1.416l2.426.788-1.043 3.209-2.425-.788 1.042-3.209z"
          />
        </defs>
        <clipPath id="cn">
          <use overflow="visible" xlinkHref="#cm" />
        </clipPath>
        <path
          fill="#707070"
          d="M136.035 2.839h3.362v.527h-3.362z"
          clipPath="url(#cn)"
          transform="rotate(107.987 137.72 3.102)"
        />
        <path
          fill="#6c6c6c"
          d="M136.519 2.996h3.362v.527h-3.362z"
          clipPath="url(#cn)"
          transform="rotate(108.006 138.2 3.257)"
        />
        <path
          fill="#676767"
          d="M137.005 3.154h3.361v.527h-3.361z"
          clipPath="url(#cn)"
          transform="rotate(108.006 138.689 3.418)"
        />
        <path
          fill="#636363"
          d="M137.489 3.311h3.362v.528h-3.362z"
          clipPath="url(#cn)"
          transform="rotate(108.006 139.171 3.572)"
        />
        <path
          fill="#5e5e5e"
          d="M137.974 3.469h3.362v.527h-3.362z"
          clipPath="url(#cn)"
          transform="rotate(108.006 139.656 3.73)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="co"
            d="M140.748 2.326l2.33 1.038-1.373 3.081-2.33-1.037 1.373-3.082z"
          />
        </defs>
        <clipPath id="cp">
          <use overflow="visible" xlinkHref="#co" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M138.647 3.777h3.329v.451h-3.329z"
          clipPath="url(#cp)"
          transform="rotate(113.991 140.311 4)"
        />
        <path
          fill="#5a5a5a"
          d="M139.113 3.984h3.329v.453h-3.329z"
          clipPath="url(#cp)"
          transform="rotate(114.004 140.78 4.21)"
        />
        <path
          fill="#555"
          d="M139.579 4.192h3.329v.452h-3.329z"
          clipPath="url(#cp)"
          transform="rotate(114.004 141.245 4.418)"
        />
        <path
          fill="#515151"
          d="M140.045 4.399h3.329v.452h-3.329z"
          clipPath="url(#cp)"
          transform="rotate(113.991 141.709 4.623)"
        />
        <path
          fill="#4d4d4d"
          d="M140.51 4.606h3.33v.453h-3.33z"
          clipPath="url(#cp)"
          transform="rotate(114.023 142.173 4.831)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cq"
            d="M116.689 8.285l.923-.623 1.647 2.443-.923.623-1.647-2.443z"
          />
        </defs>
        <clipPath id="cr">
          <use overflow="visible" xlinkHref="#cq" />
        </clipPath>
        <path
          fill="#707070"
          d="M116.107 9.32h2.946v.282h-2.946z"
          clipPath="url(#cr)"
          transform="rotate(56.006 117.577 9.458)"
        />
        <path
          fill="#6e6e6e"
          d="M116.292 9.194h2.946v.282h-2.946z"
          clipPath="url(#cr)"
          transform="rotate(55.998 117.77 9.337)"
        />
        <path
          fill="#6d6d6d"
          d="M116.476 9.07h2.946v.282h-2.946z"
          clipPath="url(#cr)"
          transform="rotate(55.998 117.954 9.212)"
        />
        <path
          fill="#6b6b6b"
          d="M116.661 8.946h2.946v.283h-2.946z"
          clipPath="url(#cr)"
          transform="rotate(56.006 118.13 9.085)"
        />
        <path
          fill="#696969"
          d="M116.845 8.822h2.947v.282h-2.947z"
          clipPath="url(#cr)"
          transform="rotate(55.986 118.324 8.966)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cs"
            d="M117.743 7.579l.954-.573 1.517 2.525-.954.573-1.517-2.525z"
          />
        </defs>
        <clipPath id="ct">
          <use overflow="visible" xlinkHref="#cs" />
        </clipPath>
        <path
          fill="#696969"
          d="M117.09 8.654h2.973v.252h-2.973z"
          clipPath="url(#ct)"
          transform="rotate(59.003 118.579 8.782)"
        />
        <path
          fill="#676767"
          d="M117.281 8.54h2.974v.252h-2.974z"
          clipPath="url(#ct)"
          transform="rotate(58.99 118.761 8.663)"
        />
        <path
          fill="#656565"
          d="M117.472 8.426h2.974v.251h-2.974z"
          clipPath="url(#ct)"
          transform="rotate(58.99 118.952 8.548)"
        />
        <path
          fill="#646464"
          d="M117.663 8.31h2.973v.252h-2.973z"
          clipPath="url(#ct)"
          transform="rotate(59.003 119.15 8.438)"
        />
        <path
          fill="#626262"
          d="M117.854 8.195h2.973v.252h-2.973z"
          clipPath="url(#ct)"
          transform="rotate(59.003 119.342 8.324)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cu"
            d="M118.832 6.929l.982-.522 1.384 2.601-.984.523-1.382-2.602z"
          />
        </defs>
        <clipPath id="cv">
          <use overflow="visible" xlinkHref="#cu" />
        </clipPath>
        <path
          fill="#626262"
          d="M118.099 8.037h3.012v.225h-3.012z"
          clipPath="url(#cv)"
          transform="rotate(61.997 119.609 8.15)"
        />
        <path
          fill="#606060"
          d="M118.296 7.933h3.012v.225h-3.012z"
          clipPath="url(#cv)"
          transform="rotate(61.997 119.805 8.045)"
        />
        <path
          fill="#5e5e5e"
          d="M118.492 7.828h3.012v.225h-3.012z"
          clipPath="url(#cv)"
          transform="rotate(61.997 120.002 7.94)"
        />
        <path
          fill="#5d5d5d"
          d="M118.689 7.723h3.012v.225h-3.012z"
          clipPath="url(#cv)"
          transform="rotate(61.997 120.199 7.836)"
        />
        <path
          fill="#5b5b5b"
          d="M118.886 7.619h3.012v.225h-3.012z"
          clipPath="url(#cv)"
          transform="rotate(61.997 120.395 7.732)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cw"
            d="M119.953 6.338l1.009-.471 1.246 2.67-1.009.471-1.246-2.67z"
          />
        </defs>
        <clipPath id="cx">
          <use overflow="visible" xlinkHref="#cw" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M119.191 7.507h2.939v.261h-2.939z"
          clipPath="url(#cx)"
          transform="rotate(65.001 120.663 7.637)"
        />
        <path
          fill="#595959"
          d="M119.393 7.413h2.939v.261h-2.939z"
          clipPath="url(#cx)"
          transform="rotate(65.001 120.865 7.542)"
        />
        <path
          fill="#575757"
          d="M119.595 7.318h2.939v.261h-2.939z"
          clipPath="url(#cx)"
          transform="rotate(64.996 121.065 7.45)"
        />
        <path
          fill="#555"
          d="M119.796 7.225h2.939v.261h-2.939z"
          clipPath="url(#cx)"
          transform="rotate(65.001 121.268 7.354)"
        />
        <path
          fill="#545454"
          d="M119.998 7.13h2.939v.261h-2.939z"
          clipPath="url(#cx)"
          transform="rotate(64.996 121.467 7.263)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cy"
            d="M121.105 5.806l1.031-.417 1.104 2.731-1.032.417-1.103-2.731z"
          />
        </defs>
        <clipPath id="cz">
          <use overflow="visible" xlinkHref="#cy" />
        </clipPath>
        <path
          fill="#545454"
          d="M120.244 6.99h2.995v.24h-2.995z"
          clipPath="url(#cz)"
          transform="rotate(68.019 121.741 7.108)"
        />
        <path
          fill="#525252"
          d="M120.451 6.907h2.996v.239h-2.996z"
          clipPath="url(#cz)"
          transform="rotate(68.007 121.948 7.022)"
        />
        <path
          fill="#505050"
          d="M120.657 6.824h2.996v.239h-2.996z"
          clipPath="url(#cz)"
          transform="rotate(67.988 122.154 6.942)"
        />
        <path
          fill="#4e4e4e"
          d="M120.863 6.74h2.995v.24h-2.995z"
          clipPath="url(#cz)"
          transform="rotate(68.019 122.36 6.858)"
        />
        <path
          fill="#4d4d4d"
          d="M121.07 6.657h2.995v.239h-2.995z"
          clipPath="url(#cz)"
          transform="rotate(68 122.567 6.777)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cA"
            d="M115.613 42.891a24.274 24.274 0 0 0 2.443-3.851l2.863 1.816a24.89 24.89 0 0 1-1.146 1.977c-.477.75-.856 1.285-1.299 1.874l-2.861-1.816z"
          />
        </defs>
        <clipPath id="cB">
          <use overflow="visible" xlinkHref="#cA" />
        </clipPath>
        <path
          fill="#707070"
          d="M115.455 43.217h3.562v.641h-3.562z"
          clipPath="url(#cB)"
          transform="rotate(31.997 117.23 43.535)"
        />
        <path
          fill="#6f6f6f"
          d="M115.801 42.662h3.562v.641h-3.562z"
          clipPath="url(#cB)"
          transform="rotate(32 117.576 42.979)"
        />
        <path
          fill="#6e6e6e"
          d="M116.149 42.106h3.561v.641h-3.561z"
          clipPath="url(#cB)"
          transform="rotate(32 117.929 42.427)"
        />
        <path
          fill="#6d6d6d"
          d="M116.496 41.551h3.562v.64h-3.562z"
          clipPath="url(#cB)"
          transform="rotate(31.997 118.271 41.869)"
        />
        <path
          fill="#6b6b6b"
          d="M116.842 40.995h3.563v.641h-3.563z"
          clipPath="url(#cB)"
          transform="rotate(31.997 118.613 41.31)"
        />
        <path
          fill="#6a6a6a"
          d="M117.19 40.44h3.562v.641h-3.562z"
          clipPath="url(#cB)"
          transform="rotate(31.997 118.965 40.758)"
        />
        <path
          fill="#696969"
          d="M117.537 39.884h3.562v.64h-3.562z"
          clipPath="url(#cB)"
          transform="rotate(31.997 119.312 40.202)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cC"
            d="M118.054 39.042a24.405 24.405 0 0 0 1.68-4.241l3.151 1.248a24.861 24.861 0 0 1-.755 2.156 27.168 27.168 0 0 1-.924 2.084l-3.152-1.247z"
          />
        </defs>
        <clipPath id="cD">
          <use overflow="visible" xlinkHref="#cC" />
        </clipPath>
        <path
          fill="#696969"
          d="M118.054 39.072h3.503v.625h-3.503z"
          clipPath="url(#cD)"
          transform="rotate(21.001 119.79 39.377)"
        />
        <path
          fill="#686868"
          d="M118.288 38.458h3.502v.625h-3.502z"
          clipPath="url(#cD)"
          transform="rotate(21.001 120.055 38.78)"
        />
        <path
          fill="#676767"
          d="M118.524 37.846h3.504v.624h-3.504z"
          clipPath="url(#cD)"
          transform="rotate(20.997 120.263 38.149)"
        />
        <path
          fill="#656565"
          d="M118.759 37.232h3.502v.625h-3.502z"
          clipPath="url(#cD)"
          transform="rotate(21.001 120.525 37.554)"
        />
        <path
          fill="#646464"
          d="M118.995 36.62h3.504v.624h-3.504z"
          clipPath="url(#cD)"
          transform="rotate(20.997 120.734 36.923)"
        />
        <path
          fill="#636363"
          d="M119.23 36.006h3.502v.625h-3.502z"
          clipPath="url(#cD)"
          transform="rotate(21.001 120.996 36.327)"
        />
        <path
          fill="#626262"
          d="M119.465 35.394h3.504v.624h-3.504z"
          clipPath="url(#cD)"
          transform="rotate(20.997 121.204 35.697)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cE"
            d="M119.732 34.806a24.33 24.33 0 0 0 .854-4.48l3.33.635c-.072.71-.17 1.384-.337 2.259-.167.874-.323 1.51-.518 2.221l-3.329-.635z"
          />
        </defs>
        <clipPath id="cF">
          <use overflow="visible" xlinkHref="#cE" />
        </clipPath>
        <path
          fill="#626262"
          d="M119.7 34.5h3.574v.63H119.7z"
          clipPath="url(#cF)"
          transform="rotate(10.003 121.454 34.804)"
        />
        <path
          fill="#616161"
          d="M119.814 33.851h3.574v.63h-3.574z"
          clipPath="url(#cF)"
          transform="rotate(10.003 121.569 34.155)"
        />
        <path
          fill="#606060"
          d="M119.928 33.203h3.574v.63h-3.574z"
          clipPath="url(#cF)"
          transform="rotate(10.003 121.683 33.507)"
        />
        <path
          fill="#5e5e5e"
          d="M120.043 32.555h3.574v.63h-3.574z"
          clipPath="url(#cF)"
          transform="rotate(10.003 121.797 32.859)"
        />
        <path
          fill="#5d5d5d"
          d="M120.157 31.906h3.575v.63h-3.575z"
          clipPath="url(#cF)"
          transform="rotate(10.003 121.95 32.211)"
        />
        <path
          fill="#5c5c5c"
          d="M120.271 31.258h3.574v.63h-3.574z"
          clipPath="url(#cF)"
          transform="rotate(10.003 122.025 31.563)"
        />
        <path
          fill="#5b5b5b"
          d="M120.386 30.61h3.574v.63h-3.574z"
          clipPath="url(#cF)"
          transform="rotate(10.003 122.14 30.915)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cG"
            d="M120.585 30.33a24.468 24.468 0 0 0 0-4.561h3.389c.062.711.093 1.391.093 2.283 0 .889-.034 1.543-.093 2.278h-3.389z"
          />
        </defs>
        <clipPath id="cH">
          <use overflow="visible" xlinkHref="#cG" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M120.6 29.698h3.506v.611H120.6z"
          clipPath="url(#cH)"
          transform="rotate(-.997 122.4 30.013)"
        />
        <path
          fill="#5a5a5a"
          d="M120.589 29.038h3.506v.611h-3.506z"
          clipPath="url(#cH)"
          transform="rotate(-.997 122.388 29.352)"
        />
        <path
          fill="#585858"
          d="M120.578 28.378h3.505v.611h-3.505z"
          clipPath="url(#cH)"
          transform="rotate(-.997 122.411 28.703)"
        />
        <path
          fill="#575757"
          d="M120.566 27.718h3.505v.611h-3.505z"
          clipPath="url(#cH)"
          transform="rotate(-1.009 121.976 27.949)"
        />
        <path
          fill="#565656"
          d="M120.554 27.059h3.505v.61h-3.505z"
          clipPath="url(#cH)"
          transform="rotate(-1.009 121.964 27.29)"
        />
        <path
          fill="#555"
          d="M120.543 26.399h3.504v.611h-3.504z"
          clipPath="url(#cH)"
          transform="rotate(-.997 122.411 26.726)"
        />
        <path
          fill="#545454"
          d="M120.531 25.739h3.505v.611h-3.505z"
          clipPath="url(#cH)"
          transform="rotate(-.997 122.365 26.059)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cI"
            d="M120.583 25.773a24.266 24.266 0 0 0-.854-4.48l3.329-.635c.194.687.352 1.35.519 2.225.166.874.256 1.523.336 2.255l-3.33.635z"
          />
        </defs>
        <clipPath id="cJ">
          <use overflow="visible" xlinkHref="#cI" />
        </clipPath>
        <path
          fill="#545454"
          d="M120.431 24.81h3.555v.679h-3.555z"
          clipPath="url(#cJ)"
          transform="rotate(-11 122.21 25.15)"
        />
        <path
          fill="#525252"
          d="M120.307 24.169h3.554v.678h-3.554z"
          clipPath="url(#cJ)"
          transform="rotate(-11.008 122.052 24.51)"
        />
        <path
          fill="#515151"
          d="M120.182 23.528h3.555v.678h-3.555z"
          clipPath="url(#cJ)"
          transform="rotate(-11 121.961 23.867)"
        />
        <path
          fill="#505050"
          d="M120.057 22.886h3.554v.678h-3.554z"
          clipPath="url(#cJ)"
          transform="rotate(-10.993 121.815 23.223)"
        />
        <path
          fill="#4f4f4f"
          d="M119.932 22.245h3.556v.679h-3.556z"
          clipPath="url(#cJ)"
          transform="rotate(-11 121.678 22.582)"
        />
        <path
          fill="#4e4e4e"
          d="M119.808 21.604h3.555v.678h-3.555z"
          clipPath="url(#cJ)"
          transform="rotate(-11 121.588 21.943)"
        />
        <path
          fill="#4d4d4d"
          d="M119.683 20.962h3.555v.678h-3.555z"
          clipPath="url(#cJ)"
          transform="rotate(-11.008 121.482 21.309)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cK"
            d="M138.203 51.07l1.192-.422 1.124 3.176-1.191.422-1.125-3.176z"
          />
        </defs>
        <clipPath id="cL">
          <use overflow="visible" xlinkHref="#cK" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M137.192 52.494h3.36v.265h-3.36z"
          clipPath="url(#cL)"
          transform="rotate(71.003 138.88 52.63)"
        />
        <path
          fill="#4e4e4e"
          d="M137.437 52.41h3.361v.264h-3.361z"
          clipPath="url(#cL)"
          transform="rotate(70.987 139.11 52.541)"
        />
        <path
          fill="#505050"
          d="M137.682 52.325h3.36v.264h-3.36z"
          clipPath="url(#cL)"
          transform="rotate(71.003 139.368 52.461)"
        />
        <path
          fill="#525252"
          d="M137.927 52.241h3.361v.265h-3.361z"
          clipPath="url(#cL)"
          transform="rotate(71.005 139.601 52.374)"
        />
        <path
          fill="#545454"
          d="M138.171 52.157h3.36v.264h-3.36z"
          clipPath="url(#cL)"
          transform="rotate(71.003 139.858 52.293)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cM"
            d="M139.398 50.647l1.169-.484 1.289 3.112-1.168.484-1.29-3.112z"
          />
        </defs>
        <clipPath id="cN">
          <use overflow="visible" xlinkHref="#cM" />
        </clipPath>
        <path
          fill="#545454"
          d="M138.451 52.021h3.42v.213h-3.42z"
          clipPath="url(#cN)"
          transform="rotate(68 140.156 52.124)"
        />
        <path
          fill="#555"
          d="M138.691 51.925h3.42v.213h-3.42z"
          clipPath="url(#cN)"
          transform="rotate(68 140.396 52.027)"
        />
        <path
          fill="#575757"
          d="M138.931 51.828h3.42v.213h-3.42z"
          clipPath="url(#cN)"
          transform="rotate(68 140.64 51.936)"
        />
        <path
          fill="#595959"
          d="M139.171 51.731h3.42v.213h-3.42z"
          clipPath="url(#cN)"
          transform="rotate(68 140.876 51.833)"
        />
        <path
          fill="#5b5b5b"
          d="M139.411 51.634h3.42v.213h-3.42z"
          clipPath="url(#cN)"
          transform="rotate(68.013 141.116 51.738)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cO"
            d="M140.57 50.161l1.141-.544 1.451 3.04-1.141.545-1.451-3.041z"
          />
        </defs>
        <clipPath id="cP">
          <use overflow="visible" xlinkHref="#cO" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M139.741 51.521h3.361v.212h-3.361z"
          clipPath="url(#cP)"
          transform="rotate(65.008 141.42 51.628)"
        />
        <path
          fill="#5d5d5d"
          d="M139.976 51.411h3.361v.212h-3.361z"
          clipPath="url(#cP)"
          transform="rotate(65.008 141.654 51.518)"
        />
        <path
          fill="#5e5e5e"
          d="M140.21 51.302h3.361v.212h-3.361z"
          clipPath="url(#cP)"
          transform="rotate(64.996 141.886 51.405)"
        />
        <path
          fill="#606060"
          d="M140.445 51.193h3.361v.211h-3.361z"
          clipPath="url(#cP)"
          transform="rotate(64.996 142.12 51.295)"
        />
        <path
          fill="#626262"
          d="M140.68 51.083h3.362v.211h-3.362z"
          clipPath="url(#cP)"
          transform="rotate(64.982 142.354 51.185)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cQ"
            d="M141.714 49.615l1.112-.604 1.608 2.961-1.111.604-1.609-2.961z"
          />
        </defs>
        <clipPath id="cR">
          <use overflow="visible" xlinkHref="#cQ" />
        </clipPath>
        <path
          fill="#626262"
          d="M140.897 50.889h3.375v.336h-3.375z"
          clipPath="url(#cR)"
          transform="rotate(62.005 142.587 51.063)"
        />
        <path
          fill="#646464"
          d="M141.125 50.767h3.375v.336h-3.375z"
          clipPath="url(#cR)"
          transform="rotate(62.01 142.82 50.94)"
        />
        <path
          fill="#656565"
          d="M141.354 50.645h3.375v.336h-3.375z"
          clipPath="url(#cR)"
          transform="rotate(62.005 143.044 50.82)"
        />
        <path
          fill="#676767"
          d="M141.582 50.524h3.375v.336h-3.375z"
          clipPath="url(#cR)"
          transform="rotate(62.01 143.277 50.696)"
        />
        <path
          fill="#696969"
          d="M141.811 50.403h3.375v.336h-3.375z"
          clipPath="url(#cR)"
          transform="rotate(62.01 143.506 50.575)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cS"
            d="M142.83 49.01l1.078-.661 1.76 2.872-1.079.661-1.759-2.872z"
          />
        </defs>
        <clipPath id="cT">
          <use overflow="visible" xlinkHref="#cS" />
        </clipPath>
        <path
          fill="#696969"
          d="M142.05 50.216h3.458v.274h-3.458z"
          clipPath="url(#cT)"
          transform="rotate(58.995 143.782 50.353)"
        />
        <path
          fill="#6b6b6b"
          d="M142.271 50.082h3.457v.275h-3.457z"
          clipPath="url(#cT)"
          transform="rotate(59.008 144.008 50.223)"
        />
        <path
          fill="#6d6d6d"
          d="M142.493 49.949h3.458v.274h-3.458z"
          clipPath="url(#cT)"
          transform="rotate(58.995 144.225 50.087)"
        />
        <path
          fill="#6e6e6e"
          d="M142.716 49.815h3.458v.274h-3.458z"
          clipPath="url(#cT)"
          transform="rotate(58.99 144.441 49.952)"
        />
        <path
          fill="#707070"
          d="M142.937 49.682h3.457v.274h-3.457z"
          clipPath="url(#cT)"
          transform="rotate(59.008 144.673 49.823)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cU"
            d="M130.133 49.332l1.859-.033.052 2.946-1.859.032-.052-2.945z"
          />
        </defs>
        <clipPath id="cV">
          <use overflow="visible" xlinkHref="#cU" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M128.873 50.617h2.963v.351h-2.963z"
          clipPath="url(#cV)"
          transform="rotate(88.997 130.363 50.798)"
        />
        <path
          fill="#aaa"
          d="M129.245 50.61h2.963v.352h-2.963z"
          clipPath="url(#cV)"
          transform="rotate(88.997 130.734 50.791)"
        />
        <path
          fill="#afafaf"
          d="M129.616 50.603h2.963v.353h-2.963z"
          clipPath="url(#cV)"
          transform="rotate(88.997 131.106 50.785)"
        />
        <path
          fill="#b3b3b3"
          d="M129.989 50.597h2.963v.352h-2.963z"
          clipPath="url(#cV)"
          transform="rotate(89.02 131.463 50.767)"
        />
        <path
          fill="#b8b8b8"
          d="M130.36 50.591h2.963v.352h-2.963z"
          clipPath="url(#cV)"
          transform="rotate(88.997 131.85 50.772)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cW"
            d="M131.99 49.299l1.849-.194.309 2.93-1.85.194-.308-2.93z"
          />
        </defs>
        <clipPath id="cX">
          <use overflow="visible" xlinkHref="#cW" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M130.826 50.532h3.011v.359h-3.011z"
          clipPath="url(#cX)"
          transform="rotate(84.007 132.334 50.71)"
        />
        <path
          fill="#bcbcbc"
          d="M131.197 50.494h3.011v.358h-3.011z"
          clipPath="url(#cX)"
          transform="rotate(83.984 132.704 50.678)"
        />
        <path
          fill="#c1c1c1"
          d="M131.566 50.455h3.011v.359h-3.011z"
          clipPath="url(#cX)"
          transform="rotate(84.007 133.074 50.632)"
        />
        <path
          fill="#c5c5c5"
          d="M131.936 50.416h3.011v.358h-3.011z"
          clipPath="url(#cX)"
          transform="rotate(83.984 133.444 50.6)"
        />
        <path
          fill="#c9c9c9"
          d="M132.306 50.377h3.011v.359h-3.011z"
          clipPath="url(#cX)"
          transform="rotate(84.007 133.815 50.556)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="cY"
            d="M133.837 49.105l1.826-.355.562 2.893-1.825.355-.563-2.893z"
          />
        </defs>
        <clipPath id="cZ">
          <use overflow="visible" xlinkHref="#cY" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M132.82 50.323h2.95v.385h-2.95z"
          clipPath="url(#cZ)"
          transform="rotate(78.989 134.292 50.513)"
        />
        <path
          fill="#cecece"
          d="M133.184 50.252h2.95v.384h-2.95z"
          clipPath="url(#cZ)"
          transform="rotate(78.989 134.659 50.446)"
        />
        <path
          fill="#d2d2d2"
          d="M133.549 50.181h2.95v.384h-2.95z"
          clipPath="url(#cZ)"
          transform="rotate(78.989 135.024 50.375)"
        />
        <path
          fill="#d7d7d7"
          d="M133.914 50.11h2.95v.384h-2.95z"
          clipPath="url(#cZ)"
          transform="rotate(79.006 135.396 50.305)"
        />
        <path
          fill="#dbdbdb"
          d="M134.279 50.039h2.95v.385h-2.95z"
          clipPath="url(#cZ)"
          transform="rotate(79.007 135.748 50.23)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="da"
            d="M135.661 48.751l1.787-.512.812 2.832-1.788.512-.811-2.832z"
          />
        </defs>
        <clipPath id="db">
          <use overflow="visible" xlinkHref="#da" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M134.767 49.926h2.918v.422h-2.918z"
          clipPath="url(#db)"
          transform="rotate(73.99 136.229 50.138)"
        />
        <path
          fill="#e0e0e0"
          d="M135.124 49.823h2.917v.423h-2.917z"
          clipPath="url(#db)"
          transform="rotate(74.026 136.584 50.039)"
        />
        <path
          fill="#e4e4e4"
          d="M135.482 49.721h2.917v.423h-2.917z"
          clipPath="url(#db)"
          transform="rotate(74.008 136.94 49.93)"
        />
        <path
          fill="#e9e9e9"
          d="M135.839 49.618h2.917v.423h-2.917z"
          clipPath="url(#db)"
          transform="rotate(74.008 137.297 49.827)"
        />
        <path
          fill="#ededed"
          d="M136.197 49.516h2.917v.423h-2.917z"
          clipPath="url(#db)"
          transform="rotate(74.008 137.654 49.725)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dc"
            d="M137.447 48.239l1.735-.666 1.056 2.751-1.737.666-1.054-2.751z"
          />
        </defs>
        <clipPath id="dd">
          <use overflow="visible" xlinkHref="#dc" />
        </clipPath>
        <path
          fill="#ededed"
          d="M136.744 49.411h2.866v.339h-2.866z"
          clipPath="url(#dd)"
          transform="rotate(68.991 138.183 49.584)"
        />
        <path
          fill="#f2f2f2"
          d="M137.092 49.277h2.866v.34h-2.866z"
          clipPath="url(#dd)"
          transform="rotate(68.991 138.53 49.45)"
        />
        <path
          fill="#f6f6f6"
          d="M137.439 49.144h2.865v.34h-2.865z"
          clipPath="url(#dd)"
          transform="rotate(69.009 138.88 49.318)"
        />
        <path
          fill="#fbfbfb"
          d="M137.786 49.01h2.865v.34h-2.865z"
          clipPath="url(#dd)"
          transform="rotate(69.009 139.226 49.185)"
        />
        <path
          fill="#fff"
          d="M138.133 48.877h2.866v.339h-2.866z"
          clipPath="url(#dd)"
          transform="rotate(68.991 139.572 49.05)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="de"
            d="M128.281 49.202l1.854.13-.206 2.939-1.854-.13.206-2.939z"
          />
        </defs>
        <clipPath id="df">
          <use overflow="visible" xlinkHref="#de" />
        </clipPath>
        <path
          fill="#949494"
          d="M126.909 50.519h2.94v.34h-2.94z"
          clipPath="url(#df)"
          transform="rotate(93.997 128.376 50.691)"
        />
        <path
          fill="#989898"
          d="M127.28 50.544h2.94v.34h-2.94z"
          clipPath="url(#df)"
          transform="rotate(93.997 128.747 50.717)"
        />
        <path
          fill="#9d9d9d"
          d="M127.65 50.57h2.94v.341h-2.94z"
          clipPath="url(#df)"
          transform="rotate(94.02 129.124 50.74)"
        />
        <path
          fill="#a1a1a1"
          d="M128.021 50.597h2.94v.34h-2.94z"
          clipPath="url(#df)"
          transform="rotate(94.002 129.485 50.762)"
        />
        <path
          fill="#a6a6a6"
          d="M128.392 50.622h2.94v.34h-2.94z"
          clipPath="url(#df)"
          transform="rotate(93.997 129.86 50.795)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dg"
            d="M126.446 48.912l1.837.29-.461 2.91-1.837-.29.461-2.91z"
          />
        </defs>
        <clipPath id="dh">
          <use overflow="visible" xlinkHref="#dg" />
        </clipPath>
        <path
          fill="#828282"
          d="M124.949 50.236h2.941v.329h-2.941z"
          clipPath="url(#dh)"
          transform="rotate(98.998 126.419 50.401)"
        />
        <path
          fill="#878787"
          d="M125.317 50.295h2.941v.329h-2.941z"
          clipPath="url(#dh)"
          transform="rotate(98.981 126.792 50.459)"
        />
        <path
          fill="#8b8b8b"
          d="M125.684 50.353h2.941v.329h-2.941z"
          clipPath="url(#dh)"
          transform="rotate(98.975 127.163 50.524)"
        />
        <path
          fill="#8f8f8f"
          d="M126.051 50.411h2.941v.33h-2.941z"
          clipPath="url(#dh)"
          transform="rotate(98.998 127.52 50.573)"
        />
        <path
          fill="#949494"
          d="M126.418 50.469h2.941v.329h-2.941z"
          clipPath="url(#dh)"
          transform="rotate(98.998 127.888 50.632)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="di"
            d="M124.644 48.462l1.804.45-.713 2.859-1.804-.45.713-2.859z"
          />
        </defs>
        <clipPath id="dj">
          <use overflow="visible" xlinkHref="#di" />
        </clipPath>
        <path
          fill="#707070"
          d="M123.01 49.77h2.966v.324h-2.966z"
          clipPath="url(#dj)"
          transform="rotate(103.981 124.493 49.937)"
        />
        <path
          fill="#757575"
          d="M123.371 49.86h2.967v.324h-2.967z"
          clipPath="url(#dj)"
          transform="rotate(104.005 124.852 50.02)"
        />
        <path
          fill="#797979"
          d="M123.731 49.95h2.967v.325h-2.967z"
          clipPath="url(#dj)"
          transform="rotate(104.022 125.214 50.11)"
        />
        <path
          fill="#7e7e7e"
          d="M124.093 50.04h2.966v.324h-2.966z"
          clipPath="url(#dj)"
          transform="rotate(103.981 125.575 50.207)"
        />
        <path
          fill="#828282"
          d="M124.453 50.13h2.967v.324h-2.967z"
          clipPath="url(#dj)"
          transform="rotate(104.005 125.934 50.29)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dk"
            d="M122.887 47.857l1.759.606-.959 2.786-1.759-.606.959-2.786z"
          />
        </defs>
        <clipPath id="dl">
          <use overflow="visible" xlinkHref="#dk" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M121.109 49.122h3.014v.328h-3.014z"
          clipPath="url(#dl)"
          transform="rotate(109.002 122.616 49.285)"
        />
        <path
          fill="#636363"
          d="M121.46 49.244h3.015v.326h-3.015z"
          clipPath="url(#dl)"
          transform="rotate(108.997 122.972 49.411)"
        />
        <path
          fill="#676767"
          d="M121.812 49.365h3.014v.327h-3.014z"
          clipPath="url(#dl)"
          transform="rotate(109.002 123.32 49.527)"
        />
        <path
          fill="#6c6c6c"
          d="M122.163 49.486h3.015v.326h-3.015z"
          clipPath="url(#dl)"
          transform="rotate(108.997 123.675 49.654)"
        />
        <path
          fill="#707070"
          d="M122.516 49.607h3.014v.327h-3.014z"
          clipPath="url(#dl)"
          transform="rotate(108.984 124.024 49.77)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dm"
            d="M121.191 47.102l1.699.756-1.199 2.692-1.699-.756 1.199-2.692z"
          />
        </defs>
        <clipPath id="dn">
          <use overflow="visible" xlinkHref="#dm" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M119.282 48.328h2.906v.412h-2.906z"
          clipPath="url(#dn)"
          transform="rotate(114.01 120.733 48.536)"
        />
        <path
          fill="#515151"
          d="M119.622 48.479h2.906v.412h-2.906z"
          clipPath="url(#dn)"
          transform="rotate(114.01 121.073 48.688)"
        />
        <path
          fill="#555"
          d="M119.961 48.631h2.907v.411h-2.907z"
          clipPath="url(#dn)"
          transform="rotate(114.01 121.412 48.834)"
        />
        <path
          fill="#5a5a5a"
          d="M120.301 48.782h2.906v.411h-2.906z"
          clipPath="url(#dn)"
          transform="rotate(113.991 121.754 48.985)"
        />
        <path
          fill="#5e5e5e"
          d="M120.641 48.934h2.906v.411h-2.906z"
          clipPath="url(#dn)"
          transform="rotate(113.991 122.094 49.137)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="do"
            d="M106.985 23.036l2.876.404-.464 3.298-2.876-.404.464-3.298z"
          />
        </defs>
        <clipPath id="dp">
          <use overflow="visible" xlinkHref="#do" />
        </clipPath>
        <path
          fill="#626262"
          d="M106.932 23.246h2.909v.545h-2.909z"
          clipPath="url(#dp)"
          transform="rotate(-172.013 108.384 23.52)"
        />
        <path
          fill="#606060"
          d="M106.854 23.795h2.909v.545h-2.909z"
          clipPath="url(#dp)"
          transform="rotate(-172.013 108.307 24.07)"
        />
        <path
          fill="#5f5f5f"
          d="M106.777 24.345h2.909v.544h-2.909z"
          clipPath="url(#dp)"
          transform="rotate(-172.013 108.23 24.62)"
        />
        <path
          fill="#5e5e5e"
          d="M106.699 24.895h2.91v.544h-2.91z"
          clipPath="url(#dp)"
          transform="rotate(-172.013 108.153 25.167)"
        />
        <path
          fill="#5c5c5c"
          d="M106.623 25.444h2.909v.545h-2.909z"
          clipPath="url(#dp)"
          transform="rotate(-172.013 108.075 25.719)"
        />
        <path
          fill="#5b5b5b"
          d="M106.545 25.994h2.909v.545h-2.909z"
          clipPath="url(#dp)"
          transform="rotate(-171.992 108.008 26.268)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dq"
            d="M106.493 26.806l2.903-.051.059 3.33-2.905.051-.057-3.33z"
          />
        </defs>
        <clipPath id="dr">
          <use overflow="visible" xlinkHref="#dq" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M106.496 26.715h2.968v.62h-2.968z"
          clipPath="url(#dr)"
          transform="rotate(178.991 108.039 27.04)"
        />
        <path
          fill="#595959"
          d="M106.505 27.269h2.969v.62h-2.969z"
          clipPath="url(#dr)"
          transform="rotate(179.003 107.99 27.578)"
        />
        <path
          fill="#585858"
          d="M106.515 27.825h2.969v.62h-2.969z"
          clipPath="url(#dr)"
          transform="rotate(179.003 108 28.133)"
        />
        <path
          fill="#565656"
          d="M106.525 28.379h2.969V29h-2.969z"
          clipPath="url(#dr)"
          transform="rotate(179.003 108.01 28.688)"
        />
        <path
          fill="#555"
          d="M106.534 28.935h2.969v.62h-2.969z"
          clipPath="url(#dr)"
          transform="rotate(179.003 108.02 29.243)"
        />
        <path
          fill="#545454"
          d="M106.545 29.49h2.968v.62h-2.968z"
          clipPath="url(#dr)"
          transform="rotate(179.003 108.03 29.798)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ds"
            d="M106.596 30.606l2.86-.503.579 3.279-2.861.505-.578-3.281z"
          />
        </defs>
        <clipPath id="dt">
          <use overflow="visible" xlinkHref="#ds" />
        </clipPath>
        <path
          fill="#545454"
          d="M106.621 30.331h2.965v.569h-2.965z"
          clipPath="url(#dt)"
          transform="rotate(169.997 108.104 30.614)"
        />
        <path
          fill="#525252"
          d="M106.716 30.878h2.967v.569h-2.967z"
          clipPath="url(#dt)"
          transform="rotate(169.997 108.2 31.161)"
        />
        <path
          fill="#515151"
          d="M106.813 31.425h2.966v.569h-2.966z"
          clipPath="url(#dt)"
          transform="rotate(169.997 108.296 31.71)"
        />
        <path
          fill="#4f4f4f"
          d="M106.91 31.971h2.966v.569h-2.966z"
          clipPath="url(#dt)"
          transform="rotate(170.008 108.4 32.256)"
        />
        <path
          fill="#4e4e4e"
          d="M107.005 32.518h2.967v.569h-2.967z"
          clipPath="url(#dt)"
          transform="rotate(169.997 108.49 32.801)"
        />
        <path
          fill="#4d4d4d"
          d="M107.102 33.064h2.966v.569h-2.966z"
          clipPath="url(#dt)"
          transform="rotate(169.997 108.585 33.35)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="du"
            d="M108.061 19.389l2.777.849-.973 3.185-2.778-.849.974-3.185z"
          />
        </defs>
        <clipPath id="dv">
          <use overflow="visible" xlinkHref="#du" />
        </clipPath>
        <path
          fill="#696969"
          d="M107.924 19.75h2.906v.607h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-162.993 109.373 20.053)"
        />
        <path
          fill="#686868"
          d="M107.761 20.281h2.906v.608h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-163.004 109.216 20.587)"
        />
        <path
          fill="#666"
          d="M107.599 20.812h2.906v.608h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-163.004 109.053 21.118)"
        />
        <path
          fill="#656565"
          d="M107.437 21.343h2.906v.608h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-163.004 108.891 21.648)"
        />
        <path
          fill="#636363"
          d="M107.275 21.874h2.906v.607h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-163.004 108.73 22.18)"
        />
        <path
          fill="#626262"
          d="M107.113 22.404h2.906v.608h-2.906z"
          clipPath="url(#dv)"
          transform="rotate(-163.004 108.567 22.71)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dw"
            d="M109.695 15.956l2.609 1.272-1.459 2.994-2.611-1.273 1.461-2.993z"
          />
        </defs>
        <clipPath id="dx">
          <use overflow="visible" xlinkHref="#dw" />
        </clipPath>
        <path
          fill="#707070"
          d="M109.417 16.601h2.89v.513h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 110.861 16.858)"
        />
        <path
          fill="#6f6f6f"
          d="M109.175 17.1h2.89v.512h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 110.618 17.357)"
        />
        <path
          fill="#6d6d6d"
          d="M108.93 17.599h2.89v.513h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 110.374 17.856)"
        />
        <path
          fill="#6c6c6c"
          d="M108.687 18.098h2.89v.512h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 110.131 18.354)"
        />
        <path
          fill="#6a6a6a"
          d="M108.444 18.597h2.89v.512h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 109.888 18.854)"
        />
        <path
          fill="#696969"
          d="M108.201 19.096h2.89v.512h-2.89z"
          clipPath="url(#dx)"
          transform="rotate(-154.001 109.645 19.353)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="dy" d="M95.203.459h2.55v3.374h-2.55V.459z" />
        </defs>
        <clipPath id="dz">
          <use overflow="visible" xlinkHref="#dy" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M95.175.474h.537v3.36h-.537z"
          clipPath="url(#dz)"
        />
        <path
          fill="#a1a1a1"
          d="M95.685.474h.537v3.36h-.537z"
          clipPath="url(#dz)"
        />
        <path
          fill="#9d9d9d"
          d="M96.195.474h.537v3.36h-.537z"
          clipPath="url(#dz)"
        />
        <path
          fill="#989898"
          d="M96.705.474h.538v3.36h-.538z"
          clipPath="url(#dz)"
        />
        <path
          fill="#949494"
          d="M97.214.474h.538v3.36h-.538z"
          clipPath="url(#dz)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dA"
            d="M92.326.744l2.537-.266.351 3.355-2.536.267-.352-3.356z"
          />
        </defs>
        <clipPath id="dB">
          <use overflow="visible" xlinkHref="#dA" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M91.069 2.141h3.426v.451h-3.426z"
          clipPath="url(#dB)"
          transform="rotate(84.013 92.785 2.365)"
        />
        <path
          fill="#b3b3b3"
          d="M91.577 2.087h3.425v.452h-3.425z"
          clipPath="url(#dB)"
          transform="rotate(84.013 93.292 2.311)"
        />
        <path
          fill="#afafaf"
          d="M92.084 2.035h3.427v.45h-3.427z"
          clipPath="url(#dB)"
          transform="rotate(83.979 93.798 2.262)"
        />
        <path
          fill="#aaa"
          d="M92.591 1.981h3.426v.451h-3.426z"
          clipPath="url(#dB)"
          transform="rotate(83.996 94.304 2.205)"
        />
        <path
          fill="#a6a6a6"
          d="M93.098 1.927h3.426v.452h-3.426z"
          clipPath="url(#dB)"
          transform="rotate(84.013 94.814 2.152)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dC"
            d="M89.494 1.328L91.99.797l.701 3.301-2.496.53-.701-3.3z"
          />
        </defs>
        <clipPath id="dD">
          <use overflow="visible" xlinkHref="#dC" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M88.387 2.653h3.407v.512h-3.407z"
          clipPath="url(#dD)"
          transform="rotate(77.989 90.09 2.908)"
        />
        <path
          fill="#c5c5c5"
          d="M88.885 2.547h3.406v.512h-3.406z"
          clipPath="url(#dD)"
          transform="rotate(78.007 90.583 2.8)"
        />
        <path
          fill="#c1c1c1"
          d="M89.385 2.441h3.407v.512h-3.407z"
          clipPath="url(#dD)"
          transform="rotate(77.989 91.087 2.696)"
        />
        <path
          fill="#bcbcbc"
          d="M89.883 2.335h3.406v.512h-3.406z"
          clipPath="url(#dD)"
          transform="rotate(78.007 91.581 2.588)"
        />
        <path
          fill="#b8b8b8"
          d="M90.382 2.228h3.406v.513h-3.406z"
          clipPath="url(#dD)"
          transform="rotate(78.007 92.08 2.482)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dE"
            d="M86.74 2.204l2.426-.788 1.042 3.209-2.425.788-1.043-3.209z"
          />
        </defs>
        <clipPath id="dF">
          <use overflow="visible" xlinkHref="#dE" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M85.829 3.484h3.397v.452h-3.397z"
          clipPath="url(#dF)"
          transform="rotate(72.013 87.533 3.712)"
        />
        <path
          fill="#d7d7d7"
          d="M86.315 3.327h3.397v.451h-3.397z"
          clipPath="url(#dF)"
          transform="rotate(72.002 88.008 3.55)"
        />
        <path
          fill="#d2d2d2"
          d="M86.8 3.169h3.397v.45H86.8z"
          clipPath="url(#dF)"
          transform="rotate(71.984 88.494 3.393)"
        />
        <path
          fill="#cecece"
          d="M87.285 3.011h3.396v.452h-3.396z"
          clipPath="url(#dF)"
          transform="rotate(72.013 88.986 3.237)"
        />
        <path
          fill="#c9c9c9"
          d="M87.77 2.854h3.397v.45H87.77z"
          clipPath="url(#dF)"
          transform="rotate(71.994 89.474 3.082)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dG"
            d="M84.091 3.364l2.33-1.038 1.373 3.082-2.331 1.038-1.372-3.082z"
          />
        </defs>
        <clipPath id="dH">
          <use overflow="visible" xlinkHref="#dG" />
        </clipPath>
        <path
          fill="#ededed"
          d="M83.317 4.542h3.344v.571h-3.344z"
          clipPath="url(#dH)"
          transform="rotate(66.003 84.985 4.826)"
        />
        <path
          fill="#e9e9e9"
          d="M83.783 4.334h3.344v.571h-3.344z"
          clipPath="url(#dH)"
          transform="rotate(65.99 85.45 4.619)"
        />
        <path
          fill="#e4e4e4"
          d="M84.249 4.126h3.344v.571h-3.344z"
          clipPath="url(#dH)"
          transform="rotate(65.996 85.92 4.41)"
        />
        <path
          fill="#e0e0e0"
          d="M84.715 3.919h3.344v.572h-3.344z"
          clipPath="url(#dH)"
          transform="rotate(66.009 86.39 4.206)"
        />
        <path
          fill="#dbdbdb"
          d="M85.181 3.712h3.344v.571h-3.344z"
          clipPath="url(#dH)"
          transform="rotate(65.996 86.853 3.996)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dI"
            d="M81.579 4.793l2.209-1.275 1.686 2.922-2.209 1.275-1.686-2.922z"
          />
        </defs>
        <clipPath id="dJ">
          <use overflow="visible" xlinkHref="#dI" />
        </clipPath>
        <path
          fill="#fff"
          d="M80.931 5.857h3.403v.518h-3.403z"
          clipPath="url(#dJ)"
          transform="rotate(60 82.636 6.117)"
        />
        <path
          fill="#fbfbfb"
          d="M81.372 5.602h3.403v.518h-3.403z"
          clipPath="url(#dJ)"
          transform="rotate(59.994 83.07 5.86)"
        />
        <path
          fill="#f6f6f6"
          d="M81.814 5.347h3.403v.519h-3.403z"
          clipPath="url(#dJ)"
          transform="rotate(60.007 83.514 5.607)"
        />
        <path
          fill="#f2f2f2"
          d="M82.256 5.092h3.403v.519h-3.403z"
          clipPath="url(#dJ)"
          transform="rotate(60 83.962 5.352)"
        />
        <path
          fill="#ededed"
          d="M82.697 4.837H86.1v.519h-3.403z"
          clipPath="url(#dJ)"
          transform="rotate(60 84.4 5.094)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dK"
            d="M98.093.478l2.536.266-.352 3.356-2.537-.267.353-3.355z"
          />
        </defs>
        <clipPath id="dL">
          <use overflow="visible" xlinkHref="#dK" />
        </clipPath>
        <path
          fill="#949494"
          d="M96.516 1.964h3.328v.485h-3.328z"
          clipPath="url(#dL)"
          transform="rotate(95.998 98.18 2.203)"
        />
        <path
          fill="#8f8f8f"
          d="M97.024 2.017h3.328v.485h-3.328z"
          clipPath="url(#dL)"
          transform="rotate(95.998 98.688 2.257)"
        />
        <path
          fill="#8b8b8b"
          d="M97.531 2.07h3.327v.485h-3.327z"
          clipPath="url(#dL)"
          transform="rotate(95.998 99.195 2.31)"
        />
        <path
          fill="#878787"
          d="M98.038 2.124h3.328v.485h-3.328z"
          clipPath="url(#dL)"
          transform="rotate(95.998 99.702 2.364)"
        />
        <path
          fill="#828282"
          d="M98.546 2.177h3.327v.485h-3.327z"
          clipPath="url(#dL)"
          transform="rotate(95.998 100.21 2.418)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dM"
            d="M100.966.797l2.494.531-.701 3.3-2.494-.53.701-3.301z"
          />
        </defs>
        <clipPath id="dN">
          <use overflow="visible" xlinkHref="#dM" />
        </clipPath>
        <path
          fill="#828282"
          d="M99.227 2.306h3.343v.435h-3.343z"
          clipPath="url(#dN)"
          transform="rotate(102.006 100.9 2.524)"
        />
        <path
          fill="#7e7e7e"
          d="M99.725 2.411h3.342v.436h-3.342z"
          clipPath="url(#dN)"
          transform="rotate(102.006 101.4 2.632)"
        />
        <path
          fill="#797979"
          d="M100.224 2.518h3.342v.436h-3.342z"
          clipPath="url(#dN)"
          transform="rotate(102.006 101.899 2.738)"
        />
        <path
          fill="#757575"
          d="M100.723 2.624h3.342v.435h-3.342z"
          clipPath="url(#dN)"
          transform="rotate(101.988 102.396 2.844)"
        />
        <path
          fill="#707070"
          d="M101.222 2.73h3.343v.436h-3.343z"
          clipPath="url(#dN)"
          transform="rotate(102.006 102.896 2.948)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dO"
            d="M103.79 1.416l2.426.788-1.043 3.209-2.425-.788 1.042-3.209z"
          />
        </defs>
        <clipPath id="dP">
          <use overflow="visible" xlinkHref="#dO" />
        </clipPath>
        <path
          fill="#707070"
          d="M101.82 2.839h3.362v.527h-3.362z"
          clipPath="url(#dP)"
          transform="rotate(108.006 103.501 3.1)"
        />
        <path
          fill="#6c6c6c"
          d="M102.305 2.996h3.362v.527h-3.362z"
          clipPath="url(#dP)"
          transform="rotate(108.006 103.987 3.257)"
        />
        <path
          fill="#676767"
          d="M102.79 3.153h3.361v.528h-3.361z"
          clipPath="url(#dP)"
          transform="rotate(108.006 104.473 3.418)"
        />
        <path
          fill="#636363"
          d="M103.275 3.312h3.362v.527h-3.362z"
          clipPath="url(#dP)"
          transform="rotate(108.006 104.956 3.573)"
        />
        <path
          fill="#5e5e5e"
          d="M103.761 3.469h3.361v.527h-3.361z"
          clipPath="url(#dP)"
          transform="rotate(107.993 105.44 3.73)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dQ"
            d="M106.534 2.326l2.329 1.038-1.371 3.082-2.33-1.038 1.372-3.082z"
          />
        </defs>
        <clipPath id="dR">
          <use overflow="visible" xlinkHref="#dQ" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M104.433 3.776h3.329v.453h-3.329z"
          clipPath="url(#dR)"
          transform="rotate(114.004 106.099 4.003)"
        />
        <path
          fill="#5a5a5a"
          d="M104.899 3.984h3.329v.452h-3.329z"
          clipPath="url(#dR)"
          transform="rotate(114.004 106.565 4.21)"
        />
        <path
          fill="#555"
          d="M105.365 4.191h3.329v.453h-3.329z"
          clipPath="url(#dR)"
          transform="rotate(114.004 107.03 4.418)"
        />
        <path
          fill="#515151"
          d="M105.831 4.399h3.33v.452h-3.33z"
          clipPath="url(#dR)"
          transform="rotate(114.004 107.495 4.622)"
        />
        <path
          fill="#4d4d4d"
          d="M106.298 4.606h3.329v.452h-3.329z"
          clipPath="url(#dR)"
          transform="rotate(113.991 107.963 4.834)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dS"
            d="M82.475 8.285l.922-.623 1.648 2.443-.923.623-1.647-2.443z"
          />
        </defs>
        <clipPath id="dT">
          <use overflow="visible" xlinkHref="#dS" />
        </clipPath>
        <path
          fill="#707070"
          d="M81.892 9.319h2.946v.282h-2.946z"
          clipPath="url(#dT)"
          transform="rotate(56.006 83.363 9.459)"
        />
        <path
          fill="#6e6e6e"
          d="M82.078 9.195h2.946v.282h-2.946z"
          clipPath="url(#dT)"
          transform="rotate(55.998 83.554 9.337)"
        />
        <path
          fill="#6d6d6d"
          d="M82.262 9.07h2.946v.282h-2.946z"
          clipPath="url(#dT)"
          transform="rotate(55.998 83.738 9.212)"
        />
        <path
          fill="#6b6b6b"
          d="M82.447 8.946h2.946v.282h-2.946z"
          clipPath="url(#dT)"
          transform="rotate(56.006 83.917 9.085)"
        />
        <path
          fill="#696969"
          d="M82.631 8.821h2.946v.282h-2.946z"
          clipPath="url(#dT)"
          transform="rotate(56.006 84.101 8.96)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dU"
            d="M83.528 7.579l.954-.573L86 9.531l-.954.573-1.518-2.525z"
          />
        </defs>
        <clipPath id="dV">
          <use overflow="visible" xlinkHref="#dU" />
        </clipPath>
        <path
          fill="#696969"
          d="M82.876 8.654h2.973v.252h-2.973z"
          clipPath="url(#dV)"
          transform="rotate(59.003 84.364 8.782)"
        />
        <path
          fill="#676767"
          d="M83.067 8.54h2.974v.251h-2.974z"
          clipPath="url(#dV)"
          transform="rotate(58.995 84.557 8.667)"
        />
        <path
          fill="#656565"
          d="M83.258 8.425h2.974v.252h-2.974z"
          clipPath="url(#dV)"
          transform="rotate(58.995 84.748 8.552)"
        />
        <path
          fill="#646464"
          d="M83.448 8.31h2.974v.252h-2.974z"
          clipPath="url(#dV)"
          transform="rotate(58.995 84.939 8.437)"
        />
        <path
          fill="#626262"
          d="M83.639 8.196h2.974v.251h-2.974z"
          clipPath="url(#dV)"
          transform="rotate(58.995 85.13 8.323)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dW"
            d="M84.617 6.929l.984-.522 1.383 2.601-.983.523-1.384-2.602z"
          />
        </defs>
        <clipPath id="dX">
          <use overflow="visible" xlinkHref="#dW" />
        </clipPath>
        <path
          fill="#626262"
          d="M83.885 8.037h3.012v.225h-3.012z"
          clipPath="url(#dX)"
          transform="rotate(61.972 85.393 8.15)"
        />
        <path
          fill="#606060"
          d="M84.081 7.932h3.012v.226h-3.012z"
          clipPath="url(#dX)"
          transform="rotate(61.992 85.588 8.047)"
        />
        <path
          fill="#5e5e5e"
          d="M84.278 7.828h3.012v.225h-3.012z"
          clipPath="url(#dX)"
          transform="rotate(61.972 85.787 7.94)"
        />
        <path
          fill="#5d5d5d"
          d="M84.474 7.724h3.012v.225h-3.012z"
          clipPath="url(#dX)"
          transform="rotate(61.992 85.976 7.835)"
        />
        <path
          fill="#5b5b5b"
          d="M84.671 7.619h3.012v.226h-3.012z"
          clipPath="url(#dX)"
          transform="rotate(61.997 86.18 7.732)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="dY"
            d="M85.74 6.338l1.009-.471 1.245 2.67-1.01.471-1.244-2.67z"
          />
        </defs>
        <clipPath id="dZ">
          <use overflow="visible" xlinkHref="#dY" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M84.978 7.507h2.939v.26h-2.939z"
          clipPath="url(#dZ)"
          transform="rotate(64.982 86.444 7.636)"
        />
        <path
          fill="#595959"
          d="M85.179 7.413h2.939v.261h-2.939z"
          clipPath="url(#dZ)"
          transform="rotate(65.001 86.65 7.543)"
        />
        <path
          fill="#575757"
          d="M85.381 7.318h2.939v.261h-2.939z"
          clipPath="url(#dZ)"
          transform="rotate(64.996 86.85 7.45)"
        />
        <path
          fill="#555"
          d="M85.583 7.225h2.939v.261h-2.939z"
          clipPath="url(#dZ)"
          transform="rotate(65.001 87.054 7.355)"
        />
        <path
          fill="#545454"
          d="M85.785 7.13h2.939v.261h-2.939z"
          clipPath="url(#dZ)"
          transform="rotate(64.996 87.254 7.262)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ea"
            d="M86.89 5.806l1.032-.417 1.105 2.731-1.033.417-1.104-2.731z"
          />
        </defs>
        <clipPath id="eb">
          <use overflow="visible" xlinkHref="#ea" />
        </clipPath>
        <path
          fill="#545454"
          d="M86.031 6.991h2.996v.238h-2.996z"
          clipPath="url(#eb)"
          transform="rotate(67.983 87.527 7.11)"
        />
        <path
          fill="#525252"
          d="M86.237 6.907h2.996v.239h-2.996z"
          clipPath="url(#eb)"
          transform="rotate(67.988 87.735 7.025)"
        />
        <path
          fill="#505050"
          d="M86.443 6.823h2.996v.24h-2.996z"
          clipPath="url(#eb)"
          transform="rotate(68.019 87.944 6.945)"
        />
        <path
          fill="#4e4e4e"
          d="M86.65 6.74h2.995v.239H86.65z"
          clipPath="url(#eb)"
          transform="rotate(68 88.148 6.86)"
        />
        <path
          fill="#4d4d4d"
          d="M86.856 6.657h2.995v.239h-2.995z"
          clipPath="url(#eb)"
          transform="rotate(68 88.354 6.777)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ec"
            d="M81.398 42.891a24.424 24.424 0 0 0 2.444-3.851l2.862 1.816a24.552 24.552 0 0 1-1.145 1.977c-.477.75-.858 1.285-1.299 1.874l-2.862-1.816z"
          />
        </defs>
        <clipPath id="ed">
          <use overflow="visible" xlinkHref="#ec" />
        </clipPath>
        <path
          fill="#707070"
          d="M81.24 43.217h3.561v.641H81.24z"
          clipPath="url(#ed)"
          transform="rotate(32 83.02 43.538)"
        />
        <path
          fill="#6f6f6f"
          d="M81.587 42.662h3.562v.641h-3.562z"
          clipPath="url(#ed)"
          transform="rotate(32 83.364 42.98)"
        />
        <path
          fill="#6e6e6e"
          d="M81.934 42.106h3.563v.641h-3.563z"
          clipPath="url(#ed)"
          transform="rotate(31.984 83.713 42.428)"
        />
        <path
          fill="#6d6d6d"
          d="M82.281 41.551h3.562v.641h-3.562z"
          clipPath="url(#ed)"
          transform="rotate(31.994 84.065 41.87)"
        />
        <path
          fill="#6b6b6b"
          d="M82.628 40.995h3.562v.64h-3.562z"
          clipPath="url(#ed)"
          transform="rotate(31.997 84.411 41.318)"
        />
        <path
          fill="#6a6a6a"
          d="M82.975 40.44h3.563v.64h-3.563z"
          clipPath="url(#ed)"
          transform="rotate(31.984 84.755 40.762)"
        />
        <path
          fill="#696969"
          d="M83.322 39.884h3.562v.641h-3.562z"
          clipPath="url(#ed)"
          transform="rotate(31.994 85.106 40.203)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ee"
            d="M83.84 39.042a24.321 24.321 0 0 0 1.679-4.241l3.152 1.248a24.828 24.828 0 0 1-.754 2.156 27.135 27.135 0 0 1-.926 2.084l-3.151-1.247z"
          />
        </defs>
        <clipPath id="ef">
          <use overflow="visible" xlinkHref="#ee" />
        </clipPath>
        <path
          fill="#696969"
          d="M83.839 39.072h3.504v.625h-3.504z"
          clipPath="url(#ef)"
          transform="rotate(20.997 85.592 39.384)"
        />
        <path
          fill="#686868"
          d="M84.075 38.459h3.504v.625h-3.504z"
          clipPath="url(#ef)"
          transform="rotate(20.997 85.828 38.77)"
        />
        <path
          fill="#676767"
          d="M84.309 37.846h3.503v.625h-3.503z"
          clipPath="url(#ef)"
          transform="rotate(21.001 86.06 38.159)"
        />
        <path
          fill="#656565"
          d="M84.545 37.232h3.504v.625h-3.504z"
          clipPath="url(#ef)"
          transform="rotate(20.997 86.299 37.544)"
        />
        <path
          fill="#646464"
          d="M84.78 36.619h3.503v.625H84.78z"
          clipPath="url(#ef)"
          transform="rotate(21.001 86.531 36.932)"
        />
        <path
          fill="#636363"
          d="M85.016 36.007h3.505v.625h-3.505z"
          clipPath="url(#ef)"
          transform="rotate(20.991 86.771 36.32)"
        />
        <path
          fill="#626262"
          d="M85.251 35.393h3.502v.625h-3.502z"
          clipPath="url(#ef)"
          transform="rotate(21.001 87.013 35.713)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eg"
            d="M85.517 34.806c.414-1.411.729-3.06.855-4.48l3.33.635c-.072.71-.17 1.384-.338 2.259a26.51 26.51 0 0 1-.518 2.221l-3.329-.635z"
          />
        </defs>
        <clipPath id="eh">
          <use overflow="visible" xlinkHref="#eg" />
        </clipPath>
        <path
          fill="#626262"
          d="M85.486 34.499h3.574v.63h-3.574z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.25 34.804)"
        />
        <path
          fill="#616161"
          d="M85.601 33.851h3.573v.63h-3.573z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.387 34.166)"
        />
        <path
          fill="#606060"
          d="M85.715 33.203h3.574v.63h-3.574z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.478 33.507)"
        />
        <path
          fill="#5e5e5e"
          d="M85.83 32.555h3.574v.63H85.83z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.593 32.86)"
        />
        <path
          fill="#5d5d5d"
          d="M85.943 31.906h3.574v.63h-3.574z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.706 32.211)"
        />
        <path
          fill="#5c5c5c"
          d="M86.058 31.258h3.574v.631h-3.574z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.836 31.58)"
        />
        <path
          fill="#5b5b5b"
          d="M86.172 30.609h3.574v.631h-3.574z"
          clipPath="url(#eh)"
          transform="rotate(10.003 87.949 30.931)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ei"
            d="M86.371 30.33a24.298 24.298 0 0 0 0-4.561h3.39c.062.711.093 1.391.093 2.283 0 .889-.035 1.543-.093 2.278h-3.39z"
          />
        </defs>
        <clipPath id="ej">
          <use overflow="visible" xlinkHref="#ei" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M86.387 29.698h3.504v.611h-3.504z"
          clipPath="url(#ej)"
          transform="rotate(-.997 88.221 30.032)"
        />
        <path
          fill="#5a5a5a"
          d="M86.375 29.038h3.505v.611h-3.505z"
          clipPath="url(#ej)"
          transform="rotate(-1.009 87.879 29.262)"
        />
        <path
          fill="#585858"
          d="M86.364 28.378h3.505v.611h-3.505z"
          clipPath="url(#ej)"
          transform="rotate(-.997 88.176 28.704)"
        />
        <path
          fill="#575757"
          d="M86.352 27.718h3.505v.611h-3.505z"
          clipPath="url(#ej)"
          transform="rotate(-.997 88.164 28.043)"
        />
        <path
          fill="#565656"
          d="M86.341 27.059h3.506v.611h-3.506z"
          clipPath="url(#ej)"
          transform="rotate(-1.003 88.326 27.436)"
        />
        <path
          fill="#555"
          d="M86.329 26.399h3.506v.611h-3.506z"
          clipPath="url(#ej)"
          transform="rotate(-.997 88.118 26.715)"
        />
        <path
          fill="#545454"
          d="M86.318 25.739h3.506v.611h-3.506z"
          clipPath="url(#ej)"
          transform="rotate(-.997 88.107 26.054)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ek"
            d="M86.371 25.773a24.434 24.434 0 0 0-.855-4.48l3.33-.635c.194.687.352 1.35.519 2.225.167.874.255 1.523.337 2.255l-3.331.635z"
          />
        </defs>
        <clipPath id="el">
          <use overflow="visible" xlinkHref="#ek" />
        </clipPath>
        <path
          fill="#545454"
          d="M86.217 24.81h3.555v.678h-3.555z"
          clipPath="url(#el)"
          transform="rotate(-11 87.996 25.15)"
        />
        <path
          fill="#525252"
          d="M86.092 24.169h3.556v.678h-3.556z"
          clipPath="url(#el)"
          transform="rotate(-11 87.847 24.504)"
        />
        <path
          fill="#515151"
          d="M85.967 23.528h3.556v.678h-3.556z"
          clipPath="url(#el)"
          transform="rotate(-11 87.722 23.863)"
        />
        <path
          fill="#505050"
          d="M85.843 22.886h3.554v.679h-3.554z"
          clipPath="url(#el)"
          transform="rotate(-10.993 87.607 23.223)"
        />
        <path
          fill="#4f4f4f"
          d="M85.719 22.245h3.554v.678h-3.554z"
          clipPath="url(#el)"
          transform="rotate(-11.008 87.473 22.585)"
        />
        <path
          fill="#4e4e4e"
          d="M85.594 21.604h3.555v.678h-3.555z"
          clipPath="url(#el)"
          transform="rotate(-11 87.372 21.943)"
        />
        <path
          fill="#4d4d4d"
          d="M85.469 20.963h3.555v.678h-3.555z"
          clipPath="url(#el)"
          transform="rotate(-11.008 87.262 21.308)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="em"
            d="M72.771 23.036l2.875.404-.463 3.298-2.875-.404.463-3.298z"
          />
        </defs>
        <clipPath id="en">
          <use overflow="visible" xlinkHref="#em" />
        </clipPath>
        <path
          fill="#626262"
          d="M72.717 23.246h2.909v.545h-2.909z"
          clipPath="url(#en)"
          transform="rotate(-172.013 74.17 23.52)"
        />
        <path
          fill="#606060"
          d="M72.64 23.795h2.909v.545H72.64z"
          clipPath="url(#en)"
          transform="rotate(-172.013 74.093 24.07)"
        />
        <path
          fill="#5f5f5f"
          d="M72.563 24.345h2.909v.544h-2.909z"
          clipPath="url(#en)"
          transform="rotate(-172.013 74.016 24.619)"
        />
        <path
          fill="#5e5e5e"
          d="M72.486 24.895h2.909v.544h-2.909z"
          clipPath="url(#en)"
          transform="rotate(-172.013 73.939 25.169)"
        />
        <path
          fill="#5c5c5c"
          d="M72.408 25.444h2.909v.545h-2.909z"
          clipPath="url(#en)"
          transform="rotate(-172.013 73.86 25.718)"
        />
        <path
          fill="#5b5b5b"
          d="M72.331 25.994h2.909v.545h-2.909z"
          clipPath="url(#en)"
          transform="rotate(-171.992 73.792 26.268)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eo"
            d="M72.278 26.806l2.904-.051.057 3.33-2.903.051-.058-3.33z"
          />
        </defs>
        <clipPath id="ep">
          <use overflow="visible" xlinkHref="#eo" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M72.283 26.715h2.967v.62h-2.967z"
          clipPath="url(#ep)"
          transform="rotate(178.991 73.806 27.039)"
        />
        <path
          fill="#595959"
          d="M72.293 27.269h2.967v.62h-2.967z"
          clipPath="url(#ep)"
          transform="rotate(179.003 73.777 27.578)"
        />
        <path
          fill="#585858"
          d="M72.303 27.825h2.967v.62h-2.967z"
          clipPath="url(#ep)"
          transform="rotate(179.003 73.787 28.133)"
        />
        <path
          fill="#565656"
          d="M72.312 28.379h2.967V29h-2.967z"
          clipPath="url(#ep)"
          transform="rotate(179.003 73.796 28.688)"
        />
        <path
          fill="#555"
          d="M72.322 28.935h2.967v.62h-2.967z"
          clipPath="url(#ep)"
          transform="rotate(179.003 73.806 29.243)"
        />
        <path
          fill="#545454"
          d="M72.33 29.49h2.968v.62H72.33z"
          clipPath="url(#ep)"
          transform="rotate(179.003 73.815 29.799)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eq"
            d="M72.382 30.606l2.859-.503.578 3.279-2.859.505-.578-3.281z"
          />
        </defs>
        <clipPath id="er">
          <use overflow="visible" xlinkHref="#eq" />
        </clipPath>
        <path
          fill="#545454"
          d="M72.405 30.331h2.967v.569h-2.967z"
          clipPath="url(#er)"
          transform="rotate(169.997 73.89 30.615)"
        />
        <path
          fill="#525252"
          d="M72.503 30.878h2.966v.569h-2.966z"
          clipPath="url(#er)"
          transform="rotate(169.997 73.986 31.163)"
        />
        <path
          fill="#515151"
          d="M72.599 31.425h2.966v.569h-2.966z"
          clipPath="url(#er)"
          transform="rotate(169.997 74.082 31.71)"
        />
        <path
          fill="#4f4f4f"
          d="M72.694 31.971h2.967v.569h-2.967z"
          clipPath="url(#er)"
          transform="rotate(170.008 74.182 32.259)"
        />
        <path
          fill="#4e4e4e"
          d="M72.792 32.518h2.966v.569h-2.966z"
          clipPath="url(#er)"
          transform="rotate(169.997 74.275 32.803)"
        />
        <path
          fill="#4d4d4d"
          d="M72.888 33.064h2.966v.569h-2.966z"
          clipPath="url(#er)"
          transform="rotate(169.997 74.37 33.35)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="es"
            d="M73.847 19.389l2.777.849-.975 3.185-2.776-.849.974-3.185z"
          />
        </defs>
        <clipPath id="et">
          <use overflow="visible" xlinkHref="#es" />
        </clipPath>
        <path
          fill="#696969"
          d="M73.71 19.75h2.906v.607H73.71z"
          clipPath="url(#et)"
          transform="rotate(-163.004 75.164 20.055)"
        />
        <path
          fill="#686868"
          d="M73.548 20.281h2.906v.608h-2.906z"
          clipPath="url(#et)"
          transform="rotate(-162.993 74.998 20.584)"
        />
        <path
          fill="#666"
          d="M73.385 20.812h2.906v.607h-2.906z"
          clipPath="url(#et)"
          transform="rotate(-163.004 74.84 21.117)"
        />
        <path
          fill="#656565"
          d="M73.223 21.343h2.906v.608h-2.906z"
          clipPath="url(#et)"
          transform="rotate(-163.004 74.677 21.648)"
        />
        <path
          fill="#636363"
          d="M73.061 21.874h2.906v.607h-2.906z"
          clipPath="url(#et)"
          transform="rotate(-163.004 74.514 22.179)"
        />
        <path
          fill="#626262"
          d="M72.898 22.404h2.905v.608h-2.905z"
          clipPath="url(#et)"
          transform="rotate(-162.999 74.35 22.71)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eu"
            d="M75.479 15.956l2.612 1.272-1.461 2.994-2.609-1.273 1.458-2.993z"
          />
        </defs>
        <clipPath id="ev">
          <use overflow="visible" xlinkHref="#eu" />
        </clipPath>
        <path
          fill="#707070"
          d="M75.203 16.601h2.89v.512h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 76.648 16.858)"
        />
        <path
          fill="#6f6f6f"
          d="M74.959 17.1h2.89v.512h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 76.403 17.357)"
        />
        <path
          fill="#6d6d6d"
          d="M74.716 17.599h2.89v.513h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 76.16 17.855)"
        />
        <path
          fill="#6c6c6c"
          d="M74.473 18.098h2.89v.512h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 75.917 18.354)"
        />
        <path
          fill="#6a6a6a"
          d="M74.23 18.597h2.89v.512h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 75.674 18.853)"
        />
        <path
          fill="#696969"
          d="M73.987 19.096h2.89v.512h-2.89z"
          clipPath="url(#ev)"
          transform="rotate(-154.001 75.43 19.352)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ew"
            d="M69.752 51.09l1.192-.423 1.124 3.176-1.192.423-1.124-3.176z"
          />
        </defs>
        <clipPath id="ex">
          <use overflow="visible" xlinkHref="#ew" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M68.741 52.513h3.36v.264h-3.36z"
          clipPath="url(#ex)"
          transform="rotate(71.003 70.424 52.649)"
        />
        <path
          fill="#4e4e4e"
          d="M68.986 52.429h3.36v.265h-3.36z"
          clipPath="url(#ex)"
          transform="rotate(71.003 70.67 52.564)"
        />
        <path
          fill="#505050"
          d="M69.231 52.345h3.36v.264h-3.36z"
          clipPath="url(#ex)"
          transform="rotate(70.998 70.91 52.475)"
        />
        <path
          fill="#525252"
          d="M69.475 52.26h3.36v.265h-3.36z"
          clipPath="url(#ex)"
          transform="rotate(71.003 71.159 52.396)"
        />
        <path
          fill="#545454"
          d="M69.72 52.176h3.361v.265H69.72z"
          clipPath="url(#ex)"
          transform="rotate(70.987 71.396 52.307)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ey"
            d="M70.947 50.666l1.169-.484 1.289 3.113-1.168.484-1.29-3.113z"
          />
        </defs>
        <clipPath id="ez">
          <use overflow="visible" xlinkHref="#ey" />
        </clipPath>
        <path
          fill="#545454"
          d="M70.001 52.041h3.42v.213h-3.42z"
          clipPath="url(#ez)"
          transform="rotate(68 71.709 52.144)"
        />
        <path
          fill="#555"
          d="M70.24 51.943h3.42v.214h-3.42z"
          clipPath="url(#ez)"
          transform="rotate(68.013 71.948 52.048)"
        />
        <path
          fill="#575757"
          d="M70.48 51.847h3.42v.213h-3.42z"
          clipPath="url(#ez)"
          transform="rotate(68 72.188 51.95)"
        />
        <path
          fill="#595959"
          d="M70.72 51.75h3.42v.213h-3.42z"
          clipPath="url(#ez)"
          transform="rotate(68 72.427 51.853)"
        />
        <path
          fill="#5b5b5b"
          d="M70.96 51.653h3.42v.213h-3.42z"
          clipPath="url(#ez)"
          transform="rotate(67.995 72.666 51.756)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eA"
            d="M72.119 50.181l1.142-.545 1.45 3.041-1.142.544-1.45-3.04z"
          />
        </defs>
        <clipPath id="eB">
          <use overflow="visible" xlinkHref="#eA" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M71.29 51.54h3.361v.211H71.29z"
          clipPath="url(#eB)"
          transform="rotate(64.996 72.969 51.643)"
        />
        <path
          fill="#5d5d5d"
          d="M71.525 51.431h3.362v.211h-3.362z"
          clipPath="url(#eB)"
          transform="rotate(64.982 73.203 51.533)"
        />
        <path
          fill="#5e5e5e"
          d="M71.76 51.321h3.361v.212H71.76z"
          clipPath="url(#eB)"
          transform="rotate(64.996 73.438 51.425)"
        />
        <path
          fill="#606060"
          d="M71.994 51.212h3.361v.212h-3.361z"
          clipPath="url(#eB)"
          transform="rotate(64.996 73.672 51.316)"
        />
        <path
          fill="#626262"
          d="M72.228 51.102h3.361v.212h-3.361z"
          clipPath="url(#eB)"
          transform="rotate(64.996 73.907 51.206)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eC"
            d="M73.264 49.634l1.112-.603 1.607 2.96-1.111.604-1.608-2.961z"
          />
        </defs>
        <clipPath id="eD">
          <use overflow="visible" xlinkHref="#eC" />
        </clipPath>
        <path
          fill="#626262"
          d="M72.445 50.908h3.375v.336h-3.375z"
          clipPath="url(#eD)"
          transform="rotate(62.005 74.133 51.08)"
        />
        <path
          fill="#646464"
          d="M72.674 50.787h3.376v.336h-3.376z"
          clipPath="url(#eD)"
          transform="rotate(61.997 74.364 50.956)"
        />
        <path
          fill="#656565"
          d="M72.902 50.665h3.376v.336h-3.376z"
          clipPath="url(#eD)"
          transform="rotate(61.997 74.592 50.834)"
        />
        <path
          fill="#676767"
          d="M73.131 50.544h3.376v.336h-3.376z"
          clipPath="url(#eD)"
          transform="rotate(61.997 74.82 50.713)"
        />
        <path
          fill="#696969"
          d="M73.359 50.422h3.375v.336h-3.375z"
          clipPath="url(#eD)"
          transform="rotate(62.01 75.05 50.594)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eE"
            d="M74.378 49.029l1.079-.661 1.761 2.873-1.078.66-1.762-2.872z"
          />
        </defs>
        <clipPath id="eF">
          <use overflow="visible" xlinkHref="#eE" />
        </clipPath>
        <path
          fill="#696969"
          d="M73.598 50.235h3.457v.275h-3.457z"
          clipPath="url(#eF)"
          transform="rotate(59.008 75.33 50.376)"
        />
        <path
          fill="#6b6b6b"
          d="M73.82 50.102h3.458v.275H73.82z"
          clipPath="url(#eF)"
          transform="rotate(58.995 75.551 50.24)"
        />
        <path
          fill="#6d6d6d"
          d="M74.042 49.968h3.457v.276h-3.457z"
          clipPath="url(#eF)"
          transform="rotate(59.023 75.768 50.103)"
        />
        <path
          fill="#6e6e6e"
          d="M74.264 49.836h3.458v.274h-3.458z"
          clipPath="url(#eF)"
          transform="rotate(58.995 75.995 49.973)"
        />
        <path
          fill="#707070"
          d="M74.486 49.702h3.457v.275h-3.457z"
          clipPath="url(#eF)"
          transform="rotate(59.008 76.219 49.843)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eG"
            d="M61.705 49.332l1.859-.033.052 2.946-1.86.032-.051-2.945z"
          />
        </defs>
        <clipPath id="eH">
          <use overflow="visible" xlinkHref="#eG" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M60.445 50.617h2.963v.352h-2.963z"
          clipPath="url(#eH)"
          transform="rotate(88.997 61.93 50.797)"
        />
        <path
          fill="#aaa"
          d="M60.816 50.61h2.964v.352h-2.964z"
          clipPath="url(#eH)"
          transform="rotate(88.997 62.301 50.79)"
        />
        <path
          fill="#afafaf"
          d="M61.189 50.604h2.963v.351h-2.963z"
          clipPath="url(#eH)"
          transform="rotate(88.991 62.673 50.78)"
        />
        <path
          fill="#b3b3b3"
          d="M61.56 50.597h2.963v.352H61.56z"
          clipPath="url(#eH)"
          transform="rotate(88.997 63.045 50.778)"
        />
        <path
          fill="#b8b8b8"
          d="M61.932 50.591h2.963v.351h-2.963z"
          clipPath="url(#eH)"
          transform="rotate(88.991 63.417 50.767)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eI"
            d="M63.562 49.299l1.849-.194.308 2.93-1.849.194-.308-2.93z"
          />
        </defs>
        <clipPath id="eJ">
          <use overflow="visible" xlinkHref="#eI" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M62.398 50.533h3.011v.359h-3.011z"
          clipPath="url(#eJ)"
          transform="rotate(83.996 63.905 50.712)"
        />
        <path
          fill="#bcbcbc"
          d="M62.768 50.494h3.011v.359h-3.011z"
          clipPath="url(#eJ)"
          transform="rotate(84.007 64.275 50.673)"
        />
        <path
          fill="#c1c1c1"
          d="M63.138 50.455h3.011v.359h-3.011z"
          clipPath="url(#eJ)"
          transform="rotate(84.007 64.645 50.634)"
        />
        <path
          fill="#c5c5c5"
          d="M63.508 50.416h3.011v.359h-3.011z"
          clipPath="url(#eJ)"
          transform="rotate(84.007 65.015 50.595)"
        />
        <path
          fill="#c9c9c9"
          d="M63.877 50.377h3.011v.359h-3.011z"
          clipPath="url(#eJ)"
          transform="rotate(83.996 65.384 50.557)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eK"
            d="M65.409 49.105l1.825-.355.562 2.893-1.825.355-.562-2.893z"
          />
        </defs>
        <clipPath id="eL">
          <use overflow="visible" xlinkHref="#eK" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M64.391 50.323h2.95v.385h-2.95z"
          clipPath="url(#eL)"
          transform="rotate(78.995 65.867 50.518)"
        />
        <path
          fill="#cecece"
          d="M64.756 50.252h2.95v.385h-2.95z"
          clipPath="url(#eL)"
          transform="rotate(79 66.232 50.444)"
        />
        <path
          fill="#d2d2d2"
          d="M65.121 50.181h2.95v.385h-2.95z"
          clipPath="url(#eL)"
          transform="rotate(79 66.597 50.373)"
        />
        <path
          fill="#d7d7d7"
          d="M65.486 50.11h2.95v.385h-2.95z"
          clipPath="url(#eL)"
          transform="rotate(79.006 66.964 50.305)"
        />
        <path
          fill="#dbdbdb"
          d="M65.851 50.039h2.95v.385h-2.95z"
          clipPath="url(#eL)"
          transform="rotate(79 67.327 50.231)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eM"
            d="M67.232 48.751l1.788-.512.812 2.832-1.788.512-.812-2.832z"
          />
        </defs>
        <clipPath id="eN">
          <use overflow="visible" xlinkHref="#eM" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M66.338 49.926h2.918v.422h-2.918z"
          clipPath="url(#eN)"
          transform="rotate(74.008 67.798 50.139)"
        />
        <path
          fill="#e0e0e0"
          d="M66.696 49.824h2.918v.422h-2.918z"
          clipPath="url(#eN)"
          transform="rotate(73.99 68.156 50.036)"
        />
        <path
          fill="#e4e4e4"
          d="M67.054 49.721h2.917v.422h-2.917z"
          clipPath="url(#eN)"
          transform="rotate(73.997 68.509 49.93)"
        />
        <path
          fill="#e9e9e9"
          d="M67.411 49.618h2.917v.422h-2.917z"
          clipPath="url(#eN)"
          transform="rotate(74.008 68.87 49.828)"
        />
        <path
          fill="#ededed"
          d="M67.768 49.516h2.917v.422h-2.917z"
          clipPath="url(#eN)"
          transform="rotate(73.997 69.224 49.725)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eO"
            d="M69.018 48.239l1.736-.666 1.056 2.751-1.736.666-1.056-2.751z"
          />
        </defs>
        <clipPath id="eP">
          <use overflow="visible" xlinkHref="#eO" />
        </clipPath>
        <path
          fill="#ededed"
          d="M68.316 49.41h2.866v.339h-2.866z"
          clipPath="url(#eP)"
          transform="rotate(69.004 69.748 49.577)"
        />
        <path
          fill="#f2f2f2"
          d="M68.663 49.277h2.866v.339h-2.866z"
          clipPath="url(#eP)"
          transform="rotate(68.986 70.094 49.444)"
        />
        <path
          fill="#f6f6f6"
          d="M69.01 49.144h2.866v.339H69.01z"
          clipPath="url(#eP)"
          transform="rotate(68.991 70.446 49.316)"
        />
        <path
          fill="#fbfbfb"
          d="M69.357 49.01h2.865v.34h-2.865z"
          clipPath="url(#eP)"
          transform="rotate(69.009 70.794 49.184)"
        />
        <path
          fill="#fff"
          d="M69.705 48.877h2.866v.339h-2.866z"
          clipPath="url(#eP)"
          transform="rotate(68.991 71.14 49.05)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eQ"
            d="M59.852 49.202l1.855.13-.206 2.939-1.855-.13.206-2.939z"
          />
        </defs>
        <clipPath id="eR">
          <use overflow="visible" xlinkHref="#eQ" />
        </clipPath>
        <path
          fill="#949494"
          d="M58.48 50.519h2.94v.34h-2.94z"
          clipPath="url(#eR)"
          transform="rotate(93.997 59.948 50.69)"
        />
        <path
          fill="#989898"
          d="M58.851 50.544h2.94v.341h-2.94z"
          clipPath="url(#eR)"
          transform="rotate(94.002 60.32 50.711)"
        />
        <path
          fill="#9d9d9d"
          d="M59.222 50.57h2.94v.34h-2.94z"
          clipPath="url(#eR)"
          transform="rotate(93.991 60.691 50.74)"
        />
        <path
          fill="#a1a1a1"
          d="M59.593 50.596h2.94v.34h-2.94z"
          clipPath="url(#eR)"
          transform="rotate(94.002 61.06 50.762)"
        />
        <path
          fill="#a6a6a6"
          d="M59.964 50.622h2.94v.34h-2.94z"
          clipPath="url(#eR)"
          transform="rotate(93.997 61.432 50.793)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eS"
            d="M58.018 48.912l1.836.29-.461 2.91-1.836-.29.461-2.91z"
          />
        </defs>
        <clipPath id="eT">
          <use overflow="visible" xlinkHref="#eS" />
        </clipPath>
        <path
          fill="#828282"
          d="M56.52 50.236h2.941v.33H56.52z"
          clipPath="url(#eT)"
          transform="rotate(99.009 57.989 50.397)"
        />
        <path
          fill="#878787"
          d="M56.888 50.294h2.941v.33h-2.941z"
          clipPath="url(#eT)"
          transform="rotate(98.998 58.358 50.46)"
        />
        <path
          fill="#8b8b8b"
          d="M57.255 50.353h2.941v.33h-2.941z"
          clipPath="url(#eT)"
          transform="rotate(98.998 58.726 50.516)"
        />
        <path
          fill="#8f8f8f"
          d="M57.622 50.411h2.942v.33h-2.942z"
          clipPath="url(#eT)"
          transform="rotate(99.004 59.092 50.578)"
        />
        <path
          fill="#949494"
          d="M57.99 50.469h2.942v.33H57.99z"
          clipPath="url(#eT)"
          transform="rotate(99.004 59.46 50.636)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eU"
            d="M56.215 48.462l1.805.45-.713 2.859-1.805-.45.713-2.859z"
          />
        </defs>
        <clipPath id="eV">
          <use overflow="visible" xlinkHref="#eU" />
        </clipPath>
        <path
          fill="#707070"
          d="M54.582 49.771h2.967v.324h-2.967z"
          clipPath="url(#eV)"
          transform="rotate(104.005 56.064 49.93)"
        />
        <path
          fill="#757575"
          d="M54.943 49.86h2.967v.324h-2.967z"
          clipPath="url(#eV)"
          transform="rotate(104.005 56.425 50.02)"
        />
        <path
          fill="#797979"
          d="M55.303 49.951h2.967v.324h-2.967z"
          clipPath="url(#eV)"
          transform="rotate(104.005 56.786 50.11)"
        />
        <path
          fill="#7e7e7e"
          d="M55.664 50.041h2.967v.324h-2.967z"
          clipPath="url(#eV)"
          transform="rotate(103.999 57.147 50.203)"
        />
        <path
          fill="#828282"
          d="M56.025 50.13h2.967v.324h-2.967z"
          clipPath="url(#eV)"
          transform="rotate(104.005 57.507 50.29)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eW"
            d="M54.459 47.857l1.758.606-.959 2.786-1.758-.606.959-2.786z"
          />
        </defs>
        <clipPath id="eX">
          <use overflow="visible" xlinkHref="#eW" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M52.68 49.122h3.014v.327H52.68z"
          clipPath="url(#eX)"
          transform="rotate(109.002 54.188 49.285)"
        />
        <path
          fill="#636363"
          d="M53.032 49.244h3.015v.327h-3.015z"
          clipPath="url(#eX)"
          transform="rotate(108.997 54.541 49.41)"
        />
        <path
          fill="#676767"
          d="M53.383 49.364h3.014v.327h-3.014z"
          clipPath="url(#eX)"
          transform="rotate(109.002 54.891 49.528)"
        />
        <path
          fill="#6c6c6c"
          d="M53.735 49.485h3.014v.327h-3.014z"
          clipPath="url(#eX)"
          transform="rotate(109.002 55.243 49.649)"
        />
        <path
          fill="#707070"
          d="M54.087 49.607h3.014v.327h-3.014z"
          clipPath="url(#eX)"
          transform="rotate(109.002 55.594 49.77)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="eY"
            d="M52.762 47.102l1.699.756-1.198 2.692-1.699-.756 1.198-2.692z"
          />
        </defs>
        <clipPath id="eZ">
          <use overflow="visible" xlinkHref="#eY" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M50.854 48.328h2.906v.412h-2.906z"
          clipPath="url(#eZ)"
          transform="rotate(114.004 52.308 48.532)"
        />
        <path
          fill="#515151"
          d="M51.194 48.479H54.1v.412h-2.906z"
          clipPath="url(#eZ)"
          transform="rotate(114.004 52.648 48.684)"
        />
        <path
          fill="#555"
          d="M51.533 48.631h2.907v.411h-2.907z"
          clipPath="url(#eZ)"
          transform="rotate(114.01 52.986 48.835)"
        />
        <path
          fill="#5a5a5a"
          d="M51.873 48.782h2.907v.412h-2.907z"
          clipPath="url(#eZ)"
          transform="rotate(114.01 53.325 48.986)"
        />
        <path
          fill="#5e5e5e"
          d="M52.213 48.933h2.906v.411h-2.906z"
          clipPath="url(#eZ)"
          transform="rotate(114.004 53.667 49.137)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fa"
            d="M47.185 42.891a24.424 24.424 0 0 0 2.444-3.851l2.862 1.816a24.856 24.856 0 0 1-1.145 1.977c-.477.75-.857 1.285-1.299 1.874l-2.862-1.816z"
          />
        </defs>
        <clipPath id="fb">
          <use overflow="visible" xlinkHref="#fa" />
        </clipPath>
        <path
          fill="#707070"
          d="M47.026 43.217h3.562v.641h-3.562z"
          clipPath="url(#fb)"
          transform="rotate(31.997 48.805 43.535)"
        />
        <path
          fill="#6f6f6f"
          d="M47.373 42.662h3.562v.641h-3.562z"
          clipPath="url(#fb)"
          transform="rotate(32 49.152 42.98)"
        />
        <path
          fill="#6e6e6e"
          d="M47.72 42.106h3.561v.641H47.72z"
          clipPath="url(#fb)"
          transform="rotate(32.001 49.504 42.43)"
        />
        <path
          fill="#6d6d6d"
          d="M48.067 41.551h3.562v.641h-3.562z"
          clipPath="url(#fb)"
          transform="rotate(31.997 49.846 41.869)"
        />
        <path
          fill="#6b6b6b"
          d="M48.414 40.995h3.562v.641h-3.562z"
          clipPath="url(#fb)"
          transform="rotate(32 50.193 41.313)"
        />
        <path
          fill="#6a6a6a"
          d="M48.762 40.44h3.561v.641h-3.561z"
          clipPath="url(#fb)"
          transform="rotate(32.001 50.546 40.763)"
        />
        <path
          fill="#696969"
          d="M49.109 39.884h3.562v.641h-3.562z"
          clipPath="url(#fb)"
          transform="rotate(31.997 50.887 40.203)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fc"
            d="M49.626 39.042a24.321 24.321 0 0 0 1.679-4.241l3.152 1.248a24.828 24.828 0 0 1-.754 2.156 27.152 27.152 0 0 1-.925 2.084l-3.152-1.247z"
          />
        </defs>
        <clipPath id="fd">
          <use overflow="visible" xlinkHref="#fc" />
        </clipPath>
        <path
          fill="#696969"
          d="M49.625 39.072h3.503v.625h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 51.37 39.378)"
        />
        <path
          fill="#686868"
          d="M49.86 38.458h3.503v.625H49.86z"
          clipPath="url(#fd)"
          transform="rotate(21.001 51.612 38.771)"
        />
        <path
          fill="#676767"
          d="M50.095 37.846h3.503v.625h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 51.84 38.152)"
        />
        <path
          fill="#656565"
          d="M50.331 37.232h3.503v.625h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 52.076 37.539)"
        />
        <path
          fill="#646464"
          d="M50.566 36.62h3.503v.624h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 52.312 36.926)"
        />
        <path
          fill="#636363"
          d="M50.801 36.006h3.503v.625h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 52.546 36.313)"
        />
        <path
          fill="#626262"
          d="M51.037 35.394h3.503v.624h-3.503z"
          clipPath="url(#fd)"
          transform="rotate(21.001 52.782 35.7)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fe"
            d="M51.303 34.806a24.33 24.33 0 0 0 .854-4.48l3.33.635c-.072.71-.17 1.384-.337 2.259-.167.874-.323 1.51-.518 2.221l-3.329-.635z"
          />
        </defs>
        <clipPath id="ff">
          <use overflow="visible" xlinkHref="#fe" />
        </clipPath>
        <path
          fill="#626262"
          d="M51.272 34.499h3.573v.63h-3.573z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.051 34.81)"
        />
        <path
          fill="#616161"
          d="M51.386 33.851h3.573v.63h-3.573z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.166 34.161)"
        />
        <path
          fill="#606060"
          d="M51.501 33.203h3.573v.63h-3.573z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.28 33.513)"
        />
        <path
          fill="#5e5e5e"
          d="M51.615 32.555h3.574v.63h-3.574z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.388 32.86)"
        />
        <path
          fill="#5d5d5d"
          d="M51.729 31.906h3.573v.63h-3.573z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.509 32.216)"
        />
        <path
          fill="#5c5c5c"
          d="M51.843 31.258h3.574v.63h-3.574z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.616 31.564)"
        />
        <path
          fill="#5b5b5b"
          d="M51.958 30.61h3.573v.63h-3.573z"
          clipPath="url(#ff)"
          transform="rotate(10.003 53.737 30.92)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fg"
            d="M52.157 30.33a24.298 24.298 0 0 0 0-4.561h3.39c.062.711.092 1.391.092 2.283 0 .889-.035 1.543-.092 2.278h-3.39z"
          />
        </defs>
        <clipPath id="fh">
          <use overflow="visible" xlinkHref="#fg" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M52.172 29.698h3.506v.611h-3.506z"
          clipPath="url(#fh)"
          transform="rotate(-.997 53.951 30.021)"
        />
        <path
          fill="#5a5a5a"
          d="M52.161 29.038h3.505v.611h-3.505z"
          clipPath="url(#fh)"
          transform="rotate(-.997 53.951 29.36)"
        />
        <path
          fill="#585858"
          d="M52.149 28.378h3.505v.611h-3.505z"
          clipPath="url(#fh)"
          transform="rotate(-1.009 53.748 28.604)"
        />
        <path
          fill="#575757"
          d="M52.138 27.718h3.505v.611h-3.505z"
          clipPath="url(#fh)"
          transform="rotate(-1.009 53.737 27.945)"
        />
        <path
          fill="#565656"
          d="M52.126 27.058h3.505v.611h-3.505z"
          clipPath="url(#fh)"
          transform="rotate(-.997 53.917 27.383)"
        />
        <path
          fill="#555"
          d="M52.115 26.399h3.505v.611h-3.505z"
          clipPath="url(#fh)"
          transform="rotate(-.997 53.905 26.722)"
        />
        <path
          fill="#545454"
          d="M52.103 25.739h3.506v.611h-3.506z"
          clipPath="url(#fh)"
          transform="rotate(-1.003 54.006 26.113)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fi"
            d="M52.156 25.773a24.35 24.35 0 0 0-.855-4.48l3.33-.635c.194.687.352 1.35.519 2.225.167.874.255 1.523.336 2.255l-3.33.635z"
          />
        </defs>
        <clipPath id="fj">
          <use overflow="visible" xlinkHref="#fi" />
        </clipPath>
        <path
          fill="#545454"
          d="M52.003 24.81h3.555v.678h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11 53.781 25.15)"
        />
        <path
          fill="#525252"
          d="M51.878 24.169h3.555v.678h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11 53.656 24.508)"
        />
        <path
          fill="#515151"
          d="M51.753 23.528h3.555v.678h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11 53.524 23.864)"
        />
        <path
          fill="#505050"
          d="M51.629 22.886h3.554v.678h-3.554z"
          clipPath="url(#fj)"
          transform="rotate(-10.993 53.398 23.223)"
        />
        <path
          fill="#4f4f4f"
          d="M51.504 22.245h3.555v.679h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11 53.275 22.583)"
        />
        <path
          fill="#4e4e4e"
          d="M51.379 21.604h3.555v.678h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11 53.158 21.943)"
        />
        <path
          fill="#4d4d4d"
          d="M51.255 20.963h3.555v.678h-3.555z"
          clipPath="url(#fj)"
          transform="rotate(-11.008 53.041 21.307)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fk"
            d="M38.557 23.036l2.875.404-.463 3.298-2.876-.404.464-3.298z"
          />
        </defs>
        <clipPath id="fl">
          <use overflow="visible" xlinkHref="#fk" />
        </clipPath>
        <path
          fill="#626262"
          d="M38.503 23.246h2.909v.545h-2.909z"
          clipPath="url(#fl)"
          transform="rotate(-172.013 39.957 23.519)"
        />
        <path
          fill="#606060"
          d="M38.426 23.795h2.91v.545h-2.91z"
          clipPath="url(#fl)"
          transform="rotate(-172.013 39.88 24.068)"
        />
        <path
          fill="#5f5f5f"
          d="M38.349 24.345h2.909v.544h-2.909z"
          clipPath="url(#fl)"
          transform="rotate(-172.013 39.802 24.618)"
        />
        <path
          fill="#5e5e5e"
          d="M38.271 24.895h2.909v.544h-2.909z"
          clipPath="url(#fl)"
          transform="rotate(-172.013 39.725 25.168)"
        />
        <path
          fill="#5c5c5c"
          d="M38.194 25.444h2.909v.545h-2.909z"
          clipPath="url(#fl)"
          transform="rotate(-172.013 39.648 25.717)"
        />
        <path
          fill="#5b5b5b"
          d="M38.117 25.994h2.909v.545h-2.909z"
          clipPath="url(#fl)"
          transform="rotate(-171.992 39.575 26.268)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fm"
            d="M38.064 26.806l2.904-.051.058 3.33-2.903.051-.059-3.33z"
          />
        </defs>
        <clipPath id="fn">
          <use overflow="visible" xlinkHref="#fm" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M38.069 26.715h2.968v.62h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(178.991 39.574 27.04)"
        />
        <path
          fill="#595959"
          d="M38.078 27.269h2.968v.62h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(179.003 39.563 27.579)"
        />
        <path
          fill="#585858"
          d="M38.088 27.825h2.968v.62h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(179.003 39.572 28.134)"
        />
        <path
          fill="#565656"
          d="M38.097 28.379h2.968V29h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(179.003 39.582 28.689)"
        />
        <path
          fill="#555"
          d="M38.107 28.935h2.968v.62h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(179.003 39.592 29.244)"
        />
        <path
          fill="#545454"
          d="M38.117 29.49h2.968v.62h-2.968z"
          clipPath="url(#fn)"
          transform="rotate(179.003 39.602 29.8)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fo"
            d="M38.168 30.606l2.86-.503.578 3.279-2.86.505-.578-3.281z"
          />
        </defs>
        <clipPath id="fp">
          <use overflow="visible" xlinkHref="#fo" />
        </clipPath>
        <path
          fill="#545454"
          d="M38.192 30.331h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(169.997 39.675 30.616)"
        />
        <path
          fill="#525252"
          d="M38.289 30.878h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(169.997 39.771 31.163)"
        />
        <path
          fill="#515151"
          d="M38.385 31.425h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(169.997 39.868 31.71)"
        />
        <path
          fill="#4f4f4f"
          d="M38.481 31.971h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(170.008 39.967 32.257)"
        />
        <path
          fill="#4e4e4e"
          d="M38.578 32.518h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(169.997 40.06 32.803)"
        />
        <path
          fill="#4d4d4d"
          d="M38.674 33.064h2.966v.569h-2.966z"
          clipPath="url(#fp)"
          transform="rotate(169.997 40.157 33.349)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fq"
            d="M39.633 19.389l2.777.849-.974 3.185-2.777-.849.974-3.185z"
          />
        </defs>
        <clipPath id="fr">
          <use overflow="visible" xlinkHref="#fq" />
        </clipPath>
        <path
          fill="#696969"
          d="M39.496 19.75h2.906v.608h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-162.993 40.947 20.054)"
        />
        <path
          fill="#686868"
          d="M39.333 20.281h2.906v.608h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-163.004 40.787 20.586)"
        />
        <path
          fill="#666"
          d="M39.171 20.812h2.906v.608h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-163.004 40.625 21.117)"
        />
        <path
          fill="#656565"
          d="M39.009 21.343h2.906v.608h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-162.999 40.462 21.646)"
        />
        <path
          fill="#636363"
          d="M38.847 21.874h2.906v.607h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-163.004 40.3 22.178)"
        />
        <path
          fill="#626262"
          d="M38.684 22.404h2.906v.607h-2.906z"
          clipPath="url(#fr)"
          transform="rotate(-163.004 40.138 22.709)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fs"
            d="M41.266 15.956l2.61 1.272-1.46 2.994-2.61-1.273 1.46-2.993z"
          />
        </defs>
        <clipPath id="ft">
          <use overflow="visible" xlinkHref="#fs" />
        </clipPath>
        <path
          fill="#707070"
          d="M40.989 16.601h2.89v.512h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 42.434 16.858)"
        />
        <path
          fill="#6f6f6f"
          d="M40.746 17.1h2.89v.512h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 42.19 17.357)"
        />
        <path
          fill="#6d6d6d"
          d="M40.502 17.599h2.89v.513h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 41.947 17.855)"
        />
        <path
          fill="#6c6c6c"
          d="M40.259 18.098h2.89v.512h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 41.704 18.354)"
        />
        <path
          fill="#6a6a6a"
          d="M40.016 18.597h2.89v.512h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 41.46 18.853)"
        />
        <path
          fill="#696969"
          d="M39.772 19.096h2.89v.512h-2.89z"
          clipPath="url(#ft)"
          transform="rotate(-154.001 41.217 19.352)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fu"
            d="M35.538 51.09l1.192-.423 1.124 3.176-1.191.423-1.125-3.176z"
          />
        </defs>
        <clipPath id="fv">
          <use overflow="visible" xlinkHref="#fu" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M34.527 52.513h3.36v.264h-3.36z"
          clipPath="url(#fv)"
          transform="rotate(70.998 36.208 52.644)"
        />
        <path
          fill="#4e4e4e"
          d="M34.772 52.429h3.36v.265h-3.36z"
          clipPath="url(#fv)"
          transform="rotate(71.003 36.453 52.564)"
        />
        <path
          fill="#505050"
          d="M35.017 52.345h3.36v.264h-3.36z"
          clipPath="url(#fv)"
          transform="rotate(70.998 36.697 52.475)"
        />
        <path
          fill="#525252"
          d="M35.261 52.26h3.36v.265h-3.36z"
          clipPath="url(#fv)"
          transform="rotate(71.003 36.943 52.396)"
        />
        <path
          fill="#545454"
          d="M35.506 52.176h3.36v.265h-3.36z"
          clipPath="url(#fv)"
          transform="rotate(71.003 37.188 52.311)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fw"
            d="M36.733 50.666l1.169-.484 1.289 3.113-1.169.484-1.289-3.113z"
          />
        </defs>
        <clipPath id="fx">
          <use overflow="visible" xlinkHref="#fw" />
        </clipPath>
        <path
          fill="#545454"
          d="M35.786 52.041h3.42v.213h-3.42z"
          clipPath="url(#fx)"
          transform="rotate(68.007 37.498 52.147)"
        />
        <path
          fill="#555"
          d="M36.026 51.944h3.42v.213h-3.42z"
          clipPath="url(#fx)"
          transform="rotate(68 37.736 52.047)"
        />
        <path
          fill="#575757"
          d="M36.266 51.847h3.42v.213h-3.42z"
          clipPath="url(#fx)"
          transform="rotate(68.007 37.978 51.953)"
        />
        <path
          fill="#595959"
          d="M36.506 51.75h3.42v.213h-3.42z"
          clipPath="url(#fx)"
          transform="rotate(68 38.216 51.854)"
        />
        <path
          fill="#5b5b5b"
          d="M36.747 51.653h3.42v.212h-3.42z"
          clipPath="url(#fx)"
          transform="rotate(67.995 38.454 51.756)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fy"
            d="M37.905 50.181l1.142-.545 1.45 3.041-1.141.544-1.451-3.04z"
          />
        </defs>
        <clipPath id="fz">
          <use overflow="visible" xlinkHref="#fy" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M37.076 51.54h3.361v.212h-3.361z"
          clipPath="url(#fz)"
          transform="rotate(65.001 38.758 51.647)"
        />
        <path
          fill="#5d5d5d"
          d="M37.311 51.431h3.361v.212h-3.361z"
          clipPath="url(#fz)"
          transform="rotate(65.001 38.993 51.538)"
        />
        <path
          fill="#5e5e5e"
          d="M37.546 51.321h3.361v.212h-3.361z"
          clipPath="url(#fz)"
          transform="rotate(65.001 39.227 51.429)"
        />
        <path
          fill="#606060"
          d="M37.78 51.212h3.361v.212H37.78z"
          clipPath="url(#fz)"
          transform="rotate(64.996 39.46 51.316)"
        />
        <path
          fill="#626262"
          d="M38.015 51.103h3.361v.212h-3.361z"
          clipPath="url(#fz)"
          transform="rotate(64.996 39.694 51.206)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fA"
            d="M39.05 49.634l1.112-.603 1.607 2.96-1.111.604-1.608-2.961z"
          />
        </defs>
        <clipPath id="fB">
          <use overflow="visible" xlinkHref="#fA" />
        </clipPath>
        <path
          fill="#626262"
          d="M38.232 50.908h3.375v.336h-3.375z"
          clipPath="url(#fB)"
          transform="rotate(61.992 39.918 51.077)"
        />
        <path
          fill="#646464"
          d="M38.46 50.787h3.376v.336H38.46z"
          clipPath="url(#fB)"
          transform="rotate(61.997 40.149 50.956)"
        />
        <path
          fill="#656565"
          d="M38.688 50.665h3.375v.336h-3.375z"
          clipPath="url(#fB)"
          transform="rotate(62.005 40.375 50.832)"
        />
        <path
          fill="#676767"
          d="M38.917 50.544h3.376v.336h-3.376z"
          clipPath="url(#fB)"
          transform="rotate(61.997 40.606 50.713)"
        />
        <path
          fill="#696969"
          d="M39.146 50.422h3.375v.336h-3.375z"
          clipPath="url(#fB)"
          transform="rotate(62.005 40.832 50.59)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fC"
            d="M40.165 49.029l1.078-.661 1.76 2.873-1.078.66-1.76-2.872z"
          />
        </defs>
        <clipPath id="fD">
          <use overflow="visible" xlinkHref="#fC" />
        </clipPath>
        <path
          fill="#696969"
          d="M39.385 50.235h3.458v.274h-3.458z"
          clipPath="url(#fD)"
          transform="rotate(59.003 41.113 50.371)"
        />
        <path
          fill="#6b6b6b"
          d="M39.607 50.102h3.458v.274h-3.458z"
          clipPath="url(#fD)"
          transform="rotate(58.995 41.337 50.24)"
        />
        <path
          fill="#6d6d6d"
          d="M39.828 49.969h3.458v.274h-3.458z"
          clipPath="url(#fD)"
          transform="rotate(58.995 41.558 50.107)"
        />
        <path
          fill="#6e6e6e"
          d="M40.05 49.835h3.457v.274H40.05z"
          clipPath="url(#fD)"
          transform="rotate(58.995 41.78 49.976)"
        />
        <path
          fill="#707070"
          d="M40.272 49.702h3.457v.274h-3.457z"
          clipPath="url(#fD)"
          transform="rotate(58.995 42.002 49.843)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fE"
            d="M27.491 49.332l1.859-.033.051 2.946-1.859.032-.051-2.945z"
          />
        </defs>
        <clipPath id="fF">
          <use overflow="visible" xlinkHref="#fE" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M26.231 50.617h2.963v.352h-2.963z"
          clipPath="url(#fF)"
          transform="rotate(88.997 27.714 50.796)"
        />
        <path
          fill="#aaa"
          d="M26.603 50.61h2.964v.352h-2.964z"
          clipPath="url(#fF)"
          transform="rotate(88.997 28.085 50.79)"
        />
        <path
          fill="#afafaf"
          d="M26.975 50.604h2.963v.352h-2.963z"
          clipPath="url(#fF)"
          transform="rotate(88.997 28.458 50.783)"
        />
        <path
          fill="#b3b3b3"
          d="M27.347 50.598h2.963v.351h-2.963z"
          clipPath="url(#fF)"
          transform="rotate(88.997 28.83 50.777)"
        />
        <path
          fill="#b8b8b8"
          d="M27.719 50.591h2.963v.352h-2.963z"
          clipPath="url(#fF)"
          transform="rotate(88.997 29.201 50.77)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fG"
            d="M29.348 49.299l1.849-.194.308 2.93-1.849.194-.308-2.93z"
          />
        </defs>
        <clipPath id="fH">
          <use overflow="visible" xlinkHref="#fG" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M28.185 50.532h3.011v.359h-3.011z"
          clipPath="url(#fH)"
          transform="rotate(83.996 29.69 50.712)"
        />
        <path
          fill="#bcbcbc"
          d="M28.554 50.494h3.011v.359h-3.011z"
          clipPath="url(#fH)"
          transform="rotate(84.007 30.06 50.674)"
        />
        <path
          fill="#c1c1c1"
          d="M28.924 50.455h3.011v.359h-3.011z"
          clipPath="url(#fH)"
          transform="rotate(83.996 30.43 50.634)"
        />
        <path
          fill="#c5c5c5"
          d="M29.294 50.416h3.011v.359h-3.011z"
          clipPath="url(#fH)"
          transform="rotate(84.007 30.8 50.595)"
        />
        <path
          fill="#c9c9c9"
          d="M29.663 50.377h3.011v.359h-3.011z"
          clipPath="url(#fH)"
          transform="rotate(84.007 31.17 50.557)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fI"
            d="M31.195 49.105l1.826-.355.562 2.893-1.826.355-.562-2.893z"
          />
        </defs>
        <clipPath id="fJ">
          <use overflow="visible" xlinkHref="#fI" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M30.177 50.323h2.95v.385h-2.95z"
          clipPath="url(#fJ)"
          transform="rotate(79.006 31.653 50.518)"
        />
        <path
          fill="#cecece"
          d="M30.542 50.252h2.95v.385h-2.95z"
          clipPath="url(#fJ)"
          transform="rotate(79 32.018 50.445)"
        />
        <path
          fill="#d2d2d2"
          d="M30.907 50.181h2.95v.385h-2.95z"
          clipPath="url(#fJ)"
          transform="rotate(79 32.383 50.374)"
        />
        <path
          fill="#d7d7d7"
          d="M31.272 50.11h2.95v.385h-2.95z"
          clipPath="url(#fJ)"
          transform="rotate(79.006 32.749 50.305)"
        />
        <path
          fill="#dbdbdb"
          d="M31.637 50.039h2.95v.385h-2.95z"
          clipPath="url(#fJ)"
          transform="rotate(79 33.113 50.232)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fK"
            d="M33.019 48.751l1.787-.512.812 2.832-1.787.512-.812-2.832z"
          />
        </defs>
        <clipPath id="fL">
          <use overflow="visible" xlinkHref="#fK" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M32.124 49.926h2.918v.422h-2.918z"
          clipPath="url(#fL)"
          transform="rotate(74.003 33.582 50.134)"
        />
        <path
          fill="#e0e0e0"
          d="M32.482 49.824H35.4v.422h-2.918z"
          clipPath="url(#fL)"
          transform="rotate(74.003 33.94 50.031)"
        />
        <path
          fill="#e4e4e4"
          d="M32.84 49.721h2.917v.422H32.84z"
          clipPath="url(#fL)"
          transform="rotate(73.997 34.296 49.93)"
        />
        <path
          fill="#e9e9e9"
          d="M33.197 49.619h2.917v.422h-2.917z"
          clipPath="url(#fL)"
          transform="rotate(73.997 34.654 49.827)"
        />
        <path
          fill="#ededed"
          d="M33.554 49.516h2.917v.422h-2.917z"
          clipPath="url(#fL)"
          transform="rotate(73.99 35.014 49.726)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fM"
            d="M34.804 48.239l1.736-.666 1.056 2.751-1.736.666-1.056-2.751z"
          />
        </defs>
        <clipPath id="fN">
          <use overflow="visible" xlinkHref="#fM" />
        </clipPath>
        <path
          fill="#ededed"
          d="M34.102 49.41h2.866v.34h-2.866z"
          clipPath="url(#fN)"
          transform="rotate(69.004 35.535 49.578)"
        />
        <path
          fill="#f2f2f2"
          d="M34.449 49.277h2.866v.34h-2.866z"
          clipPath="url(#fN)"
          transform="rotate(69.004 35.882 49.445)"
        />
        <path
          fill="#f6f6f6"
          d="M34.797 49.144h2.866v.339h-2.866z"
          clipPath="url(#fN)"
          transform="rotate(69.004 36.23 49.311)"
        />
        <path
          fill="#fbfbfb"
          d="M35.144 49.01h2.866v.339h-2.866z"
          clipPath="url(#fN)"
          transform="rotate(68.991 36.578 49.183)"
        />
        <path
          fill="#fff"
          d="M35.491 48.877h2.866v.34h-2.866z"
          clipPath="url(#fN)"
          transform="rotate(69.004 36.924 49.045)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fO"
            d="M25.638 49.202l1.855.13-.205 2.939-1.855-.13.205-2.939z"
          />
        </defs>
        <clipPath id="fP">
          <use overflow="visible" xlinkHref="#fO" />
        </clipPath>
        <path
          fill="#949494"
          d="M24.266 50.519h2.94v.34h-2.94z"
          clipPath="url(#fP)"
          transform="rotate(93.997 25.735 50.689)"
        />
        <path
          fill="#989898"
          d="M24.638 50.544h2.94v.341h-2.94z"
          clipPath="url(#fP)"
          transform="rotate(94.002 26.107 50.711)"
        />
        <path
          fill="#9d9d9d"
          d="M25.008 50.571h2.941v.34h-2.941z"
          clipPath="url(#fP)"
          transform="rotate(93.997 26.476 50.74)"
        />
        <path
          fill="#a1a1a1"
          d="M25.379 50.596h2.94v.34h-2.94z"
          clipPath="url(#fP)"
          transform="rotate(94.002 26.849 50.763)"
        />
        <path
          fill="#a6a6a6"
          d="M25.75 50.622h2.94v.34h-2.94z"
          clipPath="url(#fP)"
          transform="rotate(93.997 27.219 50.792)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fQ"
            d="M23.804 48.912l1.836.29-.461 2.91-1.836-.29.461-2.91z"
          />
        </defs>
        <clipPath id="fR">
          <use overflow="visible" xlinkHref="#fQ" />
        </clipPath>
        <path
          fill="#828282"
          d="M22.306 50.236h2.941v.33h-2.941z"
          clipPath="url(#fR)"
          transform="rotate(98.998 23.777 50.4)"
        />
        <path
          fill="#878787"
          d="M22.674 50.294h2.941v.33h-2.941z"
          clipPath="url(#fR)"
          transform="rotate(99.009 24.144 50.456)"
        />
        <path
          fill="#8b8b8b"
          d="M23.041 50.353h2.941v.33h-2.941z"
          clipPath="url(#fR)"
          transform="rotate(98.998 24.512 50.517)"
        />
        <path
          fill="#8f8f8f"
          d="M23.408 50.411h2.941v.33h-2.941z"
          clipPath="url(#fR)"
          transform="rotate(98.998 24.88 50.575)"
        />
        <path
          fill="#949494"
          d="M23.776 50.469h2.942v.33h-2.942z"
          clipPath="url(#fR)"
          transform="rotate(99.004 25.245 50.635)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fS"
            d="M22.001 48.462l1.805.45-.713 2.859-1.804-.45.712-2.859z"
          />
        </defs>
        <clipPath id="fT">
          <use overflow="visible" xlinkHref="#fS" />
        </clipPath>
        <path
          fill="#707070"
          d="M20.368 49.77h2.966v.324h-2.966z"
          clipPath="url(#fT)"
          transform="rotate(103.993 21.852 49.934)"
        />
        <path
          fill="#757575"
          d="M20.729 49.86h2.967v.324h-2.967z"
          clipPath="url(#fT)"
          transform="rotate(104.005 22.212 50.02)"
        />
        <path
          fill="#797979"
          d="M21.089 49.951h2.967v.324h-2.967z"
          clipPath="url(#fT)"
          transform="rotate(104.005 22.573 50.11)"
        />
        <path
          fill="#7e7e7e"
          d="M21.45 50.04h2.967v.324H21.45z"
          clipPath="url(#fT)"
          transform="rotate(104.005 22.934 50.2)"
        />
        <path
          fill="#828282"
          d="M21.811 50.13h2.967v.324h-2.967z"
          clipPath="url(#fT)"
          transform="rotate(104.005 23.294 50.29)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fU"
            d="M20.245 47.857l1.758.606-.959 2.786-1.758-.606.959-2.786z"
          />
        </defs>
        <clipPath id="fV">
          <use overflow="visible" xlinkHref="#fU" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M18.466 49.122h3.014v.328h-3.014z"
          clipPath="url(#fV)"
          transform="rotate(109.002 19.974 49.286)"
        />
        <path
          fill="#636363"
          d="M18.818 49.244h3.015v.327h-3.015z"
          clipPath="url(#fV)"
          transform="rotate(108.997 20.326 49.41)"
        />
        <path
          fill="#676767"
          d="M19.169 49.365h3.015v.327h-3.015z"
          clipPath="url(#fV)"
          transform="rotate(108.997 20.677 49.531)"
        />
        <path
          fill="#6c6c6c"
          d="M19.521 49.485h3.014v.327h-3.014z"
          clipPath="url(#fV)"
          transform="rotate(109.002 21.029 49.649)"
        />
        <path
          fill="#707070"
          d="M19.872 49.607h3.015v.327h-3.015z"
          clipPath="url(#fV)"
          transform="rotate(108.997 21.38 49.773)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="fW"
            d="M18.548 47.102l1.699.756-1.198 2.692-1.699-.756 1.198-2.692z"
          />
        </defs>
        <clipPath id="fX">
          <use overflow="visible" xlinkHref="#fW" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M16.64 48.328h2.906v.412H16.64z"
          clipPath="url(#fX)"
          transform="rotate(114.004 18.094 48.533)"
        />
        <path
          fill="#515151"
          d="M16.98 48.479h2.906v.412H16.98z"
          clipPath="url(#fX)"
          transform="rotate(114.004 18.434 48.685)"
        />
        <path
          fill="#555"
          d="M17.319 48.631h2.907v.411h-2.907z"
          clipPath="url(#fX)"
          transform="rotate(113.997 18.77 48.836)"
        />
        <path
          fill="#5a5a5a"
          d="M17.659 48.782h2.907v.411h-2.907z"
          clipPath="url(#fX)"
          transform="rotate(113.997 19.111 48.986)"
        />
        <path
          fill="#5e5e5e"
          d="M17.999 48.934h2.907v.411h-2.907z"
          clipPath="url(#fX)"
          transform="rotate(113.997 19.45 49.138)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="fY" d="M.48 26.561h3.369v2.977H.48v-2.977z" />
        </defs>
        <clipPath id="fZ">
          <use overflow="visible" xlinkHref="#fY" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M.48 26.619h3.36v.538H.48z"
          clipPath="url(#fZ)"
        />
        <path
          fill="#c5c5c5"
          d="M.48 27.214h3.36v.537H.48z"
          clipPath="url(#fZ)"
        />
        <path
          fill="#c1c1c1"
          d="M.48 27.81h3.36v.538H.48z"
          clipPath="url(#fZ)"
        />
        <path
          fill="#bcbcbc"
          d="M.48 28.405h3.36v.538H.48z"
          clipPath="url(#fZ)"
        />
        <path fill="#b8b8b8" d="M.48 29h3.36v.538H.48z" clipPath="url(#fZ)" />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ga"
            d="M.504 29.932l3.344-.411.362 2.955-3.343.41-.363-2.954z"
          />
        </defs>
        <clipPath id="gb">
          <use overflow="visible" xlinkHref="#ga" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M.531 29.782h3.316v.541H.531z"
          clipPath="url(#gb)"
          transform="rotate(173.004 2.19 30.052)"
        />
        <path
          fill="#b3b3b3"
          d="M.604 30.373H3.92v.541H.604z"
          clipPath="url(#gb)"
          transform="rotate(172.998 2.262 30.644)"
        />
        <path
          fill="#afafaf"
          d="M.676 30.964h3.316v.541H.676z"
          clipPath="url(#gb)"
          transform="rotate(173.004 2.334 31.234)"
        />
        <path
          fill="#aaa"
          d="M.749 31.555h3.316v.541H.749z"
          clipPath="url(#gb)"
          transform="rotate(173.004 2.407 31.825)"
        />
        <path
          fill="#a6a6a6"
          d="M.821 32.146h3.316v.541H.821z"
          clipPath="url(#gb)"
          transform="rotate(172.998 2.48 32.417)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gc"
            d="M.938 33.275l3.27-.815.72 2.888-3.269.815-.721-2.888z"
          />
        </defs>
        <clipPath id="gd">
          <use overflow="visible" xlinkHref="#gc" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M.966 32.902H4.29v.556H.966z"
          clipPath="url(#gd)"
          transform="rotate(166.001 2.628 33.18)"
        />
        <path
          fill="#a1a1a1"
          d="M1.11 33.48h3.325v.556H1.11z"
          clipPath="url(#gd)"
          transform="rotate(166.001 2.772 33.758)"
        />
        <path
          fill="#9d9d9d"
          d="M1.254 34.058h3.324v.556H1.254z"
          clipPath="url(#gd)"
          transform="rotate(166.001 2.916 34.336)"
        />
        <path
          fill="#989898"
          d="M1.398 34.636h3.325v.557H1.398z"
          clipPath="url(#gd)"
          transform="rotate(166.001 3.06 34.914)"
        />
        <path
          fill="#949494"
          d="M1.542 35.213h3.325v.556H1.542z"
          clipPath="url(#gd)"
          transform="rotate(166.001 3.205 35.491)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ge"
            d="M1.777 36.54l3.145-1.207 1.067 2.779-3.145 1.207-1.067-2.779z"
          />
        </defs>
        <clipPath id="gf">
          <use overflow="visible" xlinkHref="#ge" />
        </clipPath>
        <path
          fill="#949494"
          d="M1.776 35.937h3.387v.572H1.776z"
          clipPath="url(#gf)"
          transform="rotate(158.999 3.47 36.22)"
        />
        <path
          fill="#8f8f8f"
          d="M1.989 36.492h3.387v.572H1.989z"
          clipPath="url(#gf)"
          transform="rotate(158.999 3.683 36.776)"
        />
        <path
          fill="#8b8b8b"
          d="M2.202 37.048h3.387v.572H2.202z"
          clipPath="url(#gf)"
          transform="rotate(159.003 3.895 37.334)"
        />
        <path
          fill="#878787"
          d="M2.416 37.604h3.386v.572H2.416z"
          clipPath="url(#gf)"
          transform="rotate(159.003 4.11 37.89)"
        />
        <path
          fill="#828282"
          d="M2.629 38.16h3.386v.572H2.629z"
          clipPath="url(#gf)"
          transform="rotate(159.003 4.323 38.446)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gg"
            d="M3.008 39.679l2.974-1.582 1.398 2.628-2.975 1.582-1.397-2.628z"
          />
        </defs>
        <clipPath id="gh">
          <use overflow="visible" xlinkHref="#gg" />
        </clipPath>
        <path
          fill="#828282"
          d="M2.94 38.81h3.383v.637H2.94z"
          clipPath="url(#gh)"
          transform="rotate(152.006 4.632 39.128)"
        />
        <path
          fill="#7e7e7e"
          d="M3.219 39.336h3.383v.637H3.219z"
          clipPath="url(#gh)"
          transform="rotate(151.997 4.91 39.657)"
        />
        <path
          fill="#797979"
          d="M3.499 39.862h3.383v.637H3.499z"
          clipPath="url(#gh)"
          transform="rotate(152.006 5.19 40.18)"
        />
        <path
          fill="#757575"
          d="M3.778 40.387h3.383v.637H3.778z"
          clipPath="url(#gh)"
          transform="rotate(152.006 5.469 40.706)"
        />
        <path
          fill="#707070"
          d="M4.058 40.913h3.383v.637H4.058z"
          clipPath="url(#gh)"
          transform="rotate(152.006 5.75 41.231)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gi"
            d="M4.611 42.644l2.76-1.933 1.708 2.439-2.76 1.932-1.708-2.438z"
          />
        </defs>
        <clipPath id="gj">
          <use overflow="visible" xlinkHref="#gi" />
        </clipPath>
        <path
          fill="#707070"
          d="M4.478 41.625h3.369v.594H4.478z"
          clipPath="url(#gj)"
          transform="rotate(144.997 6.163 41.923)"
        />
        <path
          fill="#6c6c6c"
          d="M4.82 42.113h3.369v.594H4.82z"
          clipPath="url(#gj)"
          transform="rotate(145.005 6.503 42.41)"
        />
        <path
          fill="#676767"
          d="M5.161 42.601h3.37v.594h-3.37z"
          clipPath="url(#gj)"
          transform="rotate(145.005 6.847 42.897)"
        />
        <path
          fill="#636363"
          d="M5.503 43.088h3.369v.594H5.503z"
          clipPath="url(#gj)"
          transform="rotate(144.997 7.188 43.387)"
        />
        <path
          fill="#5e5e5e"
          d="M5.844 43.576h3.369v.594H5.844z"
          clipPath="url(#gj)"
          transform="rotate(144.997 7.53 43.875)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gk"
            d="M6.564 45.392l2.504-2.255 1.992 2.213-2.503 2.254-1.993-2.212z"
          />
        </defs>
        <clipPath id="gl">
          <use overflow="visible" xlinkHref="#gk" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M6.34 44.211h3.397v.559H6.34z"
          clipPath="url(#gl)"
          transform="rotate(138.005 8.039 44.489)"
        />
        <path
          fill="#5a5a5a"
          d="M6.738 44.653h3.397v.559H6.738z"
          clipPath="url(#gl)"
          transform="rotate(138.008 8.437 44.934)"
        />
        <path
          fill="#555"
          d="M7.137 45.096h3.397v.558H7.137z"
          clipPath="url(#gl)"
          transform="rotate(137.988 8.834 45.375)"
        />
        <path
          fill="#515151"
          d="M7.535 45.538h3.397v.559H7.535z"
          clipPath="url(#gl)"
          transform="rotate(138.005 9.234 45.817)"
        />
        <path
          fill="#4d4d4d"
          d="M7.934 45.981h3.397v.558H7.934z"
          clipPath="url(#gl)"
          transform="rotate(137.996 9.632 46.26)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gm"
            d="M.867 23.212l3.343.411-.362 2.954-3.344-.41.363-2.955z"
          />
        </defs>
        <clipPath id="gn">
          <use overflow="visible" xlinkHref="#gm" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M.815 23.464h3.318v.543H.815z"
          clipPath="url(#gn)"
          transform="rotate(-173.004 2.474 23.736)"
        />
        <path
          fill="#d7d7d7"
          d="M.742 24.056h3.319v.543H.742z"
          clipPath="url(#gn)"
          transform="rotate(-173.004 2.401 24.327)"
        />
        <path
          fill="#d2d2d2"
          d="M.67 24.646h3.319v.543H.67z"
          clipPath="url(#gn)"
          transform="rotate(-173.004 2.329 24.918)"
        />
        <path
          fill="#cecece"
          d="M.597 25.237h3.319v.543H.597z"
          clipPath="url(#gn)"
          transform="rotate(-172.992 2.257 25.51)"
        />
        <path
          fill="#c9c9c9"
          d="M.525 25.829h3.319v.543H.525z"
          clipPath="url(#gn)"
          transform="rotate(-173.004 2.183 26.1)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="go"
            d="M1.659 19.935l3.269.815-.72 2.888-3.27-.815.721-2.888z"
          />
        </defs>
        <clipPath id="gp">
          <use overflow="visible" xlinkHref="#go" />
        </clipPath>
        <path
          fill="#ededed"
          d="M1.532 20.371h3.325v.552H1.532z"
          clipPath="url(#gp)"
          transform="rotate(-166.001 3.194 20.647)"
        />
        <path
          fill="#e9e9e9"
          d="M1.388 20.949h3.325v.553H1.388z"
          clipPath="url(#gp)"
          transform="rotate(-166.001 3.05 21.225)"
        />
        <path
          fill="#e4e4e4"
          d="M1.244 21.527h3.325v.552H1.244z"
          clipPath="url(#gp)"
          transform="rotate(-166.007 2.906 21.803)"
        />
        <path
          fill="#e0e0e0"
          d="M1.1 22.105h3.325v.552H1.1z"
          clipPath="url(#gp)"
          transform="rotate(-166.001 2.762 22.38)"
        />
        <path
          fill="#dbdbdb"
          d="M.956 22.682h3.325v.552H.956z"
          clipPath="url(#gp)"
          transform="rotate(-166.001 2.619 22.958)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gq"
            d="M2.844 16.779l3.145 1.208-1.067 2.779-3.145-1.207 1.067-2.78z"
          />
        </defs>
        <clipPath id="gr">
          <use overflow="visible" xlinkHref="#gq" />
        </clipPath>
        <path
          fill="#fff"
          d="M2.622 17.383H6v.577H2.622z"
          clipPath="url(#gr)"
          transform="rotate(-158.999 4.31 17.67)"
        />
        <path
          fill="#fbfbfb"
          d="M2.408 17.939h3.378v.577H2.408z"
          clipPath="url(#gr)"
          transform="rotate(-158.999 4.097 18.226)"
        />
        <path
          fill="#f6f6f6"
          d="M2.195 18.495h3.378v.576H2.195z"
          clipPath="url(#gr)"
          transform="rotate(-158.999 3.884 18.782)"
        />
        <path
          fill="#f2f2f2"
          d="M1.982 19.05H5.36v.577H1.982z"
          clipPath="url(#gr)"
          transform="rotate(-158.999 3.67 19.338)"
        />
        <path
          fill="#ededed"
          d="M1.768 19.607h3.378v.576H1.768z"
          clipPath="url(#gr)"
          transform="rotate(-159.003 3.457 19.895)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gs"
            d="M4.343 23.036l2.875.404-.463 3.298-2.876-.404.464-3.298z"
          />
        </defs>
        <clipPath id="gt">
          <use overflow="visible" xlinkHref="#gs" />
        </clipPath>
        <path
          fill="#626262"
          d="M4.289 23.246h2.909v.545H4.289z"
          clipPath="url(#gt)"
          transform="rotate(-172.013 5.743 23.518)"
        />
        <path
          fill="#606060"
          d="M4.212 23.795h2.91v.545h-2.91z"
          clipPath="url(#gt)"
          transform="rotate(-172.013 5.666 24.068)"
        />
        <path
          fill="#5f5f5f"
          d="M4.135 24.345h2.909v.544H4.135z"
          clipPath="url(#gt)"
          transform="rotate(-172.013 5.589 24.617)"
        />
        <path
          fill="#5e5e5e"
          d="M4.058 24.895h2.909v.544H4.058z"
          clipPath="url(#gt)"
          transform="rotate(-172.013 5.512 25.167)"
        />
        <path
          fill="#5c5c5c"
          d="M3.98 25.444h2.909v.545H3.98z"
          clipPath="url(#gt)"
          transform="rotate(-172.013 5.434 25.717)"
        />
        <path
          fill="#5b5b5b"
          d="M3.903 25.994h2.91v.545h-2.91z"
          clipPath="url(#gt)"
          transform="rotate(-171.993 5.357 26.265)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gu"
            d="M3.851 26.806l2.903-.051.058 3.33-2.903.051-.058-3.33z"
          />
        </defs>
        <clipPath id="gv">
          <use overflow="visible" xlinkHref="#gu" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M3.855 26.715h2.968v.62H3.855z"
          clipPath="url(#gv)"
          transform="rotate(178.991 5.342 27.04)"
        />
        <path
          fill="#595959"
          d="M3.864 27.269h2.968v.62H3.864z"
          clipPath="url(#gv)"
          transform="rotate(179.003 5.349 27.58)"
        />
        <path
          fill="#585858"
          d="M3.874 27.825h2.968v.62H3.874z"
          clipPath="url(#gv)"
          transform="rotate(179.003 5.358 28.135)"
        />
        <path
          fill="#565656"
          d="M3.883 28.379h2.968V29H3.883z"
          clipPath="url(#gv)"
          transform="rotate(179.003 5.368 28.69)"
        />
        <path
          fill="#555"
          d="M3.893 28.935h2.968v.62H3.893z"
          clipPath="url(#gv)"
          transform="rotate(179.003 5.377 29.245)"
        />
        <path
          fill="#545454"
          d="M3.903 29.49h2.968v.62H3.903z"
          clipPath="url(#gv)"
          transform="rotate(179.003 5.388 29.8)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gw"
            d="M3.954 30.606l2.859-.503.579 3.279-2.86.505-.578-3.281z"
          />
        </defs>
        <clipPath id="gx">
          <use overflow="visible" xlinkHref="#gw" />
        </clipPath>
        <path
          fill="#545454"
          d="M3.978 30.331h2.966v.569H3.978z"
          clipPath="url(#gx)"
          transform="rotate(169.997 5.461 30.616)"
        />
        <path
          fill="#525252"
          d="M4.074 30.878H7.04v.569H4.074z"
          clipPath="url(#gx)"
          transform="rotate(169.997 5.557 31.162)"
        />
        <path
          fill="#515151"
          d="M4.171 31.425h2.966v.569H4.171z"
          clipPath="url(#gx)"
          transform="rotate(169.997 5.654 31.709)"
        />
        <path
          fill="#4f4f4f"
          d="M4.268 31.971h2.966v.569H4.268z"
          clipPath="url(#gx)"
          transform="rotate(170.008 5.751 32.257)"
        />
        <path
          fill="#4e4e4e"
          d="M4.364 32.518H7.33v.569H4.364z"
          clipPath="url(#gx)"
          transform="rotate(169.997 5.847 32.802)"
        />
        <path
          fill="#4d4d4d"
          d="M4.46 33.064h2.966v.569H4.46z"
          clipPath="url(#gx)"
          transform="rotate(169.997 5.943 33.349)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gy"
            d="M5.419 19.389l2.777.849-.974 3.185-2.777-.849.974-3.185z"
          />
        </defs>
        <clipPath id="gz">
          <use overflow="visible" xlinkHref="#gy" />
        </clipPath>
        <path
          fill="#696969"
          d="M5.282 19.75h2.906v.607H5.282z"
          clipPath="url(#gz)"
          transform="rotate(-162.993 6.735 20.053)"
        />
        <path
          fill="#686868"
          d="M5.12 20.281h2.906v.608H5.12z"
          clipPath="url(#gz)"
          transform="rotate(-163.004 6.572 20.585)"
        />
        <path
          fill="#666"
          d="M4.957 20.812h2.906v.607H4.957z"
          clipPath="url(#gz)"
          transform="rotate(-163.004 6.41 21.116)"
        />
        <path
          fill="#656565"
          d="M4.795 21.343h2.906v.608H4.795z"
          clipPath="url(#gz)"
          transform="rotate(-163.004 6.248 21.647)"
        />
        <path
          fill="#636363"
          d="M4.633 21.874h2.906v.607H4.633z"
          clipPath="url(#gz)"
          transform="rotate(-163.004 6.086 22.178)"
        />
        <path
          fill="#626262"
          d="M4.47 22.404h2.906v.608H4.47z"
          clipPath="url(#gz)"
          transform="rotate(-163.004 5.923 22.708)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gA"
            d="M7.052 15.956l2.61 1.272-1.46 2.994-2.61-1.273 1.46-2.993z"
          />
        </defs>
        <clipPath id="gB">
          <use overflow="visible" xlinkHref="#gA" />
        </clipPath>
        <path
          fill="#707070"
          d="M6.775 16.601h2.89v.513h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 8.22 16.857)"
        />
        <path
          fill="#6f6f6f"
          d="M6.532 17.1h2.89v.512h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 7.977 17.356)"
        />
        <path
          fill="#6d6d6d"
          d="M6.289 17.599h2.89v.513h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 7.733 17.855)"
        />
        <path
          fill="#6c6c6c"
          d="M6.045 18.098h2.89v.512h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 7.49 18.354)"
        />
        <path
          fill="#6a6a6a"
          d="M5.802 18.597h2.89v.512h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 7.247 18.853)"
        />
        <path
          fill="#696969"
          d="M5.559 19.096h2.89v.513h-2.89z"
          clipPath="url(#gB)"
          transform="rotate(-154.001 7.003 19.352)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gC"
            d="M14.047 8.285l.923-.623 1.647 2.443-.923.623-1.647-2.443z"
          />
        </defs>
        <clipPath id="gD">
          <use overflow="visible" xlinkHref="#gC" />
        </clipPath>
        <path
          fill="#707070"
          d="M13.465 9.319h2.946v.282h-2.946z"
          clipPath="url(#gD)"
          transform="rotate(56.006 14.938 9.46)"
        />
        <path
          fill="#6e6e6e"
          d="M13.65 9.194h2.946v.282H13.65z"
          clipPath="url(#gD)"
          transform="rotate(55.998 15.123 9.336)"
        />
        <path
          fill="#6d6d6d"
          d="M13.834 9.07h2.946v.282h-2.946z"
          clipPath="url(#gD)"
          transform="rotate(55.998 15.308 9.212)"
        />
        <path
          fill="#6b6b6b"
          d="M14.018 8.946h2.946v.283h-2.946z"
          clipPath="url(#gD)"
          transform="rotate(56.006 15.491 9.087)"
        />
        <path
          fill="#696969"
          d="M14.203 8.821h2.947v.282h-2.947z"
          clipPath="url(#gD)"
          transform="rotate(55.998 15.676 8.962)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gE"
            d="M15.101 7.579l.954-.573 1.517 2.525-.954.573-1.517-2.525z"
          />
        </defs>
        <clipPath id="gF">
          <use overflow="visible" xlinkHref="#gE" />
        </clipPath>
        <path
          fill="#696969"
          d="M14.448 8.654h2.973v.252h-2.973z"
          clipPath="url(#gF)"
          transform="rotate(59.003 15.935 8.78)"
        />
        <path
          fill="#676767"
          d="M14.639 8.54h2.974v.251h-2.974z"
          clipPath="url(#gF)"
          transform="rotate(58.995 16.127 8.666)"
        />
        <path
          fill="#656565"
          d="M14.83 8.425h2.974v.252H14.83z"
          clipPath="url(#gF)"
          transform="rotate(59.003 16.316 8.55)"
        />
        <path
          fill="#646464"
          d="M15.021 8.31h2.973v.252h-2.973z"
          clipPath="url(#gF)"
          transform="rotate(59.003 16.507 8.437)"
        />
        <path
          fill="#626262"
          d="M15.212 8.196h2.973v.252h-2.973z"
          clipPath="url(#gF)"
          transform="rotate(59.003 16.698 8.322)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gG"
            d="M16.189 6.929l.983-.522 1.384 2.601-.983.523-1.384-2.602z"
          />
        </defs>
        <clipPath id="gH">
          <use overflow="visible" xlinkHref="#gG" />
        </clipPath>
        <path
          fill="#626262"
          d="M15.457 8.037h3.012v.225h-3.012z"
          clipPath="url(#gH)"
          transform="rotate(61.997 16.963 8.15)"
        />
        <path
          fill="#606060"
          d="M15.654 7.932h3.012v.225h-3.012z"
          clipPath="url(#gH)"
          transform="rotate(61.997 17.16 8.045)"
        />
        <path
          fill="#5e5e5e"
          d="M15.85 7.828h3.012v.225H15.85z"
          clipPath="url(#gH)"
          transform="rotate(62.005 17.356 7.94)"
        />
        <path
          fill="#5d5d5d"
          d="M16.047 7.723h3.012v.225h-3.012z"
          clipPath="url(#gH)"
          transform="rotate(61.997 17.553 7.836)"
        />
        <path
          fill="#5b5b5b"
          d="M16.243 7.619h3.012v.225h-3.012z"
          clipPath="url(#gH)"
          transform="rotate(61.997 17.75 7.732)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gI"
            d="M17.311 6.338l1.009-.471 1.245 2.67-1.009.471-1.245-2.67z"
          />
        </defs>
        <clipPath id="gJ">
          <use overflow="visible" xlinkHref="#gI" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M16.549 7.507h2.94v.26h-2.94z"
          clipPath="url(#gJ)"
          transform="rotate(64.996 18.018 7.636)"
        />
        <path
          fill="#595959"
          d="M16.751 7.413h2.939v.261h-2.939z"
          clipPath="url(#gJ)"
          transform="rotate(65.001 18.221 7.543)"
        />
        <path
          fill="#575757"
          d="M16.953 7.318h2.939v.261h-2.939z"
          clipPath="url(#gJ)"
          transform="rotate(64.996 18.422 7.449)"
        />
        <path
          fill="#555"
          d="M17.154 7.225h2.939v.261h-2.939z"
          clipPath="url(#gJ)"
          transform="rotate(65.008 18.624 7.355)"
        />
        <path
          fill="#545454"
          d="M17.356 7.13h2.939v.261h-2.939z"
          clipPath="url(#gJ)"
          transform="rotate(64.996 18.826 7.261)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gK"
            d="M18.462 5.806l1.032-.417 1.104 2.731-1.032.417-1.104-2.731z"
          />
        </defs>
        <clipPath id="gL">
          <use overflow="visible" xlinkHref="#gK" />
        </clipPath>
        <path
          fill="#545454"
          d="M17.603 6.99h2.996v.239h-2.996z"
          clipPath="url(#gL)"
          transform="rotate(67.993 19.1 7.11)"
        />
        <path
          fill="#525252"
          d="M17.809 6.907h2.996v.239h-2.996z"
          clipPath="url(#gL)"
          transform="rotate(68.007 19.307 7.026)"
        />
        <path
          fill="#505050"
          d="M18.015 6.824h2.996v.239h-2.996z"
          clipPath="url(#gL)"
          transform="rotate(68.007 19.513 6.942)"
        />
        <path
          fill="#4e4e4e"
          d="M18.222 6.74h2.996v.239h-2.996z"
          clipPath="url(#gL)"
          transform="rotate(67.993 19.72 6.86)"
        />
        <path
          fill="#4d4d4d"
          d="M18.428 6.657h2.996v.239h-2.996z"
          clipPath="url(#gL)"
          transform="rotate(68.007 19.926 6.775)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="gM" d="M26.774.459h2.551v3.374h-2.551V.459z" />
        </defs>
        <clipPath id="gN">
          <use overflow="visible" xlinkHref="#gM" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M26.746.474h.538v3.36h-.538z"
          clipPath="url(#gN)"
        />
        <path
          fill="#a1a1a1"
          d="M27.256.474h.538v3.36h-.538z"
          clipPath="url(#gN)"
        />
        <path
          fill="#9d9d9d"
          d="M27.767.474h.538v3.36h-.538z"
          clipPath="url(#gN)"
        />
        <path
          fill="#989898"
          d="M28.277.474h.538v3.36h-.538z"
          clipPath="url(#gN)"
        />
        <path
          fill="#949494"
          d="M28.787.474h.538v3.36h-.538z"
          clipPath="url(#gN)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gO"
            d="M23.897.744l2.537-.266.353 3.355-2.537.267-.353-3.356z"
          />
        </defs>
        <clipPath id="gP">
          <use overflow="visible" xlinkHref="#gO" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M22.641 2.141h3.426v.451h-3.426z"
          clipPath="url(#gP)"
          transform="rotate(84.002 24.355 2.367)"
        />
        <path
          fill="#b3b3b3"
          d="M23.148 2.088h3.426v.451h-3.426z"
          clipPath="url(#gP)"
          transform="rotate(83.996 24.861 2.313)"
        />
        <path
          fill="#afafaf"
          d="M23.655 2.034h3.426v.451h-3.426z"
          clipPath="url(#gP)"
          transform="rotate(84.002 25.37 2.26)"
        />
        <path
          fill="#aaa"
          d="M24.163 1.981h3.425v.451h-3.425z"
          clipPath="url(#gP)"
          transform="rotate(84.002 25.876 2.206)"
        />
        <path
          fill="#a6a6a6"
          d="M24.67 1.928h3.426v.451H24.67z"
          clipPath="url(#gP)"
          transform="rotate(84.002 26.384 2.153)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gQ"
            d="M21.066 1.328l2.495-.531.701 3.301-2.495.53-.701-3.3z"
          />
        </defs>
        <clipPath id="gR">
          <use overflow="visible" xlinkHref="#gQ" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M19.958 2.653h3.407v.513h-3.407z"
          clipPath="url(#gR)"
          transform="rotate(78.007 21.66 2.909)"
        />
        <path
          fill="#c5c5c5"
          d="M20.457 2.546h3.406v.513h-3.406z"
          clipPath="url(#gR)"
          transform="rotate(78.007 22.16 2.802)"
        />
        <path
          fill="#c1c1c1"
          d="M20.956 2.441h3.406v.513h-3.406z"
          clipPath="url(#gR)"
          transform="rotate(78.007 22.658 2.696)"
        />
        <path
          fill="#bcbcbc"
          d="M21.455 2.335h3.407v.512h-3.407z"
          clipPath="url(#gR)"
          transform="rotate(77.994 23.16 2.592)"
        />
        <path
          fill="#b8b8b8"
          d="M21.954 2.229h3.407v.512h-3.407z"
          clipPath="url(#gR)"
          transform="rotate(77.994 23.658 2.485)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gS"
            d="M18.311 2.204l2.426-.788 1.042 3.209-2.425.788-1.043-3.209z"
          />
        </defs>
        <clipPath id="gT">
          <use overflow="visible" xlinkHref="#gS" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M17.401 3.484h3.397v.451h-3.397z"
          clipPath="url(#gT)"
          transform="rotate(72.002 19.099 3.71)"
        />
        <path
          fill="#d7d7d7"
          d="M17.886 3.327h3.397v.451h-3.397z"
          clipPath="url(#gT)"
          transform="rotate(72.007 19.584 3.552)"
        />
        <path
          fill="#d2d2d2"
          d="M18.371 3.169h3.397v.451h-3.397z"
          clipPath="url(#gT)"
          transform="rotate(71.994 20.071 3.395)"
        />
        <path
          fill="#cecece"
          d="M18.857 3.011h3.397v.451h-3.397z"
          clipPath="url(#gT)"
          transform="rotate(71.994 20.556 3.238)"
        />
        <path
          fill="#c9c9c9"
          d="M19.342 2.854h3.397v.451h-3.397z"
          clipPath="url(#gT)"
          transform="rotate(71.994 21.041 3.08)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gU"
            d="M15.663 3.364l2.331-1.038 1.371 3.082-2.33 1.038-1.372-3.082z"
          />
        </defs>
        <clipPath id="gV">
          <use overflow="visible" xlinkHref="#gU" />
        </clipPath>
        <path
          fill="#ededed"
          d="M14.889 4.542h3.344v.571h-3.344z"
          clipPath="url(#gV)"
          transform="rotate(66.003 16.56 4.827)"
        />
        <path
          fill="#e9e9e9"
          d="M15.355 4.334h3.344v.571h-3.344z"
          clipPath="url(#gV)"
          transform="rotate(66.003 17.026 4.62)"
        />
        <path
          fill="#e4e4e4"
          d="M15.821 4.127h3.344v.57h-3.344z"
          clipPath="url(#gV)"
          transform="rotate(65.996 17.493 4.412)"
        />
        <path
          fill="#e0e0e0"
          d="M16.287 3.919h3.343v.571h-3.343z"
          clipPath="url(#gV)"
          transform="rotate(66.001 17.96 4.204)"
        />
        <path
          fill="#dbdbdb"
          d="M16.753 3.712h3.344v.571h-3.344z"
          clipPath="url(#gV)"
          transform="rotate(65.996 18.425 3.997)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gW"
            d="M13.151 4.793l2.209-1.275 1.686 2.922-2.209 1.275-1.686-2.922z"
          />
        </defs>
        <clipPath id="gX">
          <use overflow="visible" xlinkHref="#gW" />
        </clipPath>
        <path
          fill="#fff"
          d="M12.502 5.857h3.403v.518h-3.403z"
          clipPath="url(#gX)"
          transform="rotate(60 14.204 6.116)"
        />
        <path
          fill="#fbfbfb"
          d="M12.944 5.602h3.403v.518h-3.403z"
          clipPath="url(#gX)"
          transform="rotate(60 14.646 5.86)"
        />
        <path
          fill="#f6f6f6"
          d="M13.386 5.347h3.403v.519h-3.403z"
          clipPath="url(#gX)"
          transform="rotate(60 15.088 5.606)"
        />
        <path
          fill="#f2f2f2"
          d="M13.828 5.092h3.403v.519h-3.403z"
          clipPath="url(#gX)"
          transform="rotate(60 15.53 5.351)"
        />
        <path
          fill="#ededed"
          d="M14.27 4.837h3.403v.518H14.27z"
          clipPath="url(#gX)"
          transform="rotate(60 15.971 5.096)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="gY"
            d="M29.665.478l2.536.266-.352 3.356-2.537-.267.353-3.355z"
          />
        </defs>
        <clipPath id="gZ">
          <use overflow="visible" xlinkHref="#gY" />
        </clipPath>
        <path
          fill="#949494"
          d="M28.089 1.964h3.327v.485h-3.327z"
          clipPath="url(#gZ)"
          transform="rotate(95.998 29.752 2.206)"
        />
        <path
          fill="#8f8f8f"
          d="M28.595 2.017h3.328v.485h-3.328z"
          clipPath="url(#gZ)"
          transform="rotate(95.998 30.26 2.259)"
        />
        <path
          fill="#8b8b8b"
          d="M29.103 2.07h3.327v.485h-3.327z"
          clipPath="url(#gZ)"
          transform="rotate(95.998 30.767 2.312)"
        />
        <path
          fill="#878787"
          d="M29.61 2.124h3.328v.485H29.61z"
          clipPath="url(#gZ)"
          transform="rotate(95.998 31.274 2.366)"
        />
        <path
          fill="#828282"
          d="M30.118 2.177h3.327v.485h-3.327z"
          clipPath="url(#gZ)"
          transform="rotate(95.998 31.781 2.42)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ha"
            d="M32.538.797l2.495.531-.702 3.3-2.495-.53.702-3.301z"
          />
        </defs>
        <clipPath id="hb">
          <use overflow="visible" xlinkHref="#ha" />
        </clipPath>
        <path
          fill="#828282"
          d="M30.798 2.306h3.343v.435h-3.343z"
          clipPath="url(#hb)"
          transform="rotate(101.999 32.467 2.523)"
        />
        <path
          fill="#7e7e7e"
          d="M31.297 2.412h3.342v.435h-3.342z"
          clipPath="url(#hb)"
          transform="rotate(102 32.97 2.63)"
        />
        <path
          fill="#797979"
          d="M31.796 2.518h3.342v.436h-3.342z"
          clipPath="url(#hb)"
          transform="rotate(102 33.468 2.735)"
        />
        <path
          fill="#757575"
          d="M32.295 2.624h3.343v.436h-3.343z"
          clipPath="url(#hb)"
          transform="rotate(101.999 33.965 2.842)"
        />
        <path
          fill="#707070"
          d="M32.794 2.73h3.343v.436h-3.343z"
          clipPath="url(#hb)"
          transform="rotate(102.006 34.466 2.948)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hc"
            d="M35.361 1.416l2.426.788-1.042 3.209-2.426-.788 1.042-3.209z"
          />
        </defs>
        <clipPath id="hd">
          <use overflow="visible" xlinkHref="#hc" />
        </clipPath>
        <path
          fill="#707070"
          d="M33.392 2.839h3.362v.527h-3.362z"
          clipPath="url(#hd)"
          transform="rotate(107.993 35.073 3.103)"
        />
        <path
          fill="#6c6c6c"
          d="M33.877 2.996h3.362v.527h-3.362z"
          clipPath="url(#hd)"
          transform="rotate(108.006 35.558 3.26)"
        />
        <path
          fill="#676767"
          d="M34.362 3.154h3.362v.527h-3.362z"
          clipPath="url(#hd)"
          transform="rotate(107.993 36.044 3.418)"
        />
        <path
          fill="#636363"
          d="M34.847 3.312h3.362v.527h-3.362z"
          clipPath="url(#hd)"
          transform="rotate(107.993 36.529 3.576)"
        />
        <path
          fill="#5e5e5e"
          d="M35.333 3.469h3.361v.527h-3.361z"
          clipPath="url(#hd)"
          transform="rotate(107.998 37.012 3.732)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="he"
            d="M38.105 2.326l2.331 1.038-1.373 3.082-2.33-1.038 1.372-3.082z"
          />
        </defs>
        <clipPath id="hf">
          <use overflow="visible" xlinkHref="#he" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M36.005 3.777h3.329v.453h-3.329z"
          clipPath="url(#hf)"
          transform="rotate(113.997 37.668 4.003)"
        />
        <path
          fill="#5a5a5a"
          d="M36.471 3.984H39.8v.453h-3.329z"
          clipPath="url(#hf)"
          transform="rotate(114.004 38.136 4.21)"
        />
        <path
          fill="#555"
          d="M36.937 4.192h3.329v.453h-3.329z"
          clipPath="url(#hf)"
          transform="rotate(114.004 38.602 4.418)"
        />
        <path
          fill="#515151"
          d="M37.402 4.399h3.329v.453h-3.329z"
          clipPath="url(#hf)"
          transform="rotate(114.004 39.068 4.626)"
        />
        <path
          fill="#4d4d4d"
          d="M37.869 4.607h3.329v.452h-3.329z"
          clipPath="url(#hf)"
          transform="rotate(113.997 39.532 4.833)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hg"
            d="M48.261 8.285l.923-.623 1.647 2.443-.923.623-1.647-2.443z"
          />
        </defs>
        <clipPath id="hh">
          <use overflow="visible" xlinkHref="#hg" />
        </clipPath>
        <path
          fill="#707070"
          d="M47.679 9.319h2.946v.282h-2.946z"
          clipPath="url(#hh)"
          transform="rotate(56.006 49.15 9.46)"
        />
        <path
          fill="#6e6e6e"
          d="M47.864 9.195h2.946v.282h-2.946z"
          clipPath="url(#hh)"
          transform="rotate(55.998 49.338 9.336)"
        />
        <path
          fill="#6d6d6d"
          d="M48.048 9.07h2.946v.282h-2.946z"
          clipPath="url(#hh)"
          transform="rotate(55.998 49.523 9.212)"
        />
        <path
          fill="#6b6b6b"
          d="M48.233 8.946h2.946v.282h-2.946z"
          clipPath="url(#hh)"
          transform="rotate(56.006 49.704 9.086)"
        />
        <path
          fill="#696969"
          d="M48.417 8.821h2.946v.282h-2.946z"
          clipPath="url(#hh)"
          transform="rotate(56.006 49.889 8.961)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hi"
            d="M49.314 7.579l.955-.573 1.517 2.525-.954.573-1.518-2.525z"
          />
        </defs>
        <clipPath id="hj">
          <use overflow="visible" xlinkHref="#hi" />
        </clipPath>
        <path
          fill="#696969"
          d="M48.662 8.654h2.974v.252h-2.974z"
          clipPath="url(#hj)"
          transform="rotate(58.995 50.15 8.78)"
        />
        <path
          fill="#676767"
          d="M48.853 8.54h2.973v.252h-2.973z"
          clipPath="url(#hj)"
          transform="rotate(59.003 50.34 8.667)"
        />
        <path
          fill="#656565"
          d="M49.043 8.425h2.974v.252h-2.974z"
          clipPath="url(#hj)"
          transform="rotate(59.008 50.533 8.552)"
        />
        <path
          fill="#646464"
          d="M49.234 8.31h2.973v.252h-2.973z"
          clipPath="url(#hj)"
          transform="rotate(59.003 50.722 8.437)"
        />
        <path
          fill="#626262"
          d="M49.425 8.196h2.974v.252h-2.974z"
          clipPath="url(#hj)"
          transform="rotate(58.995 50.914 8.322)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hk"
            d="M50.403 6.929l.983-.522 1.384 2.601-.983.523-1.384-2.602z"
          />
        </defs>
        <clipPath id="hl">
          <use overflow="visible" xlinkHref="#hk" />
        </clipPath>
        <path
          fill="#626262"
          d="M49.671 8.037h3.012v.225h-3.012z"
          clipPath="url(#hl)"
          transform="rotate(62.005 51.174 8.148)"
        />
        <path
          fill="#606060"
          d="M49.867 7.932h3.012v.225h-3.012z"
          clipPath="url(#hl)"
          transform="rotate(62.005 51.37 8.044)"
        />
        <path
          fill="#5e5e5e"
          d="M50.064 7.828h3.012v.225h-3.012z"
          clipPath="url(#hl)"
          transform="rotate(62.005 51.57 7.941)"
        />
        <path
          fill="#5d5d5d"
          d="M50.26 7.723h3.012v.225H50.26z"
          clipPath="url(#hl)"
          transform="rotate(62.005 51.764 7.835)"
        />
        <path
          fill="#5b5b5b"
          d="M50.457 7.619h3.012v.225h-3.012z"
          clipPath="url(#hl)"
          transform="rotate(62.005 51.963 7.732)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hm"
            d="M51.525 6.338l1.009-.471 1.245 2.67-1.009.471-1.245-2.67z"
          />
        </defs>
        <clipPath id="hn">
          <use overflow="visible" xlinkHref="#hm" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M50.763 7.507h2.939v.261h-2.939z"
          clipPath="url(#hn)"
          transform="rotate(65.001 52.234 7.637)"
        />
        <path
          fill="#595959"
          d="M50.965 7.413h2.939v.261h-2.939z"
          clipPath="url(#hn)"
          transform="rotate(65.001 52.436 7.543)"
        />
        <path
          fill="#575757"
          d="M51.167 7.318h2.939v.261h-2.939z"
          clipPath="url(#hn)"
          transform="rotate(64.996 52.636 7.45)"
        />
        <path
          fill="#555"
          d="M51.368 7.225h2.939v.261h-2.939z"
          clipPath="url(#hn)"
          transform="rotate(65.001 52.839 7.355)"
        />
        <path
          fill="#545454"
          d="M51.57 7.13h2.939v.261H51.57z"
          clipPath="url(#hn)"
          transform="rotate(64.996 53.04 7.262)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ho"
            d="M52.676 5.806l1.032-.417 1.104 2.731-1.032.417-1.104-2.731z"
          />
        </defs>
        <clipPath id="hp">
          <use overflow="visible" xlinkHref="#ho" />
        </clipPath>
        <path
          fill="#545454"
          d="M51.816 6.99h2.996v.239h-2.996z"
          clipPath="url(#hp)"
          transform="rotate(67.993 53.316 7.11)"
        />
        <path
          fill="#525252"
          d="M52.023 6.907h2.996v.239h-2.996z"
          clipPath="url(#hp)"
          transform="rotate(68.007 53.52 7.025)"
        />
        <path
          fill="#505050"
          d="M52.229 6.823h2.996v.24h-2.996z"
          clipPath="url(#hp)"
          transform="rotate(68.007 53.727 6.941)"
        />
        <path
          fill="#4e4e4e"
          d="M52.436 6.74h2.996v.239h-2.996z"
          clipPath="url(#hp)"
          transform="rotate(67.993 53.935 6.86)"
        />
        <path
          fill="#4d4d4d"
          d="M52.642 6.657h2.996v.239h-2.996z"
          clipPath="url(#hp)"
          transform="rotate(68.007 54.14 6.774)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path id="hq" d="M60.988.459h2.551v3.374h-2.551V.459z" />
        </defs>
        <clipPath id="hr">
          <use overflow="visible" xlinkHref="#hq" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M60.96.474h.538v3.36h-.538z"
          clipPath="url(#hr)"
        />
        <path
          fill="#a1a1a1"
          d="M61.47.474h.538v3.36h-.538z"
          clipPath="url(#hr)"
        />
        <path
          fill="#9d9d9d"
          d="M61.98.474h.538v3.36h-.538z"
          clipPath="url(#hr)"
        />
        <path
          fill="#989898"
          d="M62.491.474h.538v3.36h-.538z"
          clipPath="url(#hr)"
        />
        <path fill="#949494" d="M63 .474h.538v3.36H63z" clipPath="url(#hr)" />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hs"
            d="M58.111.744l2.536-.266L61 3.833l-2.536.267-.353-3.356z"
          />
        </defs>
        <clipPath id="ht">
          <use overflow="visible" xlinkHref="#hs" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M56.855 2.141h3.426v.45h-3.426z"
          clipPath="url(#ht)"
          transform="rotate(83.996 58.568 2.366)"
        />
        <path
          fill="#b3b3b3"
          d="M57.362 2.087h3.426v.451h-3.426z"
          clipPath="url(#ht)"
          transform="rotate(84.002 59.077 2.313)"
        />
        <path
          fill="#afafaf"
          d="M57.869 2.034h3.427v.451h-3.427z"
          clipPath="url(#ht)"
          transform="rotate(83.996 59.583 2.26)"
        />
        <path
          fill="#aaa"
          d="M58.377 1.981h3.426v.451h-3.426z"
          clipPath="url(#ht)"
          transform="rotate(83.996 60.09 2.205)"
        />
        <path
          fill="#a6a6a6"
          d="M58.884 1.928h3.426v.451h-3.426z"
          clipPath="url(#ht)"
          transform="rotate(83.996 60.597 2.152)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hu"
            d="M55.28 1.328l2.495-.531.701 3.301-2.495.53-.701-3.3z"
          />
        </defs>
        <clipPath id="hv">
          <use overflow="visible" xlinkHref="#hu" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M54.172 2.653h3.407v.512h-3.407z"
          clipPath="url(#hv)"
          transform="rotate(77.994 55.878 2.91)"
        />
        <path
          fill="#c5c5c5"
          d="M54.671 2.547h3.407v.512h-3.407z"
          clipPath="url(#hv)"
          transform="rotate(77.994 56.378 2.805)"
        />
        <path
          fill="#c1c1c1"
          d="M55.17 2.441h3.406v.513H55.17z"
          clipPath="url(#hv)"
          transform="rotate(78.007 56.87 2.695)"
        />
        <path
          fill="#bcbcbc"
          d="M55.669 2.335h3.406v.513h-3.406z"
          clipPath="url(#hv)"
          transform="rotate(78.007 57.368 2.59)"
        />
        <path
          fill="#b8b8b8"
          d="M56.168 2.229h3.406v.513h-3.406z"
          clipPath="url(#hv)"
          transform="rotate(78.007 57.867 2.483)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hw"
            d="M52.525 2.204l2.426-.788 1.042 3.209-2.426.788-1.042-3.209z"
          />
        </defs>
        <clipPath id="hx">
          <use overflow="visible" xlinkHref="#hw" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M51.615 3.484h3.397v.451h-3.397z"
          clipPath="url(#hx)"
          transform="rotate(72.002 53.31 3.709)"
        />
        <path
          fill="#d7d7d7"
          d="M52.1 3.327h3.397v.45H52.1z"
          clipPath="url(#hx)"
          transform="rotate(72.002 53.796 3.551)"
        />
        <path
          fill="#d2d2d2"
          d="M52.585 3.169h3.397v.451h-3.397z"
          clipPath="url(#hx)"
          transform="rotate(71.994 54.287 3.396)"
        />
        <path
          fill="#cecece"
          d="M53.071 3.011h3.397v.451h-3.397z"
          clipPath="url(#hx)"
          transform="rotate(71.994 54.772 3.238)"
        />
        <path
          fill="#c9c9c9"
          d="M53.556 2.854h3.397v.451h-3.397z"
          clipPath="url(#hx)"
          transform="rotate(71.994 55.257 3.08)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hy"
            d="M49.877 3.364l2.33-1.038 1.372 3.082-2.33 1.038-1.372-3.082z"
          />
        </defs>
        <clipPath id="hz">
          <use overflow="visible" xlinkHref="#hy" />
        </clipPath>
        <path
          fill="#ededed"
          d="M49.103 4.541h3.344v.571h-3.344z"
          clipPath="url(#hz)"
          transform="rotate(66.003 50.772 4.827)"
        />
        <path
          fill="#e9e9e9"
          d="M49.569 4.334h3.344v.571h-3.344z"
          clipPath="url(#hz)"
          transform="rotate(66.003 51.238 4.62)"
        />
        <path
          fill="#e4e4e4"
          d="M50.035 4.127h3.344v.571h-3.344z"
          clipPath="url(#hz)"
          transform="rotate(65.996 51.707 4.411)"
        />
        <path
          fill="#e0e0e0"
          d="M50.501 3.919h3.343v.571h-3.343z"
          clipPath="url(#hz)"
          transform="rotate(66.001 52.175 4.204)"
        />
        <path
          fill="#dbdbdb"
          d="M50.967 3.712h3.344v.571h-3.344z"
          clipPath="url(#hz)"
          transform="rotate(66.003 52.636 3.997)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hA"
            d="M47.365 4.793l2.209-1.275L51.26 6.44l-2.209 1.275-1.686-2.922z"
          />
        </defs>
        <clipPath id="hB">
          <use overflow="visible" xlinkHref="#hA" />
        </clipPath>
        <path
          fill="#fff"
          d="M46.716 5.857h3.403v.519h-3.403z"
          clipPath="url(#hB)"
          transform="rotate(60.007 48.417 6.116)"
        />
        <path
          fill="#fbfbfb"
          d="M47.158 5.602h3.403v.519h-3.403z"
          clipPath="url(#hB)"
          transform="rotate(60 48.86 5.86)"
        />
        <path
          fill="#f6f6f6"
          d="M47.6 5.347h3.403v.518H47.6z"
          clipPath="url(#hB)"
          transform="rotate(60 49.302 5.605)"
        />
        <path
          fill="#f2f2f2"
          d="M48.042 5.092h3.403v.519h-3.403z"
          clipPath="url(#hB)"
          transform="rotate(60 49.746 5.352)"
        />
        <path
          fill="#ededed"
          d="M48.483 4.837h3.403v.518h-3.403z"
          clipPath="url(#hB)"
          transform="rotate(60 50.186 5.095)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hC"
            d="M63.878.478l2.537.266-.353 3.356-2.536-.267.352-3.355z"
          />
        </defs>
        <clipPath id="hD">
          <use overflow="visible" xlinkHref="#hC" />
        </clipPath>
        <path
          fill="#949494"
          d="M62.302 1.964h3.327v.485h-3.327z"
          clipPath="url(#hD)"
          transform="rotate(95.998 63.966 2.205)"
        />
        <path
          fill="#8f8f8f"
          d="M62.809 2.017h3.328v.485h-3.328z"
          clipPath="url(#hD)"
          transform="rotate(95.998 64.473 2.258)"
        />
        <path
          fill="#8b8b8b"
          d="M63.317 2.07h3.327v.485h-3.327z"
          clipPath="url(#hD)"
          transform="rotate(95.998 64.981 2.312)"
        />
        <path
          fill="#878787"
          d="M63.824 2.124h3.328v.485h-3.328z"
          clipPath="url(#hD)"
          transform="rotate(95.998 65.488 2.365)"
        />
        <path
          fill="#828282"
          d="M64.331 2.177h3.327v.485h-3.327z"
          clipPath="url(#hD)"
          transform="rotate(96.004 65.996 2.42)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hE"
            d="M66.751.797l2.496.531-.702 3.3-2.495-.53.701-3.301z"
          />
        </defs>
        <clipPath id="hF">
          <use overflow="visible" xlinkHref="#hE" />
        </clipPath>
        <path
          fill="#828282"
          d="M65.012 2.306h3.343v.435h-3.343z"
          clipPath="url(#hF)"
          transform="rotate(101.999 66.679 2.523)"
        />
        <path
          fill="#7e7e7e"
          d="M65.511 2.412h3.342v.436h-3.342z"
          clipPath="url(#hF)"
          transform="rotate(102.006 67.185 2.632)"
        />
        <path
          fill="#797979"
          d="M66.01 2.518h3.342v.435H66.01z"
          clipPath="url(#hF)"
          transform="rotate(102 67.684 2.735)"
        />
        <path
          fill="#757575"
          d="M66.509 2.624h3.343v.436h-3.343z"
          clipPath="url(#hF)"
          transform="rotate(101.999 68.176 2.842)"
        />
        <path
          fill="#707070"
          d="M67.008 2.73h3.343v.436h-3.343z"
          clipPath="url(#hF)"
          transform="rotate(101.999 68.675 2.948)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hG"
            d="M69.575 1.416l2.426.788-1.043 3.209-2.425-.788 1.042-3.209z"
          />
        </defs>
        <clipPath id="hH">
          <use overflow="visible" xlinkHref="#hG" />
        </clipPath>
        <path
          fill="#707070"
          d="M67.606 2.839h3.362v.527h-3.362z"
          clipPath="url(#hH)"
          transform="rotate(107.993 69.288 3.104)"
        />
        <path
          fill="#6c6c6c"
          d="M68.091 2.996h3.362v.527h-3.362z"
          clipPath="url(#hH)"
          transform="rotate(108.006 69.772 3.259)"
        />
        <path
          fill="#676767"
          d="M68.576 3.154h3.362v.526h-3.362z"
          clipPath="url(#hH)"
          transform="rotate(107.987 70.259 3.418)"
        />
        <path
          fill="#636363"
          d="M69.061 3.311h3.362v.528h-3.362z"
          clipPath="url(#hH)"
          transform="rotate(108.006 70.743 3.574)"
        />
        <path
          fill="#5e5e5e"
          d="M69.546 3.469h3.361v.527h-3.361z"
          clipPath="url(#hH)"
          transform="rotate(108.006 71.229 3.733)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hI"
            d="M72.319 2.326l2.33 1.038-1.372 3.082-2.33-1.038 1.372-3.082z"
          />
        </defs>
        <clipPath id="hJ">
          <use overflow="visible" xlinkHref="#hI" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M70.219 3.777h3.329v.452h-3.329z"
          clipPath="url(#hJ)"
          transform="rotate(113.991 71.883 4.002)"
        />
        <path
          fill="#5a5a5a"
          d="M70.684 3.984h3.329v.453h-3.329z"
          clipPath="url(#hJ)"
          transform="rotate(114.004 72.35 4.21)"
        />
        <path
          fill="#555"
          d="M71.15 4.192h3.33v.452h-3.33z"
          clipPath="url(#hJ)"
          transform="rotate(114.004 72.815 4.416)"
        />
        <path
          fill="#515151"
          d="M71.617 4.399h3.329v.452h-3.329z"
          clipPath="url(#hJ)"
          transform="rotate(113.991 73.281 4.624)"
        />
        <path
          fill="#4d4d4d"
          d="M72.082 4.606h3.33v.453h-3.33z"
          clipPath="url(#hJ)"
          transform="rotate(114.023 73.746 4.832)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hK"
            d="M95.919 49.332l1.86-.033.051 2.946-1.86.032-.051-2.945z"
          />
        </defs>
        <clipPath id="hL">
          <use overflow="visible" xlinkHref="#hK" />
        </clipPath>
        <path
          fill="#a6a6a6"
          d="M94.659 50.617h2.963v.352h-2.963z"
          clipPath="url(#hL)"
          transform="rotate(88.98 96.145 50.795)"
        />
        <path
          fill="#aaa"
          d="M95.031 50.611h2.964v.351h-2.964z"
          clipPath="url(#hL)"
          transform="rotate(88.997 96.519 50.791)"
        />
        <path
          fill="#afafaf"
          d="M95.403 50.604h2.963v.352h-2.963z"
          clipPath="url(#hL)"
          transform="rotate(88.997 96.89 50.785)"
        />
        <path
          fill="#b3b3b3"
          d="M95.775 50.598h2.963v.351h-2.963z"
          clipPath="url(#hL)"
          transform="rotate(88.98 97.261 50.776)"
        />
        <path
          fill="#b8b8b8"
          d="M96.147 50.591h2.963v.352h-2.963z"
          clipPath="url(#hL)"
          transform="rotate(89.02 97.623 50.762)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hM"
            d="M97.777 49.299l1.848-.194.308 2.93-1.85.194-.306-2.93z"
          />
        </defs>
        <clipPath id="hN">
          <use overflow="visible" xlinkHref="#hM" />
        </clipPath>
        <path
          fill="#b8b8b8"
          d="M96.613 50.532h3.011v.359h-3.011z"
          clipPath="url(#hN)"
          transform="rotate(84.007 98.12 50.71)"
        />
        <path
          fill="#bcbcbc"
          d="M96.982 50.494h3.011v.359h-3.011z"
          clipPath="url(#hN)"
          transform="rotate(84.007 98.49 50.673)"
        />
        <path
          fill="#c1c1c1"
          d="M97.353 50.455h3.011v.359h-3.011z"
          clipPath="url(#hN)"
          transform="rotate(84.007 98.86 50.633)"
        />
        <path
          fill="#c5c5c5"
          d="M97.723 50.416h3.011v.359h-3.011z"
          clipPath="url(#hN)"
          transform="rotate(84.007 99.23 50.594)"
        />
        <path
          fill="#c9c9c9"
          d="M98.092 50.377h3.011v.359h-3.011z"
          clipPath="url(#hN)"
          transform="rotate(84.007 99.6 50.556)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hO"
            d="M99.624 49.105l1.825-.355.562 2.893-1.826.355-.561-2.893z"
          />
        </defs>
        <clipPath id="hP">
          <use overflow="visible" xlinkHref="#hO" />
        </clipPath>
        <path
          fill="#c9c9c9"
          d="M98.605 50.323h2.95v.385h-2.95z"
          clipPath="url(#hP)"
          transform="rotate(79.006 100.085 50.518)"
        />
        <path
          fill="#cecece"
          d="M98.97 50.252h2.95v.385h-2.95z"
          clipPath="url(#hP)"
          transform="rotate(79.007 100.441 50.443)"
        />
        <path
          fill="#d2d2d2"
          d="M99.335 50.181h2.95v.386h-2.95z"
          clipPath="url(#hP)"
          transform="rotate(79.007 100.806 50.373)"
        />
        <path
          fill="#d7d7d7"
          d="M99.7 50.109h2.95v.386H99.7z"
          clipPath="url(#hP)"
          transform="rotate(79.006 101.18 50.305)"
        />
        <path
          fill="#dbdbdb"
          d="M100.065 50.039h2.951v.385h-2.951z"
          clipPath="url(#hP)"
          transform="rotate(78.989 101.541 50.235)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hQ"
            d="M101.447 48.751l1.787-.512.812 2.832-1.787.512-.812-2.832z"
          />
        </defs>
        <clipPath id="hR">
          <use overflow="visible" xlinkHref="#hQ" />
        </clipPath>
        <path
          fill="#dbdbdb"
          d="M100.553 49.926h2.918v.422h-2.918z"
          clipPath="url(#hR)"
          transform="rotate(73.99 102.014 50.138)"
        />
        <path
          fill="#e0e0e0"
          d="M100.91 49.824h2.917v.422h-2.917z"
          clipPath="url(#hR)"
          transform="rotate(73.99 102.37 50.032)"
        />
        <path
          fill="#e4e4e4"
          d="M101.268 49.721h2.917v.422h-2.917z"
          clipPath="url(#hR)"
          transform="rotate(73.99 102.727 49.93)"
        />
        <path
          fill="#e9e9e9"
          d="M101.625 49.619h2.917v.422h-2.917z"
          clipPath="url(#hR)"
          transform="rotate(73.99 103.084 49.827)"
        />
        <path
          fill="#ededed"
          d="M101.983 49.516h2.917v.422h-2.917z"
          clipPath="url(#hR)"
          transform="rotate(73.99 103.442 49.724)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hS"
            d="M103.232 48.239l1.736-.666 1.056 2.751-1.735.666-1.057-2.751z"
          />
        </defs>
        <clipPath id="hT">
          <use overflow="visible" xlinkHref="#hS" />
        </clipPath>
        <path
          fill="#ededed"
          d="M102.531 49.41h2.865v.34h-2.865z"
          clipPath="url(#hT)"
          transform="rotate(69.009 103.97 49.584)"
        />
        <path
          fill="#f2f2f2"
          d="M102.877 49.277h2.865v.34h-2.865z"
          clipPath="url(#hT)"
          transform="rotate(69.009 104.316 49.451)"
        />
        <path
          fill="#f6f6f6"
          d="M103.225 49.144h2.866v.339h-2.866z"
          clipPath="url(#hT)"
          transform="rotate(68.991 104.662 49.317)"
        />
        <path
          fill="#fbfbfb"
          d="M103.572 49.011h2.866v.339h-2.866z"
          clipPath="url(#hT)"
          transform="rotate(68.991 105.01 49.184)"
        />
        <path
          fill="#fff"
          d="M103.919 48.877h2.865v.34h-2.865z"
          clipPath="url(#hT)"
          transform="rotate(69.009 105.358 49.051)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hU"
            d="M94.066 49.202l1.855.13-.205 2.939-1.855-.13.205-2.939z"
          />
        </defs>
        <clipPath id="hV">
          <use overflow="visible" xlinkHref="#hU" />
        </clipPath>
        <path
          fill="#949494"
          d="M92.694 50.518h2.94v.341h-2.94z"
          clipPath="url(#hV)"
          transform="rotate(93.997 94.161 50.69)"
        />
        <path
          fill="#989898"
          d="M93.066 50.544h2.94v.341h-2.94z"
          clipPath="url(#hV)"
          transform="rotate(94.002 94.532 50.71)"
        />
        <path
          fill="#9d9d9d"
          d="M93.437 50.57h2.94v.341h-2.94z"
          clipPath="url(#hV)"
          transform="rotate(93.997 94.904 50.743)"
        />
        <path
          fill="#a1a1a1"
          d="M93.808 50.596h2.94v.341h-2.94z"
          clipPath="url(#hV)"
          transform="rotate(94.002 95.274 50.762)"
        />
        <path
          fill="#a6a6a6"
          d="M94.179 50.622h2.94v.341h-2.94z"
          clipPath="url(#hV)"
          transform="rotate(93.997 95.647 50.794)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hW"
            d="M92.232 48.912l1.836.29-.461 2.91-1.836-.29.461-2.91z"
          />
        </defs>
        <clipPath id="hX">
          <use overflow="visible" xlinkHref="#hW" />
        </clipPath>
        <path
          fill="#828282"
          d="M90.735 50.236h2.941v.33h-2.941z"
          clipPath="url(#hX)"
          transform="rotate(99.016 92.21 50.404)"
        />
        <path
          fill="#878787"
          d="M91.102 50.294h2.942v.33h-2.942z"
          clipPath="url(#hX)"
          transform="rotate(99.016 92.577 50.461)"
        />
        <path
          fill="#8b8b8b"
          d="M91.469 50.352h2.942v.33h-2.942z"
          clipPath="url(#hX)"
          transform="rotate(99.016 92.944 50.52)"
        />
        <path
          fill="#8f8f8f"
          d="M91.836 50.411h2.942v.33h-2.942z"
          clipPath="url(#hX)"
          transform="rotate(99.016 93.311 50.577)"
        />
        <path
          fill="#949494"
          d="M92.204 50.469h2.942v.33h-2.942z"
          clipPath="url(#hX)"
          transform="rotate(99.016 93.678 50.635)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="hY"
            d="M90.429 48.462l1.805.45-.713 2.859-1.805-.45.713-2.859z"
          />
        </defs>
        <clipPath id="hZ">
          <use overflow="visible" xlinkHref="#hY" />
        </clipPath>
        <path
          fill="#707070"
          d="M88.796 49.77h2.967v.324h-2.967z"
          clipPath="url(#hZ)"
          transform="rotate(104.005 90.277 49.93)"
        />
        <path
          fill="#757575"
          d="M89.157 49.86h2.966v.324h-2.966z"
          clipPath="url(#hZ)"
          transform="rotate(103.981 90.64 50.026)"
        />
        <path
          fill="#797979"
          d="M89.518 49.951h2.966v.324h-2.966z"
          clipPath="url(#hZ)"
          transform="rotate(103.981 91 50.116)"
        />
        <path
          fill="#7e7e7e"
          d="M89.878 50.041h2.967v.324h-2.967z"
          clipPath="url(#hZ)"
          transform="rotate(104.017 91.364 50.206)"
        />
        <path
          fill="#828282"
          d="M90.239 50.13h2.967v.324h-2.967z"
          clipPath="url(#hZ)"
          transform="rotate(104.005 91.72 50.29)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ia"
            d="M88.673 47.857l1.758.606-.959 2.786-1.758-.606.959-2.786z"
          />
        </defs>
        <clipPath id="ib">
          <use overflow="visible" xlinkHref="#ia" />
        </clipPath>
        <path
          fill="#5e5e5e"
          d="M86.894 49.122h3.015v.327h-3.015z"
          clipPath="url(#ib)"
          transform="rotate(108.997 88.405 49.29)"
        />
        <path
          fill="#636363"
          d="M87.246 49.244h3.015v.327h-3.015z"
          clipPath="url(#ib)"
          transform="rotate(108.997 88.756 49.41)"
        />
        <path
          fill="#676767"
          d="M87.598 49.365h3.015v.327h-3.015z"
          clipPath="url(#ib)"
          transform="rotate(108.997 89.108 49.532)"
        />
        <path
          fill="#6c6c6c"
          d="M87.949 49.485h3.014v.327h-3.014z"
          clipPath="url(#ib)"
          transform="rotate(109.002 89.457 49.648)"
        />
        <path
          fill="#707070"
          d="M88.301 49.607h3.015v.327h-3.015z"
          clipPath="url(#ib)"
          transform="rotate(108.997 89.811 49.774)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ic"
            d="M86.977 47.102l1.698.756-1.198 2.692-1.698-.756 1.198-2.692z"
          />
        </defs>
        <clipPath id="id">
          <use overflow="visible" xlinkHref="#ic" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M85.068 48.328h2.906v.412h-2.906z"
          clipPath="url(#id)"
          transform="rotate(113.991 86.522 48.532)"
        />
        <path
          fill="#515151"
          d="M85.408 48.479h2.906v.412h-2.906z"
          clipPath="url(#id)"
          transform="rotate(113.991 86.862 48.687)"
        />
        <path
          fill="#555"
          d="M85.747 48.631h2.907v.411h-2.907z"
          clipPath="url(#id)"
          transform="rotate(114.01 87.2 48.834)"
        />
        <path
          fill="#5a5a5a"
          d="M86.087 48.782h2.907v.411h-2.907z"
          clipPath="url(#id)"
          transform="rotate(114.01 87.539 48.986)"
        />
        <path
          fill="#5e5e5e"
          d="M86.427 48.933h2.907v.412h-2.907z"
          clipPath="url(#id)"
          transform="rotate(114.01 87.878 49.137)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ie"
            d="M103.978 51.09l1.191-.423 1.125 3.176-1.192.423-1.124-3.176z"
          />
        </defs>
        <clipPath id="if">
          <use overflow="visible" xlinkHref="#ie" />
        </clipPath>
        <path
          fill="#4d4d4d"
          d="M102.967 52.513h3.36v.264h-3.36z"
          clipPath="url(#if)"
          transform="rotate(71.003 104.652 52.649)"
        />
        <path
          fill="#4e4e4e"
          d="M103.212 52.429h3.36v.265h-3.36z"
          clipPath="url(#if)"
          transform="rotate(71.003 104.897 52.565)"
        />
        <path
          fill="#505050"
          d="M103.456 52.345h3.36v.264h-3.36z"
          clipPath="url(#if)"
          transform="rotate(71.003 105.141 52.48)"
        />
        <path
          fill="#525252"
          d="M103.701 52.26h3.361v.265h-3.361z"
          clipPath="url(#if)"
          transform="rotate(70.987 105.376 52.392)"
        />
        <path
          fill="#545454"
          d="M103.946 52.176h3.361v.264h-3.361z"
          clipPath="url(#if)"
          transform="rotate(70.987 105.621 52.308)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ig"
            d="M105.173 50.666l1.168-.484 1.289 3.113-1.168.484-1.289-3.113z"
          />
        </defs>
        <clipPath id="ih">
          <use overflow="visible" xlinkHref="#ig" />
        </clipPath>
        <path
          fill="#545454"
          d="M104.226 52.041h3.42v.213h-3.42z"
          clipPath="url(#ih)"
          transform="rotate(68 105.932 52.144)"
        />
        <path
          fill="#555"
          d="M104.466 51.944h3.42v.213h-3.42z"
          clipPath="url(#ih)"
          transform="rotate(68 106.173 52.047)"
        />
        <path
          fill="#575757"
          d="M104.707 51.847h3.42v.213h-3.42z"
          clipPath="url(#ih)"
          transform="rotate(68 106.413 51.95)"
        />
        <path
          fill="#595959"
          d="M104.946 51.75h3.42v.212h-3.42z"
          clipPath="url(#ih)"
          transform="rotate(68 106.653 51.853)"
        />
        <path
          fill="#5b5b5b"
          d="M105.186 51.653h3.42v.213h-3.42z"
          clipPath="url(#ih)"
          transform="rotate(68 106.892 51.756)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ii"
            d="M106.345 50.181l1.141-.545 1.451 3.041-1.142.544-1.45-3.04z"
          />
        </defs>
        <clipPath id="ij">
          <use overflow="visible" xlinkHref="#ii" />
        </clipPath>
        <path
          fill="#5b5b5b"
          d="M105.516 51.54h3.361v.212h-3.361z"
          clipPath="url(#ij)"
          transform="rotate(64.996 107.193 51.643)"
        />
        <path
          fill="#5d5d5d"
          d="M105.751 51.43h3.361v.213h-3.361z"
          clipPath="url(#ij)"
          transform="rotate(65.008 107.429 51.537)"
        />
        <path
          fill="#5e5e5e"
          d="M105.985 51.321h3.361v.213h-3.361z"
          clipPath="url(#ij)"
          transform="rotate(65.008 107.663 51.428)"
        />
        <path
          fill="#606060"
          d="M106.22 51.212h3.361v.212h-3.361z"
          clipPath="url(#ij)"
          transform="rotate(65.008 107.898 51.319)"
        />
        <path
          fill="#626262"
          d="M106.454 51.102h3.361v.212h-3.361z"
          clipPath="url(#ij)"
          transform="rotate(65.008 108.133 51.21)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="ik"
            d="M107.49 49.634l1.111-.603 1.607 2.96-1.111.604-1.607-2.961z"
          />
        </defs>
        <clipPath id="il">
          <use overflow="visible" xlinkHref="#ik" />
        </clipPath>
        <path
          fill="#626262"
          d="M106.671 50.908h3.375v.337h-3.375z"
          clipPath="url(#il)"
          transform="rotate(61.992 108.358 51.078)"
        />
        <path
          fill="#646464"
          d="M106.9 50.786h3.376v.337H106.9z"
          clipPath="url(#il)"
          transform="rotate(61.997 108.59 50.955)"
        />
        <path
          fill="#656565"
          d="M107.128 50.665h3.376v.337h-3.376z"
          clipPath="url(#il)"
          transform="rotate(61.997 108.818 50.834)"
        />
        <path
          fill="#676767"
          d="M107.357 50.544h3.376v.336h-3.376z"
          clipPath="url(#il)"
          transform="rotate(61.997 109.047 50.712)"
        />
        <path
          fill="#696969"
          d="M107.586 50.422h3.376v.336h-3.376z"
          clipPath="url(#il)"
          transform="rotate(61.997 109.276 50.59)"
        />
      </g>
      <g clipPath="url(#b)">
        <defs>
          <path
            id="im"
            d="M108.605 49.029l1.078-.661 1.76 2.873-1.078.66-1.76-2.872z"
          />
        </defs>
        <clipPath id="in">
          <use overflow="visible" xlinkHref="#im" />
        </clipPath>
        <path
          fill="#696969"
          d="M107.825 50.235h3.457v.274h-3.457z"
          clipPath="url(#in)"
          transform="rotate(59.008 109.559 50.376)"
        />
        <path
          fill="#6b6b6b"
          d="M108.046 50.102h3.457v.274h-3.457z"
          clipPath="url(#in)"
          transform="rotate(59.008 109.78 50.243)"
        />
        <path
          fill="#6d6d6d"
          d="M108.268 49.969h3.458v.274h-3.458z"
          clipPath="url(#in)"
          transform="rotate(58.995 110 50.106)"
        />
        <path
          fill="#6e6e6e"
          d="M108.49 49.835h3.458v.274h-3.458z"
          clipPath="url(#in)"
          transform="rotate(58.99 110.216 49.972)"
        />
        <path
          fill="#707070"
          d="M108.712 49.702h3.458v.274h-3.458z"
          clipPath="url(#in)"
          transform="rotate(58.99 110.439 49.838)"
        />
      </g>
      <path
        fill="none"
        stroke="#4d4d4d"
        strokeMiterlimit="3.864"
        strokeWidth=".318"
        d="M40.642 45.188c-3.516 2.565-7.908 4.131-12.593 4.131-11.756 0-21.27-9.514-21.27-21.27s9.513-21.27 21.27-21.27c4.685 0 9.074 1.565 12.593 4.13a27.43 27.43 0 0 0-5.968 17.139 27.629 27.629 0 0 0 5.968 17.14zM45.156 15.412a21.109 21.109 0 0 0-4.163 12.638c0 4.737 1.544 9.101 4.163 12.637a21.148 21.148 0 0 0 4.163-12.637c0-4.744-1.54-9.099-4.163-12.638zM74.856 45.188a21.187 21.187 0 0 1-12.608 4.124c-4.717 0-9.05-1.527-12.578-4.124a27.628 27.628 0 0 0 5.968-17.139c0-6.482-2.227-12.43-5.968-17.139a21.104 21.104 0 0 1 12.578-4.124c4.718 0 9.079 1.526 12.608 4.124a27.432 27.432 0 0 0-5.969 17.139 27.635 27.635 0 0 0 5.969 17.139zM109.07 45.188a21.182 21.182 0 0 1-12.607 4.124 21.111 21.111 0 0 1-12.579-4.124 27.623 27.623 0 0 0 5.969-17.139c0-6.482-2.227-12.43-5.969-17.139a21.111 21.111 0 0 1 12.579-4.124 21.18 21.18 0 0 1 12.607 4.124 27.437 27.437 0 0 0-5.968 17.139 27.628 27.628 0 0 0 5.968 17.139zM113.584 15.412a21.109 21.109 0 0 0-4.163 12.638c0 4.737 1.545 9.101 4.163 12.637a21.147 21.147 0 0 0 4.162-12.637 21.093 21.093 0 0 0-4.162-12.638z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#4d4d4d"
        strokeMiterlimit="3.864"
        strokeWidth=".318"
        d="M45.156 6.403A27.471 27.471 0 0 0 28.049.459C12.809.459.46 12.809.46 28.049c0 15.239 12.35 27.589 27.589 27.589a27.464 27.464 0 0 0 17.107-5.943 27.472 27.472 0 0 0 17.107 5.943 27.464 27.464 0 0 0 17.107-5.943 27.475 27.475 0 0 0 17.108 5.943 27.461 27.461 0 0 0 17.106-5.943 27.457 27.457 0 0 0 17.106 5.943c15.24 0 27.59-12.35 27.59-27.589 0-15.24-12.35-27.59-27.59-27.59-6.464 0-12.4 2.221-17.106 5.944A27.458 27.458 0 0 0 96.478.459 27.476 27.476 0 0 0 79.37 6.403 27.457 27.457 0 0 0 62.263.459a27.473 27.473 0 0 0-17.107 5.944z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#4d4d4d"
        strokeMiterlimit="3.864"
        strokeWidth=".318"
        d="M118.099 45.188a27.628 27.628 0 0 0 5.968-17.139c0-6.482-2.227-12.43-5.968-17.139 3.518-2.565 7.906-4.13 12.592-4.13 11.757 0 21.27 9.513 21.27 21.27 0 11.756-9.513 21.27-21.27 21.27-4.686-.001-9.076-1.567-12.592-4.132zM79.37 15.412a21.108 21.108 0 0 0-4.162 12.638c0 4.737 1.544 9.101 4.162 12.637a21.147 21.147 0 0 0 4.162-12.637c0-4.744-1.539-9.099-4.162-12.638z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth=".476"
        d="M3.833 28.049c0-13.374 10.842-24.216 24.216-24.216s24.216 10.842 24.216 24.216-10.842 24.216-24.216 24.216S3.833 41.423 3.833 28.049z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth=".476"
        d="M38.048 28.049c0-13.374 10.841-24.216 24.215-24.216 13.375 0 24.217 10.842 24.217 24.216S75.638 52.265 62.263 52.265c-13.374 0-24.215-10.842-24.215-24.216z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth=".476"
        d="M72.262 28.049c0-13.374 10.841-24.216 24.216-24.216 13.373 0 24.215 10.842 24.215 24.216s-10.842 24.215-24.215 24.215c-13.375.001-24.216-10.841-24.216-24.215z"
        clipPath="url(#b)"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.864"
        strokeWidth=".476"
        d="M106.476 28.049c0-13.374 10.842-24.216 24.215-24.216 13.375 0 24.217 10.842 24.217 24.216s-10.842 24.215-24.217 24.215c-13.373.001-24.215-10.841-24.215-24.215z"
        clipPath="url(#b)"
      />
      <path
        fill="#cc1e47"
        d="M133.597 97.391V76.598h-8.645v20.793h8.645zM87.822 88.691V76.598H79.44v9.282c0 2.652-.107 3.395-.266 4.085-.584 1.909-2.6 2.758-4.668 2.758-2.865 0-4.19-1.114-4.721-2.865-.159-.583-.212-1.219-.266-1.855-.053-.689 0-1.433 0-2.229v-9.176h-8.487v12.146c0 2.705.902 4.721 2.387 6.1 2.546 2.494 6.843 3.236 10.821 3.236 4.827 0 8.541-.689 11.193-3.289 1.381-1.326 2.389-3.076 2.389-6.1zM46.241 87.08h-11.54l5.752-9.488 5.788 9.488zM62.52 97.391L43.423 68.747H32.708l2.96 3.501-16.803 25.143h9.654l2.88-4.721h18.208l2.94 4.721h9.973zM122.618 86.623c0-2.334-.955-5.039-3.925-8.806l-7.161-9.07h-9.973l5.676 6.365c.955 1.061 2.175 2.015 2.175 2.015-1.379-.795-3.129-1.113-4.509-1.113-3.288 0-6.63.689-9.601 2.334-3.183 1.75-5.305 4.668-5.305 8.54 0 2.97 1.326 5.251 3.023 7.002 3.819 3.819 10.768 4.19 13.314 4.19 3.766 0 9.654-.85 12.995-3.873 1.965-1.803 3.291-4.296 3.291-7.584zm-8.275.265c0 2.175-1.433 3.819-3.236 4.721-1.484.796-3.235 1.008-4.773 1.008-1.539 0-3.342-.317-4.827-1.061-1.804-.901-3.183-2.652-3.183-4.562 0-2.281 1.75-4.084 3.819-4.986 1.272-.584 2.812-.796 4.031-.796 1.167 0 2.864.159 4.19.69 2.387 1.008 3.979 2.916 3.979 4.986z"
      />
    </svg>
  );
}
export default AudiIcon;
