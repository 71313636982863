import React from "react";

function InstagramIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M50,9c13.35,0,14.93.05,20.2.29A27.74,27.74,0,0,1,79.49,11a15.56,15.56,0,0,1,5.75,3.74A15.56,15.56,0,0,1,89,20.51,27.74,27.74,0,0,1,90.7,29.8c.24,5.27.29,6.85.29,20.2s0,14.93-.29,20.2A27.74,27.74,0,0,1,89,79.49,16.55,16.55,0,0,1,79.49,89,27.74,27.74,0,0,1,70.2,90.7c-5.27.24-6.85.29-20.2.29s-14.93,0-20.2-.29A27.74,27.74,0,0,1,20.51,89a15.56,15.56,0,0,1-5.75-3.74A15.56,15.56,0,0,1,11,79.49,27.74,27.74,0,0,1,9.3,70.2C9.06,64.93,9,63.35,9,50s.05-14.93.29-20.2A27.74,27.74,0,0,1,11,20.51a15.56,15.56,0,0,1,3.74-5.75A15.56,15.56,0,0,1,20.51,11,27.74,27.74,0,0,1,29.8,9.3C35.07,9.06,36.65,9,50,9m0-9C36.42,0,34.72.06,29.39.3A36.77,36.77,0,0,0,17.25,2.62,24.54,24.54,0,0,0,8.39,8.39a24.54,24.54,0,0,0-5.77,8.86A36.77,36.77,0,0,0,.3,29.39C.06,34.72,0,36.42,0,50S.06,65.28.3,70.61A36.77,36.77,0,0,0,2.62,82.75a24.54,24.54,0,0,0,5.77,8.86,24.54,24.54,0,0,0,8.86,5.77A36.77,36.77,0,0,0,29.39,99.7c5.33.24,7,.3,20.61.3s15.28-.06,20.61-.3a36.77,36.77,0,0,0,12.14-2.32A25.58,25.58,0,0,0,97.38,82.75,36.77,36.77,0,0,0,99.7,70.61c.24-5.33.3-7,.3-20.61s-.06-15.28-.3-20.61a36.77,36.77,0,0,0-2.32-12.14,24.54,24.54,0,0,0-5.77-8.86,24.54,24.54,0,0,0-8.86-5.77A36.77,36.77,0,0,0,70.61.3C65.28.06,63.58,0,50,0Z" />
      <path d="M50,24.32A25.68,25.68,0,1,0,75.68,50,25.68,25.68,0,0,0,50,24.32Zm0,42.35A16.67,16.67,0,1,1,66.67,50,16.68,16.68,0,0,1,50,66.67Z" />
      <circle cx="76.69" cy="23.31" r="6" />
    </svg>
  );
}
export default InstagramIcon;
