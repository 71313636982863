import React from 'react'

// icons
import CloseIcon from '../../../common/common-assets/icons/closeicon'

// style
import './share.scss'

// redux
import {connect} from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'


function ShareSentModal(props) {
    const { closeModal } = props

    return (
        <div className='share-modal'>
            <div className='modal-title'>{props.sharedSuccess}</div>
            <div className='close-modal' onClick={() => closeModal()}>
                <CloseIcon />
            </div>

            <div className='form-row button' onClick={() => closeModal()}>
                <div className='close-button'>
                    Close
                </div>
            </div>
            
        </div>
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
}

export default connect(null, mapDispatchToProps)(ShareSentModal)
