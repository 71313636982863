const REDUCER_CONSTANTS = {
  GET_URL_INFO: "GET_URL_INFO",
  STASH_URL_HISTORY: "STASH_URL_HISTORY",
  CHECK_V3_MIGRATED:"CHECK_V3_MIGRATED",
  GET_VEHICLE_PAGE:"GET_VEHICLE_PAGE",
  GET_VEHICLE_MODULES:"GET_VEHICLE_MODULES",
  GET_VEHICLE_IMAGES:"GET_VEHICLE_IMAGES",
  GET_SALESPERSON_INFO:"GET_SALESPERSON_INFO",
  GET_PAYMENT_CALCULATOR:"GET_PAYMENT_CALCULATOR",
  GET_SITE_SKIN:"GET_SITE_SKIN",
  GET_SITE_CONFIG:"GET_SITE_CONFIG",
  GET_VEHICLE_MODULE_QV:"GET_VEHICLE_MODULE_QV",
  SHOW_MODAL:"SHOW_MODAL",
  CLOSE_MODAL:"CLOSE_MODAL",
  UPDATE_ZOOM_MEETING_REQUEST_FORM:"UPDATE_ZOOM_MEETING_REQUEST_FORM",
  UPDATE_CONFIRM_AVAILABILITY_FORM:"UPDATE_CONFIRM_AVAILABILITY_FORM",
}
export default REDUCER_CONSTANTS
