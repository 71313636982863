import React, { useState, useEffect} from 'react'

import CloseIcon from '../common-assets/icons/closeicon'

import IPacketIcon from '../../common/common-assets/icons/iPacketSimpleIcon'
import ModuleArrow from '../common-assets/icons/moduleArrowIcon'
import ExclamationIcon from '../common-assets/icons/exclamationIcon'


import './navigationDrawer.scss'


export default function NavigationDrawer(props) {
    const { drawerToggleLabel } = props
    const drawerToggleSaveName = drawerToggleLabel ? drawerToggleLabel : 'drawerToggleValue'
    const [drawerToggle, toggleDrawer] = useState(true)
    const [checkedStorage, setCheckedStorage] = useState(false)
    const navigationDrawerClass = drawerToggle ? 'navigation-drawer-container open' : 'navigation-drawer-container close'
    const handleToggle = () => {
        sessionStorage.setItem(drawerToggleSaveName, !drawerToggle)
        toggleDrawer(!drawerToggle)
    }

    useEffect(()=>{
        if(!sessionStorage.getItem(drawerToggleSaveName)){
            sessionStorage.setItem(drawerToggleSaveName, 'true')
        }
        sessionStorage.getItem(drawerToggleSaveName) === 'true' ? toggleDrawer(true) : toggleDrawer(false)
        setCheckedStorage(true)
    },[])

    if(checkedStorage){
      return (
          <div className={navigationDrawerClass} onClick={()=>handleToggle()}>
              {drawerToggle&& <div className='navigation-drawer-close-button'><CloseIcon/></div>}
              <div className='navigation-drawer-main-content'>{props.children}</div>
              <div className='navigation-drawer-open-close-toggle'>
                <ExclamationIcon/>
              </div>
          </div>
      )
    }
    return(<React.Fragment/>)
}
