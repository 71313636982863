import React from "react";

function InfinitiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path d="M58.439 140.307v-9.213h4.877v1.35h-3.157v2.334h2.961v1.353h-2.961v4.176h-1.72zM134.289 140.381v-9.213h1.721v9.213h-1.721zM156.623 131.12h5.736v1.352h-2.002v7.861h-1.72v-7.861h-2.014v-1.352zM8.504 140.381v-9.213h1.72v9.213h-1.72zM111.461 131.085h1.477v9.214h-2l-2.399-6.941v6.941h-1.437v-9.214h2.017l2.358 7.016h-.026l.01-7.016zM182.533 140.381v-9.213h1.719v9.213h-1.719zM83.933 140.381v-9.213h1.72v9.213h-1.72zM35.744 131.085h1.475v9.214h-2.002l-2.396-6.941v6.941h-1.437v-9.214H33.4l2.357 7.016h-.024l.011-7.016zM96.363 52.375c-37.081 0-67.142 15.156-67.142 33.85 0 15.136 19.71 27.952 46.897 32.282L96.363 70.15l20.245 48.356c27.189-4.33 46.896-17.146 46.896-32.282 0-18.693-30.059-33.849-67.141-33.849zm30.832 50.839L96.363 67.409l-30.716 35.704C50.521 98.891 40.551 91.5 40.551 82.414c0-14.412 24.798-27.925 55.753-27.925 30.957 0 56.348 13.513 56.348 27.925 0 9.156-10.129 16.594-25.457 20.8z" />
      </g>
    </svg>
  );
}
export default InfinitiIcon;
