import React from "react";

function FacebookIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M26.36,53.26h11V98.39A1.61,1.61,0,0,0,38.93,100H57.52a1.61,1.61,0,0,0,1.61-1.61V53.47h12.6A1.6,1.6,0,0,0,73.33,52l1.92-16.61a1.62,1.62,0,0,0-1.61-1.8H59.13V23.22c0-3.14,1.69-4.73,5-4.73h9.49a1.61,1.61,0,0,0,1.62-1.62V1.62A1.61,1.61,0,0,0,73.64,0H60C57.7,0,49.81.45,43.58,6.18a17.17,17.17,0,0,0-5.72,15.27V33.63H26.36a1.61,1.61,0,0,0-1.62,1.62v16.4A1.61,1.61,0,0,0,26.36,53.26Z" />
    </svg>
  );
}
export default FacebookIcon;
