import React, {useState, useEffect} from 'react'
import {isMobile, isAndroid} from 'react-device-detect'
import CancelIcon from '../../../common/common-assets/icons/cancelicon'

import './mobileRecommendationBanner.scss'

function MobileRecommendationBanner(){
    const ignoreAppStoreToken = localStorage.getItem('ignoreAppStoreToken');
    const [ignoreAppStore, setignoreAppStore] = useState(false);
    useEffect(() => {
        if (ignoreAppStoreToken === null) {
            setignoreAppStore(false)
        } else {
            setignoreAppStore(true)
        }
    },[])
    const bannerHide = () => {
        localStorage.setItem('ignoreAppStoreToken','true')
        setignoreAppStore(true)
    }
    if(!ignoreAppStore && isMobile){
    return (
        <div className="mobile-rec-banner">
          We recommend using our mobile application for the best experience.
          <br/>
          <br/>
          Click below to visit the app store and download for free!
          <a
            target="_blank" rel="noopener noreferrer"
            href={isAndroid ?
                "https://play.google.com/store/apps/details?id=com.autoipacket.marketplaceapp"
                :
                "https://apps.apple.com/us/app/ipacket/id1570295337"}
          >
            <button className="mobile-rec-button-appstore">
                VISIT THE APP STORE
            </button>
          </a>
          <button 
            className="mobile-rec-button-ignore"
            onClick={()=>bannerHide()}
          >
            ...or continue in your browser
          </button>
          <div
            onClick={()=>bannerHide()}
          >
            <CancelIcon/>
          </div>
        </div>
    )
    } else {
        return(<React.Fragment></React.Fragment>)
    }
}
export default MobileRecommendationBanner