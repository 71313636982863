import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import KEYS from '../common-constants/keys'
import './searchInventoryFilterBox.scss'
import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../api/aipDjapi'
import validateZip from '../../../utils/validateZip'
import { toast } from 'react-toastify';


function SearchInventoryFilterBox(props){

  const{onSearchAction, searchButtonClassName} = props
  let  condition  = props.condition? props.condition: 'New & Used'

  const [ zipcode, setZipcode ] = useState('');
  const [ makes, setMakes ] = useState(null);
  const [selectedMake, setSelectedMake ] = useState('All Makes')
  const [models, setModels] = useState(null);
  const [selectedModel, setSelectedModel ] = useState('All Models')
  let history = useHistory()
  const decodedLocationKey = window.atob(KEYS.LOCATION)

  useEffect(() => {
    if(selectedMake!=='All Makes'){
      getModels()
    }
    setSelectedModel('All Models')

  },[selectedMake])

  useEffect(() => {
    getMakes()
    // get user location and set zip code
    const existingLocationData = JSON.parse(localStorage.getItem('locationData'))
    if(existingLocationData && existingLocationData.zipcode){
      setZipcode(existingLocationData.zipcode)
    }
    else {
      let locationData = {}
      const options = {
        timeout: 5000,
        maximumAge: 2000,
      };
      try {window.navigator.geolocation.getCurrentPosition(position => {
        //TODO: Will probably need to update this below check to be within a range of distance vs exact coords - Khris
        if( !existingLocationData || position.coords.latitude !== existingLocationData.latitude || position.coords.longitude !== existingLocationData.longitude){
          getZipcode(position.coords.latitude, position.coords.longitude)
        }
      },
      console.error, options)}
      catch (e){
        console.log("zip code retrieval error", e)
      }
      function getZipcode(lat, long){
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var address = JSON.parse(this.responseText)
                const zip = (address.results[0].address_components.find(component => component.types == 'postal_code').short_name)
                if(zip.length == 5 ){
                  locationData = {coords: {latitude: lat, longitude: long}, zipcode: zip}
                  setZipcode(zip)
                  localStorage.setItem('locationData',JSON.stringify(locationData))
                }
            }
        };
        xhttp.open("GET", `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${decodedLocationKey}`, true);
        xhttp.send();
      }
    }
  }, []);
  // nuumbers only in zip field
  const handleZip = event => {
    const result = event.target.value.replace(/\D/g, '');
    setZipcode(result);
  }

  const getMakes = () =>{
    AIP_DJAPI(`${AIP_DJAPI_URLS.SEARCH_PAGE}?items_per_page=0`).then(response =>{
      return response.json()
    }).then(res=>{
      if(res && res.aggregations && res.aggregations.make){
        setMakes(res.aggregations.make.buckets)
      }
    })
  }

  const getModels = () =>{

    if(selectedMake && selectedMake !== 'All Makes'){
      AIP_DJAPI(`${AIP_DJAPI_URLS.SEARCH_PAGE}?make=${selectedMake}&items_per_page=0`).then(response =>{
        return response.json()
      }).then(res=>{
        if(res && res.aggregations && res.aggregations.model){
          setModels(res.aggregations.model.buckets)
        }
      })
    }
  }

  const search = (zipcode) =>{
    if(validateZip(zipcode)){
      let path = `/search${'/'+zipcode}${'/'+condition}${'/'+selectedMake}${'/'+selectedModel}`
      path = encodeURI(path)
      history.push(path)
      localStorage.setItem('locationData',JSON.stringify({"zipcode": zipcode}))
    }
    else{
      //TODO: Add Error Message
      console.log('invalid zip')
    }
  }

  const makesDropdown = () => {
    let options = [];
    if(makes){
      let makesPopular = [];
      let makesContinued = [];
      let alphabetRestarted = false;
      let lastChar = null;

      makes.forEach(make => {
        const currentChar = make.key.charAt(0).toLowerCase();
        if (lastChar === null) {
          lastChar = currentChar;
        }
        if (lastChar > currentChar) {
          alphabetRestarted = true;
        }
        if (!alphabetRestarted) {
          makesPopular.push(make);
        } else {
          makesContinued.push(make);
        }
        lastChar = currentChar;
      });

      const optgroup1 =
        (<optgroup label="Makes">
          <option key={'All Makes'} value={'All Makes'}>All Makes</option>
          {makesPopular.map(make => (<option key={make.key} value={make.key}>{make.key}</option>))}
        </optgroup>)

      const optgroup2 =
        (<optgroup label="Makes Continued...">
          {makesContinued.map(make => (<option key={make.key} value={make.key}>{make.key}</option>))}
        </optgroup>)

      options = [optgroup1, optgroup2]
    }

    return(
      <select
        className="filter-box-form-select-makes"
        name="makes"
        value={selectedMake}
        disabled={!(makes&&makes.length)}
        onChange={(e)=>{setSelectedMake(e.target.value)}}
      >
        {options}
      </select>
    )
  }


  const modelsDropdown = () =>{
    let options = []
    if(models){
      options = models.map(model=>{
        return(<option key={model.key} value={model.key}>{model.key}</option>)
      })
    }
    options.unshift(<option key={'All Models'} value={'All Models'}>All Models</option>)
    return(
      <select
        className="filter-box-form-select-models"
        name="models" value={selectedModel}
        disabled={selectedMake==='All Makes' || !models || !models.length}
        onChange={(e)=>{setSelectedModel(e.target.value)}}
      >
        {options}
      </select>
    )
  }
  return(
    <div className="search-inventory-filter-box">
      <div className="search-inventory-filter-box-form">
        {makesDropdown()}

        {modelsDropdown()}

        <div className='filter-box-input-zip-container'>
          {!validateZip(zipcode) ? <span>*valid ZIP required</span> : null}
          <input
            className={`filter-box-form-input-zip${!validateZip(zipcode) ? ' input-required':''}`}
            placeholder="ZIP Code*" value={zipcode}
            onChange={handleZip}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                search(zipcode)
                if(onSearchAction)(onSearchAction())
              }
            }}
            required
            minLength={4}
            maxLength={5}
          />
        </div>

        <button
          className={`primary-button full-width filter-box-button ${!validateZip(zipcode) ? 'disabled' : ''} ${searchButtonClassName?searchButtonClassName:''}`}
          alt="Click to search" onClick={()=>{
            search(zipcode)
            if(onSearchAction){onSearchAction()}
          }}
          disabled={!validateZip(zipcode)}
        >
          Search
        </button>
      </div>
    </div>
  )
}
export default SearchInventoryFilterBox
