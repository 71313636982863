import REDUCER_CONSTANTS from '../constants/reducerConstants'
const initialState = {
  urlParams:{}
}

function urlInfoReducer(state=initialState, action){
  switch(action.type){
    case REDUCER_CONSTANTS.GET_URL_INFO:
      return{
        ...state,
        ...action.urlInfo
      }
    default:
      return state
  }
}

export default urlInfoReducer
