import React from "react";
import { connect } from "react-redux";
import Pagination from "../../../../common/common-components/paginator";
import SearchVehicleTile from "./searchVehicleTile";
import SAVED_VEHICLES_PAGE_ACTIONS from "../../saved-vehicle-page/state/savedVehiclePageActions"
import SEARCH_PAGE_ACTIONS from '../state/searchPageActions'
import "./searchVehicleDisplay.scss";
import LoadingSpinner from "../../../../common/common-components/loadingSpinner";



function SearchVehicleDisplay(props) {
  const { vehicles, total_vehicle_count, page, updatePage, loading, saveVehicle, removeLikedVehicle, isUserLoggedIn, savedVehicles, externalSrp } = props;

  const handleSaveVehicle = (vehicle) => {
    savedVehicles.find(savedVehicle => savedVehicle.vin === vehicle.vin) ? removeLikedVehicle(vehicle) : saveVehicle(vehicle)
  }
  if (!loading) {
    return (
      <div className="search-results-container">
        {vehicles.length > 0 ? (
          <div>
            <Pagination
              className="pagination-bar pagination-mobile-bar"
              currentPage={page}
              totalCount={total_vehicle_count==='>10,000'?24*100:total_vehicle_count}
              pageSize={24}
              onPageChange={(pageNum) => {

                updatePage(pageNum)
                setTimeout(()=> {window.scrollTo({top: 0, left:0, behavior:'smooth'})}, 500)
              }}
            />
            <div className="search-vehicle-display-container">
              {vehicles &&
                vehicles.length &&
                vehicles.map((vehicle,i) => {
                  const isVehicleSaved = savedVehicles.find(savedVehicle => savedVehicle.vin === vehicle.vin) ? true : false
                  return (
                    <SearchVehicleTile
                      key={vehicle.vehicle_id}
                      vehicle={vehicle}
                      saveVehicle={handleSaveVehicle}
                      fillIcon={isVehicleSaved}
                      isUserLoggedIn={isUserLoggedIn}
                      externalSrp={externalSrp}
                    />);
                })}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={total_vehicle_count==='>10,000'?24*100:total_vehicle_count}
              pageSize={24}
              onPageChange={(pageNum) => {

                updatePage(pageNum)
                setTimeout(()=> {window.scrollTo({top: 0, left:0, behavior:'smooth'})}, 500)
              }}
            />
          </div>) : (
          <div className="no-search-results-found">
            No Results Found
          </div>)
        }

      </div>
    );
  } else {
    return (
      <div className="search-results-container">
        <LoadingSpinner loading />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state.searchPage,
  savedVehicles: state.savedVehiclePage.savedVehicles,
  isUserLoggedIn: state.userProfile.user && state.userProfile.token ? true : false,
});

const mapDispatchToProps = {
  ...SAVED_VEHICLES_PAGE_ACTIONS.dispatchActions,
  ...SEARCH_PAGE_ACTIONS.dispatchActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchVehicleDisplay);
