import React from "react";

function GmcIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1200"
      height="506"
      viewBox="0 0 26.007 5.26"
    >
      <path
        fill="#cf4037"
        d="M0 1.446C0 .583.537 0 1.552 0h6.589v1.224H1.75c-.179 0-.338.092-.338.362v2.041c0 .248.104.408.431.408h4.501c.237 0 .42-.084.42-.396v-.478H4.129V1.995h4.012v1.876c0 .898-.653 1.389-1.679 1.389H1.563C.572 5.26.001 4.723.001 3.825L0 1.446M14.019 5.26l2.251-3.989V5.26h1.411V0h-2.17l-2.146 3.778L11.219 0H9.05v5.26h1.411V1.271l2.251 3.989h1.307M26.007 5.26h-5.9c-.993 0-1.562-.537-1.562-1.435V1.446c0-.863.535-1.446 1.55-1.446h5.913v1.322h-5.714c-.179 0-.339.091-.339.361v1.846c0 .249.105.408.432.408h5.621V5.26"
      />
    </svg>
  );
}
export default GmcIcon;
