import MODAL_CONSTANTS from './modalConstants'

const initialState = {
  modalList:[]
}
/*
  modalObjectExample={
    type:MODAL_CONSTANTS.EXAMPLE_MODAL
    props: {...}
  }
*/

function modalReducer(state=initialState, action){
  let { modalList } = initialState
  let { modal, numModals } = action
  switch(action.type){
    case MODAL_CONSTANTS.PUSH_MODAL:
      return{
        ...state,
        modalList:[...modalList,action.modal]
      }
    case MODAL_CONSTANTS.POP_MODAL:
      //TODO: Add additional logic for max length
      numModals = numModals ? numModals : 1
      return{
        ...state,
        modalList:[...modalList.slice(0,modalList.length-(1 + numModals ))]
      }
    case MODAL_CONSTANTS.CLOSE_MODAL:
      return{
        ...state,
        modalList:[]
      }
    default:
      return state
  }
}

export default modalReducer
