import React from 'react'
import {connect} from 'react-redux'
import SavedVehicleDisplay from './components/savedVehicleDisplay'
// import SearchSidebar from '../search-page/components/searchSidebar'
import './savedVehiclePage.scss'

function SavedVehiclesPage(props){
  const {savedVehiclesCount} = props
  let savedVehiclesVal = savedVehiclesCount ? `(${savedVehiclesCount})` : ''
  return(
    <div className="saved-vehicle-page-container">
      <div className="saved-vehicle-page">
        <div className="saved-vehicle-page-header-container">
          <div className="saved-vehicle-page-header">
            <h1>Saved Vehicles {savedVehiclesVal}</h1>
          </div>
        </div>
        <div className="saved-vehicle-page-main-content">
          <SavedVehicleDisplay />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.savedVehiclePage,
})

export default connect(mapStateToProps, null)(SavedVehiclesPage)
