import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Slider from "react-slick";
import moment from 'moment';

// redux
import { connect } from "react-redux";
import MODAL_ACTIONS from "../../marketplace-app/modals/state/modalActions";

// assets
import ArrowLeft from "../common-assets/icons/arrowLeft";
import ArrowRight from "../common-assets/icons/arrowRight";
import SavePacketButton from "./savePacketButton";
import ExpandFullscreen from "../common-assets/icons/expandFullscreen";
import ShareIcon from "../common-assets/icons/shareIcon";
import IPacketFolderIconFallback from '../common-assets/images/fallback_ipacket_folder.png'

// style
import "./imageSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//TODO: Move this to the VDP Page Folder Because it is a very page specific component now

//Main Slider Next Arrow
function MainNextArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "20px",
    zIndex: "2",
    borderRadius: "10px",
  };
  return (
    <div
      className={className}
      style={{ ...style, ...newStyle }}
      onClick={onClick}
      id="next-arrow"
    >
      <ArrowRight />
    </div>
  );
}

//Main Slider Previous Arrow
function MainPrevArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "20px",
    zIndex: "2",
    borderRadius: "10px",
  };

  return (
    <div
      className={className}
      style={{ ...style, ...newStyle }}
      onClick={onClick}
      id="prev-arrow"
    >
      <ArrowLeft />
    </div>
  );
}

//Mini Slider Next Arrow
function MiniNextArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "0",
    zIndex: "2",
    height: "100%",
  };
  return (
    <div
      className={className}
      style={{ ...style, ...newStyle }}
      onClick={onClick}
      id="next-arrow"
    >
      <ArrowRight />
    </div>
  );
}

//Mini Slider Previous Arrow
function MiniPrevArrow(props) {
  const { className, style, onClick } = props;
  const newStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: "0",
    zIndex: "2",
    height: "100%",
  };

  return (
    <div
      className={className}
      style={{ ...style, ...newStyle }}
      onClick={onClick}
      id="prev-arrow"
    >
      <ArrowLeft />
    </div>
  );
}

class ImageSlider extends Component {
  //the props for the ImageSlider are as follows
  //required props: images
  /*
    optional props: displayingVehicleImages, expandable (if the slider can be expanded to lightbox), mockAdd, 
    slider (choose whether the secondary slider is displayed at the bottom)
  */
    constructor(props) {
    super(props);
    this.state = {
      mainNav: null,
      miniNav: null,
      currentFillIcon: null,
      initialIndex: null,
      likeCount: null,
    };
  }

  //set initial slider value on mount
  componentDidMount() {
    const { vehicle } = this.props
    this.setState({
      mainNav: this.mainSlider,
      miniNav: this.miniSlider,
      currentFillIcon: vehicle.is_liked,
      likeCount: vehicle.like_count
    });
  }

  handleSaveVehicle(e, vehicle) {
      const { saveVehicle } = this.props
      const { currentFillIcon, likeCount } = this.state
      e.preventDefault()
      currentFillIcon ? this.setState({ likeCount: likeCount - 1 }) : this.setState({ likeCount: likeCount + 1 })
      this.setState({ currentFillIcon: !currentFillIcon })
      saveVehicle(vehicle)
  }

  render() {
    const { pushModal, images, mockAdd, vehicle, isUserLoggedIn, etsInfo, viewTimes, externalVdp, sendModuleViewEts,sendGenericActionEts} = this.props;
    const { mainNav, miniNav, currentFillIcon, likeCount  } = this.state;
    //Settings for Main Carousel
    const mainSettings = {
      dots: false,
      fade: false,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.props.initialIndex ? this.props.initialIndex : 0,
      nextArrow: <MainNextArrow />,
      prevArrow: <MainPrevArrow />,
      afterChange: index => this.setState({ initialIndex: index }),
    };
    //Settings for Bottom Mini Carousel
    const reactSlickInfiniteProp = !images.length <= 4 ? false : true;
    const miniSettings = {
      dots: false,
      lazyLoad: true,
      infinite: reactSlickInfiniteProp,
      swipeToSlide: true,
      focusOnSelect: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      nextArrow: <MiniNextArrow />,
      prevArrow: <MiniPrevArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 4,
          },
        }
      ],
    };
    const onImageError = (currentTarget) => {
      currentTarget.src = IPacketFolderIconFallback;
      currentTarget.onerror = null;
    }

    return (
      <div className="slider-container">
        <div className="slider-main-image-container">
          <div className="slider-main-image">
            {this.props.expandable &&
              images.length >= 1 && (
                <div
                  className="expand-full-screen-icon"
                  onClick={() => {
                    pushModal({
                      type: MODAL_ACTIONS.MODAL_CONSTANTS.LIGHTBOX,
                      props: { ...this.props, initialIndex: this.state.initialIndex },
                    });
                  }}
                >
                  <ExpandFullscreen />
                </div>
              )}

            <div
              className="expand-full-screen-icon share-icon"
              onClick={() => {
                pushModal({
                  type: MODAL_ACTIONS.MODAL_CONSTANTS.SHARE,
                  props: { ...this.props },
                  etsGenericActionSubmitEvent:externalVdp?{event:"generic_action",action:"consumer_share_submit"}:null,
                });
              }}
            >
              <ShareIcon />
            </div>

            {images && (
              <Slider
                asNavFor={miniNav}
                ref={(slider) => {
                  if (slider && !this.mainSlider) {
                    this.mainSlider = slider;
                    this.setState({ mainNav: this.mainSlider });
                  }
                }}
                {...mainSettings}
              >
                {images.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (this.props.expandable && images.length >= 1) {
                        pushModal({
                          type: MODAL_ACTIONS.MODAL_CONSTANTS.LIGHTBOX,
                          props: { ...this.props, initialIndex: index },
                        });
                      }
                    }}
                  >
                    <img
                      src={image}
                      alt="vehicle"
                      height="450"
                      className={images && images.length >= 1 && image !== IPacketFolderIconFallback ? null : "fallback-image"}
                      onError={({currentTarget}) => onImageError(currentTarget)}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
        {this.props.displayingVehicleImages && vehicle && (
          <div className="slider-main-image-overlay">
            <SavePacketButton
                count={isUserLoggedIn ? likeCount : null}
                fillIcon={currentFillIcon}
                onClick={(e)=>{
                    this.handleSaveVehicle(e,vehicle)
                }}
            />
            {(etsInfo && etsInfo.module_view_stats['22'])&&
              <div className="vehicle-image-heatmap-view">
                {function(){
                  return(
                    etsInfo.module_view_stats['22'].view_seconds>300?"5:00+": moment.utc(etsInfo.module_view_stats['22'].view_seconds*1000).format('mm:ss')
                  )
                }()}
              </div>
            }
          </div>
        )}
        <div className="slider-selector-container">
          {this.props.slider && images &&
            images.length > 1 && (
              <Slider
                asNavFor={mainNav}
                ref={(slider) => {
                  if (slider && !this.miniSlider) {
                    this.miniSlider = slider;
                    this.setState({ miniNav: this.miniSlider });
                  }
                }}
                {...miniSettings}
              >
                {images.map((image, index) => (
                  <div data-index={index} key={index}>
                    <img src={image} alt="Vehicle" onError={({currentTarget}) => onImageError(currentTarget)}/>
                  </div>
                ))}
              </Slider>
            )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...MODAL_ACTIONS.dispatchActions,
};

const mapStateToProps = (state) => ({
  modals: state.modal.modalList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageSlider);
