import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers/rootReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist:[]
}

const persistedReducer = persistReducer(persistConfig,rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


export default () =>{
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return{ store, persistor }
}
