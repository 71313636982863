import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import './scheduleDemo.scss'

import ZoomMeeting from '../../../common/common-assets/images/dealer-center-zoom.png'

class Calendly extends Component {
    componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
    render(){
        return (
          <div>
            <div id="schedule_form">
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/autoipacket-demo?primary_color=1cd760"
                style={{ minWidth: '320px', minHeight: '1000px', height: 'calc(100vh - 80px)' }} />
            </div>
          </div>
        );
      }
}

function DemoSchedule(props) {
    return(
        <div className="scheduledemo-page">
            <div className="scheduledemo-body1">
                <h1>Schedule a demo, and let's start the conversation.</h1>
                <div className="scheduledemo-callbtn">
                    <a href="tel:+8558939340">Speak with the iPacket success team now at <span>855-893-9340</span></a>
                </div>
                <Calendly />
            </div>
            <div className="scheduledemo-body2">
            <div className="scheduledemo-container">
                <div className="scheduledemo-body2-column1">
                    <h1>We'd love to show you firsthand.</h1>
                    <h2>Schedule a demo and have a conversation about how iPacket can help amplify your business. Reach out today.</h2>
                    <div className="scheduledemo-callbtn">
                        <a href="tel:+8558939340">
                            CALL US NOW AT <span>855-893-9340</span>
                        </a>
                    </div>
                </div>
                <div className="scheduledemo-body2-column2">
                    <img src={ZoomMeeting} alt="iPacket Demo"/>
                </div>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(DemoSchedule)
