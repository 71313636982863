import React from 'react'

const VDI_Color = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.94 41.25">
        <path d="M33.59,20.62V16.5A16.33,16.33,0,0,0,17.47,0,16.34,16.34,0,0,0,1.34,16.5v4.12H0v2.76H5.38v16.5a1.35,1.35,0,0,0,1.34,1.37h21.5a1.35,1.35,0,0,0,1.34-1.37V23.38h5.38V20.62ZM26.87,38.5H8.06V25.19A7.08,7.08,0,0,1,9.68,27c1.07,1.47,2.41,3.3,5.1,3.3,2.28,0,3.75-1.72,5.31-3.54,1.74-2.05,3.55-4.15,6.78-4.61Zm0-19.15c-4.45.5-6.95,3.37-8.81,5.55-1.37,1.61-2.27,2.6-3.28,2.6-1.28,0-1.93-.79-3-2.2a7.09,7.09,0,0,0-3.76-3.11V17.88H26.87Zm4,1.27H29.56V16.5a1.36,1.36,0,0,0-1.34-1.38H6.72A1.36,1.36,0,0,0,5.38,16.5v4.12H4V16.5A13.61,13.61,0,0,1,17.47,2.75,13.61,13.61,0,0,1,30.91,16.5Z"/>
        <path d="M25.86,30.62H23.18V37.5h2.68Z"/>
        <path d="M22,30.62H19.34V37.5H22Z"/>
    </svg>
  )
}
export default VDI_Color
