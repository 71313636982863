import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MarketplaceApp from './marketplaceApp'

function MarketplaceAppContainer(props){
  return(
    <div>
      <BrowserRouter>
        <MarketplaceApp/>
      </BrowserRouter>
    </div>
  )
}

export default connect(null)(MarketplaceAppContainer)
