import React from 'react'

const NewsPage = ()=>{
  return(
    <div className="news-page-container marketplace-iframe-only-page">
      <iframe src={'https://www.ipacket.us/dealer-center/news'} frameBorder="0" allowFullScreen/>
    </div>
  )
}
export default NewsPage
