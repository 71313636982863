import React from 'react'
import { Link } from 'react-router-dom'

// icons
import CloseIcon from '../../../common/common-assets/icons/closeicon'
//car-brand-logos
import AlfaIcon from '../../../common/common-assets/icons/car-brands-logos/alfa.js'
import AudiIcon from '../../../common/common-assets/icons/car-brands-logos/audi.js'
import BentleyIcon from '../../../common/common-assets/icons/car-brands-logos/bentley.js'
import BmwIcon from '../../../common/common-assets/icons/car-brands-logos/bmw.js'
import CadillacIcon from '../../../common/common-assets/icons/car-brands-logos/cadillac.js'
import ChevroletIcon from '../../../common/common-assets/icons/car-brands-logos/chevrolet.js'
import ChryslerIcon from '../../../common/common-assets/icons/car-brands-logos/chrysler.js'
import DodgeIcon from '../../../common/common-assets/icons/car-brands-logos/dodge.js'
import FerrariIcon from '../../../common/common-assets/icons/car-brands-logos/ferrari.js'
import FordIcon from '../../../common/common-assets/icons/car-brands-logos/ford.js'
import GmcIcon from '../../../common/common-assets/icons/car-brands-logos/gmc.js'
import HondaIcon from '../../../common/common-assets/icons/car-brands-logos/honda.js'
import HyundaiIcon from '../../../common/common-assets/icons/car-brands-logos/hyundai.js'
import InfinitiIcon from '../../../common/common-assets/icons/car-brands-logos/infiniti.js'
import JaguarIcon from '../../../common/common-assets/icons/car-brands-logos/jaguar.js'
import JeepIcon from '../../../common/common-assets/icons/car-brands-logos/jeep.js'
import KiaIcon from '../../../common/common-assets/icons/car-brands-logos/kia.js'
import LamborghiniIcon from '../../../common/common-assets/icons/car-brands-logos/lamborghini.js'
import LandIcon from '../../../common/common-assets/icons/car-brands-logos/land.js'
import LexusIcon from '../../../common/common-assets/icons/car-brands-logos/lexus.js'
import LincolnIcon from '../../../common/common-assets/icons/car-brands-logos/lincoln.js'
import MercedesIcon from '../../../common/common-assets/icons/car-brands-logos/mercedes.js'
import NissanIcon from '../../../common/common-assets/icons/car-brands-logos/nissan.js'
import PorscheIcon from '../../../common/common-assets/icons/car-brands-logos/porsche.js'
import ToyotaIcon from '../../../common/common-assets/icons/car-brands-logos/toyota.js'
import VolkswagenIcon from '../../../common/common-assets/icons/car-brands-logos/volkswagen.js'
import VolvoIcon from '../../../common/common-assets/icons/car-brands-logos/volvo.js'

// style
import '../ask-a-question-modal/askaquestion.scss'
import './carBrandsModal.scss'

// redux
import {connect} from 'react-redux'
import MODAL_ACTIONS from '../state/modalActions'


function CarBrandsModal(props) {
    const { closeModal } = props

    return (
        <div className='question-modal car-brands-modal'>
            <div className='modal-title'>Select a Manufacturer</div>
            <div className='close-modal' onClick={() => closeModal()}>
                <CloseIcon />
            </div>
            <div className="brand-logos">
                <Link to='/'><AlfaIcon /></Link>
                <Link to='/'><AudiIcon /></Link>
                <Link to='/'><BentleyIcon /></Link>
                <Link to='/'><BmwIcon /></Link>
                <Link to='/'><CadillacIcon /></Link>
                <Link to='/'><ChevroletIcon /></Link>
                <Link to='/'><ChryslerIcon /></Link>
                <Link to='/'><DodgeIcon /></Link>
                <Link to='/'><FerrariIcon /></Link>
                <Link to='/'><FordIcon /></Link>
                <Link to='/'><GmcIcon /></Link>
                <Link to='/'><HondaIcon /></Link>
                <Link to='/'><HyundaiIcon /></Link>
                <Link to='/'><InfinitiIcon /></Link>
                <Link to='/'><JaguarIcon /></Link>
                <Link to='/'><JeepIcon /></Link>
                <Link to='/'><KiaIcon /></Link>
                <Link to='/'><LamborghiniIcon /></Link>
                <Link to='/'><LandIcon /></Link>
                <Link to='/'><LexusIcon /></Link>
                <Link to='/'><LincolnIcon /></Link>
                <Link to='/'><MercedesIcon /></Link>
                <Link to='/'><NissanIcon /></Link>
                <Link to='/'><PorscheIcon /></Link>
                <Link to='/'><ToyotaIcon /></Link>
                <Link to='/'><VolkswagenIcon /></Link>
                <Link to='/'><VolvoIcon /></Link>
            </div>

            <div className='form-row button' onClick={() => closeModal()}>
                <div className='close-button'>
                    Close
                </div>
            </div>
            
        </div>
    )
}

const mapDispatchToProps={
    ...MODAL_ACTIONS.dispatchActions,
}

export default connect(null, mapDispatchToProps)(CarBrandsModal)
