import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleLogin from 'react-apple-login'
import { toast } from 'react-toastify'

import validateEmail from '../../../../utils/validateEmail'
import PasswordInput from '../../../common/common-components/passwordInput'
import './loginForm.scss'
import AppleIcon from '../../../common/common-assets/icons/apple-icon'
import FacebookIcon1 from '../../../common/common-assets/icons/facebook1-icon'
import GoogleGIcon from '../../../common/common-assets/icons/googleg-icon'
import { login, socialLogin } from '../../marketplaceAppUtils'
import LoadingSpinner from '../../../common/common-components/loadingSpinner'

function LoginForm(){

  const [emailField, setEmailField] = useState("")
  const [emailValid, setEmailValid] = useState()
  const [passwordField, setPasswordField] = useState("")
  const [passwordValid, setPasswordValid] = useState()
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState()
  const [facebookLoading, setFacebookLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)
  const googleClientId = '1075399473185-uuaie8kabnv5qg75vl0pak7b903u9lbt.apps.googleusercontent.com'
  const rememberMeToken = localStorage.getItem('rememberMeToken');
  const rememberedEmail = JSON.parse(localStorage.getItem('rememberMeToken'))

  // remember me logic
  useEffect(() => {
      if (rememberMeToken === null) {
          setRememberMe(false)
      } else {
          setRememberMe(true)
          setEmailField(rememberedEmail)
      }
  },[])
  const rememberMeCheck = () => {
      setRememberMe(!rememberMe)
      if (rememberMeToken != null) {
        localStorage.removeItem('rememberMeToken')
      }
  }

  // temporary formSubmit logic
  useEffect(() => {
    if (emailValid && passwordValid){
      toast("You have successfully logged in.")
      setTimeout(()=>{window.location.href='/'},1000)
    }
    console.log(window.location)
  }, [emailValid, passwordValid])

  function formSubmit() {
    setLoading(true)
    if (validateEmail(emailField) && passwordField) {
      // perform login to get user info
      login(emailField, passwordField).then(response =>{
        if(response.status === 400){
          setLoading(false)
        }
        return response.json()
      }).then(res=>{
        if(res && res.token && res.user){
          console.log(res)
          if (rememberMe) {
            localStorage.setItem('rememberMeToken', JSON.stringify(emailField))
          }
          localStorage.setItem('userProfile', JSON.stringify(res))
          setEmailValid(true)
          setPasswordValid(true)
        }
        else{
          setEmailValid(false)
          setPasswordValid(false)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        toast("An error occured while logging in. Please try again later.")
        setLoading(false)
      })
    } else {
      setLoading(false)
      toast(validateEmail(emailField) ? null : "Invalid email")
    }
  }

  async function socialLoginSuccessApple(res) {
    // send Apple token to iPacket backend
    console.log("Apple Login Success\n",res)
    const socialReturn = await socialLogin(res.code, 'apple-id')

    const socialReturnJSON = await socialReturn.json()
    console.log(await socialReturnJSON)
    // check login success
    if (socialReturn.status == 200) {
      // get user profile and token
      if (socialReturnJSON) {
        console.log(socialReturnJSON)
        // save user profile to local storage
        localStorage.setItem('userProfile', JSON.stringify(socialReturnJSON))
        // perform redirect
        setEmailValid(true)
        setPasswordValid(true)
      }

    } else {
      toast(socialReturnJSON && socialReturnJSON.errors && socialReturnJSON.errors.detail?socialReturnJSON.errors.detail:"An error occurred when logging in. Please try again later.")
    }

  }

  async function socialLoginSuccessGoogle(res) {
    //send Google token to iPacket backend
    console.log("Google Login Success\n",res)
    const socialReturn = await socialLogin(res.accessToken, 'google-oauth2')

    const socialReturnJSON = await socialReturn.json()
    console.log(await socialReturnJSON)
    // check login success
    if (socialReturn.status == 200) {
      // get user profile and token
      if(socialReturnJSON){
        console.log(socialReturnJSON)
        // save user profile to local storage
        localStorage.setItem('userProfile', JSON.stringify(socialReturnJSON))
        setGoogleLoading(false)
        //perform redirect
        setEmailValid(true)
        setPasswordValid(true)
      }


    } else {
      setGoogleLoading(false)
      toast(socialReturnJSON && socialReturnJSON.errors && socialReturnJSON.errors.detail?socialReturnJSON.errors.detail:"An error occurred when logging in. Please try again later.")
    }


  }

  async function socialLoginSuccessFacebook(res) {
    console.log("Facebook Login Success\n",res)
    //send Google token to iPacket backend
    const socialReturn = await socialLogin(res.accessToken, 'facebook')

    const socialReturnJSON = await socialReturn.json()
    console.log(await socialReturnJSON)
    // check login success
    if (socialReturn.status == 200) {
      // get user profile and token
      if(socialReturnJSON){
        console.log(socialReturnJSON)
        // save user profile to local storage
        localStorage.setItem('userProfile', JSON.stringify(socialReturnJSON))
        setFacebookLoading(false)
        //perform redirect
        setEmailValid(true)
        setPasswordValid(true)
      }


    } else {
      setFacebookLoading(false)
      toast(socialReturnJSON && socialReturnJSON.errors && socialReturnJSON.errors.detail?socialReturnJSON.errors.detail:"An error occurred when logging in. Please try again later.")
    }

  }

  function socialLoginFailure(res) {
    setGoogleLoading(false)
    console.log(res.json())
    console.log(`Failed to sign in to Google account: ${res}`)
    console.log(JSON.stringify(res))
  }


  function responseFacebook(res) {
    console.log(res)
  }

  return(
    <div className="signup-login-page-form">
      <div className="signup-login-page-form-heading">
        Welcome to your iPacket sign in.
      </div>
      <input className="signup-login-page-form-field" placeholder="Enter Email" value={emailField} type="email"  onChange={(e)=> setEmailField(e.target.value)} />
      <p className={emailValid == false && passwordValid == false ? "enter-valid-email" : "hidden"}>Invalid email or password</p>
      <PasswordInput setPasswordField={setPasswordField} formSubmit={formSubmit} placeholder="Enter Password" />
      <div className="signup-login-page-form-blurb signup-login-form-blurb-split">
        <label>
          Remember me
          <input
            type="checkbox"
            checked={rememberMe}
            onClick={()=>rememberMeCheck()}
            />
        </label>
      </div>
      <div className="signup-login-page-form-blurb signup-login-form-blurb-split">
        <Link to="/marketplace/password-reset"><span>Forgot your password?</span></Link>
      </div>
      {
        loading ?
        <div className='signup-login-page-button-loading-spinner'><LoadingSpinner loading /></div> :
        <button className={googleLoading === true || facebookLoading === true ? `signup-login-page-form-button disabled` : `signup-login-page-form-button`} type="image" alt="Click to log in" onClick={formSubmit} >
          LOG IN
        </button>
      }
      <hr/>
      <div className="signup-login-page-button-cta-text">Or Sign in with:</div>
      {/*
      <AppleLogin
        clientId='com.marketplace.client'
        redirectURI={"https://marketplace.autoipacket-stg.com/login/apple"}
        callback={socialLoginSuccessApple}
        render={renderProps => (
          <button onClick={renderProps.onClick} className="signup-login-page-form-button signup-login-page-form-button-apple">
            <AppleIcon/><div>Continue with Apple</div>
          </button>
        )}
      />
      */}
      <FacebookLogin
        appId="326118059332803"
        onClick={() => setFacebookLoading(true)}
        callback={socialLoginSuccessFacebook}
        render={renderProps => (
          <button onClick={renderProps.onClick}
            className={loading === true || googleLoading === true ? `signup-login-page-form-button signup-login-page-form-button-facebook disabled`: `signup-login-page-form-button signup-login-page-form-button-facebook`}>
            <FacebookIcon1/>
              {
                facebookLoading ?
                <div className='social-media-loader'></div> :
                <div>Continue with Facebook</div>
              }
          </button>
        )}
      />
      <GoogleLogin
        clientId={googleClientId}
        onSuccess={socialLoginSuccessGoogle}
        onFailure={socialLoginFailure}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <button onClick={(e) => { setGoogleLoading(true); renderProps.onClick(e)}} disabled={renderProps.disabled}
            className={loading === true || facebookLoading == true ?`signup-login-page-form-button signup-login-page-form-button-google disabled` : `signup-login-page-form-button signup-login-page-form-button-google`}>
            <GoogleGIcon/>
            {
              googleLoading ?
              <div className='social-media-loader'></div>:
              <div>Continue with Google</div>
            }
          </button>
        )}
      />
    </div>
  )
}
export default LoginForm
