import React from 'react'

function ModuleArrow(){
  return(
    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5448 5.97415C6.54006 5.76928 6.46033 5.57348 6.32102 5.42464L1.41044 0.252693C1.25934 0.0948587 1.05252 0.00403833 0.835302 0.000131108C0.618086 -0.00377611 0.4082 0.0795588 0.251648 0.231858C0.0950947 0.384158 0.00464778 0.593002 0.000133062 0.812615C-0.00438165 1.03223 0.077404 1.24468 0.227562 1.40342L4.59463 6.00001L0.227562 10.5966C0.0774032 10.7553 -0.00438252 10.9678 0.000132155 11.1874C0.00464683 11.407 0.0950938 11.6159 0.251647 11.7682C0.408199 11.9205 0.618085 12.0038 0.835301 11.9999C1.05252 11.996 1.25934 11.9051 1.41043 11.7473L6.32102 6.57537C6.39607 6.4953 6.45453 6.40086 6.49296 6.29761C6.53139 6.19436 6.54901 6.08438 6.5448 5.97415Z"/>
    </svg>
  )
}

export default ModuleArrow
