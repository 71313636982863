import React from 'react'
import {connect} from 'react-redux'
import './landingPageSearchCta.scss'
import LandingPageBackground from '../../../common/common-assets/images/landing-page-image.jpg'
import SearchInventoryFilterBox from '../../../common/common-components/searchInventoryFilterBox'
import SEARCH_PAGE_ACTIONS from '../search-page/state/searchPageActions'


function LandingPageSearchCta(props){
  const {clearSearchPage} = props
  return(
    <div className="landing-page-search-cta">
      <div className="search-cta-filter-box">
        <div className="search-cta-filter-box-text">
          <h1>
            Drive the truth.
          </h1>
          <h2>
            Take the guesswork out of car shopping. Start your search right here with iPacket verified car listings.
          </h2>
        </div>
        <div className="searchByArea">
          <SearchInventoryFilterBox
            searchButtonClassName="ga-marketplace-landing-page-search-button"
            onSearchAction={()=>{
              clearSearchPage(false)

            }}
          />
        </div>
      </div>
      <div className="search-cta-background-image" style={{backgroundImage:`url(${LandingPageBackground})`}}>
      </div>
      <div className="search-cta-bottom-text">
        <span>Know everything before you buy</span>
        <p>
          Choose from thousands of vehicles with exclusive data from iPacket.
        </p>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({})
const mapDispatchToProps= {
  ...SEARCH_PAGE_ACTIONS.dispatchActions,
}
export default connect(mapStateToProps,mapDispatchToProps)(LandingPageSearchCta)
