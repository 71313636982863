import parseURL from '../utils/parseURL'
import packageJson from '../../package.json'
import { store } from '../index'

import MarketplaceApiIntercepts from '../apps/marketplace-app/marketplaceApiIntercepts'

let baseUrl = "https://web-api.autoipacket.com/"

if(window.location.hostname !== 'marketplace.ipacket.us' && window.location.hostname !== 'www.marketplace.ipacket.us' && window.location.hostname !== 'ipacket.us' && window.location.hostname !== 'www.ipacket.us' && window.location.hostname !== 'replay.autoipacket.com' &&  window.location.hostname !== 'www.replay.autoipacket.com' && window.location.hostname !== 'ipacket.info' && window.location.hostname !== 'www.ipacket.info'){
  baseUrl = "https://djapi.autoipacket-stg.com/"
}

if (process.env.API_URL) {
  baseUrl = process.env.API_URL + '/'
}


const AIP_DJAPI_URLS = {
  BASE_URL: baseUrl,
  HEARTBEAT: `${baseUrl}heartbeat`,
  VEHICLE_PAGE: `${baseUrl}v1/vdp/vehicle`,
  V3_MIGRATED: `${baseUrl}v1/vdp/v3-migrated`,
  VEHICLE_MODULES: `${baseUrl}v1/vdp/modules`,
  VEHICLE_IMAGES: `${baseUrl}v1/vdp/vehicle/images`,
  SALESPERSON_INFO: `${baseUrl}v1/vdp/sales-person`,
  ZOOM_MEETING_REQUEST: `${baseUrl}v1/vdp/zoom-meeting-request`,
  CONFIRM_AVAILABILITY: `${baseUrl}v1/vdp/confirm-availability`,
  PAYMENT_CALCULATOR: `${baseUrl}v1/vdp/payment-calculator`,
  FLAG_ATTACHMENT: `${baseUrl}v1/vdp/flag-attachment/`,
  CONFIG: `${baseUrl}v1/vdp/configuration`,
  SKIN:`${baseUrl}v1/vdp/skin`,
  TRACKING:`${baseUrl}v1/vdp/tracking`,
  SEARCH_PAGE: `${baseUrl}v1/query/public/filter/vehicles`,
  LOGIN: `${baseUrl}v1/users/auth/consumer/login/`,
  SOCIAL_LOGIN: `${baseUrl}v1/users/auth/consumer/social-login/`,
  USERS: `${baseUrl}v1/users/`,
  MAKE_LIST:`${baseUrl}v1/make-list/`,
  MODEL_LIST: `${baseUrl}v1/model-list/`,
  UPDATE_USER_SETTINGS: `${baseUrl}v1/users/auth/consumer/update-user-info/`,
  CHANGE_PASSWORD: `${baseUrl}v1/users/auth/legacy/change-password/`,
  FORGOT_PASSWORD: `${baseUrl}v1/users/auth/legacy/reset-password/`,
  RESET_CONFIRM_PASSWORD: `${baseUrl}v1/users/auth/legacy/reset-password/confirm/`,
  GET_USER_PROFILE: `${baseUrl}v1/users/consumer-profile-detail/`,
  VEHICLE_INQUIRY: `${baseUrl}v1/users/vehicle-inquire/`,
  VEHICLE_SHARE: `${baseUrl}v1/packets/consumer/send`,
  PASSWORD_VALIDATION: `${baseUrl}/password-evaluation/`,
  CREATE_USER: `${baseUrl}v1/users/auth/consumer/create-user/`,
  STORE_SKIN: `${baseUrl}v1/vdp/skin`,
  STORE_CONFIGURATION: `${baseUrl}v2/vdp/configuration`,
  VDP_CONNECTION_START: `${baseUrl}v1/vdp/connection-start/`,
  VDP_CONNECTION_CLOSE: `${baseUrl}v1/vdp/connection-close/`,
  VDP_CONNECTION_KEEP_ALIVE: `${baseUrl}v1/vdp/connection-keep-alive/`,
  VDP_CONNECTION_ACTION: `${baseUrl}v1/vdp/connection-action/`,
  GET_ETS_INFO: `${baseUrl}v1/vdp/ets-info`,
  GET_ETS_CUSTOMER_INFO: `${baseUrl}v1/vdp/ets-customer-info`,
  GET_ETS_VEHICLE_INFO: `${baseUrl}v1/vdp/ets-vehicle-info`,
  GET_ETS_USER_INFO: `${baseUrl}v1/vdp/ets-user-info`,
  INITIATE_VDP_PRINT: `${baseUrl}v1/store/print/`,
  RETRIEVE_VDP_PRINT: `${baseUrl}v1/store/print/retrieve/`
}

// 'AIP-Application-Type': 4 VDP
// 'AIP-Application-Type': 5 Marketplace
const AIP_DJAPI_HEADERS ={
  'AIP-Application-Type': 4,
  'AIP-Application-Version': packageJson.version
}

export default function AIP_DJAPI(url,params,useToken=true){


  const { userProfile } = store.getState()


  if(params){
    if(params.headers){
      params.headers ={
        ...AIP_DJAPI_HEADERS,
        ...params.headers,
      }
    }
    else{
      params.headers ={
        ...AIP_DJAPI_HEADERS,
      }
    }
  }
  else{
    params = {
      headers:AIP_DJAPI_HEADERS
    }
  }

  if(useToken && userProfile && userProfile.token){
    params.headers={
      ...params.headers,
      Authorization: `Token ${userProfile.token}`
    }
  }

  return fetch(url,params).then((response)=>{
    try{
      MarketplaceApiIntercepts(response)
    }
    catch(e){console.log(e)}
    return response
  })
}

export {AIP_DJAPI_URLS,AIP_DJAPI_HEADERS}
