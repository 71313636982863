import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import validateEmail from "../../../../../utils/validateEmail";
import { forgotPassword } from '../../../marketplaceAppUtils';
import LoadingSpinner from '../../../../common/common-components/loadingSpinner'

import "./passwordReset.scss";

function PasswordResetForm() {
  let history = useHistory();

  const [emailField, setEmailField] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [forgotPasswordResponse, setForgotPasswordResponse] = useState()
  const [loading, setLoading] = useState(false)

  function formSubmit() {
    if (validateEmail(emailField)) {
      setEmailValid(true);
      setLoading(true)
      forgotPassword(emailField).then((response) => {
        setLoading(false)
        if (response.status == 200) {
          setForgotPasswordResponse(200)
          toast("Check your email to reset your password.");
          setTimeout(redirectToLogin, 2000);
        } else if(response.status == 400){
          setForgotPasswordResponse(400)
          toast("Email not associated with an account.")
        } else {
          setForgotPasswordResponse('error')
          toast("An error has occurred, please try again later.")
        }
      });
    } else {
      setEmailValid(false);
    }
  }

  function redirectToLogin() {
    history.push("/marketplace/login");
  }

  return (
    <div className="password-reset-page-container">
      <div className="password-reset-page">
        <div className="password-reset-form-container">
          <div className="password-reset-page-form">
            <div className="password-reset-page-form-heading">
              Password Reset
            </div>
            {loading && <LoadingSpinner loading />}
            <input
              className="password-reset-page-form-field"
              type="email"
              value={emailField}
              placeholder="Enter Email"
              onChange={(e) => setEmailField(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  formSubmit();
                }
              }}
            />
            <div className="password-reset-page-form-blurb">
              <span className={emailValid ? "hidden" : "enter-valid-email"}>
                Please enter a valid email address.
              </span>
            </div>
            {forgotPasswordResponse == 200 ? (
              <span className="success-message">Check your email to reset your password.</span>
            ) : (
            <button
            className="password-reset-page-form-button"
            type="image"
            alt="Click to submit"
            onClick={() => formSubmit()}
          >
            Submit
          </button>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetForm;
