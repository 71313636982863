import React, {useState, useEffect} from 'react'
import './signupLoginPage.scss'
import LoginForm from './loginForm.js'
import SignupForm from './signupForm.js'

function SignupLoginPage(props){
  const [showLogin, setShowLogin] = useState(props.login)
  useEffect(()=>{
    setShowLogin(props.login)
  },[props.login])
  const showForm = ()=>{
    if(showLogin){
      return(
        <LoginForm/>
      )
    }
    return(
      <SignupForm/>
    )
  }
  return(
    <div className="signup-login-page-container">
      <div className="signup-login-page">
        <div className="signup-login-page-toggle">
          <div className="signup-login-page-toggle-btn-container">
            <button className={`signup-login-page-toggle-btn ${showLogin? "" : "signup-login-page-toggle-btn-selected"}`} type="image" alt="Click to sign up" onClick={()=>{
              setShowLogin(false)
            }}>
              Sign Up
            </button>
          </div>
          <div className="vr"/>
          <div className="signup-login-page-toggle-btn-container">
            <button className={`signup-login-page-toggle-btn ${showLogin? "signup-login-page-toggle-btn-selected" : ""}`} type="image" alt="Click to log in" onClick={()=>{
              setShowLogin(true)
            }}>
              Log In
            </button>
          </div>
        </div>
        {showForm()}
      </div>
    </div>
  )
}
export default SignupLoginPage;
