import searchPage from '../pages/search-page/state/searchPageReducer'
import modal from '../modals/state/modalReducer'
import savedVehiclePage from '../pages/saved-vehicle-page/state/savedVehiclePageReducer'
import userProfile from './userProfileReducer'
import marketplaceApp from './marketplaceAppReducer'

const rootMarketplaceAppReducer = {
  searchPage,
  modal,
  savedVehiclePage,
  userProfile,
  marketplaceApp,
}

export default rootMarketplaceAppReducer
