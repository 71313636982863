const MODAL_CONSTANTS = {
  //Modal Type Constants
  CONTACT_MODAL: "CONTACT_MODAL",
  ASK_QUESTION: "ASK_QUESTION",
  SHARE_MODAL: "SHARE",
  BUY_NOW: 'BUY_NOW',
  CAR_BRANDS_MODAL: "CAR_BRANDS_MODAL",
  LIGHTBOX: 'LIGHTBOX',
  MODULE_MODAL: 'MODULE_MODAL',
  VIDEO_MODAL: 'VIDEO_MODAL',
  
  //Reducer Constants
  PUSH_MODAL:"PUSH_MODAL",
  POP_MODAL: "POP_MODAL",
  CLOSE_MODAL :"CLOSE_MODAL",
}
export default MODAL_CONSTANTS
