import React from 'react'
import './styles/moduleHtmlUrl.scss'

function ModuleHtmlUrl(props){
  let{module} = props
  return (
    <div className="module-html-url">
      Module Html Url
    </div>
  )
}
export default ModuleHtmlUrl
