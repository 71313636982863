import React from "react";

function HondaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2500"
      height="2500"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path
          fill="#c23c40"
          d="M33.897 139.551l2.465.26c1.168.131 1.168 1.039 1.168 1.688v2.984H26.111v-2.984c0-.648.13-1.557 1.298-1.688l2.465-.26v-2.465H15.082v2.465l2.466.26c1.038.131 1.167 1.039 1.167 1.688v9.602c0 .65-.129 1.559-1.167 1.688l-2.466.26v2.465h14.793v-2.465l-2.465-.26c-1.168-.129-1.298-1.037-1.298-1.688v-2.854h11.419v2.854c0 .65 0 1.559-1.168 1.688l-2.465.26v2.465H48.69v-2.465l-2.465-.26c-1.038-.129-1.168-1.037-1.168-1.688v-9.602c0-.648.13-1.557 1.168-1.688l2.465-.26v-2.465H33.897v2.465zM132.906 137.086h-18.297v2.465l2.207.26c.777.131 1.297.391 1.297 1.559v9.73c0 .65-.129 1.559-1.297 1.688l-2.467.26v2.465h18.557c6.617 0 12.068-2.594 12.068-9.213.001-6.618-5.451-9.214-12.068-9.214zm-1.297 14.922h-6.229V140.59h6.229c3.633 0 4.801 1.688 4.801 5.709 0 4.023-1.168 5.709-4.801 5.709z"
        />
        <path
          fill="#c23c40"
          d="M176.248 152.787c-1.168-.129-1.559-.908-1.947-1.428l-8.564-13.105c-.648-.908-1.039-1.168-1.947-1.168h-14.014v2.465l3.244.391c.52 0 1.168.648.648 1.557l-6.357 10.252c-.26.389-.52.908-1.299 1.037l-2.465.26v2.465h11.809v-2.465l-1.947-.129c-.908-.131-1.428-.779-.908-1.428l.908-1.559h11.42l.779 1.299c.777 1.037-.131 1.557-1.039 1.557l-2.076.26v2.465h16.09v-2.465l-2.335-.261zm-20.893-6.228l3.504-5.58 3.762 5.58h-7.266zM101.244 139.551l2.596.26c1.037.131 1.168 1.039 1.168 1.688v9.213l-9.733-12.846c-.519-.65-.908-.779-1.946-.779H80.612v2.465l2.465.26c1.168.131 1.298 1.039 1.298 1.688v9.602c0 .65-.13 1.559-1.298 1.688l-2.465.26v2.465H92.42v-2.465l-2.466-.26c-1.038-.129-1.168-1.037-1.168-1.688v-9.213l9.733 12.848c.52.648.908.777 1.945.777h8.955V141.5c0-.648.129-1.557 1.297-1.688l2.465-.26v-2.465h-11.938v2.464h.001zM64.651 136.438c-12.068 0-15.182 5.32-15.182 9.861 0 4.672 3.114 9.863 15.182 9.863s15.183-5.191 15.183-9.863c-.001-4.541-3.115-9.861-15.183-9.861zm0 16.22c-4.671 0-6.618-1.947-6.618-6.359s1.946-6.357 6.618-6.357c4.672 0 6.618 1.945 6.618 6.357s-1.946 6.359-6.618 6.359zM58.163 133.582l-8.954-8.305 45.158-5.189 21.801 13.494H58.163zM134.203 98.156l-94.986 17.779 8.175 7.654 43.86-5.449c-.779-.389-.908-.779-.908-1.297 0-.52.389-1.039 1.167-1.168.39 0 24.266-3.504 25.953-3.764.777-.131 2.725-.52 4.801-1.557 2.076-.908 3.373-1.947 4.152-2.727.648-.518 2.465-2.465 3.764-3.893a26.973 26.973 0 0 0 2.076-2.336c.52-.648 1.688-2.076 2.205-2.725.132-.257.001-.517-.259-.517zM149.127 77.395L30.394 107.889l7.137 6.619 36.593-7.008c-.648-.389-1.038-.648-1.038-1.297a1.2 1.2 0 0 1 1.038-1.168l59.431-11.549c.779-.13 2.855-.52 4.801-1.817 1.947-1.168 2.986-2.465 3.635-3.115.777-.908 2.205-2.725 3.244-4.022.777-1.038 1.428-2.076 1.945-2.854.52-.778 1.688-2.855 2.336-3.763.13-.261-.129-.65-.389-.52z"
        />
        <path
          fill="#c23c40"
          d="M133.426 42.878c1.557-.649 9.473-4.023 11.158-4.932 1.818-.778 7.008-3.114 8.955-4.022 2.205-.908 8.434-3.893 9.342-4.282 4.412-2.076 9.863-4.671 10.9-5.32.26-.13.779.13.648.519a91.178 91.178 0 0 1-3.244 8.824c-.777 1.817-1.945 4.412-2.984 6.099-1.297 2.077-2.594 3.503-3.633 4.412-2.205 2.077-4.023 3.115-5.969 3.894-1.168.389-3.633 1.427-5.32 2.076-.389.13-7.008 2.595-8.436 2.984L27.798 94.524c-2.725.908-5.839 2.724-5.839 6.228 0 1.559.909 3.635 2.725 5.32l29.586 27.51H40.515c-.259-.777-.649-1.945-1.168-2.725s-.778-1.557-2.336-2.984c-1.167-1.299-2.335-2.465-2.725-2.855l-15.572-15.57c-2.984-2.855-4.542-6.619-4.542-9.992 0-4.672 2.595-9.213 10.381-12.328l108.873-44.25z"
        />
        <path
          fill="#c23c40"
          d="M146.271 58.579c1.557-.519 4.543-1.557 5.32-1.816.779-.259 3.893-1.427 4.412-1.557 2.076-.908 4.152-1.687 6.359-2.595.258-.13.648.13.518.519-.26.649-1.686 3.503-2.076 4.152-.908 1.817-1.816 3.504-2.725 4.931-.908 1.428-2.076 3.374-2.854 4.283-1.299 1.557-2.467 2.595-4.283 3.893-2.336 1.557-4.412 2.076-5.32 2.466L59.331 95.951c-.648.129-.908.908-.908 1.168 0 .52.389 1.037.908 1.297l-30.624 7.916-2.465-2.336c-.908-.777-1.298-1.945-1.298-2.854 0-2.207 1.687-3.244 4.413-4.152l116.914-38.411z"
        />
      </g>
    </svg>
  );
}
export default HondaIcon;
